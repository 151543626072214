import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import Auth from 'helpers/auth';
import UserRoles from 'helpers/userRoles';
import { Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { externalRedirectToSsoLogin } from 'helpers/singleSignOn';
import { getRedirectTo } from 'utils/history';

const PermissionDeniedWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '70vh',
  padding: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(2),
  },
}));

const ButtonsWrapper = styled('div')(({ theme }) => ({
  color: theme.primary,
  '&>a': {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    },
  },
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  textAlign: 'center',
}));

const PermissionDenied = () => {
  const user = Auth.getUser();

  const homePage = UserRoles.getDefaultRedirectTo({
    user,
  });

  const onLogoutClick = () => {
    Auth.logout({});
    const redirectToPathname = getRedirectTo(); // Pass redirectTo query param to sso. Not path with query param /forbidden?redirectTo=redirectTo

    // decodeURIComponent here to avoid double encode
    // firstly in getRedirectTo and then in externalRedirectToSsoLogin
    const callbackRedirectTo = `${window.location.origin}${decodeURIComponent(
      redirectToPathname,
    )}`;
    externalRedirectToSsoLogin(callbackRedirectTo);
  };

  return (
    <PermissionDeniedWrapper>
      <StyledTypography variant="h4">ACCESS RESTRICTED!</StyledTypography>

      <StyledTypography variant="subtitle1">
        You do not have sufficient permissions to access the requested page.
        <br />
        Please contact your Property Manager or Support for further assistance.
      </StyledTypography>

      <ButtonsWrapper>
        <Button variant="contained" component={Link} to={homePage}>
          Go Back To Home Page
        </Button>
        <Button variant="outlined" onClick={onLogoutClick}>
          Return To Login
        </Button>
      </ButtonsWrapper>
    </PermissionDeniedWrapper>
  );
};

export default memo(PermissionDenied);
