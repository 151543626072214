import { buildS3TeamImageUrl } from 'helpers/image';
import { decodeHtmlEntities } from 'helpers/general';

// ------------------------------------
export const convertUser = (pm) => {
  if (!pm) {
    return null;
  }

  return {
    id: pm.Id,
    name: decodeHtmlEntities(pm.Name),
    email: pm.Email || '',
    phone: pm.Phone || '',
    title: pm.Title || '',
    mobilePhone: pm.MobilePhone || '',
    managedPropertyCap: pm.Managed_Property_Cap__c || 10,
    image: buildS3TeamImageUrl(pm.Id),
    queuePhoneNumber: pm.Queue_Phone_Number__c,
    seniorPropertyManager: convertUser(pm.Senior_Property_Manager__r),
    remoteTeamMember: convertUser(pm.Remote_Team_Member__r),
    assistant: convertUser(pm.Assistant__r),
    isActive: !!pm.IsActive,
  };
};
