import { useMemo } from 'react';
import Auth from 'helpers/auth';
import sortBy from 'lodash/sortBy';
import { forDropdown, constants } from 'enums/reportType.enum';

const useReportTypes = () =>
  useMemo(() => {
    const user = Auth.getUser();

    const reportTypes = forDropdown.reduce((accum, option) => {
      if (
        option.value === constants.SIMPLE_SIGNUP &&
        user?.permissions?.includes('Visual_Manager__c:Simple Signup')
      ) {
        accum.push(option);
      } else if (
        option.value === constants.POST_OFFICE &&
        user?.permissions?.includes('Process_Automation__c')
      ) {
        accum.push(option);
      } else if (
        option.value === constants.PRICE_BOOK &&
        (user?.permissions?.includes('View_Pricebooks__c') ||
          user?.permissions?.includes('Edit_Pricebooks__c'))
      ) {
        accum.push(option);
      } else if (
        [
          constants.ACTIVE_PROPERTY_LISTINGS,
          constants.RENTAL_LISTING_SYNDICATION,
        ].includes(option.value) &&
        user?.permissions?.includes(
          'Visual_Manager__c:Property Listing Reports',
        )
      ) {
        accum.push(option);
      } else if (
        [
          constants.ACCOUNTS_RECEIVABLE_ONQ,
          constants.ACCOUNTS_RECEIVABLE_MAMMOTH,
          constants.INSPECTION_ACCOUNTS_RECEIVABLE,
        ].includes(option.value) &&
        user?.permissions?.includes('Accounts_Receivable__c')
      ) {
        accum.push(option);
      } else if (
        [
          constants.ACCOUNTS_PAYABLE_ONQ,
          constants.ACCOUNTS_PAYABLE_MAMMOTH,
          constants.COMPANY_CARD_RECONCILIATION,
        ].includes(option.value) &&
        user?.permissions?.includes('Accounts_Payable__c')
      ) {
        accum.push(option);
      } else if (
        user?.permissions?.includes(
          'Visual_Manager__c:Property Manager Reports',
        )
      ) {
        accum.push(option);
      } else if (
        option.value === constants.FASTFIELDS_INSPECTIONS &&
        user?.permissions?.includes('Inspection_Processing__c')
      ) {
        accum.push(option);
      } else if (
        option.value === constants.WORK_ORDERS &&
        user?.permissions?.includes('View_Work_Orders__c')
      ) {
        accum.push(option);
      }

      return accum;
    }, []);

    return sortBy(reportTypes, ['group']);
  }, []);

export default useReportTypes;
