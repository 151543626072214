class RequestMemoWrapper {
  constructor(getPromise) {
    this.cache = {};
    this.getPromise = getPromise;
    this.makeRequest = this.makeRequest.bind(this);
  }

  makeRequest({ uniqueKey, url, method, options }) {
    if (!uniqueKey) {
      return Promise.reject(new Error('Unique key not passed'));
    }

    // skip caching GET requests
    if (method === 'GET') {
      // return this.getPromise(url, options);
    }

    // skip rental-property-onboarding form requests
    if (
      uniqueKey.includes('rental-property-onboarding') ||
      uniqueKey.includes('feedback')
    ) {
      return this.getPromise(url, options);
    }

    if (this.cache[uniqueKey]) {
      // eslint-disable-next-line no-console
      console.error(`Duplicated request ${uniqueKey}`);
      return this.cache[uniqueKey];
    }

    const promise = this.getPromise(url, options);
    this.cache[uniqueKey] = promise.finally(() => {
      delete this.cache[uniqueKey];
    });

    return this.cache[uniqueKey];
  }
}

export default RequestMemoWrapper;
