import { formatDate, formatPhoneNumber } from 'helpers/format';
import { decodeHtmlEntities } from 'helpers/general';

// ------------------------------------
export const convertContact = (contact) => {
  if (!contact) {
    return null;
  }
  return {
    id: contact.Id,
    name: decodeHtmlEntities(contact.Name),
    firstName: decodeHtmlEntities(contact.FirstName),
    lastName: decodeHtmlEntities(contact.LastName),
    title: decodeHtmlEntities(contact.Title),
    middleName: decodeHtmlEntities(contact.MiddleName),
    email: contact.Email || '',
    phone: formatPhoneNumber(contact.Phone || ''),
    mobilePhone: formatPhoneNumber(contact.MobilePhone || ''),
    isPortalAccessEnabled: !!contact.Cloud_Portal_Access__c,
    lastLoginDate: formatDate(
      contact.Cloud_Portal_Last_Login_Date__c,
      'MM/DD/YYYY HH:mm:ss',
    ),
    verificationCode: contact.Cloud_Portal_Temp_Password__c || '',
    failedAttempts: contact.Cloud_Portal_Failed_Login_Attempts__c || 0,
    pastMonths: contact.Cloud_Portal_Past_Months__c || 0,
  };
};

// ------------------------------------
export const convertAccountContactRelation = (relation) => {
  if (!relation) {
    return null;
  }
  return {
    id: relation.Id,
    type: relation.Type__c || '',
    ownershipPercent: relation.Percentage_of_Ownership__c,
    contact: convertContact(relation.Contact),
  };
};

// ------------------------------------
export const convertAccountContactRelations = (acrs = []) =>
  acrs.map((acr) => convertAccountContactRelation(acr));

// ------------------------------------
export const convertRecordType = (recordType) => {
  if (!recordType) {
    return null;
  }
  return {
    id: recordType.Id,
    name: decodeHtmlEntities(recordType.Name),
  };
};
