import React from 'react';
import { styled } from '@mui/system';
import { ListItemButton } from '@mui/material';

const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  '&.Mui-selected': {
    textDecoration: 'underline #D9B53C ',
    textDecorationColor: '#D9B53C',
    textUnderlineOffset: '2px',
    textDecorationThickness: '2px',
    ...(theme.palette.mode === 'dark' && {
      backgroundColor: theme.palette.grey[800],
    }),
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: '#D9B53C',
      textUnderlineOffset: '2px',
      textDecorationThickness: '2px',
    },
  },
}));

export default StyledListItem;
