import set from 'lodash/set';

export default function parseValidation(errors) {
  let result = null;
  if (Array.isArray(errors) && errors.length > 0) {
    const reason = errors.reduce((memo, err) => {
      const path = err?.field.join('.');
      set(memo, path, err.messages[0]);
      return memo;
    }, {});
    const fieldsWithError = errors.reduce((memo, err) => {
      const path = err?.field.join('.');
      set(memo, path, true);
      return memo;
    }, {});
    result = {
      hasError: fieldsWithError,
      help: reason,
    };
  }
  return result;
}
