import React, { lazy, Suspense } from 'react';

const loadable = (importFunc, options) => {
  const { fallback = null } = options || {};
  const LazyComponent = lazy(importFunc);
  return function wrappedSuspense(props) {
    return (
      <Suspense fallback={fallback}>
        <LazyComponent {...props} />
      </Suspense>
    );
  };
};

export default loadable;
