import {
  SEARCH_RECORDS,
  SEARCH_RECORDS_SUCCESS,
  SEARCH_RECORDS_ERROR,
  CLEAR_SEARCH_RECORDS,
} from './constants';

export function searchRecords(data) {
  return {
    type: SEARCH_RECORDS,
    ...data,
  };
}

export function searchRecordsSuccess(searchResults) {
  return {
    type: SEARCH_RECORDS_SUCCESS,
    searchResults,
  };
}
export function searchRecordsError(error) {
  return {
    type: SEARCH_RECORDS_ERROR,
    error,
  };
}
export function clearSearchRecords() {
  return {
    type: CLEAR_SEARCH_RECORDS,
  };
}
