export default {
  INSPECTIONS: 'inspections',
  WORK_ORDERS: 'work-orders',
  OPPORTUNITIES: 'opportunities',
  LEADS: 'leads',
  VENDORS: 'vendors',
  PROPERTIES: 'properties',
  ACCOUNTS: 'accounts',
  CONTRACTS: 'contracts',
  LISTINGS: 'property-listings',
  LOCKERS: 'lockers',
  INVOICES: 'invoices',
  JOBS: 'jobs',
  APPLICATIONS: 'applications',
  USERS: 'users',
  APPOINTMENTS: 'appointments',
};
