import React from 'react';
import Moize from 'moize';
import { Box, Link, Typography } from '@mui/material';
import { string, oneOfType, number, func, node } from 'prop-types';
import SalesforceCloudLink from 'components/SalesforceCloudLink';

const ClickCell = ({
  text = '',
  href = '',
  salesforceId = '',
  children,
  onClick,
}) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    {!!salesforceId && (
      <span style={{ marginRight: 8 }}>
        <SalesforceCloudLink id={salesforceId} />
      </span>
    )}
    {href && onClick ? (
      <Link
        href={href}
        onClick={(e) => {
          e.preventDefault();
          onClick(href);
        }}
      >
        {text}
      </Link>
    ) : (
      <Typography variant="body2">{text}</Typography>
    )}
    {children}
  </Box>
);

ClickCell.propTypes = {
  href: string,
  salesforceId: string,
  text: oneOfType([string, number]),
  onClick: func,
  children: node,
};

export default Moize.react(ClickCell);
