import { isFailedFetch } from 'helpers/general';
import { CLEAR_MESSAGES } from 'containers/App/constants';
import {
  SEARCH_RECORDS,
  SEARCH_RECORDS_SUCCESS,
  SEARCH_RECORDS_ERROR,
  CLEAR_SEARCH_RECORDS,
} from './constants';

export const initialState = {
  isSearching: false,
  searchResults: [],
  searchIn: '',
  error: '',
};

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CLEAR_SEARCH_RECORDS: {
      return {
        ...state,
        searchResults: [],
        searchIn: '',
      };
    }
    case SEARCH_RECORDS: {
      return {
        ...state,
        isSearching: true,
        searchResults: [],
        searchIn: action?.searchIn,
        error: '',
      };
    }
    case SEARCH_RECORDS_SUCCESS: {
      return {
        ...state,
        isSearching: false,
        searchResults: action?.searchResults,
      };
    }

    case SEARCH_RECORDS_ERROR: {
      return {
        ...state,
        isSearching: false,
        isLoading: false,
        isFailedFetch: isFailedFetch(action.error),
        error: action?.error?.responseBody?.message || action?.error?.message,
      };
    }

    case CLEAR_MESSAGES: {
      return {
        ...state,
        error: '',
      };
    }

    default:
      return state;
  }
}

export default reducer;
