import React from 'react';
import { Tabs } from '@mui/material/';
import { styled } from '@mui/system';

const VerticalTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-flexContainer': {
    padding: theme.spacing(2, 0),
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

export default VerticalTabs;
