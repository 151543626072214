import Company from 'helpers/company';
import Download from 'js-file-download';
import NoImage from 'images/no-image.jpg';
import { formatDate } from 'helpers/format';
import { getFileExtension, makeStringArray } from 'helpers/general';
import companyId from 'enums/companyId';

const domainCompanyId = Company.getIdByDomain();

export const supportWebp = () => {
  try {
    const elem = document.createElement('canvas');
    if (elem.getContext && elem.getContext('2d')) {
      // was able or not to get WebP representation
      return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }
  } catch (err) {
    // something happened
  }
  // very old browser like IE 8, canvas not supported
  return false;
};

const getFileNameFromUrl = (url) => {
  if (!url) {
    return url;
  }
  const [noQueryParams] = url.split('?');
  const urlArray = noQueryParams.split('/');
  return urlArray.pop();
};

/* eslint-disable indent */
export const onError =
  (defaultImage = NoImage) =>
  (e) => {
    if (e.target.src !== defaultImage) {
      e.target.src = defaultImage;
    } else {
      e.target.onerror = null;
      e.target.src = NoImage;
    }
  };
/* eslint-enable */

export const isBrowserSupportedImage = (url) => {
  const ext = getFileExtension(url);
  // supported image types
  return [
    'jpg',
    'jpeg',
    'jpe',
    'jif',
    'jfif',
    'jfi',
    'gif',
    'bmp',
    'png',
    'svg',
    'webp',
  ].includes(ext);
};

export const buildS3ImageUrl = (rawImage = '') => {
  if (!rawImage) {
    return rawImage;
  }
  const [bucket, key] = rawImage.split(/__(.*)/);
  let region = 'us-west-2';
  if (bucket === 'salesforce-file-storage') {
    region = 'us-east-1';
  }
  return `https://${bucket}.s3.${region}.amazonaws.com/${key}`;
};

export const buildS3TeamImageUrl = (propertyManagerId) => {
  if (!propertyManagerId) {
    return propertyManagerId;
  }
  const lowercasePropertyManagerId = (propertyManagerId || '').toLowerCase();
  return `https://onqpm-team-photos.s3.us-west-2.amazonaws.com/${lowercasePropertyManagerId}.jpg`;
};

export const buildPreviewUrl = ({ size, version, id }) => {
  const format = supportWebp() ? 'webp' : 'jpeg';
  const v = version ? `?version=${version}` : '';
  return `${process.env.FILE_SERVER_DOMAIN}/v2/preview/${id}/${size}x90.${format}${v}`;
};

export const buildWatermarkUrl = ({ id }) =>
  `${process.env.FILE_SERVER_DOMAIN}/v2/watermark/${id}/${domainCompanyId}/1200x1200x90.jpeg`;

export const isImageFile = (linkOrName) => {
  const ext = getFileExtension(linkOrName);
  return ['jpg', 'jpeg', 'png', 'gif'].includes(ext);
};

export const isVideoFile = (linkOrName) => {
  const ext = getFileExtension(linkOrName);
  return ['mp4'].includes(ext);
};

export const downloadFile = ({ url, fileName = '' }) =>
  new Promise((resolve, reject) => {
    let fname = fileName;
    if (!fname) {
      fname = getFileNameFromUrl(url);
    }
    // make a request
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        // force file save
        Download(xhr.response, fname);
        return resolve();
      }
      // error
      return reject(new Error(xhr.statusText));
    };
    xhr.send();
  });

// --------------------- V2 ---------------------------------
export const convertToImageObjectV2 = (img) => {
  const dateCreated = img.createdAt
    ? formatDate(img.createdAt, 'MM/DD/YYYY @ h:mma')
    : '';

  const version = new Date(img.updatedAt || img.createdAt).getTime();
  return {
    id: img.id,
    url: img.url,
    // TODO: honnor watermarked images
    large: buildPreviewUrl({
      id: img.id,
      size: '1200x1200',
      version,
    }),
    small: buildPreviewUrl({
      id: img.id,
      size: '600x600',
      version,
    }),
    thumbnail: buildPreviewUrl({
      id: img.id,
      size: '200x200',
      version,
    }),
    title: img.tags || img.id,
    dateCreated,
    source: img.source,
  };
};

export const findAndGroupWorkOrderImages = ({
  images = [],
  lineItemId,
  isSkipConversion = false,
}) =>
  images.reduce(
    (memo, img) => {
      // filter only line item images
      if (img.sobjectId === lineItemId) {
        const tags = makeStringArray(img.tags || '');
        if (tags.includes('After')) {
          memo.after.push(isSkipConversion ? img : convertToImageObjectV2(img));
        } else {
          memo.before.push(
            isSkipConversion ? img : convertToImageObjectV2(img),
          );
        }
      }
      return memo;
    },
    {
      before: [],
      after: [],
    },
  );

export const findAndGroupInspectionImages = ({ images = [], lineItemId }) =>
  images.reduce((memo, img) => {
    // filter only line item images
    if (img.sobjectId === lineItemId) {
      memo.push(convertToImageObjectV2(img));
    }
    return memo;
  }, []);

// -----------------------------------------
export const resizeFileManagerImage = ({ url, size }) => {
  const format = supportWebp() ? 'webp' : 'jpeg';
  return `${url.replace('1200x1200x90', size).replace('jpeg', format)}`;
};

export const convertWatermarkedToPreviewUrl = (url = '') => {
  if (!url) {
    return '';
  }
  // Watermarked - 'https://file-server-cdn.onqpm.com/v2/watermark/cf6367ff-94a1-4ec4-897f-cc4297e96edd/onqpm/600x600x90.webp?version=1718225237258'
  // Preview     - 'https://file-server-cdn.onqpm.com/v2/preview/cf6367ff-94a1-4ec4-897f-cc4297e96edd/600x600x90.webp?version=1718225237258'
  return `${url.replace('/watermark/', '/preview/').replace(`/${companyId.ONQ}/`, '/').replace(`/${companyId.MAMMOTH}/`, '/')}`;
};
