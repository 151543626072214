import { cloneDeep, set } from 'lodash';
import parseValidation from 'helpers/parseValidation';
import Company from 'helpers/company';
import CompanyId from 'enums/companyId';
import { isFailedFetch } from 'helpers/general';
import defaultCompanyInfoMammoth from 'companyInfo/mammoth_services.json'; // TODO webpack-to-vite
import defaultCompanyInfoOnqpm from 'companyInfo/onqpm.json'; // TODO webpack-to-vite
import {
  LOAD_WORK_ORDER,
  LOAD_WORK_ORDER_SUCCESS,
  LOAD_WORK_ORDER_ERROR,
  LOAD_COMPANY_INFO,
  LOAD_COMPANY_INFO_SUCCESS,
  LOAD_COMPANY_INFO_ERROR,
  LOAD_COMPANIES,
  LOAD_COMPANIES_SUCCESS,
  LOAD_COMPANIES_ERROR,
  SUBMIT_COMMENT,
  SUBMIT_COMMENT_SUCCESS,
  SUBMIT_COMMENT_ERROR,
  CLEAR_MESSAGES,
  LOAD_USERS,
  LOAD_USERS_ERROR,
  LOAD_USERS_SUCCESS,
  LOAD_TECHNICIANS,
  LOAD_TECHNICIANS_SUCCESS,
  LOAD_TECHNICIANS_ERROR,
  UPDATE_TASK,
  UPDATE_TASK_ERROR,
  UPDATE_TASK_SUCCESS,
  CREATE_TASK,
  CREATE_TASK_SUCCESS,
  CREATE_TASK_ERROR,
  SEND_EMAIL,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_ERROR,
  LOG_NEW_ACTIVITY,
  CANCEL_NEW_ACTIVITY,
  LOAD_SOBJECT,
  LOAD_SOBJECT_SUCCESS,
  LOAD_SOBJECT_ERROR,
  LOAD_PICK_LISTS,
  LOAD_PICK_LISTS_ERROR,
  LOAD_PICK_LISTS_SUCCESS,
  LOAD_RECORD_TYPES,
  LOAD_RECORD_TYPES_ERROR,
  LOAD_RECORD_TYPES_SUCCESS,
  SEARCH_RECORDS,
  SEARCH_RECORDS_SUCCESS,
  SEARCH_RECORDS_ERROR,
  CLEAR_SEARCH_RECORDS,
  DELETE_SOBJECT_FILE,
  DELETE_SOBJECT_FILE_SUCCESS,
  DELETE_SOBJECT_FILE_ERROR,
  RENAME_SOBJECT_FILE,
  RENAME_SOBJECT_FILE_SUCCESS,
  RENAME_SOBJECT_FILE_ERROR,
  CLEAR_SOBJET,
  LOAD_JOB,
  LOAD_JOB_ERROR,
  LOAD_JOB_SUCCESS,
  SCHEDULE_APPOINTMENT,
  SCHEDULE_APPOINTMENT_SUCCESS,
  SCHEDULE_APPOINTMENT_ERROR,
  UPDATE_APPOINTMENT,
  UPDATE_APPOINTMENT_SUCCESS,
  UPDATE_APPOINTMENT_ERROR,
  LOAD_JOBS,
  LOAD_JOBS_SUCCESS,
  LOAD_JOBS_ERROR,
  CLEAR_JOBS,
  UPDATE_APPOINTMENT_STATUS,
  UPDATE_APPOINTMENT_STATUS_SUCCESS,
  UPDATE_APPOINTMENT_STATUS_ERROR,
  LOAD_EVENTS,
  LOAD_EVENTS_SUCCESS,
  LOAD_EVENTS_ERROR,
  CLEAR_DATES,
  CLEAR_FLAGS,
  LOAD_SMS_MESSAGES,
  LOAD_SMS_MESSAGES_SUCCESS,
  LOAD_SMS_MESSAGES_ERROR,
  SEND_SMS,
  SEND_SMS_SUCCESS,
  SEND_SMS_ERROR,
  LOAD_SMS_TEMPLATES,
  LOAD_SMS_TEMPLATES_SUCCESS,
  LOAD_SMS_TEMPLATES_ERROR,
  UPDATE_SMS_MESSAGE,
  UPDATE_SMS_MESSAGE_SUCCESS,
  UPDATE_SMS_MESSAGE_ERROR,
  UPDATE_JOB,
  UPDATE_JOB_SUCCESS,
  UPDATE_JOB_ERROR,
  LOAD_MAINTENANCE_GROUPS,
  LOAD_MAINTENANCE_GROUPS_SUCCESS,
  LOAD_MAINTENANCE_GROUPS_ERROR,
  UPDATE_INSPECTION_STATUS,
  UPDATE_INSPECTION_STATUS_SUCCESS,
  UPDATE_INSPECTION_STATUS_ERROR,
  UPDATE_PROPERTY_ROUTINE_INSPECTIONS,
  UPDATE_PROPERTY_ROUTINE_INSPECTIONS_SUCCESS,
  UPDATE_PROPERTY_ROUTINE_INSPECTIONS_ERROR,
  CLEAR_COMMUNICATION_DATA,
  LOAD_COMMUNICATION_DATA,
  LOAD_COMMUNICATION_DATA_SUCCESS,
  LOAD_COMMUNICATION_DATA_ERROR,
  SAVE_INSPECTION,
  SAVE_INSPECTION_SUCCESS,
  SAVE_INSPECTION_ERROR,
  SAVE_PROPERTY,
  SAVE_PROPERTY_SUCCESS,
  SAVE_PROPERTY_ERROR,
  SAVE_WORK_ORDER,
  SAVE_WORK_ORDER_SUCCESS,
  SAVE_WORK_ORDER_ERROR,
  SAVE_OPPORTUNITY,
  SAVE_OPPORTUNITY_SUCCESS,
  SAVE_OPPORTUNITY_ERROR,
  SAVE_LEAD,
  SAVE_LEAD_SUCCESS,
  SAVE_LEAD_ERROR,
  DELETE_LEAD,
  DELETE_LEAD_SUCCESS,
  DELETE_LEAD_ERROR,
  SAVE_CONTRACT,
  SAVE_CONTRACT_SUCCESS,
  SAVE_CONTRACT_ERROR,
  SAVE_PROPERTY_LISTING,
  SAVE_PROPERTY_LISTING_SUCCESS,
  SAVE_PROPERTY_LISTING_ERROR,
  SAVE_LOCKER,
  SAVE_LOCKER_SUCCESS,
  SAVE_LOCKER_ERROR,
  LOOKUP_PROPERTY,
  LOOKUP_PROPERTY_SUCCESS,
  LOOKUP_PROPERTY_ERROR,
  SEARCH_CONTACTS,
  SEARCH_CONTACTS_SUCCESS,
  SEARCH_CONTACTS_ERROR,
  LOAD_INSPECTION,
  LOAD_INSPECTION_SUCCESS,
  LOAD_INSPECTION_ERROR,
  CREATE_LINE_ITEM,
  CREATE_LINE_ITEM_SUCCESS,
  CREATE_LINE_ITEM_ERROR,
  UPDATE_LINE_ITEM,
  UPDATE_LINE_ITEM_SUCCESS,
  UPDATE_LINE_ITEM_ERROR,
  DELETE_LINE_ITEM,
  DELETE_LINE_ITEM_SUCCESS,
  DELETE_LINE_ITEM_ERROR,
  LOAD_PROPERTY,
  LOAD_PROPERTY_SUCCESS,
  LOAD_PROPERTY_ERROR,
  RUN_CRONTAB_JOB,
  RUN_CRONTAB_JOB_SUCCESS,
  RUN_CRONTAB_JOB_ERROR,
  LOAD_INVOICE_SUCCESS,
  RESERVE_NEXT_AVAILABLE_LOCKER,
  RESERVE_NEXT_AVAILABLE_LOCKER_SUCCESS,
  RESERVE_NEXT_AVAILABLE_LOCKER_ERROR,
  ENRICH_PROPERTY,
  ENRICH_PROPERTY_SUCCESS,
  ENRICH_PROPERTY_ERROR,
  SAVE_PROPERTY_OWNER_SETTINGS,
  SAVE_PROPERTY_OWNER_SETTINGS_SUCCESS,
  SAVE_PROPERTY_OWNER_SETTINGS_ERROR,
  LOAD_PROPERTY_DETAILS_AND_OWNER_SETTINGS,
  LOAD_PROPERTY_DETAILS_AND_OWNER_SETTINGS_SUCCESS,
  LOAD_PROPERTY_DETAILS_AND_OWNER_SETTINGS_ERROR,
  DELETE_FILES,
  DELETE_FILES_SUCCESS,
  DELETE_FILES_ERROR,
  UPDATE_FILES,
  UPDATE_FILES_SUCCESS,
  UPDATE_FILES_ERROR,
  LOAD_ACCOUNTS_BY_TYPE,
  LOAD_ACCOUNTS_BY_TYPE_SUCCESS,
  LOAD_ACCOUNTS_BY_TYPE_ERROR,
  SAVE_INVOICE,
  SAVE_INVOICE_ERROR,
  SAVE_INVOICE_SUCCESS,
  CREATE_PROPERTY_LISTING,
  CREATE_PROPERTY_LISTING_SUCCESS,
  CREATE_PROPERTY_LISTING_ERROR,
  DELETE_INVOICE,
  DELETE_INVOICE_SUCCESS,
  DELETE_INVOICE_ERROR,
  DELETE_EXPENSE,
  DELETE_EXPENSE_SUCCESS,
  DELETE_EXPENSE_ERROR,
  LOAD_COMPANY_CARDS,
  LOAD_COMPANY_CARDS_SUCCESS,
  LOAD_COMPANY_CARDS_ERROR,
  SAVE_EXPENSE,
  SAVE_EXPENSE_SUCCESS,
  SAVE_EXPENSE_ERROR,
  LOAD_EMAIL_TEMPLATES,
  LOAD_EMAIL_TEMPLATES_SUCCESS,
  LOAD_EMAIL_TEMPLATES_ERROR,
  UPDATE_PROPERTY_KEY_LOG,
  UPDATE_PROPERTY_KEY_LOG_SUCCESS,
  UPDATE_PROPERTY_KEY_LOG_ERROR,
  UPDATE_LOCKBOX_SUCCESS,
  DELETE_JOB,
  DELETE_JOB_ERROR,
  DELETE_JOB_SUCCESS,
  LOAD_LOCKBOXES,
  LOAD_LOCKBOXES_SUCCESS,
  LOAD_LOCKBOXES_ERROR,
  ASSIGN_LOCKBOX_PROPERTY,
  ASSIGN_LOCKBOX_PROPERTY_SUCCESS,
  ASSIGN_LOCKBOX_PROPERTY_ERROR,
  UNASSIGN_LOCKBOX_PROPERTY,
  UNASSIGN_LOCKBOX_PROPERTY_SUCCESS,
  UNASSIGN_LOCKBOX_PROPERTY_ERROR,
  SEND_OPPORTUNITY_WELCOME_EMAIL,
  SEND_OPPORTUNITY_WELCOME_EMAIL_SUCCESS,
  SEND_OPPORTUNITY_WELCOME_EMAIL_ERROR,
  LOAD_MARKETS,
  LOAD_MARKETS_SUCCESS,
  LOAD_MARKETS_ERROR,
  LOAD_ZPL_TEMPLATES,
  LOAD_ZPL_TEMPLATES_SUCCESS,
  LOAD_ZPL_TEMPLATES_ERROR,
  DELETE_DOCUSIGN_ENVELOPE,
  DELETE_DOCUSIGN_ENVELOPE_SUCCESS,
  DELETE_DOCUSIGN_ENVELOPE_ERROR,
  UPDATE_CONTRACT_ADDENDUM,
  UPDATE_CONTRACT_ADDENDUM_SUCCESS,
  UPDATE_CONTRACT_ADDENDUM_ERROR,
  LOAD_USER,
  LOAD_USER_SUCCESS,
  LOAD_USER_ERROR,
  CREATE_INVOICE,
  CREATE_INVOICE_SUCCESS,
  CREATE_INVOICE_ERROR,
  UPDATE_APPLICATION,
  UPDATE_APPLICATION_SUCCESS,
  UPDATE_APPLICATION_ERROR,
  SAVE_CONTACT,
  SAVE_CONTACT_SUCCESS,
  SAVE_CONTACT_ERROR,
  CREATE_CONTACT,
  CREATE_CONTACT_SUCCESS,
  CREATE_CONTACT_ERROR,
  DELETE_CONTACT,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_ERROR,
} from './constants';

/* eslint-disable */
// let defaultCompanyInfo = require('companyInfo/mammoth_services.json'); // TODO webpack-to-vite
let defaultCompanyInfo = defaultCompanyInfoMammoth;
if (Company.getIdByDomain() === CompanyId.ONQ) {
  // defaultCompanyInfo = require('companyInfo/onqpm.json'); // TODO webpack-to-vite
  defaultCompanyInfo = defaultCompanyInfoOnqpm;
}
/* eslint-enable */

// The initial state of the App
export const initialState = {
  isLoading: false,
  error: '',
  validation: null,
  isFailedFetch: false,
  companyInfo: defaultCompanyInfo,
  isCompaniesLoading: false,
  companies: [],
  isSubmitCommentLoading: false,
  isCommentSubmitted: false,
  isUsersLoading: false,
  isUsersLoaded: false,
  users: [],
  isTechniciansLoading: false,
  technicians: [],
  isUpdatingTask: false,
  isUpdatedTask: false,
  isCreatingTask: false,
  isCreatedTask: false,
  isSendingEmail: false,
  isSentEmail: false,
  newActivity: null,
  isSobjectLoading: false,
  sobject: null,
  isPickListsLoading: false,
  pickLists: null,
  isRecordTypesLoading: false,
  searchIn: '',
  recordTypes: null,
  isSearching: false,
  searchResults: [],
  isDeletingFile: false,
  isRenamingFile: false,
  isSchedulingAppointment: false,
  isScheduledAppointment: false,
  isUpdatingAppointment: false,
  isUpdatedAppointment: false,
  jobs: null,
  startDate: '',
  endDate: '',
  isJobsLoaded: false,
  isUpdatingAppointmentStatus: false,
  isUpdatedAppointmentStatus: false,
  isEventsLoading: false,
  isEventsLoaded: false,
  events: [],
  isLoadingSmsMessages: false,
  isSendingSms: false,
  isSentSms: false,
  smsMessages: [],
  isLoadingSmsTemplates: false,
  smsTemplates: [],
  isUpdatingSmsMessage: false,
  isUpdatingJob: false,
  isUpdatedJob: false,
  isMaintenanceGroupsLoading: false,
  maintenanceGroups: [],
  isUpdatingInspectionStatus: false,
  isUpdatedInspectionStatus: false,
  isUpdatingPropertyRoutineInspections: false,
  isUpdatedPropertyRoutineInspections: false,
  isCommunicationDataLoading: false,
  isCommunicationDataLoaded: false,
  isSavingInspection: false,
  isSavedInspection: false,
  communicationData: null,
  isSavingProperty: false,
  isSavedProperty: false,
  isSavingWorkOrder: false,
  isSavedWorkOrder: false,
  isSavingOpportunity: false,
  isSavedOpportunity: false,
  isSavingLead: false,
  isSavedLead: false,
  isDeletingLead: false,
  isDeletedLead: false,
  isSavingContract: false,
  isSavedContract: false,
  isSavingPropertyListing: false,
  isSavedPropertyListing: false,
  isSavingLocker: false,
  isSavedLocker: false,
  isLookingProperty: false,
  foundProperty: null,
  isSearchingContacts: false,
  isSearchedContacts: false,
  searchContactsResults: [],
  searchContactsQuery: null,

  isLoadingWorkOrder: false,
  workOrder: null,

  isLoadingInspection: false,
  inspection: null,

  isCreatingLineItem: false,
  isCreatedLineItem: false,
  createdLineItem: null,

  isUpdatingLineItem: false,
  isUpdatedLineItem: false,
  updatedLineItem: null,

  isDeletingLineItem: false,
  isDeletedLineItem: false,

  isLoadingProperty: false,
  property: null,

  isRunningCrontabJob: false,
  isReservingNextAvailableLocker: false,
  nextAvailableLocker: null,

  isEnrichedProperty: false,
  isEnrichingProperty: false,
  enrichmentResult: null,

  isSavingPropertyOwnerSettings: false,
  isSavedPropertyOwnerSettings: false,

  isLoadingPropertyDetailsAndOwnerSettings: false,
  isLoadedPropertyDetailsAndOwnerSettings: false,
  propertyDetailsAndOwnerSettings: null,

  isDeletingFiles: false,
  isDeletedFiles: false,

  isUpdatingFiles: false,
  isUpdatedFiles: false,

  accountsByType: null,
  isLoadingAccountsByType: false,
  isLoadedAccountsByType: false,

  isSavedInvoice: false,
  isSavingInvoice: false,

  isCreatingPropertyListing: false,
  isCreatedPropertyListing: false,

  isDeletingInvoice: false,
  isDeletedInvoice: false,

  isDeletingExpense: false,
  isDeletedExpense: false,

  isLoadingCompanyCards: false,
  isLoadedCompanyCards: false,
  companyCards: null,

  isSavingExpense: false,
  isSavedExpense: false,

  emailTemplates: null,
  isLoadingEmailTemplates: false,

  isUpdatingPropertyKeyLog: false,
  isUpdatedPropertyKeyLog: false,

  isLoadingLockboxes: false,
  availableLockboxes: null,

  isAssigningLockboxProperty: false,
  isAssignedLockboxProperty: false,

  isUnassigningLockboxProperty: false,
  isUnassignedLockboxProperty: false,

  isSendingOpportunityWelcomeEmail: false,
  isSentOpportunityWelcomeEmail: false,

  isLoadingMarkets: false,
  markets: [],

  isLoadingZplTemplates: false,
  zplTemplates: [],

  isDeletingDocusignEnvelope: false,
  isDeletedDocusignEnvelope: false,

  isUpdatingContractAddendum: false,
  isUpdatedContractAddendum: false,

  isLoadedUser: false,

  isCreatingInvoice: false,
  isCreatedInvoice: false,
  createdInvoice: null,

  isUpdatingApplication: false,
  isUpdatedApplication: false,

  isSavingContact: false,
  isSavedContact: false,

  isCreatedContact: false,
  isCreatingContact: false,

  isDeletedContact: false,
  isDeletingContact: false,
};

function appReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_WORK_ORDER: {
      return {
        ...state,
        isLoading: true, // TODO: Add separate loading flag
        isLoadingWorkOrder: true,
      };
    }

    case LOAD_WORK_ORDER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoadingWorkOrder: false,
        companyInfo: action?.workOrder?.CompanyInfo,
        workOrder: action?.workOrder,
      };
    }

    case LOAD_INSPECTION: {
      return {
        ...state,
        isLoadingInspection: true,
      };
    }

    case LOAD_INSPECTION_SUCCESS: {
      return {
        ...state,
        isLoadingInspection: false,
        inspection: action?.inspection,
      };
    }

    case LOAD_JOB: {
      return {
        ...state,
        isLoading: true, // TODO: Add separate loading flag
      };
    }

    case LOAD_JOB_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        companyInfo: action?.job?.CompanyInfo,
      };
    }

    case LOAD_INVOICE_SUCCESS: {
      return {
        ...state,
        companyInfo: action?.invoice?.CompanyInfo,
      };
    }

    case LOAD_EVENTS: {
      return {
        ...state,
        isEventsLoading: true,
        isEventsLoaded: false,
      };
    }

    case LOAD_EVENTS_SUCCESS: {
      return {
        ...state,
        isEventsLoading: false,
        isEventsLoaded: true,
        events: action?.events,
      };
    }

    case LOAD_COMPANY_INFO: {
      return {
        ...state,
        isLoading: true, // TODO: Add separate loading flag
      };
    }

    case LOAD_COMPANY_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        companyInfo: action?.companyInfo,
      };
    }

    case CLEAR_DATES: {
      return {
        ...state,
        startDate: '',
        endDate: '',
      };
    }

    case CLEAR_JOBS: {
      return {
        ...state,
        startDate: '',
        endDate: '',
        jobs: null,
      };
    }

    case LOAD_JOBS: {
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
        isLoading: true, // TODO: Add separate loading flag
        isJobsLoaded: false,
      };
    }

    case LOAD_JOBS_SUCCESS: {
      return {
        ...state,
        jobs: action.jobs,
        isLoading: false,
        isJobsLoaded: true,
      };
    }

    case LOAD_SMS_MESSAGES: {
      return {
        ...state,
        isLoadingSmsMessages: true,
      };
    }

    case LOAD_SMS_MESSAGES_SUCCESS: {
      return {
        ...state,
        smsMessages: action.messages,
        isLoadingSmsMessages: false,
      };
    }

    case LOAD_SMS_TEMPLATES: {
      return {
        ...state,
        isLoadingSmsTemplates: true,
      };
    }

    case LOAD_SMS_TEMPLATES_SUCCESS: {
      return {
        ...state,
        smsTemplates: action.templates,
        isLoadingSmsTemplates: false,
      };
    }

    case SEND_SMS: {
      return {
        ...state,
        isSendingSms: true,
        isSentSms: false,
      };
    }

    case SEND_SMS_SUCCESS: {
      return {
        ...state,
        successMessage: 'SMS was successfully sent',
        isSendingSms: false,
        isSentSms: true,
      };
    }

    case LOAD_COMPANIES: {
      return {
        ...state,
        isCompaniesLoading: true,
      };
    }

    case LOAD_COMPANIES_SUCCESS: {
      return {
        ...state,
        isCompaniesLoading: false,
        companies: action?.companies,
      };
    }

    case SUBMIT_COMMENT: {
      return {
        ...state,
        isSubmitCommentLoading: true,
        isCommentSubmitted: false,
      };
    }

    case SUBMIT_COMMENT_SUCCESS: {
      return {
        ...state,
        isSubmitCommentLoading: false,
        isCommentSubmitted: true,
        successMessage: 'Your comment was successfully submitted.',
      };
    }

    case CLEAR_MESSAGES: {
      return {
        ...state,
        error: '',
        successMessage: '',
        // TODO: use CLEAR_FLAGS instead to reset flags below
        isCommentSubmitted: false,
        isScheduledAppointment: false,
        isUpdatedAppointment: false,
        isJobsLoaded: false,
        isUpdatedAppointmentStatus: false,
        isEventsLoaded: false,
        isUpdatedJob: false,
        isUpdatedInspectionStatus: false,
        isUpdatedPropertyRoutineInspections: false,
        foundProperty: null,
        isSearchedContacts: false,
        isLookedProperty: false,
        isSavedContract: false,
        isSavedPropertyListing: false,
        isSavedLocker: false,
        isDeletedDocusignEnvelope: false,
        isDeletedLead: false,
      };
    }

    case CLEAR_FLAGS: {
      return {
        ...state,
        isCommentSubmitted: false,
        isScheduledAppointment: false,
        isUpdatedAppointment: false,
        isJobsLoaded: false,
        isUpdatedAppointmentStatus: false,
        isEventsLoaded: false,
        isUpdatedJob: false,
        isUpdatedInspectionStatus: false,
        isUpdatedPropertyRoutineInspections: false,
        isUpdatedTask: false,
        isCreatedTask: false,
        isSentEmail: false,
        isSentSms: false,
        isSavedInspection: false,
        isSavedProperty: false,
        isSavedWorkOrder: false,
        isSavedOpportunity: false,
        validation: null,
        isSearchedContacts: false,
        isLookedProperty: false,
        isCreatedLineItem: false,
        isUpdatedLineItem: false,
        isDeletedLineItem: false,
        isSavedContract: false,
        isSavedPropertyListing: false,
        isSavedLocker: false,
        foundProperty: null,
        isEnrichedProperty: false,
        isSavedPropertyOwnerSettings: false,
        isDeletedFiles: false,
        isUpdatedFiles: false,
        isLoadedAccountsByType: false,
        isSavedInvoice: false,
        isCreatedPropertyListing: false,
        isDeletedInvoice: false,
        isDeletedExpense: false,
        isLoadedCompanyCards: false,
        isSavedExpense: false,
        isUpdatedPropertyKeyLog: false,
        isDeletedJob: false,
        isAssignedLockboxProperty: false,
        isUnassignedLockboxProperty: false,
        isSentOpportunityWelcomeEmail: false,
        isUpdatedContractAddendum: false,
        isUsersLoaded: false,
        isSavedLead: false,
        isLoadedUser: false,
        isCreatedInvoice: false,
        isUpdatedApplication: false,
        isSavedContact: false,
        isCreatedContact: false,
        isDeletedContact: false,
      };
    }

    case CLEAR_SOBJET: {
      return {
        ...state,
        sobject: null,
      };
    }

    case LOAD_USERS: {
      return {
        ...state,
        isUsersLoading: true,
      };
    }

    case LOAD_USERS_SUCCESS: {
      return {
        ...state,
        isUsersLoading: false,
        isUsersLoaded: true,
        users: action?.users,
      };
    }

    case LOAD_TECHNICIANS: {
      return {
        ...state,
        isTechniciansLoading: true,
      };
    }

    case LOAD_TECHNICIANS_SUCCESS: {
      return {
        ...state,
        isTechniciansLoading: false,
        technicians: action?.technicians,
      };
    }

    case UPDATE_TASK: {
      return {
        ...state,
        isUpdatingTask: true,
        isUpdatedTask: false,
      };
    }

    case UPDATE_TASK_SUCCESS: {
      return {
        ...state,
        isUpdatingTask: false,
        isUpdatedTask: true,
        successMessage: 'Task was successfully updated.',
      };
    }

    case CREATE_TASK: {
      return {
        ...state,
        isCreatingTask: true,
        isCreatedTask: false,
      };
    }

    case CREATE_TASK_SUCCESS: {
      return {
        ...state,
        isCreatingTask: false,
        isCreatedTask: true,
        successMessage: 'Task was successfully created.',
      };
    }

    case SEND_EMAIL: {
      return {
        ...state,
        isSendingEmail: true,
        isSentEmail: false,
        newActivity: null,
      };
    }

    case SEND_EMAIL_SUCCESS: {
      return {
        ...state,
        isSendingEmail: false,
        isSentEmail: true,
        successMessage: 'Email was successfully sent.',
      };
    }

    case LOG_NEW_ACTIVITY: {
      return {
        ...state,
        newActivity: action?.activity,
      };
    }

    case CANCEL_NEW_ACTIVITY: {
      return {
        ...state,
        newActivity: null,
        validation: null,
      };
    }

    case LOAD_SOBJECT: {
      return {
        ...state,
        isSobjectLoading: true,
      };
    }

    case LOAD_SOBJECT_SUCCESS: {
      return {
        ...state,
        isSobjectLoading: false,
        sobject: action?.sobject,
      };
    }

    case LOAD_PICK_LISTS: {
      return {
        ...state,
        isPickListsLoading: true,
      };
    }

    case LOAD_PICK_LISTS_SUCCESS: {
      return {
        ...state,
        isPickListsLoading: false,
        pickLists: action?.pickLists,
      };
    }

    case LOAD_RECORD_TYPES: {
      return {
        ...state,
        isRecordTypesLoading: true,
      };
    }

    case LOAD_RECORD_TYPES_SUCCESS: {
      return {
        ...state,
        isRecordTypesLoading: false,
        recordTypes: action?.recordTypes,
      };
    }

    case CLEAR_SEARCH_RECORDS: {
      return {
        ...state,
        searchResults: [],
        searchIn: '',
        searchContactsQuery: null,
        searchContactsResults: [],
        isSearchedContacts: false,
        validation: null,
      };
    }

    case SEARCH_RECORDS: {
      return {
        ...state,
        isSearching: true,
        searchResults: [],
        searchIn: action?.searchIn,
      };
    }

    case SEARCH_RECORDS_SUCCESS: {
      return {
        ...state,
        isSearching: false,
        searchResults: action?.searchResults,
      };
    }

    case DELETE_SOBJECT_FILE: {
      return {
        ...state,
        isDeletingFile: true,
      };
    }

    case DELETE_SOBJECT_FILE_SUCCESS: {
      return {
        ...state,
        isDeletingFile: false,
        successMessage: 'File successfully deleted.',
      };
    }

    case RENAME_SOBJECT_FILE: {
      return {
        ...state,
        isRenamingFile: true,
      };
    }

    case RENAME_SOBJECT_FILE_SUCCESS: {
      return {
        ...state,
        isRenamingFile: false,
        successMessage: 'File successfully renamed.',
      };
    }

    case SCHEDULE_APPOINTMENT: {
      return {
        ...state,
        validation: null,
        isSchedulingAppointment: true,
        isScheduledAppointment: false,
      };
    }

    case SCHEDULE_APPOINTMENT_SUCCESS: {
      return {
        ...state,
        isSchedulingAppointment: false,
        isScheduledAppointment: true,
        successMessage: 'Appointment was successfully scheduled',
      };
    }

    case UPDATE_APPOINTMENT: {
      return {
        ...state,
        validation: null,
        isUpdatingAppointment: true,
        isUpdatedAppointment: false,
      };
    }

    case UPDATE_APPOINTMENT_SUCCESS: {
      return {
        ...state,
        isUpdatingAppointment: false,
        isUpdatedAppointment: true,
        successMessage: 'Appointment was successfully updated',
      };
    }

    case UPDATE_APPOINTMENT_STATUS: {
      return {
        ...state,
        validation: null,
        isUpdatingAppointmentStatus: true,
        isUpdatedAppointmentStatus: false,
      };
    }

    case UPDATE_APPOINTMENT_STATUS_SUCCESS: {
      return {
        ...state,
        isUpdatingAppointmentStatus: false,
        isUpdatedAppointmentStatus: true,
        successMessage: 'Appointment status was successfully updated',
      };
    }

    case UPDATE_SMS_MESSAGE: {
      return {
        ...state,
        isUpdatingSmsMessage: true,
      };
    }

    case UPDATE_SMS_MESSAGE_SUCCESS: {
      return {
        ...state,
        isUpdatingSmsMessage: false,
        successMessage: 'SMS was successfully updated',
      };
    }

    case UPDATE_JOB: {
      return {
        ...state,
        isUpdatingJob: true,
        isUpdatedJob: false,
      };
    }

    case UPDATE_JOB_SUCCESS: {
      return {
        ...state,
        isUpdatingJob: false,
        isUpdatedJob: true,
        successMessage: 'Job was successfully updated',
      };
    }

    case LOAD_MAINTENANCE_GROUPS: {
      return {
        ...state,
        isMaintenanceGroupsLoading: true,
      };
    }

    case LOAD_MAINTENANCE_GROUPS_SUCCESS: {
      return {
        ...state,
        isMaintenanceGroupsLoading: false,
        maintenanceGroups: action?.groups,
      };
    }

    case UPDATE_INSPECTION_STATUS: {
      return {
        ...initialState,
        isUpdatingInspectionStatus: true,
        isUpdatedInspectionStatus: false,
      };
    }

    case UPDATE_INSPECTION_STATUS_SUCCESS: {
      return {
        ...state,
        successMessage: 'Inspection status was successfuly updated',
        isUpdatingInspectionStatus: false,
        isUpdatedInspectionStatus: true,
      };
    }

    case UPDATE_PROPERTY_ROUTINE_INSPECTIONS: {
      return {
        ...state,
        isUpdatingPropertyRoutineInspections: true,
        isUpdatedPropertyRoutineInspections: false,
      };
    }

    case UPDATE_PROPERTY_ROUTINE_INSPECTIONS_SUCCESS: {
      return {
        ...state,
        isUpdatingPropertyRoutineInspections: false,
        isUpdatedPropertyRoutineInspections: true,
        successMessage: 'Property successfully updated',
      };
    }

    case CLEAR_COMMUNICATION_DATA: {
      return {
        ...state,
        communicationData: null,
      };
    }

    case LOAD_COMMUNICATION_DATA: {
      return {
        ...state,
        isCommunicationDataLoading: true,
        isCommunicationDataLoaded: false,
      };
    }

    case UPDATE_LOCKBOX_SUCCESS: {
      if (state.communicationData) {
        const selector = state.communicationData.entity?.Property__r
          ? 'entity.Property__r.Lockbox__r'
          : 'entity.Lockbox__r';

        const clonedState = cloneDeep(state.communicationData);
        set(clonedState, selector, action?.result?.lockbox);

        return {
          ...state,
          communicationData: clonedState,
        };
      }
      return state;
    }

    case LOAD_COMMUNICATION_DATA_SUCCESS: {
      return {
        ...state,
        isCommunicationDataLoading: false,
        isCommunicationDataLoaded: true,
        communicationData: action?.communicationData,
      };
    }

    case SAVE_INSPECTION: {
      return {
        ...state,
        isSavingInspection: true,
        isSavedInspection: false,
      };
    }

    case SAVE_INSPECTION_SUCCESS: {
      return {
        ...state,
        isSavingInspection: false,
        isSavedInspection: true,
        successMessage: 'Inspection was successfully saved',
      };
    }

    case SAVE_PROPERTY: {
      return {
        ...state,
        isSavingProperty: true,
        isSavedProperty: false,
      };
    }

    case SAVE_PROPERTY_SUCCESS: {
      return {
        ...state,
        isSavingProperty: false,
        isSavedProperty: true,
        successMessage: 'Property was successfully saved',
      };
    }

    case SAVE_PROPERTY_OWNER_SETTINGS: {
      return {
        ...state,
        isSavingPropertyOwnerSettings: true,
        isSavedPropertyOwnerSettings: false,
      };
    }

    case SAVE_PROPERTY_OWNER_SETTINGS_SUCCESS: {
      return {
        ...state,
        isSavingPropertyOwnerSettings: false,
        isSavedPropertyOwnerSettings: true,
        successMessage: 'The property owner settings were successfully saved.',
      };
    }

    case SAVE_WORK_ORDER: {
      return {
        ...state,
        isSavingWorkOrder: true,
        isSavedWorkOrder: false,
      };
    }

    case SAVE_WORK_ORDER_SUCCESS: {
      return {
        ...state,
        isSavingWorkOrder: false,
        isSavedWorkOrder: true,
        successMessage: 'Work Order was successfully saved',
      };
    }

    case SAVE_OPPORTUNITY: {
      return {
        ...state,
        isSavingOpportunity: true,
        isSavedOpportunity: false,
      };
    }

    case SAVE_OPPORTUNITY_SUCCESS: {
      return {
        ...state,
        successMessage: 'Opportunity was successfully saved',
        isSavingOpportunity: false,
        isSavedOpportunity: true,
      };
    }

    case SAVE_LEAD: {
      return {
        ...state,
        isSavingLead: true,
        isSavedLead: false,
      };
    }

    case SAVE_LEAD_SUCCESS: {
      return {
        ...state,
        successMessage: 'Lead was successfully saved',
        isSavingLead: false,
        isSavedLead: true,
      };
    }

    case DELETE_LEAD: {
      return {
        ...state,
        isDeletingLead: true,
        isDeletedLead: false,
      };
    }

    case DELETE_LEAD_SUCCESS: {
      return {
        ...state,
        successMessage: 'Lead was successfully deleted',
        isDeletingLead: false,
        isDeletedLead: true,
      };
    }

    case SAVE_CONTRACT: {
      return {
        ...state,
        isSavingContract: true,
        isSavedContract: false,
      };
    }

    case SAVE_CONTRACT_SUCCESS: {
      return {
        ...state,
        successMessage: 'Contract was successfully saved',
        isSavingContract: false,
        isSavedContract: true,
      };
    }

    case SAVE_PROPERTY_LISTING: {
      return {
        ...state,
        isSavingPropertyListing: true,
        isSavedPropertyListing: false,
      };
    }

    case SAVE_PROPERTY_LISTING_SUCCESS: {
      return {
        ...state,
        successMessage: 'Property Listing was successfully saved',
        isSavingPropertyListing: false,
        isSavedPropertyListing: true,
      };
    }

    case SAVE_LOCKER: {
      return {
        ...state,
        isSavingLocker: true,
        isSavedLocker: false,
      };
    }

    case SAVE_LOCKER_SUCCESS: {
      return {
        ...state,
        successMessage: 'Locker was successfully saved',
        isSavingLocker: false,
        isSavedLocker: true,
      };
    }

    case LOOKUP_PROPERTY: {
      return {
        ...state,
        isLookingProperty: true,
        isLookedProperty: false,
        foundProperty: null,
      };
    }

    case LOOKUP_PROPERTY_SUCCESS: {
      return {
        ...state,
        isLookingProperty: false,
        isLookedProperty: true,
        foundProperty: action?.property,
      };
    }

    case SEARCH_CONTACTS: {
      return {
        ...state,
        validation: null,
        isSearchingContacts: true,
        isSearchedContacts: false,
        searchContactsQuery: action,
      };
    }

    case SEARCH_CONTACTS_SUCCESS: {
      return {
        ...state,
        isSearchingContacts: false,
        isSearchedContacts: true,
        searchContactsResults: action?.contacts,
      };
    }

    // ---------------
    case CREATE_LINE_ITEM: {
      return {
        ...state,
        validation: null,
        createdLineItem: null,
        isCreatingLineItem: true,
        isCreatedLineItem: false,
      };
    }

    case CREATE_LINE_ITEM_SUCCESS: {
      return {
        ...state,
        isCreatingLineItem: false,
        isCreatedLineItem: true,
        createdLineItem: action.lineItem,
        successMessage: 'Line Item was successfully created',
      };
    }

    case UPDATE_LINE_ITEM: {
      return {
        ...state,
        validation: null,
        isUpdatingLineItem: true,
        isUpdatedLineItem: false,
      };
    }

    case UPDATE_LINE_ITEM_SUCCESS: {
      return {
        ...state,
        isUpdatingLineItem: false,
        isUpdatedLineItem: true,
        updatedLineItem: action.lineItem,
        successMessage: 'Line Item was successfully updated',
      };
    }

    case DELETE_LINE_ITEM: {
      return {
        ...state,
        validation: null,
        isDeletingLineItem: true,
        isDeletedLineItem: false,
      };
    }

    case DELETE_LINE_ITEM_SUCCESS: {
      return {
        ...state,
        isDeletingLineItem: false,
        isDeletedLineItem: true,
        successMessage: 'Line Item was successfully deleted',
      };
    }

    case LOAD_PROPERTY: {
      return {
        ...state,
        isLoadingProperty: true,
      };
    }

    case LOAD_PROPERTY_SUCCESS: {
      return {
        ...state,
        property: action.property,
        isLoadingProperty: false,
      };
    }

    case RUN_CRONTAB_JOB: {
      return {
        ...state,
        isRunningCrontabJob: true,
      };
    }

    case RUN_CRONTAB_JOB_SUCCESS: {
      return {
        ...state,
        isRunningCrontabJob: false,
        successMessage: 'Crontab job was successfully ran.',
      };
    }

    case RESERVE_NEXT_AVAILABLE_LOCKER: {
      return {
        ...state,
        isReservingNextAvailableLocker: true,
      };
    }

    case RESERVE_NEXT_AVAILABLE_LOCKER_SUCCESS: {
      return {
        ...state,
        isReservingNextAvailableLocker: false,
        nextAvailableLocker: action.locker || null,
      };
    }

    case ENRICH_PROPERTY: {
      return {
        ...state,
        isEnrichingProperty: true,
        isEnrichedProperty: false,
      };
    }

    case ENRICH_PROPERTY_SUCCESS: {
      return {
        ...state,
        isEnrichingProperty: false,
        isEnrichedProperty: true,
        enrichmentResult: action.enrichmentResult,
        successMessage:
          'The property was successfully enriched / report generated.',
      };
    }

    case LOAD_PROPERTY_DETAILS_AND_OWNER_SETTINGS: {
      return {
        ...state,
        isLoadingPropertyDetailsAndOwnerSettings: true,
        isLoadedPropertyDetailsAndOwnerSettings: false,
      };
    }

    case LOAD_PROPERTY_DETAILS_AND_OWNER_SETTINGS_SUCCESS: {
      return {
        ...state,
        isLoadingPropertyDetailsAndOwnerSettings: false,
        isLoadedPropertyDetailsAndOwnerSettings: true,
        propertyDetailsAndOwnerSettings: action?.result,
      };
    }

    case DELETE_FILES: {
      return {
        ...state,
        isDeletingFiles: true,
        isDeletedFiles: false,
      };
    }

    case DELETE_FILES_SUCCESS: {
      return {
        ...state,
        isDeletedFiles: true,
        isDeletingFiles: false,
        successMessage: 'File(s) has been deleted',
      };
    }

    case UPDATE_FILES: {
      return {
        ...state,
        isUpdatingFiles: true,
        isUpdatedFiles: false,
      };
    }

    case UPDATE_FILES_SUCCESS: {
      return {
        ...state,
        isUpdatedFiles: true,
        isUpdatingFiles: false,
        successMessage: 'File(s) has been updated',
      };
    }

    case LOAD_ACCOUNTS_BY_TYPE: {
      return {
        ...state,
        isLoadingAccountsByType: true,
        isLoadedAccountsByType: false,
      };
    }

    case LOAD_ACCOUNTS_BY_TYPE_SUCCESS: {
      return {
        ...state,
        accountsByType: action.result || [],
        isLoadingAccountsByType: false,
        isLoadedAccountsByType: true,
      };
    }

    case SAVE_INVOICE: {
      return {
        ...state,
        isSavingInvoice: true,
        isSavedInvoice: false,
      };
    }

    case SAVE_INVOICE_SUCCESS: {
      return {
        ...state,
        isSavedInvoice: true,
        isSavingInvoice: false,
        successMessage: 'Invoice was successfully saved',
      };
    }

    case CREATE_PROPERTY_LISTING: {
      return {
        ...state,
        isCreatingPropertyListing: true,
        isCreatedPropertyListing: false,
      };
    }

    case CREATE_PROPERTY_LISTING_SUCCESS: {
      return {
        ...state,
        isCreatingPropertyListing: false,
        isCreatedPropertyListing: true,
        successMessage: 'The property listing successfully created.',
      };
    }

    case DELETE_INVOICE: {
      return {
        ...state,
        isDeletingInvoice: true,
        isDeletedInvoice: false,
      };
    }

    case DELETE_INVOICE_SUCCESS: {
      return {
        ...state,
        isDeletingInvoice: false,
        isDeletedInvoice: true,
        successMessage: 'Invoice successfully deleted',
      };
    }

    case DELETE_EXPENSE: {
      return {
        ...state,
        isDeletingExpense: true,
        isDeletedExpense: false,
      };
    }

    case DELETE_EXPENSE_SUCCESS: {
      return {
        ...state,
        isDeletingExpense: false,
        isDeletedExpense: true,
        successMessage: 'Expense successfully deleted',
      };
    }

    case LOAD_COMPANY_CARDS: {
      return {
        ...state,
        isLoadingCompanyCards: true,
        isLoadedCompanyCards: false,
      };
    }

    case LOAD_COMPANY_CARDS_SUCCESS: {
      return {
        ...state,
        isLoadingCompanyCards: false,
        isLoadedCompanyCards: true,
        companyCards: action.result,
      };
    }

    case SAVE_EXPENSE: {
      return {
        ...state,
        isSavingExpense: true,
        isSavedExpense: false,
      };
    }

    case SAVE_EXPENSE_SUCCESS: {
      return {
        ...state,
        isSavingExpense: false,
        isSavedExpense: true,
        successMessage: 'Expense successfully updated',
      };
    }

    case LOAD_EMAIL_TEMPLATES: {
      return {
        ...state,
        isLoadingEmailTemplates: true,
      };
    }

    case LOAD_EMAIL_TEMPLATES_SUCCESS: {
      return {
        ...state,
        isLoadingEmailTemplates: false,
        emailTemplates: action.emailTemplates,
      };
    }

    case UPDATE_PROPERTY_KEY_LOG: {
      return {
        ...state,
        isUpdatingPropertyKeyLog: true,
        isUpdatedPropertyKeyLog: false,
      };
    }

    case UPDATE_PROPERTY_KEY_LOG_SUCCESS: {
      return {
        ...state,
        isUpdatingPropertyKeyLog: false,
        isUpdatedPropertyKeyLog: true,
        successMessage: 'Key log successfully updated',
      };
    }

    case DELETE_JOB: {
      return {
        ...state,
        isDeletingJob: true,
        isDeletedJob: false,
      };
    }

    case DELETE_JOB_SUCCESS: {
      return {
        ...state,
        isDeletingJob: false,
        isDeletedJob: true,
        successMessage: 'Job successfully deleted',
      };
    }

    case LOAD_LOCKBOXES: {
      return {
        ...state,
        isLoadingLockboxes: true,
      };
    }

    case LOAD_LOCKBOXES_SUCCESS: {
      return {
        ...state,
        isLoadingLockboxes: false,
        availableLockboxes: action.result || [],
      };
    }

    case ASSIGN_LOCKBOX_PROPERTY: {
      return {
        ...state,
        validation: null,
        isAssigningLockboxProperty: true,
        isAssignedLockboxProperty: false,
      };
    }

    case ASSIGN_LOCKBOX_PROPERTY_SUCCESS: {
      return {
        ...state,
        successMessage: 'Lockbox assigned successfully.',
        isAssigningLockboxProperty: false,
        isAssignedLockboxProperty: true,
      };
    }

    case UNASSIGN_LOCKBOX_PROPERTY: {
      return {
        ...state,
        validation: null,
        isUnassigningLockboxProperty: true,
        isUnassignedLockboxProperty: false,
      };
    }

    case UNASSIGN_LOCKBOX_PROPERTY_SUCCESS: {
      return {
        ...state,
        successMessage: 'Property unassigned successfully.',
        isUnassigningLockboxProperty: false,
        isUnassignedLockboxProperty: true,
      };
    }

    case SEND_OPPORTUNITY_WELCOME_EMAIL: {
      return {
        ...state,
        validation: null,
        isSendingOpportunityWelcomeEmail: true,
        isSentOpportunityWelcomeEmail: false,
      };
    }

    case SEND_OPPORTUNITY_WELCOME_EMAIL_SUCCESS: {
      return {
        ...state,
        successMessage: 'Welcome Email send successfully.',
        isSendingOpportunityWelcomeEmail: false,
        isSentOpportunityWelcomeEmail: true,
      };
    }

    case LOAD_MARKETS: {
      return {
        ...state,
        isLoadingMarkets: true,
      };
    }

    case LOAD_MARKETS_SUCCESS: {
      return {
        ...state,
        markets: action.result || [],
        isLoadingMarkets: false,
      };
    }

    case LOAD_ZPL_TEMPLATES: {
      return {
        ...state,
        isLoadingZplTemplates: true,
      };
    }

    case LOAD_ZPL_TEMPLATES_SUCCESS: {
      return {
        ...state,
        zplTemplates: action.result || [],
        isLoadingZplTemplates: false,
      };
    }

    case DELETE_DOCUSIGN_ENVELOPE: {
      return {
        ...state,
        validation: null,
        isDeletingDocusignEnvelope: true,
        isDeletedDocusignEnvelope: false,
      };
    }

    case DELETE_DOCUSIGN_ENVELOPE_SUCCESS: {
      return {
        ...state,
        saveResults: action?.envelope,
        successMessage: 'Docusign contract was successfully deleted',
        isDeletingDocusignEnvelope: false,
        isDeletedDocusignEnvelope: true,
      };
    }

    case UPDATE_CONTRACT_ADDENDUM: {
      return {
        ...state,
        isUpdatingContractAddendum: true,
        isUpdatedContractAddendum: false,
      };
    }

    case UPDATE_CONTRACT_ADDENDUM_SUCCESS: {
      return {
        ...state,
        successMessage: 'Contract Addendum successfully updated.',
        isUpdatingContractAddendum: false,
        isUpdatedContractAddendum: true,
      };
    }

    case LOAD_USER: {
      return {
        ...state,
        isLoadingUser: true,
        isLoadedUser: false,
      };
    }

    case LOAD_USER_SUCCESS: {
      return {
        ...state,
        isLoadingUser: false,
        isLoadedUser: true,
      };
    }

    case CREATE_INVOICE: {
      return {
        ...state,
        isCreatingInvoice: true,
        isCreatedInvoice: false,
      };
    }

    case CREATE_INVOICE_SUCCESS: {
      return {
        ...state,
        isCreatingInvoice: false,
        isCreatedInvoice: true,
        successMessage: 'Invoice successfully created',
        createdInvoice: action.result,
      };
    }

    case UPDATE_APPLICATION: {
      return {
        ...state,
        isUpdatingApplication: true,
        isUpdatedApplication: false,
      };
    }

    case UPDATE_APPLICATION_SUCCESS: {
      return {
        ...state,
        isUpdatingApplication: false,
        isUpdatedApplication: true,
        successMessage: 'Application successfully updated',
      };
    }

    case SAVE_CONTACT: {
      return {
        ...state,
        validation: null,
        isSavedContact: false,
        isSavingContact: true,
      };
    }

    case SAVE_CONTACT_SUCCESS: {
      return {
        ...state,
        successMessage: 'Contact was successfully saved',
        isSavedContact: true,
        isSavingContact: false,
      };
    }

    case CREATE_CONTACT: {
      return {
        ...state,
        validation: null,
        isCreatedContact: false,
        isCreatingContact: true,
      };
    }

    case CREATE_CONTACT_SUCCESS: {
      return {
        ...state,
        successMessage: 'Contact was successfully created',
        isCreatedContact: true,
        isCreatingContact: false,
      };
    }

    case DELETE_CONTACT: {
      return {
        ...state,
        validation: null,
        isDeletedContact: false,
        isDeletingContact: true,
      };
    }

    case DELETE_CONTACT_SUCCESS: {
      return {
        ...state,
        successMessage: 'Contact was successfully deleted',
        isDeletedContact: true,
        isDeletingContact: false,
      };
    }

    case LOAD_WORK_ORDER_ERROR:
    case LOAD_INSPECTION_ERROR:
    case LOAD_COMPANY_INFO_ERROR:
    case LOAD_COMPANIES_ERROR:
    case LOAD_USERS_ERROR:
    case LOAD_TECHNICIANS_ERROR:
    case SUBMIT_COMMENT_ERROR:
    case UPDATE_TASK_ERROR:
    case CREATE_TASK_ERROR:
    case SEND_EMAIL_ERROR:
    case LOAD_SOBJECT_ERROR:
    case LOAD_PICK_LISTS_ERROR:
    case LOAD_RECORD_TYPES_ERROR:
    case SEARCH_RECORDS_ERROR:
    case DELETE_SOBJECT_FILE_ERROR:
    case RENAME_SOBJECT_FILE_ERROR:
    case LOAD_JOB_ERROR:
    case SCHEDULE_APPOINTMENT_ERROR:
    case UPDATE_APPOINTMENT_ERROR:
    case UPDATE_APPOINTMENT_STATUS_ERROR:
    case LOAD_JOBS_ERROR:
    case LOAD_EVENTS_ERROR:
    case LOAD_SMS_MESSAGES_ERROR:
    case SEND_SMS_ERROR:
    case LOAD_SMS_TEMPLATES_ERROR:
    case UPDATE_SMS_MESSAGE_ERROR:
    case UPDATE_JOB_ERROR:
    case LOAD_MAINTENANCE_GROUPS_ERROR:
    case UPDATE_INSPECTION_STATUS_ERROR:
    case UPDATE_PROPERTY_ROUTINE_INSPECTIONS_ERROR:
    case LOAD_COMMUNICATION_DATA_ERROR:
    case SAVE_INSPECTION_ERROR:
    case SAVE_PROPERTY_ERROR:
    case SAVE_WORK_ORDER_ERROR:
    case SAVE_OPPORTUNITY_ERROR:
    case SAVE_LEAD_ERROR:
    case DELETE_LEAD_ERROR:
    case SAVE_CONTRACT_ERROR:
    case LOOKUP_PROPERTY_ERROR:
    case SEARCH_CONTACTS_ERROR:
    case CREATE_LINE_ITEM_ERROR:
    case UPDATE_LINE_ITEM_ERROR:
    case DELETE_LINE_ITEM_ERROR:
    case LOAD_PROPERTY_ERROR:
    case RUN_CRONTAB_JOB_ERROR:
    case SAVE_PROPERTY_LISTING_ERROR:
    case SAVE_LOCKER_ERROR:
    case RESERVE_NEXT_AVAILABLE_LOCKER_ERROR:
    case ENRICH_PROPERTY_ERROR:
    case SAVE_PROPERTY_OWNER_SETTINGS_ERROR:
    case LOAD_PROPERTY_DETAILS_AND_OWNER_SETTINGS_ERROR:
    case DELETE_FILES_ERROR:
    case UPDATE_FILES_ERROR:
    case LOAD_ACCOUNTS_BY_TYPE_ERROR:
    case SAVE_INVOICE_ERROR:
    case CREATE_PROPERTY_LISTING_ERROR:
    case DELETE_INVOICE_ERROR:
    case DELETE_EXPENSE_ERROR:
    case LOAD_COMPANY_CARDS_ERROR:
    case SAVE_EXPENSE_ERROR:
    case LOAD_EMAIL_TEMPLATES_ERROR:
    case DELETE_JOB_ERROR:
    case UPDATE_PROPERTY_KEY_LOG_ERROR:
    case LOAD_LOCKBOXES_ERROR:
    case ASSIGN_LOCKBOX_PROPERTY_ERROR:
    case SEND_OPPORTUNITY_WELCOME_EMAIL_ERROR:
    case UNASSIGN_LOCKBOX_PROPERTY_ERROR:
    case LOAD_MARKETS_ERROR:
    case LOAD_ZPL_TEMPLATES_ERROR:
    case DELETE_DOCUSIGN_ENVELOPE_ERROR:
    case UPDATE_CONTRACT_ADDENDUM_ERROR:
    case LOAD_USER_ERROR:
    case CREATE_INVOICE_ERROR:
    case UPDATE_APPLICATION_ERROR:
    case SAVE_CONTACT_ERROR:
    case CREATE_CONTACT_ERROR:
    case DELETE_CONTACT_ERROR: {
      const validation = parseValidation(action.error?.responseBody?.errors);
      return {
        ...state,
        error: action?.error?.responseBody?.message || action?.error?.message,
        validation,
        isLoading: false,
        isFailedFetch: isFailedFetch(action.error),
        isSubmitCommentLoading: false,
        isCompaniesLoading: false,
        isUsersLoading: false,
        isUsersLoaded: false,
        isTechniciansLoading: false,
        isCreatingTask: false,
        isLoadingTasks: false,
        isUpdatingTask: false,
        isSendingEmail: false,
        isSobjectLoading: false,
        isRenamingFile: false,
        isDeletingFile: false,
        isSchedulingAppointment: false,
        isUpdatingAppointment: false,
        isUpdatingAppointmentStatus: false,
        isEventsLoading: false,
        isLoadingSmsMessages: false,
        isSendingSms: false,
        isLoadingSmsTemplates: false,
        isUpdatingSmsMessage: false,
        isUpdatingJob: false,
        isMaintenanceGroupsLoading: false,
        isUpdatingInspectionStatus: false,
        isUpdatingPropertyRoutineInspections: false,
        isInspectionConfigLoading: false,
        isCommunicationDataLoading: false,
        isSavingInspection: false,
        isSavingProperty: false,
        isSavingWorkOrder: false,
        isSavingOpportunity: false,
        isSavingLead: false,
        isDeletingLead: false,
        isSavingContract: false,
        isLookingProperty: false,
        isSearching: false,
        isSearchingContacts: false,
        isLoadingWorkOrder: false,
        isLoadingWorkInspection: false,
        isCreatingLineItem: false,
        isUpdatingLineItem: false,
        isDeletingLineItem: false,
        isRecordTypesLoading: false,
        isLoadingProperty: false,
        isRunningCrontabJob: false,
        isSavingPropertyListing: false,
        isReservingNextAvailableLocker: false,
        isSavingLocker: false,
        isEnrichingProperty: false,
        isSavingPropertyOwnerSettings: false,
        isLoadingPropertyDetailsAndOwnerSettings: false,
        isDeletingFiles: false,
        isUpdatingFiles: false,
        isLoadingAccountsByType: false,
        isSavingInvoice: false,
        isCreatingPropertyListing: false,
        isDeletingInvoice: false,
        isDeletingExpense: false,
        isLoadingCompanyCards: false,
        isSavingExpense: false,
        isLoadingEmailTemplates: false,
        isUpdatingPropertyKeyLog: false,
        isDeletingJob: false,
        isLoadingLockboxes: false,
        isUpdatingLockbox: false,
        isAssigningLockboxProperty: false,
        isUnassigningLockboxProperty: false,
        isSendingOpportunityWelcomeEmail: false,
        isLoadingMarkets: false,
        isLoadingZplTemplates: false,
        isDeletingDocusignEnvelope: false,
        isUpdatingContractAddendum: false,
        isLoadingUser: false,
        isCreatingInvoice: false,
        isUpdatingApplication: false,
        isSavingContact: false,
        isCreatingContact: false,
        isDeletingContact: false,
      };
    }

    default:
      return state;
  }
}

export default appReducer;
