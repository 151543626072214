import React from 'react';
import { styled, keyframes } from '@mui/system';

const StyledLoadingIndicator = styled('div', {
  shouldForwardProp: (prop) => prop !== 'bgcolor',
})(({ bgcolor, theme }) => ({
  position: 'absolute',
  width: theme.spacing(8),
  height: theme.spacing(8),
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  div: {
    animation: `${keyFrame} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite`,
    transformOrigin: theme.spacing(4, 4),
    '&:after': {
      content: '" "',
      display: 'block',
      position: 'absolute',
      width: theme.spacing(0.75),
      height: theme.spacing(0.75),
      borderRadius: '50%',
      background: bgcolor === 'white' ? 'white' : 'grey',
      margin: '-3px 0 0 -3px',
      top: theme.spacing(6.25),
      left: theme.spacing(6.25),
    },
    '&>nth-of-type(1)': {
      animationDelay: '-0.036s',
      '&:after': {
        top: theme.spacing(6.25),
        left: theme.spacing(6.25),
      },
    },
    '&:nth-of-type(2)': {
      animationDelay: '-0.072s',
      '&:after': {
        top: theme.spacing(6.75),
        left: theme.spacing(5.625),
      },
    },
    '&:nth-of-type(3)': {
      animationDelay: '-0.108s',
      '&:after': {
        top: theme.spacing(7.125),
        left: theme.spacing(4.875),
      },
    },
    '&:nth-of-type(4)': {
      animationDelay: '-0.144s',
      '&:after': {
        top: theme.spacing(7.25),
        left: theme.spacing(4),
      },
    },
    '&:nth-of-type(5)': {
      animationDelay: '-0.18s',
      '&:after': {
        top: theme.spacing(7.125),
        left: theme.spacing(3.125),
      },
    },
    '&:nth-of-type(6)': {
      animationDelay: '-0.216s',
      '&:after': {
        top: theme.spacing(6.75),
        left: theme.spacing(2.375),
      },
    },
    '&:nth-of-type(7)': {
      animationDelay: '-0.252s',
      '&:after': {
        top: theme.spacing(6.25),
        left: theme.spacing(1.75),
      },
    },
    '&:nth-of-type(8)': {
      animationDelay: '-0.288s',
      '&:after': {
        top: theme.spacing(5.625),
        left: theme.spacing(1.25),
      },
    },
  },
}));

const keyFrame = keyframes`
  0% {
    transform: rotate(0deg)
  },

  100% {
    transform: rotate(360deg)
  }`;

const LoadingIndicator = (props) => (
  <StyledLoadingIndicator bgcolor={props.bgColor}>
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </StyledLoadingIndicator>
);

export default LoadingIndicator;
