import 'whatwg-fetch';
import React, { useEffect } from 'react';
import { string, number } from 'prop-types';
import { Alert, AlertTitle, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import timer from 'battery-friendly-timer';
import { useMergeState } from 'helpers/state';

/**
 * Java-like hashCode function for strings
 *
 * taken from http://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript-jquery/7616484#7616484
 */
/* eslint-disable no-bitwise */
const makeHash = (str) => {
  const len = str.length;
  let hash = 0;
  if (len === 0) return hash;
  let i;
  for (i = 0; i < len; i += 1) {
    hash = (hash << 5) - hash + str.charCodeAt(i);
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};
/* eslint-enable no-bitwise */

const StyledMessageAlert = styled(Alert)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 1111,
  '& button': {
    marginTop: theme.spacing(1),
  },
}));

const reloadApp = (e) => {
  e.preventDefault();
  window.location.reload();
};

let interval = null;
let previousHash = null;

const AutoReload = ({
  url = '/index.html',
  tryDelay = 10 * 60 * 1000, // 10 minutes
  forceDelay = 24 * 60 * 60 * 1000, // 1 day
}) => {
  const [state, setState] = useMergeState({
    codeHasChanged: false,
  });

  useEffect(() => {
    previousHash = null;
    fetchSource();
    interval = timer.setInterval(fetchSource, tryDelay, forceDelay);

    return () => {
      timer.clearInterval(interval);
    };
  }, []);

  const fetchSource = () =>
    fetch(`${url}?random=${Date.now()}`, {
      method: 'GET',
      headers: new Headers({
        pragma: 'no-cache',
        'Cache-Control': 'no-cache',
      }),
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error('offline');
        }
        return response.text();
      })
      .then((html) => {
        const hash = makeHash(html);
        if (!previousHash) {
          previousHash = hash;
          return;
        }
        if (previousHash !== hash) {
          previousHash = hash;
          setState({ codeHasChanged: true });
        }
      })
      .catch(() => {
        /* do nothing */
      });

  if (!state.codeHasChanged) return null;

  return (
    <StyledMessageAlert severity="warning" variant="filled">
      <AlertTitle>Can you please refresh?</AlertTitle>
      <Typography variant="body2">New app version is available!</Typography>
      <Button
        variant="outlined"
        color="inherit"
        size="small"
        onClick={reloadApp}
      >
        Click to refresh
      </Button>
    </StyledMessageAlert>
  );
};

AutoReload.propTypes = {
  url: string,
  tryDelay: number,
  forceDelay: number,
};

export default AutoReload;
