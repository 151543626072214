import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { noop } from 'lodash';
import {
  makeGetIsUsersLoadingSelector,
  makeGetPickListsSelector,
} from 'containers/App/selectors';
import {
  loadUsers,
  clearFlags,
  clearMessages,
  loadPickLists,
} from 'containers/App/actions';
import { loadVMData, clearVMData } from './actions';
import { makeGetPropertyManagersSelector } from './selectors';
import sharedSaga from './saga';

export default ({
  Component,
  reducer,
  saga,
  injectName = 'visualManagerDashboard',
  stateToProps = null,
  dispatchToProps = noop,
}) => {
  const mapDispatchToProps = (dispatch) => ({
    loadUsers: (data) => dispatch(loadUsers(data)),
    loadVMData: (params) => dispatch(loadVMData(params)),
    loadPickLists: (type) => dispatch(loadPickLists(type)),
    clearVMData: () => dispatch(clearVMData()),
    // for PageWrapper
    clearFlags: () => dispatch(clearFlags()),
    clearMessages: () => dispatch(clearMessages()),
    ...dispatchToProps(dispatch),
  });

  const mapStateToProps = createStructuredSelector({
    ...stateToProps,
    isUsersLoading: makeGetIsUsersLoadingSelector(),
    propertyManagers: makeGetPropertyManagersSelector(),
    pickLists: makeGetPickListsSelector(),
  });

  const withReducer = injectReducer({ key: injectName, reducer });
  const withConnect = connect(mapStateToProps, mapDispatchToProps);
  const withSharedSaga = injectSaga({
    key: 'visualSharedManager',
    saga: sharedSaga,
  });

  const toCompose = [withReducer, withSharedSaga, withConnect];
  if (saga) {
    toCompose.push(injectSaga({ key: injectName, saga }));
  }

  return compose(...toCompose)(Component);
};
