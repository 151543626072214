import React, { memo } from 'react';
import { string, object } from 'prop-types';
import { styled } from '@mui/system';
import WarningIcon from '@mui/icons-material/Warning';
import EmailIcon from '@mui/icons-material/Email';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import { Typography, Button, Link } from '@mui/material';

const StyledOopsPage = styled('section')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '70vh',
  paddingTop: theme.spacing(3),
}));

const StyledAction = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  width: '100%',
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(0),
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(3),
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const StyledSubtitle = styled(Typography)(({ theme }) => ({
  maxWidth: '90%',
  textAlign: 'center',
  marginBottom: theme.spacing(2),
}));

const StyledActionItem = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  width: '25%',
});

const StyledBoxSpan = styled('div')({
  width: '10%',
  height: '3px',
  backgroundColor: '#004a82',
  transitionDuration: '0.2s',
  marginBottom: '1rem',
  marginTop: '1rem',
});

const StyledActionTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '1.5rem',
    fontWeight: '500',
  },
}));

const StyledActionDescription = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '0.875rem',
  },
}));

const sxIcon = (theme) => ({
  fontSize: '2.7rem',
  marginBottom: '1rem',
  color: theme.palette.primary.main,
});

const OopsPage = ({
  companyFooterInfo = null,
  message = 'Sorry about this, but it looks like we ran into a bit of a problem pulling up what you were looking for.',
}) => (
  <StyledOopsPage>
    <Typography sx={{ mb: 2 }} variant="h4">
      OOPS...
    </Typography>
    <StyledSubtitle variant="h5">{message}</StyledSubtitle>
    <br />
    <Button
      variant="contained"
      color="primary"
      onClick={() => window.location.reload()}
    >
      Refresh Page
    </Button>
    <StyledAction>
      <StyledActionItem>
        <WarningIcon sx={sxIcon} />
        <StyledActionTitle variant="h5">
          Why am I seeing this?
        </StyledActionTitle>
        <StyledBoxSpan />
        <StyledActionDescription variant="body1">
          The site is under maintenance, you are using an old link that
          doesn&#39;t exist any longer, or there may be something wrong with our
          site at the moment.
        </StyledActionDescription>
      </StyledActionItem>
      <StyledActionItem>
        <PhoneForwardedIcon sx={sxIcon} />
        <StyledActionTitle variant="h5">Give us a call</StyledActionTitle>
        <StyledBoxSpan />
        <StyledActionDescription variant="body1">
          Want to chat? <br />
          Give us a call we are happy to help!
        </StyledActionDescription>
        <Link href={`tel:${companyFooterInfo?.phone}`}>
          {companyFooterInfo?.phone || 'Phone'}
        </Link>
      </StyledActionItem>
      <StyledActionItem>
        <EmailIcon sx={sxIcon} />
        <StyledActionTitle variant="h5">Shoot us an email</StyledActionTitle>
        <StyledBoxSpan />
        <StyledActionDescription variant="body1">
          Questions, comments & concerns are always welcome!
        </StyledActionDescription>
        <Link href={`mailto:${companyFooterInfo?.email}`}>
          {companyFooterInfo?.email || 'Email'}
        </Link>
      </StyledActionItem>
    </StyledAction>
  </StyledOopsPage>
);
OopsPage.propTypes = {
  message: string,
  companyFooterInfo: object,
};

export default memo(OopsPage);
