import React, { memo } from 'react';
import { string } from 'prop-types';
import { Tooltip, Link } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSalesforce } from '@fortawesome/free-brands-svg-icons';

const SalesforceCloudLink = ({ id = '', size = '1x', tooltip = '' }) => {
  const content = (
    <Link
      target="_blank"
      href={`${process.env.SALESFORCE_URL}/${id}`}
      rel="noreferrer noopener"
    >
      <FontAwesomeIcon icon={faSalesforce} size={size} color="#00a1e0" />
    </Link>
  );
  return (
    <>
      {tooltip ? (
        <Tooltip title={tooltip}>
          <div>{content}</div>
        </Tooltip>
      ) : (
        <>{content}</>
      )}
    </>
  );
};

SalesforceCloudLink.propTypes = {
  id: string,
  size: string,
  tooltip: string,
};

export default memo(SalesforceCloudLink);
