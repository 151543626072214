import Handlebars from 'handlebars/dist/cjs/handlebars';
import { emailTemplateVariableNames } from 'enums/emailTemplateVariableNames';
import { formatMoney, formatTextForPdf } from 'helpers/format';

// compare lvalue === rvalue
Handlebars.registerHelper(
  'compare',
  // NOTE: we need function directive here due to the scope
  // eslint-disable-next-line func-names
  function (lvalue, operator, rvalue, options) {
    if (options === undefined) {
      options = rvalue;
      rvalue = operator;
      operator = '===';
    }

    const operators = {
      // eslint-disable-next-line eqeqeq
      '==': (l, r) => l == r,
      '===': (l, r) => l === r,
      // eslint-disable-next-line eqeqeq
      '!=': (l, r) => l != r,
      '!==': (l, r) => l !== r,

      '<': (l, r) => l < r,
      '&lt;': (l, r) => l < r,

      '>': (l, r) => l > r,
      '&gt;': (l, r) => l > r,

      '<=': (l, r) => l <= r,
      '&lt;=': (l, r) => l <= r,

      '>=': (l, r) => l >= r,
      '&gt;=': (l, r) => l >= r,
    };

    if (!operators[operator]) {
      throw new Error(
        `Handlerbars Helper 'compare' doesn't know the operator ${operator}`,
      );
    }

    const result = operators[operator](lvalue, rvalue);
    if (result) {
      return options.fn(this);
    }
    return options.inverse(this);
  },
);

Handlebars.registerHelper(
  'formatMoney',
  // eslint-disable-next-line func-names, prefer-arrow-callback
  function (options) {
    return formatMoney(options);
  },
);

Handlebars.registerHelper(
  'isNumberEven',
  // eslint-disable-next-line func-names, prefer-arrow-callback
  function (index, options) {
    if (index % 2 === 0) {
      return options.fn(this);
    }
    return options.inverse(this);
  },
);

Handlebars.registerHelper(
  'joinWithSingleLineBrake',
  // eslint-disable-next-line func-names, prefer-arrow-callback
  function (array) {
    if (array && Array.isArray(array)) {
      const text = array.join('<br>');
      return new Handlebars.SafeString(text);
    }
    return null;
  },
);

Handlebars.registerHelper(
  'formatTextForPdf',
  // eslint-disable-next-line func-names, prefer-arrow-callback
  function (text) {
    const formattedText = formatTextForPdf(text);
    return new Handlebars.SafeString(formattedText);
  },
);

export const renderTemplate = (template, context) => {
  if (!template) {
    return '';
  }
  const compiled = Handlebars.compile(template);
  return compiled(context || {});
};

export const encodeBase64 = (str) => btoa(unescape(encodeURIComponent(str)));

export const decodeBase64 = (str) => decodeURIComponent(escape(atob(str)));

// useArea is communication || process-automation. If type is shared returns only shared names for useArea
export const getEmailTemplateVariableNames = ({ useArea = '', type = '' }) => {
  if (!useArea || !type) return {};

  const sharedNames = emailTemplateVariableNames?.[useArea]?.shared || {};

  if (type === 'shared') {
    return sharedNames;
  }

  const namesByType = emailTemplateVariableNames?.[useArea]?.[type] || {};

  return { ...sharedNames, ...namesByType };
};

export const invoiceTemplate = `
<div style='padding:0;line-height:1.3'>
  <div style='display:flex;align-items:center;justify-content:space-between'>
    <div style='flex-basis:50%'>
      <img style='width:65%' src='{{LOGO}}' alt='{{BILL_FROM_COMPANY_NAME}}' />
    </div>
    <div
      style='flex-basis:50%;display:flex;flex-direction:column;align-items:flex-end'
    >
      <div>{{INVOICE_FOR}} #{{ENTITY_NUMBER}}</div>
      <div style="font-size:24px"><strong>Invoice #{{INVOICE_NUMBER}}</strong></div>
      <div>Created Date: {{CREATED_DATE}}</div>
      <div>Due Date: {{DUE_DATE}}</div>
    </div>
  </div>
  <div
    style='display:flex;align-items:start;justify-content:space-between;margin-top:15px'
  >
    <div style="display:flex;flex-direction:column;">
      <div><strong>Bill To</strong></div>
      <div>{{joinWithSingleLineBrake BILL_TO}}</div>
    </div>
    <div style='display:flex;flex-direction:column;align-items:flex-start'>
      <div><strong>Service Address</strong></div>
      <div>{{joinWithSingleLineBrake SERVICE_ADDRESS}}</div>     
    </div>
    <div style='display:flex;flex-direction:column;align-items:flex-end'>
      <div><strong>Bill From</strong></div>
      <div style="text-align:right;">{{joinWithSingleLineBrake BILL_FROM}}</div>      
    </div>
  </div>  
  <div style="border-bottom:1px solid black;padding:0;margin: 5px;"></div>
  <div style='display:flex;align-items:center;justify-content:space-between'>
    <div>Item</div>
    <div>Public Notes</div>
    <div>Total</div>
  </div>
  {{#each LINE_ITEMS}}
    <div style='display:flex;align-items:center;justify-content:space-between;background-color:  {{#isNumberEven @index}}#EEEEEE{{else}}#D3D3D3{{/isNumberEven}};padding:5px;'>
      <div style="flex-basis:30%">{{subject}}</div>
      <div style="flex-basis:50%">{{publicNotes}}</div>
      <div style="flex-basis:20%;text-align:right;">{{formatMoney total}}</div>
    </div>
  {{/each}} 
  <div style='display:flex;margin-top:50px'>
    <div style='flex-basis:50%'></div>
    <div style='flex-basis:50%;font-size:16px'>
      <div style='display:flex;flex-direction:column'>
        <div
          style='display:flex;align-items:center;justify-content:space-between'
        >
          <div>Total</div>
          <div>{{formatMoney GRAND_TOTAL}}</div>
        </div>
        <div
          style='display:flex;align-items:center;justify-content:space-between'
        >
          <div>Amount Paid</div>
          <div>{{formatMoney AMOUNT_PAID}}</div>
        </div>
        <div style="border-bottom:1px solid black;padding:0;margin: 5px 0;"></div>
        <div
          style='display:flex;align-items:center;justify-content:space-between'
        >
          <div><strong>Amount Due</strong></div>
          <div><strong>{{formatMoney AMOUNT_DUE}}</strong></div>
        </div>
      </div>
    </div>
  </div>
</div>
`;
