import React, { memo } from 'react';
import { func, object } from 'prop-types';
import { styled } from '@mui/system';
import { truncate } from 'lodash';
import { IconButton, Typography } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';

const StyledOptionContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
});

const StyledNarrow = styled('span')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightLight,
}));

const StyledContractInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: 0,
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const StyledContractInfoLesser = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const StyledContractInfoTextTypography = styled(Typography)({
  fontSize: '0.75rem',
});

const AutocompleteOption = ({ option = null, onOpenCommunicationPanel }) => (
  <StyledOptionContainer>
    <div>
      {truncate(option.address, { length: 65 })}
      {option.ownerStatus ? (
        <StyledContractInfo>
          {!!option.pmName && (
            <StyledContractInfoTextTypography
              variant="subtitle2"
              color="textSecondary"
            >
              <StyledNarrow>PM</StyledNarrow>: {option.pmName}
            </StyledContractInfoTextTypography>
          )}

          <StyledContractInfoTextTypography
            variant="subtitle2"
            color="textSecondary"
          >
            <StyledNarrow>Owner</StyledNarrow>: {option.ownerStatus}
          </StyledContractInfoTextTypography>

          {!!option.tenantStatus && (
            <StyledContractInfoTextTypography
              variant="subtitle2"
              color="textSecondary"
            >
              <StyledNarrow>Tenant</StyledNarrow>: {option.tenantStatus}
            </StyledContractInfoTextTypography>
          )}
        </StyledContractInfo>
      ) : (
        <StyledContractInfoLesser>
          <StyledContractInfoTextTypography
            variant="subtitle2"
            color="textSecondary"
          >
            <StyledNarrow>Owner</StyledNarrow>: Not In Management
          </StyledContractInfoTextTypography>
        </StyledContractInfoLesser>
      )}
    </div>
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
        onOpenCommunicationPanel(option);
      }}
      size="large"
    >
      <ChatIcon />
    </IconButton>
  </StyledOptionContainer>
);

AutocompleteOption.propTypes = {
  option: object,
  onOpenCommunicationPanel: func.isRequired,
};

export default memo(AutocompleteOption);
