import React, { useCallback, useEffect } from 'react';
import { useMergeState } from 'helpers/state';
import Moment from 'moment';
import { object, func, bool, array } from 'prop-types';
import { constants as ReportTypes } from 'enums/reportType.enum';
import LockersTable from './LockersTable';
import MainLayout from '../components/MainLayout';

const LockerManager = (props) => {
  const {
    error = '',
    successMessage = '',
    isFailedFetch = false,
    isRunningCrontabJob = false,
    vmData = null,
    runCrontabJob,
    loadVMData,
    clearFlags,
    lockerLog = null,
    loadLockerHistoryLog,
    isLoadingLockerHistoryLog = false,
    markets,
  } = props;

  const [state, setState] = useMergeState({
    startDate: Moment().subtract(30, 'days').format('MM/DD/YYYY'),
    endDate: Moment().format('MM/DD/YYYY'),
  });

  useEffect(() => {
    // listen for iframe messages
    window.addEventListener('message', onIframeMessage);

    return () => {
      window.removeEventListener('message', onIframeMessage);
    };
  }, []);

  const onIframeMessage = (e) => {
    const { source, action, payload } = e.data || {};
    if (source === 'communicationPanel') {
      switch (action) {
        case 'savedData':
          if (payload?.isNeedRefreshData) {
            clearFlags();
            loadVMData({ reportType: ReportTypes.LOCKER_MANAGER });
          }
          break;

        default:
      }
    }
  };

  const onReleaseLockers = useCallback(
    () => runCrontabJob('releaseReservedLockers'),
    [],
  );

  const onLoadLockerHistoryLog = useCallback(
    () =>
      loadLockerHistoryLog({
        startDate: state.startDate,
        endDate: state.endDate,
      }),
    [state.startDate, state.endDate],
  );

  const onSelectDateRange = useCallback(
    ({ startDate, endDate }) => {
      setState({ startDate, endDate });
      loadLockerHistoryLog({
        startDate,
        endDate,
      });
    },
    [state.startDate, state.endDate],
  );

  return (
    <MainLayout
      {...props}
      isSkipLoadUsers
      isLoadMarkets
      error={error}
      successMessage={successMessage}
      isFailedFetch={isFailedFetch}
      reportType={ReportTypes.LOCKER_MANAGER}
      primaryContent={({ isLoading, onOpenSidebarIframe }) => (
        <LockersTable
          {...vmData}
          isLoading={
            isLoading || isRunningCrontabJob || isLoadingLockerHistoryLog
          }
          lockerLog={lockerLog}
          startDate={state.startDate}
          endDate={state.endDate}
          isLoadingLockerHistoryLog={isLoadingLockerHistoryLog}
          onSelectDateRange={onSelectDateRange}
          onLoadLockerHistoryLog={onLoadLockerHistoryLog}
          onOpenSidebarIframe={onOpenSidebarIframe}
          onReleaseLockers={onReleaseLockers}
          markets={markets}
        />
      )}
    />
  );
};

LockerManager.propTypes = {
  clearFlags: func.isRequired,
  vmData: object,
  loadVMData: func.isRequired,
  isRunningCrontabJob: bool,
  runCrontabJob: func.isRequired,
  loadLockerHistoryLog: func.isRequired,
  isLoadingLockerHistoryLog: bool,
  lockerLog: array,
  loadMarkets: func.isRequired,
  markets: array.isRequired,
};

export default LockerManager;
