// ------------------------------------
import { decodeHtmlEntities } from 'helpers/general';

export const convertGroup = (group) => {
  if (!group) {
    return null;
  }
  return {
    id: group.Id,
    name: decodeHtmlEntities(group.Name),
  };
};
