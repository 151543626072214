/* eslint-disable camelcase */
import moment from 'moment';
import { formatMoney } from './format';

const getRentalRateBreakdown = (contract) => {
  const rentalRateBreakdown = [];
  if (contract.Rent_Rate_Lease__c) {
    rentalRateBreakdown.push({
      label: 'Base Rent Rate',
      value: formatMoney(contract.Rent_Rate_Lease__c),
    });
  }
  if (contract.Pet_Rent__c) {
    rentalRateBreakdown.push({
      label: 'Pet Rent',
      value: formatMoney(contract.Pet_Rent__c),
    });
  }
  if (contract.Parking_Rent__c) {
    rentalRateBreakdown.push({
      label: 'Parking Rent',
      value: formatMoney(contract.Parking_Rent__c),
    });
  }
  if (contract.Storage_Rent__c) {
    rentalRateBreakdown.push({
      label: 'Storage Rent',
      value: formatMoney(contract.Storage_Rent__c),
    });
  }
  if (contract.RUBS__c) {
    rentalRateBreakdown.push({
      label: 'RUBS',
      value: formatMoney(contract.RUBS__c),
    });
  }
  if (contract.Other_Rent__c) {
    rentalRateBreakdown.push({
      label: 'Other Rent',
      value: formatMoney(contract.Other_Rent__c),
    });

    if (contract.Other_Rent_Description__c) {
      rentalRateBreakdown.push({
        label: 'Other Rent Description',
        value: contract.Other_Rent_Description__c,
      });
    }
  }

  return rentalRateBreakdown;
};

const getDepositsBreakdown = (contract) => {
  const depositsBreakdown = [];
  if (contract.Security_Deposit__c) {
    depositsBreakdown.push({
      label: 'Security Deposit',
      value: formatMoney(contract.Security_Deposit__c),
    });
  }
  if (contract.Pet_Deposit__c) {
    depositsBreakdown.push({
      label: 'Pet Deposit',
      value: formatMoney(contract.Pet_Deposit__c),
    });
  }
  if (contract.Other_Deposit__c) {
    depositsBreakdown.push({
      label: 'Other Deposit',
      value: formatMoney(contract.Other_Deposit__c),
    });

    if (contract.Other_Deposit_Description__c) {
      depositsBreakdown.push({
        label: 'Other Deposit Description',
        value: contract.Other_Deposit_Description__c,
      });
    }
  }

  return depositsBreakdown;
};

const сalculateLeasePercent = (tenantContract) => {
  const startMoment = moment
    .utc(tenantContract.StartDate, 'YYYY-MM-DD')
    .local();
  const endMoment = moment.utc(tenantContract.EndDate, 'YYYY-MM-DD').local();
  const today = moment();
  return Math.round(
    (Math.abs(today.toDate() - startMoment.toDate()) /
      Math.abs(endMoment.toDate() - startMoment.toDate())) *
      100,
  );
};

export const convertLease = (contract) => {
  if (!contract) {
    return null;
  }
  const leaseStatus = contract?.Status || '';

  let deposits = 0;
  if (contract?.Security_Deposit__c) {
    deposits += Number(contract.Security_Deposit__c);
  }
  if (contract?.Pet_Deposit__c) {
    deposits += Number(contract.Pet_Deposit__c);
  }
  if (contract?.Other_Deposit__c) {
    deposits += Number(contract.Other_Deposit__c);
  }

  const rentalRateBreakdown = getRentalRateBreakdown(contract);
  const depositsBreakdown = getDepositsBreakdown(contract);

  let leasePercent = 0;
  if (contract?.StartDate && contract?.EndDate) {
    leasePercent = сalculateLeasePercent(contract);
  }

  return {
    dateStart: contract?.StartDate || '',
    dateEnd: contract?.EndDate || '',
    primaryTenant: contract?.Contact_1__r?.FirstName || '',
    status: leaseStatus,
    statusWithProgress: `${leaseStatus} (${leasePercent}%)`,
    id: contract?.Id,
    rentRate: Number(contract?.Total_Rent_Rate__c) || 0,
    rentalRateBreakdown,
    deposits,
    depositsBreakdown,
    leasePercent,
  };
};

export const getOwnerContract = (property) => {
  if (!property) {
    return null;
  }
  let ownerContract = property.Active_Owner_Contract__r;
  if (!ownerContract || ownerContract.Status === 'Pending Termination') {
    ownerContract = property.Most_Recent_Owner_Agreement__r;
  }
  return ownerContract;
};

export const fixOutdatedMostRecentContract = (property) => {
  if (!property) {
    return null;
  }

  // delete the most recent if equal to the active
  if (
    property.mostRecentOwnerAgreement &&
    property.activeOwnerContract &&
    property.mostRecentOwnerAgreement.id === property.activeOwnerContract.id
  ) {
    delete property.mostRecentOwnerAgreement;
  }

  // delete the previous if equal to the active
  if (
    property.previousOwnerAgreement &&
    property.activeOwnerContract &&
    property.previousOwnerAgreement.id === property.activeOwnerContract.id
  ) {
    delete property.previousTenantAgreement;
  }

  // delete the most recent if equal to the previous
  if (
    property.mostRecentOwnerAgreement &&
    property.previousOwnerAgreement &&
    property.mostRecentOwnerAgreement.id === property.previousOwnerAgreement.id
  ) {
    delete property.mostRecentOwnerAgreement;
  }

  if (property.mostRecentOwnerAgreement) {
    const dateStart = moment(
      property.mostRecentOwnerAgreement.dateStart,
      'MM/DD/YYYY',
    );
    const today = moment().startOf('day');
    if (dateStart < today) {
      // override
      property.previousOwnerAgreement = property.mostRecentOwnerAgreement;
      // delete most recent
      delete property.mostRecentOwnerAgreement;
    }
  }

  // ------------------------------------------------
  // delete the most recent if equal to the active
  if (
    property.mostRecentTenantAgreement &&
    property.activeTenantContract &&
    property.mostRecentTenantAgreement.id === property.activeTenantContract.id
  ) {
    delete property.mostRecentTenantAgreement;
  }

  // delete the previous if equal to the active
  if (
    property.previousTenantAgreement &&
    property.activeTenantContract &&
    property.previousTenantAgreement?.id === property.activeTenantContract?.id
  ) {
    delete property.previousTenantAgreement;
  }

  // delete the most recent if equal to the previous
  if (
    property.mostRecentTenantAgreement &&
    property.previousTenantAgreement &&
    property.mostRecentTenantAgreement?.id ===
      property.previousTenantAgreement?.id
  ) {
    delete property.mostRecentTenantAgreement;
  }

  if (property.mostRecentTenantAgreement) {
    const dateStart = moment(
      property.mostRecentTenantAgreement.dateStart,
      'MM/DD/YYYY',
    );
    const dateEnd = moment(
      property.mostRecentTenantAgreement.dateEnd,
      'MM/DD/YYYY',
    );
    const today = moment().startOf('day');

    if (dateStart < today && dateEnd <= today) {
      // override
      property.previousTenantAgreement = property.mostRecentTenantAgreement;
      // delete most recent
      delete property.mostRecentTenantAgreement;
    }
  }

  return property;
};
