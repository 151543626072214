import CompanyId from 'enums/companyId';
import Mammoth from 'companyInfo/mammoth_services.json';
import Onq from 'companyInfo/onqpm.json';

const company = {
  getAccountIdByWorkOrderRecordType(recordTypeId) {
    if (Mammoth.SalesforceIds?.WorkOrder === recordTypeId) {
      return Mammoth.SalesforceIds.Account;
    }
    if (Onq.SalesforceIds?.WorkOrder === recordTypeId) {
      return Onq.SalesforceIds.Account;
    }
    return '';
  },

  getAccountIdByCompanyId(companyId) {
    if (CompanyId.MAMMOTH === companyId) {
      return Mammoth.SalesforceIds.Account;
    }
    if (CompanyId.ONQ === companyId) {
      return Onq.SalesforceIds.Account;
    }
    return '';
  },

  getIdByDomain() {
    // for local dev testing
    if (window.location.hostname.includes('localhost')) {
      return CompanyId.ONQ;
    }
    if (window.location.hostname.includes(CompanyId.ONQ)) {
      return CompanyId.ONQ;
    }
    return CompanyId.MAMMOTH;
  },

  setId(companyId) {
    localStorage.setItem('companyId', companyId);
  },

  convertNameToId(companyName) {
    if (companyName) {
      let name = 'On Q Property Managment'.toLowerCase();
      if (
        name.includes(companyName.toLowerCase()) ||
        companyName === CompanyId.ONQ
      ) {
        return CompanyId.ONQ;
      }

      name = 'Mammoth Services'.toLowerCase();
      if (
        name.includes(companyName.toLowerCase()) ||
        companyName === CompanyId.MAMMOTH
      ) {
        return CompanyId.MAMMOTH;
      }
    }
    return '';
  },

  getId() {
    return localStorage.getItem('companyId');
  },
};

export default company;
