import {
  convertOwnerContract,
  getStarterPropertyOwnerSettings,
  prepareServicesListAndHOA,
} from 'helpers/rentalAnalysisReport';

export const convertPropertyDetailsAndOwnerSettings = (property) => {
  if (!property) {
    return null;
  }

  const swimmingPool = !!property.Swimming_Pool__c;
  const ownerContract = property.Active_Owner_Contract__r;
  const activeOwnerContract = convertOwnerContract({
    contract: ownerContract,
  });

  const propertyOwnerSettings = getStarterPropertyOwnerSettings({
    swimmingPool,
    activeOwnerContract: ownerContract,
    relatedCrmProperty: property,
  });

  const servicesToConvert =
    property?.Home_Warranty__r?.records || property?.Property_Services__r || [];

  const { servicesList, hoaService } = prepareServicesListAndHOA({
    servicesToConvert,
  });

  // add street view image to the list
  let images = property.MarketingImages || [];
  if (property.StreetViewImage) {
    images = [property.StreetViewImage].concat(images);
  }

  return {
    id: property.Id || '',
    swimmingPool,
    propertyType: property.Property_Type__c || '',
    yearBuilt: property.Year_Built__c || '',
    squareFootage: property.Square_Footage__c || 0,
    floors: property.Number_of_Floors__c || 1,
    bedrooms: property.Number_of_Beds__c || 0,
    bathrooms: property.Number_of_Baths__c || 0,
    parkingType: property.Parking_Type__c || '',
    matterportLink: property.Matterport_Link__c || '',
    mlsCompsLink: property.MLS_Comps_Link__c || '',
    hoa: !!property.HOA__c,
    hvac: property.HVAC__c || '',
    lotSquareFootage: property.Lot_Square_Footage__c || 0,
    marketingHeading: property.Marketing_Heading__c || '',
    marketingDescription: property.Marketing_Description__c || '',
    latitude: property.Latitude__c || 0,
    longitude: property.Longitude__c || 0,
    streetViewImage: property.StreetViewImage?.url,
    images,
    propertyOwnerSettings,
    activeOwnerContract,
    servicesList,
    hoaService,
  };
};
