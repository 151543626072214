import request from 'utils/request';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  loadLockerLog,
  loadLockerLogSuccess,
  loadLockerLogError,
} from './reducer';

export function* loadLockerLogReq({ payload: { startDate, endDate } }) {
  try {
    const result = yield call(
      request,
      `${process.env.API_URL}/v1/locker/history-logs?startDate=${startDate}&endDate=${endDate}`,
      {
        method: 'GET',
      },
    );
    yield put(loadLockerLogSuccess(result));
  } catch (error) {
    yield put(loadLockerLogError(error));
  }
}

export default function* LockerLog() {
  yield takeLatest(loadLockerLog.type, loadLockerLogReq);
}
