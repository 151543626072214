/* eslint-disable camelcase */
import { createSelector } from 'reselect';

const getGlobalState = (state) => state.global;

const getUsers = createSelector(getGlobalState, (state) => state.users || []);
export const makeGetPropertyManagersSelector = () =>
  createSelector(getUsers, (users) => {
    // collect dropdown items for PMs list
    const filters = users
      .filter((u) =>
        ['Property Manager', 'Director Property Management'].includes(
          u.UserRole?.Name,
        ),
      )
      .map((pm) => {
        // Use first name as label
        const firstName = pm.Name.split(' ')[0];
        const ids = [pm.Id, pm.Assistant__r?.Id].filter(Boolean);
        return {
          value: ids.join(','),
          label: `${firstName}'s Team`,
        };
      });

    return [{ value: '', label: 'All Teams' }, ...filters];
  });
