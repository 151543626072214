import React, { memo, useState, useCallback } from 'react';
import { styled } from '@mui/system';
import { func, string, bool, array, node } from 'prop-types';
import { AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import UserMenu from './UserMenu';
import PrimaryMenu from './PrimaryMenu';
import SearchForm from './SearchForm';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: `${theme.spacing(7)} !important`,
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  paddingLeft: '1rem',
  paddingRight: '1rem',
}));

const CustomContentWrapper = styled('div')(({ theme, hasMargin }) => ({
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  marginRight: hasMargin ? theme.spacing(4) : 0,
}));

const Title = styled(Typography)({
  flexGrow: 1,
  textAlign: 'left',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const Logo = styled('img')(({ theme }) => ({
  height: 45,
  marginRight: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const Header = ({
  logo = '',
  title = '',
  companyName = '',
  isManagerIconsVisible = false,
  isSearching = false,
  foundProperties = [],
  customHeaderContent = null,
  searchRecords,
  isInIframe = false,
}) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const onToggleMenu = useCallback(
    () => setMenuOpen(!isMenuOpen),
    [isMenuOpen],
  );

  return (
    <AppBar>
      <StyledToolbar>
        {!!logo && <Logo src={logo} alt={companyName} title={companyName} />}
        {isManagerIconsVisible &&
          !isInIframe && [
            <StyledIconButton
              size="small"
              color="inherit"
              key="menu-button"
              onClick={onToggleMenu}
            >
              <MenuIcon />
            </StyledIconButton>,

            <PrimaryMenu
              key="primary-menu"
              isMenuOpen={isMenuOpen}
              onMenuClose={onToggleMenu}
            />,
          ]}
        {!!title && <Title variant="h5">{title}</Title>}
        {!!customHeaderContent && (
          <CustomContentWrapper hasMargin={isManagerIconsVisible}>
            {customHeaderContent}
          </CustomContentWrapper>
        )}
        {isManagerIconsVisible && (
          <SearchForm
            isSearching={isSearching}
            foundProperties={foundProperties}
            searchRecords={searchRecords}
          />
        )}
        <UserMenu />
      </StyledToolbar>
    </AppBar>
  );
};

Header.propTypes = {
  logo: string,
  title: string,
  companyName: string,
  isManagerIconsVisible: bool,
  isSearching: bool,
  foundProperties: array,
  customHeaderContent: node,
  searchRecords: func,
  isInIframe: bool,
};

export default memo(Header);
