/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const CLEAR_DATA = 'boilerplate/App/CLEAR_DATA';
export const CLEAR_MESSAGES = 'boilerplate/App/CLEAR_MESSAGES';
export const CLEAR_FLAGS = 'boilerplate/App/CLEAR_FLAGS';
export const CLEAR_SOBJET = 'boilerplate/App/CLEAR_SOBJET';
export const CLEAR_JOBS = 'boilerplate/App/CLEAR_JOBS';
export const CLEAR_LINE_ITEM_CACHE = 'boilerplate/App/CLEAR_LINE_ITEM_CACHE';

export const LOAD_WORK_ORDER = 'boilerplate/App/LOAD_WORK_ORDER';
export const LOAD_WORK_ORDER_SUCCESS =
  'boilerplate/App/LOAD_WORK_ORDER_SUCCESS';
export const LOAD_WORK_ORDER_ERROR = 'boilerplate/App/LOAD_WORK_ORDER_ERROR';

export const LOAD_COMPANY_INFO = 'boilerplate/App/LOAD_COMPANY_INFO';
export const LOAD_COMPANY_INFO_SUCCESS =
  'boilerplate/App/LOAD_COMPANY_INFO_SUCCESS';
export const LOAD_COMPANY_INFO_ERROR =
  'boilerplate/App/LOAD_COMPANY_INFO_ERROR';

export const LOAD_COMPANIES = 'boilerplate/App/LOAD_COMPANIES';
export const LOAD_COMPANIES_SUCCESS = 'boilerplate/App/LOAD_COMPANIES_SUCCESS';
export const LOAD_COMPANIES_ERROR = 'boilerplate/App/LOAD_COMPANIES_ERROR';

export const SUBMIT_COMMENT = 'boilerplate/App/SUBMIT_COMMENT';
export const SUBMIT_COMMENT_SUCCESS = 'boilerplate/App/SUBMIT_COMMENT_SUCCESS';
export const SUBMIT_COMMENT_ERROR = 'boilerplate/App/SUBMIT_COMMENT_ERROR';

export const LOAD_USERS = 'boilerplate/App/LOAD_USERS';
export const LOAD_USERS_SUCCESS = 'boilerplate/App/LOAD_USERS_SUCCESS';
export const LOAD_USERS_ERROR = 'boilerplate/App/LOAD_USERS_ERROR';

export const LOAD_TECHNICIANS = 'boilerplate/App/LOAD_TECHNICIANS';
export const LOAD_TECHNICIANS_SUCCESS =
  'boilerplate/App/LOAD_TECHNICIANS_SUCCESS';
export const LOAD_TECHNICIANS_ERROR = 'boilerplate/App/LOAD_TECHNICIANS_ERROR';

export const UPDATE_TASK = 'boilerplate/App/UPDATE_TASK';
export const UPDATE_TASK_SUCCESS = 'boilerplate/App/UPDATE_TASK_SUCCESS';
export const UPDATE_TASK_ERROR = 'boilerplate/App/UPDATE_TASK_ERROR';

export const CREATE_TASK = 'boilerplate/App/CREATE_TASK';
export const CREATE_TASK_SUCCESS = 'boilerplate/App/CREATE_TASK_SUCCESS';
export const CREATE_TASK_ERROR = 'boilerplate/App/CREATE_TASK_ERROR';

export const SEND_EMAIL = 'boilerplate/App/SEND_EMAIL';
export const SEND_EMAIL_SUCCESS = 'boilerplate/App/SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_ERROR = 'boilerplate/App/SEND_EMAIL_ERROR';

export const LOG_NEW_ACTIVITY = 'boilerplate/App/LOG_NEW_ACTIVITY';
export const CANCEL_NEW_ACTIVITY = 'boilerplate/App/CANCEL_NEW_ACTIVITY';

export const LOAD_SOBJECT = 'boilerplate/App/LOAD_SOBJECT';
export const LOAD_SOBJECT_SUCCESS = 'boilerplate/App/LOAD_SOBJECT_SUCCESS';
export const LOAD_SOBJECT_ERROR = 'boilerplate/App/LOAD_SOBJECT_ERROR';

export const LOAD_PICK_LISTS = 'boilerplate/App/LOAD_PICK_LISTS';
export const LOAD_PICK_LISTS_ERROR = 'boilerplate/App/LOAD_PICK_LISTS_ERROR';
export const LOAD_PICK_LISTS_SUCCESS =
  'boilerplate/App/LOAD_PICK_LISTS_SUCCESS';

export const LOAD_RECORD_TYPES = 'boilerplate/App/LOAD_RECORD_TYPES';
export const LOAD_RECORD_TYPES_ERROR =
  'boilerplate/App/LOAD_RECORD_TYPES_ERROR';
export const LOAD_RECORD_TYPES_SUCCESS =
  'boilerplate/App/LOAD_RECORD_TYPES_SUCCESS';

export const SEARCH_RECORDS = 'boilerplate/App/SEARCH_RECORDS';
export const SEARCH_RECORDS_SUCCESS = 'boilerplate/App/SEARCH_RECORDS_SUCCESS';
export const SEARCH_RECORDS_ERROR = 'boilerplate/App/SEARCH_RECORDS_ERROR';
export const CLEAR_SEARCH_RECORDS = 'boilerplate/App/CLEAR_SEARCH_RECORDS';

export const DELETE_SOBJECT_FILE = 'boilerplate/App/DELETE_SOBJECT_FILE';
export const DELETE_SOBJECT_FILE_SUCCESS =
  'boilerplate/App/DELETE_SOBJECT_FILE_SUCCESS';
export const DELETE_SOBJECT_FILE_ERROR =
  'boilerplate/App/DELETE_SOBJECT_FILE_ERROR';
export const RENAME_SOBJECT_FILE = 'boilerplate/App/RENAME_SOBJECT_FILE';
export const RENAME_SOBJECT_FILE_SUCCESS =
  'boilerplate/App/RENAME_SOBJECT_FILE_SUCCESS';
export const RENAME_SOBJECT_FILE_ERROR =
  'boilerplate/App/RENAME_SOBJECT_FILE_ERROR';

export const LOAD_JOB = 'boilerplate/App/LOAD_JOB';
export const LOAD_JOB_SUCCESS = 'boilerplate/App/LOAD_JOB_SUCCESS';
export const LOAD_JOB_ERROR = 'boilerplate/App/LOAD_JOB_ERROR';

export const LOAD_JOBS = 'boilerplate/App/LOAD_JOBS';
export const LOAD_JOBS_SUCCESS = 'boilerplate/App/LOAD_JOBS_SUCCESS';
export const LOAD_JOBS_ERROR = 'boilerplate/App/LOAD_JOBS_ERROR';

export const SCHEDULE_APPOINTMENT = 'boilerplate/App/SCHEDULE_APPOINTMENT';
export const SCHEDULE_APPOINTMENT_SUCCESS =
  'boilerplate/App/SCHEDULE_APPOINTMENT_SUCCESS';
export const SCHEDULE_APPOINTMENT_ERROR =
  'boilerplate/App/SCHEDULE_APPOINTMENT_ERROR';

export const UPDATE_APPOINTMENT = 'boilerplate/App/UPDATE_APPOINTMENT';
export const UPDATE_APPOINTMENT_SUCCESS =
  'boilerplate/App/UPDATE_APPOINTMENT_SUCCESS';
export const UPDATE_APPOINTMENT_ERROR =
  'boilerplate/App/UPDATE_APPOINTMENT_ERROR';

export const UPDATE_APPOINTMENT_STATUS =
  'boilerplate/App/UPDATE_APPOINTMENT_STATUS';
export const UPDATE_APPOINTMENT_STATUS_SUCCESS =
  'boilerplate/App/UPDATE_APPOINTMENT_STATUS_SUCCESS';
export const UPDATE_APPOINTMENT_STATUS_ERROR =
  'boilerplate/App/UPDATE_APPOINTMENT_STATUS_ERROR';

export const LOAD_EVENTS = 'boilerplate/App/LOAD_EVENTS';
export const LOAD_EVENTS_SUCCESS = 'boilerplate/App/LOAD_EVENTS_SUCCESS';
export const LOAD_EVENTS_ERROR = 'boilerplate/App/LOAD_EVENTS_ERROR';

export const CLEAR_DATES = 'boilerplate/App/CLEAR_DATES';

export const LOAD_SMS_MESSAGES = 'boilerplate/App/LOAD_SMS_MESSAGES';
export const LOAD_SMS_MESSAGES_SUCCESS =
  'boilerplate/App/LOAD_SMS_MESSAGES_SUCCESS';
export const LOAD_SMS_MESSAGES_ERROR =
  'boilerplate/App/LOAD_SMS_MESSAGES_ERROR';

export const SEND_SMS = 'boilerplate/App/SEND_SMS';
export const SEND_SMS_SUCCESS = 'boilerplate/App/SEND_SMS_SUCCESS';
export const SEND_SMS_ERROR = 'boilerplate/App/SEND_SMS_ERROR';

export const LOAD_SMS_TEMPLATES = 'boilerplate/App/LOAD_SMS_TEMPLATES';
export const LOAD_SMS_TEMPLATES_SUCCESS =
  'boilerplate/App/LOAD_SMS_TEMPLATES_SUCCESS';
export const LOAD_SMS_TEMPLATES_ERROR =
  'boilerplate/App/LOAD_SMS_TEMPLATES_ERROR';

export const UPDATE_SMS_MESSAGE = 'boilerplate/App/UPDATE_SMS_MESSAGE';
export const UPDATE_SMS_MESSAGE_SUCCESS =
  'boilerplate/App/UPDATE_SMS_MESSAGE_SUCCESS';
export const UPDATE_SMS_MESSAGE_ERROR =
  'boilerplate/App/UPDATE_SMS_MESSAGE_ERROR';

export const UPDATE_JOB = 'boilerplate/App/UPDATE_JOB';
export const UPDATE_JOB_SUCCESS = 'boilerplate/App/UPDATE_JOB_SUCCESS';
export const UPDATE_JOB_ERROR = 'boilerplate/App/UPDATE_JOB_ERROR';

export const LOAD_MAINTENANCE_GROUPS =
  'boilerplate/App/LOAD_MAINTENANCE_GROUPS';
export const LOAD_MAINTENANCE_GROUPS_SUCCESS =
  'boilerplate/App/LOAD_MAINTENANCE_GROUPS_SUCCESS';
export const LOAD_MAINTENANCE_GROUPS_ERROR =
  'boilerplate/App/LOAD_MAINTENANCE_GROUPS_ERROR';

export const UPDATE_INSPECTION_STATUS =
  'boilerplate/App/UPDATE_INSPECTION_STATUS';
export const UPDATE_INSPECTION_STATUS_SUCCESS =
  'boilerplate/App/UPDATE_INSPECTION_STATUS_SUCCESS';
export const UPDATE_INSPECTION_STATUS_ERROR =
  'boilerplate/App/UPDATE_INSPECTION_STATUS_ERROR';

export const UPDATE_PROPERTY_ROUTINE_INSPECTIONS =
  'boilerplate/App/UPDATE_PROPERTY_ROUTINE_INSPECTIONS';
export const UPDATE_PROPERTY_ROUTINE_INSPECTIONS_SUCCESS =
  'boilerplate/App/UPDATE_PROPERTY_ROUTINE_INSPECTIONS_SUCCESS';
export const UPDATE_PROPERTY_ROUTINE_INSPECTIONS_ERROR =
  'boilerplate/App/UPDATE_PROPERTY_ROUTINE_INSPECTIONS_ERROR';

export const CLEAR_COMMUNICATION_DATA =
  'boilerplate/App/CLEAR_COMMUNICATION_DATA';

export const LOAD_COMMUNICATION_DATA =
  'boilerplate/App/LOAD_COMMUNICATION_DATA';
export const LOAD_COMMUNICATION_DATA_SUCCESS =
  'boilerplate/App/LOAD_COMMUNICATION_DATA_SUCCESS';
export const LOAD_COMMUNICATION_DATA_ERROR =
  'boilerplate/App/LOAD_COMMUNICATION_DATA_ERROR';

export const SAVE_INSPECTION = 'boilerplate/App/SAVE_INSPECTION';
export const SAVE_INSPECTION_SUCCESS =
  'boilerplate/App/SAVE_INSPECTION_SUCCESS';
export const SAVE_INSPECTION_ERROR = 'boilerplate/App/SAVE_INSPECTION_ERROR';

export const SAVE_PROPERTY = 'boilerplate/App/SAVE_PROPERTY';
export const SAVE_PROPERTY_SUCCESS = 'boilerplate/App/SAVE_PROPERTY_SUCCESS';
export const SAVE_PROPERTY_ERROR = 'boilerplate/App/SAVE_PROPERTY_ERROR';

export const SAVE_PROPERTY_OWNER_SETTINGS =
  'boilerplate/App/SAVE_PROPERTY_OWNER_SETTINGS';
export const SAVE_PROPERTY_OWNER_SETTINGS_SUCCESS =
  'boilerplate/App/SAVE_PROPERTY_OWNER_SETTINGS_SUCCESS';
export const SAVE_PROPERTY_OWNER_SETTINGS_ERROR =
  'boilerplate/App/SAVE_PROPERTY_OWNER_SETTINGS_ERROR';

export const SAVE_WORK_ORDER = 'boilerplate/App/SAVE_WORK_ORDER';
export const SAVE_WORK_ORDER_SUCCESS =
  'boilerplate/App/SAVE_WORK_ORDER_SUCCESS';
export const SAVE_WORK_ORDER_ERROR = 'boilerplate/App/SAVE_WORK_ORDER_ERROR';

export const SAVE_OPPORTUNITY = 'boilerplate/App/SAVE_OPPORTUNITY';
export const SAVE_OPPORTUNITY_SUCCESS =
  'boilerplate/App/SAVE_OPPORTUNITY_SUCCESS';
export const SAVE_OPPORTUNITY_ERROR = 'boilerplate/App/SAVE_OPPORTUNITY_ERROR';

export const SAVE_LEAD = 'boilerplate/App/SAVE_LEAD';
export const SAVE_LEAD_SUCCESS = 'boilerplate/App/SAVE_LEAD_SUCCESS';
export const SAVE_LEAD_ERROR = 'boilerplate/App/SAVE_LEAD_ERROR';

export const DELETE_LEAD = 'boilerplate/App/DELETE_LEAD';
export const DELETE_LEAD_SUCCESS = 'boilerplate/App/DELETE_LEAD_SUCCESS';
export const DELETE_LEAD_ERROR = 'boilerplate/App/DELETE_LEAD_ERROR';

export const SAVE_CONTRACT = 'boilerplate/App/SAVE_CONTRACT';
export const SAVE_CONTRACT_SUCCESS = 'boilerplate/App/SAVE_CONTRACT_SUCCESS';
export const SAVE_CONTRACT_ERROR = 'boilerplate/App/SAVE_CONTRACT_ERROR';

export const SAVE_PROPERTY_LISTING = 'boilerplate/App/SAVE_PROPERTY_LISTING';
export const SAVE_PROPERTY_LISTING_SUCCESS =
  'boilerplate/App/SAVE_PROPERTY_LISTING_SUCCESS';
export const SAVE_PROPERTY_LISTING_ERROR =
  'boilerplate/App/SAVE_PROPERTY_LISTING_ERROR';

export const SAVE_LOCKER = 'boilerplate/App/SAVE_LOCKER';
export const SAVE_LOCKER_SUCCESS = 'boilerplate/App/SAVE_LOCKER_SUCCESS';
export const SAVE_LOCKER_ERROR = 'boilerplate/App/SAVE_LOCKER_ERROR';

export const LOOKUP_PROPERTY = 'boilerplate/App/LOOKUP_PROPERTY';
export const LOOKUP_PROPERTY_SUCCESS =
  'boilerplate/App/LOOKUP_PROPERTY_SUCCESS';
export const LOOKUP_PROPERTY_ERROR = 'boilerplate/App/LOOKUP_PROPERTY_ERROR';

export const SEARCH_CONTACTS = 'boilerplate/App/SEARCH_CONTACTS';
export const SEARCH_CONTACTS_SUCCESS =
  'boilerplate/App/SEARCH_CONTACTS_SUCCESS';
export const SEARCH_CONTACTS_ERROR = 'boilerplate/App/SEARCH_CONTACTS_ERROR';

export const LOAD_INSPECTION = 'boilerplate/App/LOAD_INSPECTION';
export const LOAD_INSPECTION_SUCCESS =
  'boilerplate/App/LOAD_INSPECTION_SUCCESS';
export const LOAD_INSPECTION_ERROR = 'boilerplate/App/LOAD_INSPECTION_ERROR';

export const UPDATE_LINE_ITEM = 'boilerplate/App/UPDATE_LINE_ITEM';
export const UPDATE_LINE_ITEM_SUCCESS =
  'boilerplate/App/UPDATE_LINE_ITEM_SUCCESS';
export const UPDATE_LINE_ITEM_ERROR = 'boilerplate/App/UPDATE_LINE_ITEM_ERROR';

export const CREATE_LINE_ITEM = 'boilerplate/App/CREATE_LINE_ITEM';
export const CREATE_LINE_ITEM_SUCCESS =
  'boilerplate/App/CREATE_LINE_ITEM_SUCCESS';
export const CREATE_LINE_ITEM_ERROR = 'boilerplate/App/CREATE_LINE_ITEM_ERROR';

export const DELETE_LINE_ITEM = 'boilerplate/App/DELETE_LINE_ITEM';
export const DELETE_LINE_ITEM_SUCCESS =
  'boilerplate/App/DELETE_LINE_ITEM_SUCCESS';
export const DELETE_LINE_ITEM_ERROR = 'boilerplate/App/DELETE_LINE_ITEM_ERROR';

export const LOAD_PROPERTY = 'boilerplate/App/LOAD_PROPERTY';
export const LOAD_PROPERTY_SUCCESS = 'boilerplate/App/LOAD_PROPERTY_SUCCESS';
export const LOAD_PROPERTY_ERROR = 'boilerplate/App/LOAD_PROPERTY_ERROR';

export const RUN_CRONTAB_JOB = 'boilerplate/App/RUN_CRONTAB_JOB';
export const RUN_CRONTAB_JOB_SUCCESS =
  'boilerplate/App/RUN_CRONTAB_JOB_SUCCESS';
export const RUN_CRONTAB_JOB_ERROR = 'boilerplate/App/RUN_CRONTAB_JOB_ERROR';

export const LOAD_INVOICE = 'boilerplate/App/LOAD_INVOICE';
export const LOAD_INVOICE_SUCCESS = 'boilerplate/App/LOAD_INVOICE_SUCCESS';
export const LOAD_INVOICE_ERROR = 'boilerplate/App/LOAD_INVOICE_ERROR';

export const SAVE_INVOICE = 'boilerplate/App/SAVE_INVOICE';
export const SAVE_INVOICE_SUCCESS = 'boilerplate/App/SAVE_INVOICE_SUCCESS';
export const SAVE_INVOICE_ERROR = 'boilerplate/App/SAVE_INVOICE_ERROR';

export const RESERVE_NEXT_AVAILABLE_LOCKER =
  'boilerplate/App/RESERVE_NEXT_AVAILABLE_LOCKER';
export const RESERVE_NEXT_AVAILABLE_LOCKER_SUCCESS =
  'boilerplate/App/RESERVE_NEXT_AVAILABLE_LOCKER_SUCCESS';
export const RESERVE_NEXT_AVAILABLE_LOCKER_ERROR =
  'boilerplate/App/RESERVE_NEXT_AVAILABLE_LOCKER_ERROR';

export const ENRICH_PROPERTY = 'boilerplate/App/ENRICH_PROPERTY';
export const ENRICH_PROPERTY_SUCCESS =
  'boilerplate/App/ENRICH_PROPERTY_SUCCESS';
export const ENRICH_PROPERTY_ERROR = 'boilerplate/App/ENRICH_PROPERTY_ERROR';

export const LOAD_PROPERTY_DETAILS_AND_OWNER_SETTINGS =
  'boilerplate/App/LOAD_PROPERTY_DETAILS_AND_OWNER_SETTINGS';
export const LOAD_PROPERTY_DETAILS_AND_OWNER_SETTINGS_SUCCESS =
  'boilerplate/App/LOAD_PROPERTY_DETAILS_AND_OWNER_SETTINGS_SUCCESS';
export const LOAD_PROPERTY_DETAILS_AND_OWNER_SETTINGS_ERROR =
  'boilerplate/App/LOAD_PROPERTY_DETAILS_AND_OWNER_SETTINGS_ERROR';

export const DELETE_FILES = 'boilerplate/App/DELETE_FILES';
export const DELETE_FILES_SUCCESS = 'boilerplate/App/DELETE_FILES_SUCCESS';
export const DELETE_FILES_ERROR = 'boilerplate/App/DELETE_FILES_ERROR';

export const UPDATE_FILES = 'boilerplate/App/UPDATE_FILES';
export const UPDATE_FILES_SUCCESS = 'boilerplate/App/UPDATE_FILES_SUCCESS';
export const UPDATE_FILES_ERROR = 'boilerplate/App/UPDATE_FILES_ERROR';

export const LOAD_ACCOUNTS_BY_TYPE = 'boilerplate/App/LOAD_ACCOUNTS_BY_TYPE';
export const LOAD_ACCOUNTS_BY_TYPE_SUCCESS =
  'boilerplate/App/LOAD_ACCOUNTS_BY_TYPE_SUCCESS';
export const LOAD_ACCOUNTS_BY_TYPE_ERROR =
  'boilerplate/App/LOAD_ACCOUNTS_BY_TYPE_ERROR';

export const CREATE_PROPERTY_LISTING =
  'boilerplate/App/CREATE_PROPERTY_LISTING';
export const CREATE_PROPERTY_LISTING_SUCCESS =
  'boilerplate/App/CREATE_PROPERTY_LISTING_SUCCESS';
export const CREATE_PROPERTY_LISTING_ERROR =
  'boilerplate/App/CREATE_PROPERTY_LISTING_ERROR';

export const DELETE_INVOICE = 'boilerplate/App/DELETE_INVOICE';
export const DELETE_INVOICE_SUCCESS = 'boilerplate/App/DELETE_INVOICE_SUCCESS';
export const DELETE_INVOICE_ERROR = 'boilerplate/App/DELETE_INVOICE_ERROR';

export const DELETE_EXPENSE = 'boilerplate/App/DELETE_EXPENSE';
export const DELETE_EXPENSE_SUCCESS = 'boilerplate/App/DELETE_EXPENSE_SUCCESS';
export const DELETE_EXPENSE_ERROR = 'boilerplate/App/DELETE_EXPENSE_ERROR';

export const LOAD_COMPANY_CARDS = 'boilerplate/App/LOAD_COMPANY_CARDS';
export const LOAD_COMPANY_CARDS_SUCCESS =
  'boilerplate/App/LOAD_COMPANY_CARDS_SUCCESS';
export const LOAD_COMPANY_CARDS_ERROR =
  'boilerplate/App/LOAD_COMPANY_CARDS_ERROR';

export const SAVE_EXPENSE = 'boilerplate/App/SAVE_EXPENSE';
export const SAVE_EXPENSE_SUCCESS = 'boilerplate/App/SAVE_EXPENSE_SUCCESS';
export const SAVE_EXPENSE_ERROR = 'boilerplate/App/SAVE_EXPENSE_ERROR';

export const LOAD_EMAIL_TEMPLATES = 'boilerplate/App/LOAD_EMAIL_TEMPLATES';
export const LOAD_EMAIL_TEMPLATES_SUCCESS =
  'boilerplate/App/LOAD_EMAIL_TEMPLATES_SUCCESS';
export const LOAD_EMAIL_TEMPLATES_ERROR =
  'boilerplate/App/LOAD_EMAIL_TEMPLATES_ERROR';

export const UPDATE_PROPERTY_KEY_LOG =
  'boilerplate/App/UPDATE_PROPERTY_KEY_LOG';
export const UPDATE_PROPERTY_KEY_LOG_SUCCESS =
  'boilerplate/App/UPDATE_PROPERTY_KEY_LOG_SUCCESS';
export const UPDATE_PROPERTY_KEY_LOG_ERROR =
  'boilerplate/App/UPDATE_PROPERTY_KEY_LOG_ERROR';

export const UPDATE_LOCKBOX = 'boilerplate/App/UPDATE_LOCKBOX';
export const UPDATE_LOCKBOX_SUCCESS = 'boilerplate/App/UPDATE_LOCKBOX_SUCCESS';
export const UPDATE_LOCKBOX_ERROR = 'boilerplate/App/UPDATE_LOCKBOX_ERROR';

export const DELETE_JOB = 'boilerplate/App/DELETE_JOB';
export const DELETE_JOB_SUCCESS = 'boilerplate/App/DELETE_JOB_SUCCESS';
export const DELETE_JOB_ERROR = 'boilerplate/App/DELETE_JOB_ERROR';

export const LOAD_LOCKBOXES = 'boilerplate/App/LOAD_LOCKBOXES';
export const LOAD_LOCKBOXES_SUCCESS = 'boilerplate/App/LOAD_LOCKBOXES_SUCCESS';
export const LOAD_LOCKBOXES_ERROR = 'boilerplate/App/LOAD_LOCKBOXES_ERROR';

export const ASSIGN_LOCKBOX_PROPERTY =
  'boilerplate/App/ASSIGN_LOCKBOX_PROPERTY';
export const ASSIGN_LOCKBOX_PROPERTY_SUCCESS =
  'boilerplate/App/ASSIGN_LOCKBOX_PROPERTY_SUCCESS';
export const ASSIGN_LOCKBOX_PROPERTY_ERROR =
  'boilerplate/App/ASSIGN_LOCKBOX_PROPERTY_ERROR';

export const UNASSIGN_LOCKBOX_PROPERTY =
  'boilerplate/App/UNASSIGN_LOCKBOX_PROPERTY';
export const UNASSIGN_LOCKBOX_PROPERTY_SUCCESS =
  'boilerplate/App/UNASSIGN_LOCKBOX_PROPERTY_SUCCESS';
export const UNASSIGN_LOCKBOX_PROPERTY_ERROR =
  'boilerplate/App/UNASSIGN_LOCKBOX_PROPERTY_ERROR';

export const SEND_OPPORTUNITY_WELCOME_EMAIL =
  'boilerplate/App/SEND_OPPORTUNITY_WELCOME_EMAIL';
export const SEND_OPPORTUNITY_WELCOME_EMAIL_SUCCESS =
  'boilerplate/App/SEND_OPPORTUNITY_WELCOME_EMAIL_SUCCESS';
export const SEND_OPPORTUNITY_WELCOME_EMAIL_ERROR =
  'boilerplate/App/SEND_OPPORTUNITY_WELCOME_EMAIL_ERROR';

export const LOAD_MARKETS = 'boilerplate/App/LOAD_MARKETS';
export const LOAD_MARKETS_SUCCESS = 'boilerplate/App/LOAD_MARKETS_SUCCESS';
export const LOAD_MARKETS_ERROR = 'boilerplate/App/LOAD_MARKETS_ERROR';

export const LOAD_ZPL_TEMPLATES = 'boilerplate/App/LOAD_ZPL_TEMPLATES';
export const LOAD_ZPL_TEMPLATES_SUCCESS =
  'boilerplate/App/LOAD_ZPL_TEMPLATES_SUCCESS';
export const LOAD_ZPL_TEMPLATES_ERROR =
  'boilerplate/App/LOAD_ZPL_TEMPLATES_ERROR';

export const DELETE_DOCUSIGN_ENVELOPE =
  'boilerplate/App/DELETE_DOCUSIGN_ENVELOPE';
export const DELETE_DOCUSIGN_ENVELOPE_SUCCESS =
  'boilerplate/App/DELETE_DOCUSIGN_ENVELOPE_SUCCESS';
export const DELETE_DOCUSIGN_ENVELOPE_ERROR =
  'boilerplate/App/DELETE_DOCUSIGN_ENVELOPE_ERROR';

export const UPDATE_CONTRACT_ADDENDUM =
  'boilerplate/App/UPDATE_CONTRACT_ADDENDUM';
export const UPDATE_CONTRACT_ADDENDUM_SUCCESS =
  'boilerplate/App/UPDATE_CONTRACT_ADDENDUM_SUCCESS';
export const UPDATE_CONTRACT_ADDENDUM_ERROR =
  'boilerplate/App/UPDATE_CONTRACT_ADDENDUM_ERROR';

export const LOAD_USER = 'boilerplate/App/LOAD_USER';
export const LOAD_USER_SUCCESS = 'boilerplate/App/LOAD_USER_SUCCESS';
export const LOAD_USER_ERROR = 'boilerplate/App/LOAD_USER_ERROR';

export const CREATE_INVOICE = 'boilerplate/App/CREATE_INVOICE';
export const CREATE_INVOICE_SUCCESS = 'boilerplate/App/CREATE_INVOICE_SUCCESS';
export const CREATE_INVOICE_ERROR = 'boilerplate/App/CREATE_INVOICE_ERROR';

export const UPDATE_APPLICATION = 'boilerplate/App/UPDATE_APPLICATION';
export const UPDATE_APPLICATION_SUCCESS =
  'boilerplate/App/UPDATE_APPLICATION_SUCCESS';
export const UPDATE_APPLICATION_ERROR =
  'boilerplate/App/UPDATE_APPLICATION_ERROR';

export const SAVE_CONTACT = 'boilerplate/App/SAVE_CONTACT';
export const SAVE_CONTACT_SUCCESS = 'boilerplate/App/SAVE_CONTACT_SUCCESS';
export const SAVE_CONTACT_ERROR = 'boilerplate/App/SAVE_CONTACT_ERROR';

export const CREATE_CONTACT = 'boilerplate/App/CREATE_CONTACT';
export const CREATE_CONTACT_SUCCESS = 'boilerplate/App/CREATE_CONTACT_SUCCESS';
export const CREATE_CONTACT_ERROR = 'boilerplate/App/CREATE_CONTACT_ERROR';

export const DELETE_CONTACT = 'boilerplate/App/DELETE_CONTACT';
export const DELETE_CONTACT_SUCCESS = 'boilerplate/App/DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT_ERROR = 'boilerplate/App/DELETE_CONTACT_ERROR';

export const UPDATE_VENDOR = 'boilerplate/JobDashboard/UPDATE_VENDOR';
export const UPDATE_VENDOR_SUCCESS =
  'boilerplate/JobDashboard/UPDATE_VENDOR_SUCCESS';
export const UPDATE_VENDOR_ERROR =
  'boilerplate/JobDashboard/UPDATE_VENDOR_ERROR';
