import { createSelector } from 'reselect';
import { getError, getIsFailedFetch } from 'containers/App/selectors';
import orderBy from 'lodash/orderBy';

const getState = (state) => state.pageWrapper;

// combine primary selectors with global selectors
export const makeGetIsSearchingSelector = () =>
  createSelector(getState, (state) => state.isSearching);

export const makeGetErrorSelector = () =>
  createSelector([getError, getState], (error, state) => error || state.error);

export const getSearchResults = createSelector(getState, (state) => ({
  searchIn: state.searchIn || '',
  searchResults: state.searchResults || [],
}));

const convertSearchResults = (property) => ({
  id: property.Id,
  address: property.Full_Address__c,
  name: property.Name,
  pmName:
    property.Active_Owner_Contract__r?.Property_Manager__r?.Name?.split(' ')[0],
  ownerStatus: property.Active_Owner_Contract__r?.Status,
  tenantStatus: property.Active_Tenant_Contract__r?.Status,
});

export const makeGetFoundPropertiesSelector = () =>
  createSelector(getSearchResults, ({ searchResults = [] }) => {
    if (Array.isArray(searchResults)) {
      const convertedSearchResults = searchResults.map((prop) =>
        convertSearchResults(prop),
      );
      return orderBy(convertedSearchResults, ['ownerStatus'], ['asc']);
    }
    return [];
  });

export const makeGetIsFailedFetchSelector = () =>
  createSelector(getIsFailedFetch, (isFailedFetch) => isFailedFetch);
