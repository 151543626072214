import React, { memo, useCallback, useEffect, useState } from 'react';
import { bool, func } from 'prop-types';
import { Drawer } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import VerticalTabs from './VerticalTabs';
import VerticalTab from './VerticalTab';
import TabPanel from './TabPanel';
import useMenuItems from './useMenuItems';

const drawerSx = (theme) => ({
  '& > .MuiPaper-root': {
    top: theme.spacing(7),
    ...(theme.palette.mode === 'light' && {
      backgroundColor: '#6C7987',
    }),
    height: 'calc(100vh - 56px)',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'row',
  },
});

const PrimaryMenu = ({ isMenuOpen = false, onMenuClose }) => {
  const theme = useTheme();

  const [selected, setSelected] = useState(false);

  const isDownXs = useMediaQuery(theme.breakpoints.down('sm'));

  const menuItems = useMenuItems();

  useEffect(() => {
    const selectedItem = menuItems.find((mi) => mi.selected);
    setSelected(selectedItem?.label || false);
  }, [menuItems]);

  const onTabChange = useCallback((e, newValue) => setSelected(newValue), []);

  return (
    <Drawer
      sx={drawerSx}
      anchor="left"
      open={isMenuOpen}
      onClose={onMenuClose}
      ModalProps={{ BackdropProps: { invisible: true } }}
    >
      <VerticalTabs
        value={selected}
        orientation="vertical"
        variant="scrollable"
        onChange={onTabChange}
      >
        {menuItems.map(({ visible, items, label, ...item }) => (
          <VerticalTab
            {...item}
            key={label}
            value={label}
            wrapped={false}
            label={isDownXs ? undefined : label}
          />
        ))}
      </VerticalTabs>

      {menuItems.map((item) => {
        // if no sub items -> link
        if (!item.items || !item.items.length) {
          return null;
        }
        return (
          <TabPanel
            key={item.label}
            items={item.items}
            index={item.label}
            value={selected}
          />
        );
      })}
    </Drawer>
  );
};

PrimaryMenu.propTypes = {
  isMenuOpen: bool,
  onMenuClose: func.isRequired,
};

export default memo(PrimaryMenu);
