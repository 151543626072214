import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import {
  CLEAR_FLAGS,
  CLEAR_MESSAGES,
  RUN_CRONTAB_JOB,
  RUN_CRONTAB_JOB_SUCCESS,
  RUN_CRONTAB_JOB_ERROR,
  LOAD_MARKETS_SUCCESS,
  LOAD_MARKETS_ERROR,
} from 'containers/App/constants';
import truncate from 'lodash/truncate';
import { convertMarket } from 'helpers/converters/shared';
import { setErrorState } from 'helpers/state';
import {
  loadVMDataError,
  clearVMData,
  loadVMData as loadVmDataG,
  loadVMDataSuccess as loadVMDataSuccessG,
} from '../actions';

const convertLocker = (locker) => {
  let contentsFor = '';
  const contractNumber = locker.Contract__r?.ContractNumber;
  const property = locker.Contract__r?.Property__r;

  if (contractNumber) {
    contentsFor = Number(contractNumber).toString();
    if (property) {
      // collect property address
      contentsFor += [
        `, ${property.Street_Address__c} ${property.Street_Address_2__c || ''}`,
        property.Building__c ? `Building ${property.Building__c}` : '',
        property.Unit__c ? `Unit ${property.Unit__c}` : '',
      ]
        .filter(Boolean)
        .join(', ');
    }
  } else {
    contentsFor = locker.Account__r?.Name || '';
  }

  return {
    id: locker.Id,
    number: locker.Locker_Number__c || 0,
    lockerType: locker.Locker_Type__c || '',
    status: locker.Status__c || '',
    filledBy: locker.Filled_By__r?.Name || '',
    dateOccupied: locker.Occupied_Date__c || '', // YYYY-MM-DD
    contentsFor,
    contentsForId: locker.Contract__r?.Id || locker.Account__r?.Id,
    contents: truncate(locker.Contents_Description__c, { length: 100 }),
    accessCode: locker.Code__c || '',
    adminCode: locker.Admin_Code__c || '',
    dateLastChange: locker.Code_Last_Changed_Date__c || '', // ISO string
    dateExpectedRetrieval: locker.Expected_Retrieval_Date__c || '', // YYYY-MM-DD
    dateRetrieved: locker.Retrieved_Date__c || '', // ISO string
    market: convertMarket(locker.Market__r),
  };
};

// The initial state of the App
const initialState = {
  isLoadingVMData: false,
  isLoadedVMData: false,

  vmData: null,
  error: '',
  isFailedFetch: false,
  successMessage: '',

  // Locker log
  isLoadingLockerHistoryLog: false,
  lockerLog: [],

  // Global
  isRunningCrontabJob: false,
  markets: [],
};

const slice = createSlice({
  name: 'lockerManager',
  initialState,
  reducers: {
    loadLockerLog: (state) => {
      state.isLoadingLockerHistoryLog = true;
    },
    loadLockerLogSuccess: (state, { payload }) => {
      state.isLoadingLockerHistoryLog = false;
      if (Array.isArray(payload)) {
        state.lockerLog = payload;
      }
    },
    loadLockerLogError: (state, { payload: error }) => {
      setErrorState({ state, error });
      state.isLoadingLockerHistoryLog = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(CLEAR_FLAGS, (state) => {
        state.isLoadedVMData = false;
      })
      .addCase(CLEAR_MESSAGES, (state) => {
        state.error = '';
      })
      .addCase(loadVmDataG, (state) => {
        // action is inferred correctly here if using TS
        state.isLoadingVMData = true;
        state.isLoadedVMData = false;
      })
      .addCase(loadVMDataSuccessG, (state, { payload: result }) => {
        state.isLoadingVMData = false;
        state.isLoadedVMData = true;
        state.vmData = result || [];
      })
      .addCase(loadVMDataError, (state, { payload: error }) => {
        setErrorState({ state, error });
        state.isLoadingVMData = false;
      })
      .addCase(clearVMData, (state) => {
        state.vmData = null;
      })
      .addCase(RUN_CRONTAB_JOB, (state) => {
        state.isRunningCrontabJob = true;
      })
      .addCase(RUN_CRONTAB_JOB_SUCCESS, (state) => {
        state.isRunningCrontabJob = false;
        state.successMessage = 'Crontab job was successfully ran.';
      })
      .addCase(RUN_CRONTAB_JOB_ERROR, (state, { error }) => {
        setErrorState({ state, error });
        state.isRunningCrontabJob = false;
      })
      .addCase(LOAD_MARKETS_SUCCESS, (state, { result }) => {
        state.markets = result;
      })
      .addCase(LOAD_MARKETS_ERROR, (state, { error }) => {
        setErrorState({ state, error });
      });
  },
  selectors: {
    error: (state) => state.error,
    successMessage: (state) => state.successMessage,
    validation: (state) => state.validation,
    isFailedFetch: (state) => state.isFailedFetch,
    isLoadingVMData: (state) => state.isLoadingVMData,
    isLoadedVMData: (state) => state.isLoadedVMData,
    vmData: createSelector(
      (state) => state.vmData,
      (vmData) => {
        if (!vmData) {
          return null;
        }
        return {
          data: vmData.lockers?.map(convertLocker),
          unreadLogs: vmData.unreadLogs || [],
        };
      },
    ),
    lockerLog: (state) => state.lockerLog || [],
    isLoadingLockerHistoryLog: (state) => state.isLoadingLockerHistoryLog,
    isRunningCrontabJob: (state) => state.isRunningCrontabJob,
    markets: createSelector(
      (state) => state.markets,
      (markets) =>
        [{ value: '', label: '' }].concat(
          markets.map((market) => ({
            value: market.Id,
            label: market.Name,
            extraInfo: market.ExtraInfo,
          })),
        ),
    ),
  },
});

export const { loadLockerLog, loadLockerLogSuccess, loadLockerLogError } =
  slice.actions;

export const {
  error,
  successMessage,
  validation,
  isFailedFetch,
  isLoadingVMData,
  isLoadedVMData,
  vmData,
  lockerLog,
  isLoadingLockerHistoryLog,
  isRunningCrontabJob,
  markets,
} = slice.selectors;

export default slice.reducer;
