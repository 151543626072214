import React, { memo, useCallback, useMemo, useEffect } from 'react';
import { styled } from '@mui/system';
import { array, bool, func } from 'prop-types';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import CustomDrawer from 'components/CustomDrawer';
import SearchIcon from '@mui/icons-material/Search';
import {
  TextField,
  IconButton,
  CircularProgress,
  Autocomplete,
  ListItem,
} from '@mui/material';
import { useMergeState } from 'helpers/state';
import EntityType from 'enums/entityType';
import AutocompleteOption from './AutocompleteOption';

const StyledForm = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isVisible',
})(({ isVisible, theme }) => ({
  width: theme.spacing(5.75),
  padding: theme.spacing(1),
  transition: `all 300ms ${theme.transitions.easing.easeInOut} 0ms`,
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
  }),
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    top: 0,
    right: theme.spacing(7),
    position: 'absolute',
  },
  ...(isVisible && {
    width: theme.spacing(75),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(31.25),
    },
  }),
}));

const StyledSearchTextField = styled(TextField)(({ theme }) => ({
  '& input': {
    padding: theme.spacing(1),
  },
  '&>div': {
    ...(theme.palette.mode === 'light' && {
      backgroundColor: 'white',
    }),
    ...(theme.palette.mode === 'dark' && {
      backgroundColor: theme.palette.grey[700],
    }),
  },
}));

const autocompleteSx = (theme) => ({
  '&.MuiAutocomplete-option': {
    fontSize: '0.875rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
    },
  },
});

const SearchForm = ({
  isSearching = false,
  foundProperties = [],
  searchRecords,
}) => {
  const [state, setState] = useMergeState({
    sobjectId: '',
    searchText: '',
    isVisibleForm: false,
    isOpenAutocomplete: false,
    isOpenCommunicationPanel: false,
  });

  // --------------------------------------
  const fetch = useMemo(() => debounce(searchRecords, 500), []);

  // --------------------------------------
  useEffect(() => {
    if (state.searchText) {
      fetch({
        search: state.searchText,
        searchIn: EntityType.PROPERTIES,
      });
    }
  }, [state.searchText, fetch]);

  // --------------------------------------
  const onOpenCommunicationPanel = useCallback(
    (property) =>
      setState({
        isOpenCommunicationPanel: !!property,
        sobjectId: property?.id || '',
      }),
    [],
  );

  // --------------------------------------
  const navigate = useNavigate();
  const onRedirectToProperty = useCallback((e, property) => {
    if (property) {
      navigate(`/properties/${property.id}`);
    }
  }, []);

  // --------------------------------------
  const onCloseCommunicationPanel = useCallback(
    () => setState({ isOpenCommunicationPanel: false, sobjectId: '' }),
    [],
  );

  // --------------------------------------
  return (
    <StyledForm isVisible={state.isVisibleForm}>
      <IconButton
        size="small"
        color="inherit"
        style={{ marginRight: 8 }}
        onClick={() =>
          setState({
            isVisibleForm: !state.isVisibleForm,
          })
        }
      >
        <SearchIcon />
      </IconButton>

      <Autocomplete
        fullWidth
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.address || ''}
        options={foundProperties}
        loading={isSearching}
        onChange={onRedirectToProperty}
        groupBy={(option) => (!option.ownerStatus ? 'Not In Management' : '')}
        renderOption={(props, option) => (
          <ListItem sx={autocompleteSx} {...props}>
            <AutocompleteOption
              onOpenCommunicationPanel={onOpenCommunicationPanel}
              option={option}
            />
          </ListItem>
        )}
        onInputChange={(e, searchText) =>
          setState({
            searchText,
          })
        }
        renderInput={(params) => (
          <StyledSearchTextField
            {...params}
            variant="outlined"
            size="small"
            margin="none"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isSearching && state.isVisibleForm && (
                    <CircularProgress color="inherit" size={20} />
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />

      <CustomDrawer
        isOpen={state.isOpenCommunicationPanel}
        onClose={onCloseCommunicationPanel}
      >
        <iframe
          title="Communication Panel"
          frameBorder="0"
          src={`/communication/${state.sobjectId}`}
          width="100%"
          height="99%"
        />
      </CustomDrawer>
    </StyledForm>
  );
};

SearchForm.propTypes = {
  isSearching: bool,
  foundProperties: array,
  searchRecords: func.isRequired,
};

export default memo(SearchForm);
