import React, { memo, useState, useEffect } from 'react';
import { array, bool, string, func } from 'prop-types';
import StyledDataGrid from 'components/StyledDataGrid';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { formatDate } from 'helpers/table';
import DateRangePickerButton from 'components/DateRangePickerButton';
import LinkCell from './LinkCell';

const StyledHeaderBox = styled('header')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'space-between',
}));

const StyledTitleTypography = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1.25),
  fontSize: '1.5rem',
  color: theme.palette.common.white,
}));

const StyledNoLogTypography = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(3),
}));

const rootSx = {
  color: '#fff',
};

const initialState = {
  pagination: {
    paginationModel: {
      pageSize: 10,
    },
  },
};
const LockerLog = ({
  data = [],
  isLoading = false,
  startDate = '',
  endDate = '',
  onSelectDateRange,
}) => {
  const [logRows, setLogRows] = useState(data);

  useEffect(() => {
    setLogRows(data);
  }, [data]);
  const columns = [
    {
      width: 150,
      field: 'locker',
      headerName: 'Locker ID',
      renderCell: ({ row }) => {
        if (row.lockerId) {
          return (
            <LinkCell
              href={`/communication/${row.lockerId}`}
              text="communication"
            />
          );
        }
        return null;
      },
    },
    {
      flex: 1,
      minWidth: 150,
      field: 'message',
      headerName: 'Message',
      renderCell: ({ row }) => {
        if (row.cloudwatchLink) {
          return <LinkCell href={row.cloudwatchLink} text={row.message} />;
        }
        return null;
      },
    },
    {
      width: 200,
      field: 'ipAndEmail.email',
      valueGetter: (value, row) => row.ipAndEmail?.email || '',
      headerName: 'Email',
    },
    {
      field: 'status',
      headerName: 'Status',
    },
    {
      width: 150,
      field: 'createdAt',
      headerName: ' Created',
      type: 'date',
      sort: 'asc',
      renderCell: ({ row }) =>
        formatDate(row.createdAt, 'MM/DD/YYYY HH:mm:ss') || '',
      valueGetter: (value, row) => {
        if (row.createdAt) {
          return new Date(row.createdAt);
        }
        return undefined;
      },
    },
  ];

  return (
    <Box component="section" sx={{ width: '100%' }}>
      <StyledHeaderBox>
        <StyledTitleTypography>Locker Log</StyledTitleTypography>
        <DateRangePickerButton
          rootSx={rootSx}
          startDate={startDate}
          endDate={endDate}
          isLoading={isLoading}
          onSelectDateRange={onSelectDateRange}
        />
      </StyledHeaderBox>
      <Box sx={{ width: '100%', minHeight: 400 }}>
        {data.length ? (
          <StyledDataGrid
            disableRowSelectionOnClick
            loading={isLoading}
            rows={logRows}
            columns={columns}
            initialState={initialState}
          />
        ) : (
          <StyledNoLogTypography>
            There no log on period from {startDate} to {endDate}
          </StyledNoLogTypography>
        )}
      </Box>
    </Box>
  );
};

LockerLog.propTypes = {
  data: array,
  isLoading: bool,
  startDate: string,
  endDate: string,
  onSelectDateRange: func.isRequired,
};

export default memo(LockerLog);
