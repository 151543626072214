import Moize from 'moize';
import {
  formatNonUtcDate as defaultFormatNonUtcDate,
  formatDate as defaultFormatDate,
  humanReadable as defaultHumanReadable,
  formatMoney as defaultFormatMoney,
} from 'helpers/format';

export const formatNonUtcDate = Moize((date, format = 'MM/DD/YYYY') => {
  if (date) {
    return defaultFormatNonUtcDate(date, format);
  }
  return 0;
});

export const formatDate = Moize((date, format = 'MM/DD/YYYY') => {
  if (date) {
    return defaultFormatDate(date, format);
  }
  return 0;
});

export const humanReadable = Moize(defaultHumanReadable);

export const formatMoney = Moize(defaultFormatMoney);

export const keyTagSortComparator = (v1, v2) =>
  v1.localeCompare(v2, undefined, {
    numeric: true,
    sensitivity: 'base',
  });
