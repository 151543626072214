import qs from 'qs';
import Moment from 'moment';
import { htmlToText } from 'html-to-text';
import { call, put, takeLatest } from 'redux-saga/effects';
import request, { customRequestSso } from 'utils/request';
import Auth from 'helpers/auth';
import Company from 'helpers/company';
import { decodeBase64 } from 'helpers/template';

import {
  LOAD_COMPANY_INFO,
  LOAD_COMPANIES,
  SUBMIT_COMMENT,
  LOAD_USERS,
  LOAD_TECHNICIANS,
  UPDATE_TASK,
  CREATE_TASK,
  SEND_EMAIL,
  LOAD_SOBJECT,
  LOAD_PICK_LISTS,
  LOAD_RECORD_TYPES,
  SEARCH_RECORDS,
  DELETE_SOBJECT_FILE,
  RENAME_SOBJECT_FILE,
  LOAD_JOBS,
  LOAD_EVENTS,
  LOAD_SMS_MESSAGES,
  SEND_SMS,
  LOAD_SMS_TEMPLATES,
  UPDATE_SMS_MESSAGE,
  UPDATE_JOB,
  LOAD_WORK_ORDER,
  LOAD_MAINTENANCE_GROUPS,
  UPDATE_INSPECTION_STATUS,
  UPDATE_PROPERTY_ROUTINE_INSPECTIONS,
  LOAD_COMMUNICATION_DATA,
  SAVE_INSPECTION,
  SAVE_PROPERTY,
  SAVE_WORK_ORDER,
  SAVE_OPPORTUNITY,
  SAVE_CONTRACT,
  SAVE_PROPERTY_LISTING,
  SAVE_LOCKER,
  LOOKUP_PROPERTY,
  SEARCH_CONTACTS,
  LOAD_INSPECTION,
  UPDATE_LINE_ITEM,
  CREATE_LINE_ITEM,
  DELETE_LINE_ITEM,
  LOAD_PROPERTY,
  RUN_CRONTAB_JOB,
  RESERVE_NEXT_AVAILABLE_LOCKER,
  ENRICH_PROPERTY,
  SAVE_PROPERTY_OWNER_SETTINGS,
  LOAD_PROPERTY_DETAILS_AND_OWNER_SETTINGS,
  DELETE_FILES,
  UPDATE_FILES,
  LOAD_ACCOUNTS_BY_TYPE,
  SAVE_INVOICE,
  SCHEDULE_APPOINTMENT,
  CREATE_PROPERTY_LISTING,
  DELETE_INVOICE,
  DELETE_EXPENSE,
  LOAD_COMPANY_CARDS,
  SAVE_EXPENSE,
  LOAD_EMAIL_TEMPLATES,
  UPDATE_PROPERTY_KEY_LOG,
  DELETE_JOB,
  LOAD_LOCKBOXES,
  ASSIGN_LOCKBOX_PROPERTY,
  UNASSIGN_LOCKBOX_PROPERTY,
  SEND_OPPORTUNITY_WELCOME_EMAIL,
  LOAD_MARKETS,
  LOAD_ZPL_TEMPLATES,
  DELETE_DOCUSIGN_ENVELOPE,
  UPDATE_CONTRACT_ADDENDUM,
  SAVE_LEAD,
  DELETE_LEAD,
  LOAD_USER,
  CREATE_INVOICE,
  UPDATE_APPLICATION,
  SAVE_CONTACT,
  CREATE_CONTACT,
  DELETE_CONTACT,
  UPDATE_VENDOR,
} from './constants';

import {
  loadCompaniesSuccess,
  loadCompaniesError,
  submitCommentSuccess,
  submitCommentError,
  loadUsersSuccess,
  loadUsersError,
  loadTechniciansSuccess,
  loadTechniciansError,
  updateTaskSuccess,
  updateTaskError,
  createTaskSuccess,
  createTaskError,
  sendEmailSuccess,
  sendEmailError,
  logNewActivity,
  cancelNewActivity,
  loadSobject,
  loadSobjectSuccess,
  loadSobjectError,
  loadPickListsSuccess,
  loadPickListsError,
  searchRecordsSuccess,
  searchRecordsError,
  deleteSobjectFileSuccess,
  deleteSobjectFileError,
  renameSobjectFileSuccess,
  renameSobjectFileError,
  loadJobsSuccess,
  loadJobsError,
  loadEventsSuccess,
  loadEventsError,
  loadSmsMessages,
  loadSmsMessagesSuccess,
  loadSmsMessagesError,
  sendSmsSuccess,
  sendSmsError,
  loadSmsTemplatesSuccess,
  loadSmsTemplatesError,
  updateSmsMessageSuccess,
  updateSmsMessageError,
  updateJobSuccess,
  updateJobError,
  loadWorkOrderSuccess,
  loadWorkOrderError,
  loadMaintenanceGroupsSuccess,
  loadMaintenanceGroupsError,
  updateInspectionStatusSuccess,
  updateInspectionStatusError,
  updatePropertyRoutineInspectionsSuccess,
  updatePropertyRoutineInspectionsError,
  loadCommunicationDataSuccess,
  loadCommunicationDataError,
  saveInspectionSuccess,
  saveInspectionError,
  savePropertySuccess,
  savePropertyError,
  saveWorkOrderSuccess,
  saveWorkOrderError,
  saveOpportunitySuccess,
  saveOpportunityError,
  saveLeadSuccess,
  saveLeadError,
  deleteLeadSuccess,
  deleteLeadError,
  saveContractSuccess,
  saveContractError,
  lookupPropertySuccess,
  lookupPropertyError,
  searchContactsSuccess,
  searchContactsError,
  loadInspectionSuccess,
  loadInspectionError,
  updateLineItemSuccess,
  updateLineItemError,
  createLineItemSuccess,
  createLineItemError,
  deleteLineItemSuccess,
  deleteLineItemError,
  loadRecordTypesSuccess,
  loadRecordTypesError,
  loadPropertySuccess,
  loadPropertyError,
  runCrontabJobSuccess,
  runCrontabJobError,
  savePropertyListingSuccess,
  savePropertyListingError,
  saveLockerSuccess,
  saveLockerError,
  reserveNextAvailableLockerSuccess,
  reserveNextAvailableLockerError,
  enrichPropertySuccess,
  enrichPropertyError,
  savePropertyOwnerSettingsSuccess,
  savePropertyOwnerSettingsError,
  loadPropertyDetailsAndOwnerSettingsError,
  loadPropertyDetailsAndOwnerSettingsSuccess,
  deleteFilesSuccess,
  deleteFilesError,
  updateFilesSuccess,
  updateFilesError,
  loadAccountsByTypeSuccess,
  loadAccountsByTypeError,
  saveInvoiceSuccess,
  saveInvoiceError,
  scheduleAppointmentSuccess,
  scheduleAppointmentError,
  createPropertyListingSuccess,
  createPropertyListingError,
  deleteInvoiceSuccess,
  deleteInvoiceError,
  deleteExpenseSuccess,
  deleteExpenseError,
  loadCompanyCardsSuccess,
  loadCompanyCardsError,
  saveExpenseSuccess,
  saveExpenseError,
  loadEmailTemplatesSuccess,
  loadEmailTemplatesError,
  updatePropertyKeyLogSuccess,
  updatePropertyKeyLogError,
  deleteJobSuccess,
  deleteJobError,
  loadLockboxesSuccess,
  loadLockboxesError,
  assignLockboxPropertySuccess,
  assignLockboxPropertyError,
  unassignLockboxPropertySuccess,
  unassignLockboxPropertyError,
  sendOpportunityWelcomeEmailSuccess,
  sendOpportunityWelcomeEmailError,
  loadMarketsSuccess,
  loadMarketsError,
  loadCompanyInfoSuccess,
  loadCompanyInfoError,
  loadZplTemplatesSuccess,
  loadZplTemplatesError,
  deleteDocusignEnvelopeSuccess,
  deleteDocusignEnvelopeError,
  updateContractAddendumSuccess,
  updateContractAddendumError,
  loadUserSuccess,
  loadUserError,
  createInvoiceSuccess,
  createInvoiceError,
  updateApplicationError,
  updateApplicationSuccess,
  saveContactSuccess,
  saveContactError,
  createContactSuccess,
  createContactError,
  deleteContactSuccess,
  deleteContactError,
  updateVendorSuccess,
  updateVendorError,
} from './actions';

// import jobs from '../../../fixtures/jobs.json';
// import workOrder from '../../../fixtures/workOrder.json';

const domainCompanyId = Company.getIdByDomain();

/**
 * Company Info request/response handler
 */
export function* getCompanyInfo() {
  try {
    // Call our request helper (see 'utils/request')
    const companyInfo = yield call(
      request,
      `${process.env.API_URL}/v1/companies/${domainCompanyId}`,
      {
        method: 'GET',
      },
    );

    yield put(loadCompanyInfoSuccess(companyInfo));
  } catch (err) {
    yield put(loadCompanyInfoError(err));
  }
}

// import companies from '../../../fixtures/companies.json';

/**
 * Companies request/response handler
 */
export function* getCompanies() {
  try {
    // Call our request helper (see 'utils/request')
    const companies = yield call(
      request,
      `${process.env.API_URL}/v1/companies`,
      {
        method: 'GET',
      },
    );

    yield put(loadCompaniesSuccess(companies));
  } catch (err) {
    yield put(loadCompaniesError(err));
  }
}

/**
 * Submit comment request/response handler
 */
export function* submitComment({ entityId, entityType, comment, ownerId }) {
  try {
    // Call our request helper (see 'utils/request')
    const commentObject = yield call(
      request,
      // inspections, work-orders
      `${process.env.API_URL}/v1/comments/${entityType}/${entityId}`,
      {
        method: 'POST',
        body: JSON.stringify({
          comment,
          ownerId,
        }),
      },
    );

    yield put(submitCommentSuccess(commentObject));
  } catch (err) {
    yield put(submitCommentError(err));
  }
}

// import users from '../../../fixtures/users.json';

/**
 * Property Managers request/response handler
 */
export function* getUsers({ type, ...params }) {
  try {
    const query = qs.stringify(params);

    // Call our request helper (see 'utils/request')
    const users = yield call(
      request,
      `${process.env.API_URL}/v1/users?${query}`,
      {
        method: 'GET',
      },
    );

    yield put(loadUsersSuccess(users));
  } catch (err) {
    yield put(loadUsersError(err));
  }
}

/**
 * Technicians request/response handler
 */
export function* getTechnicians({ type, ...params }) {
  try {
    const query = qs.stringify(params);

    // Call our request helper (see 'utils/request')
    const technicians = yield call(
      request,
      `${process.env.API_URL}/v1/users/technicians?${query}`,
      {
        method: 'GET',
      },
    );

    yield put(loadTechniciansSuccess(technicians));
  } catch (err) {
    yield put(loadTechniciansError(err));
  }
}

/**
 * Update task request/response handler
 */
export function* updateTask({ id, entityId, type, ...body }) {
  try {
    // Call our request helper (see 'utils/request')
    const task = yield call(request, `${process.env.API_URL}/v1/tasks/${id}`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });
    yield put(updateTaskSuccess(task));
  } catch (err) {
    yield put(updateTaskError(err));
  }
}

/**
 * Update task request/response handler
 */
export function* createTask({ id, entityId, type, ...body }) {
  try {
    // Call our request helper (see 'utils/request')
    const task = yield call(
      request,
      `${process.env.API_URL}/v1/tasks/by/entity/${entityId}`,
      {
        method: 'POST',
        body: JSON.stringify(body),
      },
    );
    yield put(createTaskSuccess(task));
    // clear/hide activity form
    yield put(cancelNewActivity());
  } catch (err) {
    yield put(createTaskError(err));
  }
}

/**
 * Send email request/response handler
 */
export function* sendEntityEmail({ entityId, type, ...payload }) {
  try {
    // exclude date reminder from body
    const { dateReminder, ...body } = payload;
    // send request
    const result = yield call(
      request,
      `${process.env.API_URL}/v1/emails/${entityId}/send?companyId=${domainCompanyId}`,
      {
        method: 'POST',
        body: JSON.stringify(body),
      },
    );

    yield put(sendEmailSuccess(result));

    // open new activity form with pre-populated data if date reminder is set
    if (dateReminder) {
      const user = Auth.getUser();
      const description = `To: ${payload?.to}\nCC: ${payload?.cc}\nBCC: ${
        payload?.bcc
      }\n\n${htmlToText(decodeBase64(payload?.html))}`;
      yield put(
        logNewActivity({
          status: 'In Progress',
          subject: `Email Follow Up: ${payload?.subject}`,
          description,
          dateActivity: dateReminder,
          assignedToId: user?.employeeId,
          taskType: 'Email',
        }),
      );
    }
  } catch (err) {
    yield put(sendEmailError(err));
  }
}

/**
 * sobject request/response handler
 */
export function* getSobject({ id }) {
  try {
    // Call our request helper (see 'utils/request')
    const sobject = yield call(
      request,
      `${process.env.API_URL}/v1/files/sobject/${id}`,
      {
        method: 'GET',
      },
    );

    yield put(loadSobjectSuccess(sobject));
  } catch (err) {
    yield put(loadSobjectError(err));
  }
}

/**
 * Pick Lists request/response handler
 */
export function* getPickLists({ sobject }) {
  try {
    // Call our request helper (see 'utils/request')
    const pickLists = yield call(
      request,
      `${process.env.API_URL}/v1/pick-lists/${sobject}`,
      {
        method: 'GET',
      },
    );

    yield put(loadPickListsSuccess(pickLists));
  } catch (err) {
    yield put(loadPickListsError(err));
  }
}

/**
 * Record Types request/response handler
 */
export function* getRecordTypes({ sobject }) {
  try {
    // Call our request helper (see 'utils/request')
    const recordTypes = yield call(
      request,
      `${process.env.API_URL}/v1/record-types/${sobject}`,
      {
        method: 'GET',
      },
    );

    yield put(loadRecordTypesSuccess(recordTypes));
  } catch (err) {
    yield put(loadRecordTypesError(err));
  }
}

/**
 * Search request/response handler
 */
export function* searchRecords({ search, searchIn, type, ...params }) {
  const query = qs.stringify(params);

  try {
    // Call our request helper (see 'utils/request')
    const searchResults = yield call(
      request,
      `${
        process.env.API_URL
      }/v1/${searchIn}/search/by/name/${encodeURIComponent(search)}?${query}`,
      {
        method: 'GET',
      },
    );

    yield put(searchRecordsSuccess(searchResults));
  } catch (err) {
    yield put(searchRecordsError(err));
  }
}

/**
 * sobject file delete request/response handler
 */
export function* deleteSobjectFile({ id, fileName }) {
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(
      request,
      `${process.env.API_URL}/v1/files/sobject/${id}/${fileName}`,
      {
        method: 'DELETE',
      },
    );

    yield put(deleteSobjectFileSuccess(result));
    // reload sobject
    yield put(loadSobject(id));
  } catch (err) {
    yield put(deleteSobjectFileError(err));
  }
}

/**
 * sobject file rename request/response handler
 */
export function* renameSobjectFile({ id, oldFileName, newFileName }) {
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(
      request,
      `${process.env.API_URL}/v1/files/sobject/${id}/rename/${oldFileName}/${newFileName}`,
      {
        method: 'PUT',
      },
    );

    yield put(renameSobjectFileSuccess(result));
    // reload sobject
    yield put(loadSobject(id));
  } catch (err) {
    yield put(renameSobjectFileError(err));
  }
}

/**
 * Load Job request/response handler
 */
export function* getJobs({
  vendorId,
  technicianId,
  startDate,
  endDate,
  entityType = '',
}) {
  try {
    let postfix = `vendors/${vendorId}`;
    if (technicianId) {
      postfix = `technicians/${technicianId}`;
    }

    // convert dates to UTC
    const startEndDateFormat = 'MM/DD/YYYY';
    const startUtc = Moment(startDate, startEndDateFormat)
      .startOf('day')
      .utc()
      .format(startEndDateFormat);
    const endUtc = Moment(endDate, startEndDateFormat)
      .endOf('day')
      .utc()
      .format(startEndDateFormat);

    const query = qs.stringify({
      startDate: startUtc,
      endDate: endUtc,
      entityType,
      companyId: domainCompanyId,
    });

    // Call our request helper (see 'utils/request')
    const jobs = yield call(
      request,
      `${process.env.API_URL}/v1/jobs/${postfix}?${query}`,
      {
        method: 'GET',
      },
    );

    // yield delay(1000);

    yield put(loadJobsSuccess(jobs));
  } catch (err) {
    yield put(loadJobsError(err));
  }
}

/**
 * Events request/response handler
 */
export function* getEvents({ targetId }) {
  try {
    // Call our request helper (see 'utils/request')
    const users = yield call(
      request,
      `${process.env.API_URL}/v1/events/dynamo/by/target/${targetId}`,
      {
        method: 'GET',
      },
    );

    yield put(loadEventsSuccess(users));
  } catch (err) {
    yield put(loadEventsError(err));
  }
}

// import messages from './smsHistory.json';
/**
 * SMS request/response handler
 */
export function* getSmsMessages({
  companyId,
  source = '',
  contactId,
  startDate,
  endDate,
}) {
  try {
    const query = {
      companyId,
    };

    if (source) {
      query.source = source;
    }

    if (startDate && endDate) {
      query.startDate = startDate;
      query.endDate = endDate;
    }

    const stringified = qs.stringify(query);

    // Call our request helper (see 'utils/request')
    const results = yield call(
      request,
      // load all or by phone
      `${process.env.API_URL}/v1/sms${
        contactId ? `/${contactId}` : ''
      }?${stringified}`,
      {
        method: 'GET',
      },
    );

    yield put(loadSmsMessagesSuccess(contactId ? results?.messages : results));
  } catch (err) {
    yield put(loadSmsMessagesError(err));
  }
}

/**
 * Send SMS request/response handler
 */
export function* sendSms({
  contactId,
  companyId = '',
  source = '',
  startDate,
  endDate,
  isSkipReloadMessages = false,
  ...payload
}) {
  try {
    const query = {
      companyId: companyId || domainCompanyId,
    };
    if (source) {
      query.source = source;
    }

    const stringified = qs.stringify(query);

    // Call our request helper (see 'utils/request')
    const sms = yield call(
      request,
      `${process.env.API_URL}/v1/sms/${contactId}?${stringified}`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
      },
    );
    yield put(sendSmsSuccess(sms));

    if (!isSkipReloadMessages) {
      // load fresh list
      yield put(loadSmsMessages({ startDate, endDate, companyId }));
    }
  } catch (err) {
    yield put(sendSmsError(err));
  }
}

/**
 * Sms Templates request/response handler
 */
export function* getSmsTemplates() {
  try {
    const smsTemplates = yield call(
      request,
      `${process.env.API_URL}/v1/sms/templates`,
      {
        method: 'GET',
      },
    );

    yield put(loadSmsTemplatesSuccess(smsTemplates));
  } catch (err) {
    yield put(loadSmsTemplatesError(err));
  }
}

/**
 * Update SMS request/response handler
 */
export function* updateSmsMessage({
  type,
  id,
  contactId,
  startDate,
  endDate,
  companyId,
  ...payload
}) {
  try {
    // Call our request helper (see 'utils/request')
    const sms = yield call(
      request,
      `${process.env.API_URL}/v1/sms/${contactId}/messages/${id}?companyId=${companyId}`,
      {
        method: 'PUT',
        body: JSON.stringify(payload),
      },
    );
    yield put(updateSmsMessageSuccess(sms));
    // load fresh list
    yield put(loadSmsMessages({ companyId, startDate, endDate }));
  } catch (err) {
    yield put(updateSmsMessageError(err));
  }
}

/**
 * Job request/response handler
 */
export function* updateJob({ type, jobId, ...payload }) {
  try {
    // Call our request helper (see 'utils/request')
    const job = yield call(request, `${process.env.API_URL}/v1/jobs/${jobId}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
    });

    yield put(
      updateJobSuccess({
        ...job,
        id: jobId,
        companyId: domainCompanyId,
      }),
    );
  } catch (err) {
    yield put(updateJobError(err));
  }
}

/**
 * Work Order request/response handler
 */
export function* getWorkOrder({ id, isViewAsCustomer = false }) {
  try {
    const query = qs.stringify({
      companyId: domainCompanyId,
      isViewAsCustomer,
    });

    // Call our request helper (see 'utils/request')
    const workOrder = yield call(
      request,
      `${process.env.API_URL}/v1/work-orders/${id}?${query}`,
      {
        method: 'GET',
      },
    );

    yield put(loadWorkOrderSuccess(workOrder));
  } catch (err) {
    yield put(loadWorkOrderError(err));
  }
}

/**
 * Search Jobs request/response handler
 */
export function* getMaintenanceGroups({ accountId = '' }) {
  try {
    // Call our request helper (see 'utils/request')
    const groups = yield call(
      request,
      `${process.env.API_URL}/v1/users/maintenance-groups?accountId=${accountId}`,
      {
        method: 'GET',
      },
    );

    yield put(loadMaintenanceGroupsSuccess(groups));
  } catch (err) {
    yield put(loadMaintenanceGroupsError(err));
  }
}

/**
 * Inspection request/response handler
 */
export function* updateInspectionStatus({
  inspectionId,
  status,
  inspectionType,
}) {
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(
      request,
      `${process.env.API_URL}/v1/inspections/${inspectionId}/${inspectionType}/status`,
      {
        method: 'PUT',
        body: JSON.stringify({
          status,
        }),
      },
    );

    yield put(updateInspectionStatusSuccess(result));
  } catch (err) {
    yield put(updateInspectionStatusError(err));
  }
}

/**
 * Property routine inspection request/response handler
 */
export function* updatePropertyRoutineInspections({ propertyId, isApproved }) {
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(
      request,
      `${process.env.API_URL}/v1/properties/${propertyId}/routine-inspection`,
      {
        method: 'PUT',
        body: JSON.stringify({
          isApproved,
        }),
      },
    );

    yield put(updatePropertyRoutineInspectionsSuccess(result));
  } catch (err) {
    yield put(updatePropertyRoutineInspectionsError(err));
  }
}

// import data from '../../../fixtures/communicationData.json';

/**
 * Get inspection options
 */
export function* loadCommunicationData({ entityId }) {
  try {
    // Call our request helper (see 'utils/request')
    const data = yield call(
      request,
      `${process.env.API_URL}/v1/communication/${entityId}?companyId=${domainCompanyId}`,
      {
        method: 'GET',
      },
    );
    yield put(loadCommunicationDataSuccess(data));
  } catch (err) {
    yield put(loadCommunicationDataError(err));
  }
}

/**
 * Inspection request/response handler
 */
export function* saveInspection({ type, inspectionId, ...payload }) {
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(
      request,
      `${process.env.API_URL}/v1/inspections/${inspectionId}`,
      {
        method: 'PUT',
        body: JSON.stringify(payload),
      },
    );

    yield put(saveInspectionSuccess(result));
  } catch (err) {
    yield put(saveInspectionError(err));
  }
}

/**
 * Property request/response handler
 */
export function* saveProperty({ type, propertyId, ...payload }) {
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(
      request,
      `${process.env.API_URL}/v1/properties/${propertyId}`,
      {
        method: 'PUT',
        body: JSON.stringify(payload),
      },
    );

    yield put(savePropertySuccess(result));
  } catch (err) {
    yield put(savePropertyError(err));
  }
}

/**
 * Property owner settings request/response handler
 */
export function* savePropertyOwnerSettings({ type, propertyId, ...payload }) {
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(
      request,
      `${process.env.API_URL}/v1/properties/${propertyId}/owner-settings`,
      {
        method: 'PUT',
        body: JSON.stringify(payload),
      },
    );

    yield put(savePropertyOwnerSettingsSuccess(result));
  } catch (err) {
    yield put(savePropertyOwnerSettingsError(err));
  }
}

// --------------------------------------------------
export function* deleteJob({ jobId, isWithInspection = false }) {
  try {
    const result = yield call(
      request,
      `${
        process.env.API_URL
      }/v1/jobs/${jobId}?isWithInspection=${!!isWithInspection}`,
      {
        method: 'DELETE',
      },
    );
    yield put(deleteJobSuccess(result));
  } catch (err) {
    yield put(deleteJobError(err));
  }
}
/**
 * Work Order request/response handler
 */
export function* saveWorkOrder({ type, workOrderId, ...payload }) {
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(
      request,
      `${process.env.API_URL}/v1/work-orders/${workOrderId}`,
      {
        method: 'PUT',
        body: JSON.stringify(payload),
      },
    );

    yield put(saveWorkOrderSuccess(result));
  } catch (err) {
    yield put(saveWorkOrderError(err));
  }
}

/**
 * Opportunity request/response handler
 */
export function* saveOpportunity({ type, opportunityId, ...body }) {
  try {
    const result = yield call(
      request,
      `${process.env.API_URL}/v1/opportunities/${opportunityId}`,
      {
        method: 'PUT',
        body: JSON.stringify(body),
      },
    );
    yield put(saveOpportunitySuccess(result));
  } catch (err) {
    yield put(saveOpportunityError(err));
  }
}

/**
 * Lead request/response handler
 */

export function* saveLead({ type, leadId, ...body }) {
  try {
    const result = yield call(
      request,
      `${process.env.API_URL}/v1/leads/${leadId}`,
      {
        method: 'PUT',
        body: JSON.stringify(body),
      },
    );
    yield put(saveLeadSuccess(result));
  } catch (err) {
    yield put(saveLeadError(err));
  }
}

/**
 * Lead delete request/response handler
 */
export function* deleteLead({ leadId }) {
  try {
    const lead = yield call(
      request,
      `${process.env.API_URL}/v1/leads/${leadId}`,
      {
        method: 'DELETE',
      },
    );

    yield put(deleteLeadSuccess(lead));
  } catch (err) {
    yield put(deleteLeadError(err));
  }
}

/**
 * Contract request/response handler
 */
export function* saveContract({ type, contractId, ...body }) {
  try {
    const result = yield call(
      request,
      `${process.env.API_URL}/v1/contracts/${contractId}`,
      {
        method: 'PUT',
        body: JSON.stringify(body),
      },
    );
    yield put(saveContractSuccess(result));
  } catch (err) {
    yield put(saveContractError(err));
  }
}

/*
 * Property Listing request/response handler
 */
export function* savePropertyListing({ type, listingId, ...body }) {
  try {
    const result = yield call(
      request,
      `${process.env.API_URL}/v1/property-listings/${listingId}`,
      {
        method: 'PUT',
        body: JSON.stringify(body),
      },
    );
    yield put(savePropertyListingSuccess(result));
  } catch (err) {
    yield put(savePropertyListingError(err));
  }
}

/**
 * Locker request/response handler
 */
export function* saveLocker({ type, lockerId, ...body }) {
  try {
    const result = yield call(
      request,
      `${process.env.API_URL}/v1/locker/${lockerId}`,
      {
        method: 'PUT',
        body: JSON.stringify(body),
      },
    );
    yield put(saveLockerSuccess(result));
  } catch (err) {
    yield put(saveLockerError(err));
  }
}

/**
 * Lookup property request/response handler
 */
export function* lookupProperty({ index, type, ...params }) {
  try {
    const query = qs.stringify(params);
    const property = yield call(
      request,
      `${process.env.API_URL}/v1/properties/lookup?${query}`,
      {
        method: 'GET',
      },
    );
    yield put(lookupPropertySuccess({ ...property, index }));
  } catch (err) {
    yield put(lookupPropertyError(err));
  }
}

/**
 * Search request/response handler
 */
export function* searchContacts({ type, ...params }) {
  try {
    const query = qs.stringify(params);
    const contacts = yield call(
      request,
      `${process.env.API_URL}/v1/contacts/search?${query}`,
      {
        method: 'GET',
      },
    );

    yield put(searchContactsSuccess(contacts));
  } catch (err) {
    yield put(searchContactsError(err));
  }
}

// import inspection from '../../../fixtures/inspection.json';

/**
 * Inspection request/response handler
 */
export function* getInspection({ id, isViewAsCustomer = false }) {
  try {
    const inspection = yield call(
      request,
      `${process.env.API_URL}/v1/inspections/${id}?isViewAsCustomer=${isViewAsCustomer}`,
      {
        method: 'GET',
      },
    );

    yield put(loadInspectionSuccess(inspection));
  } catch (err) {
    yield put(loadInspectionError(err));
  }
}

/**
 * Work Order/Inspection line item create request/response handler
 */
export function* createLineItem({
  type,
  workOrderId = '',
  inspectionId = '',
  ...payload
}) {
  try {
    let endpoint = `work-orders/${workOrderId}`;
    if (inspectionId) {
      endpoint = `inspections/${inspectionId}`;
    }

    const created = yield call(
      request,
      `${process.env.API_URL}/v1/${endpoint}/line-items`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
      },
    );

    yield put(createLineItemSuccess(created));
  } catch (err) {
    yield put(createLineItemError(err));
  }
}

/**
 *  Work Order/Inspection line item update request/response handler
 */
export function* updateLineItem({
  id,
  type,
  workOrderId = '',
  inspectionId = '',
  ...payload
}) {
  try {
    let endpoint = `work-orders/${workOrderId}`;
    if (inspectionId) {
      endpoint = `inspections/${inspectionId}`;
    }

    // Call our request helper (see 'utils/request')
    const lineItem = yield call(
      request,
      `${process.env.API_URL}/v1/${endpoint}/line-items/${id}`,
      {
        method: 'PUT',
        body: JSON.stringify(payload),
      },
    );
    yield put(updateLineItemSuccess(lineItem));
  } catch (err) {
    yield put(updateLineItemError(err));
  }
}

/**
 * Work Order/Inspection line item delete request/response handler
 */
export function* deleteLineItem({
  workOrderId = '',
  inspectionId = '',
  lineItemId,
}) {
  try {
    let endpoint = `work-orders/${workOrderId}`;
    if (inspectionId) {
      endpoint = `inspections/${inspectionId}`;
    }

    const lineItem = yield call(
      request,
      `${process.env.API_URL}/v1/${endpoint}/line-items/${lineItemId}`,
      {
        method: 'DELETE',
      },
    );

    yield put(deleteLineItemSuccess(lineItem));
  } catch (err) {
    yield put(deleteLineItemError(err));
  }
}

/**
 * Load Property request/response handler
 */
export function* getProperty({ propertyId }) {
  try {
    // Call our request helper (see 'utils/request')
    const property = yield call(
      request,
      `${process.env.API_URL}/v1/properties/${propertyId}`,
      {
        method: 'GET',
      },
    );

    yield put(loadPropertySuccess(property));
  } catch (err) {
    yield put(loadPropertyError(err));
  }
}

/**
 * Run crontab job request/response handler
 */
export function* runCrontabJob({ name }) {
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(
      request,
      `${process.env.API_URL}/v1/crontab-jobs/${name}`,
      {
        method: 'PUT',
      },
    );

    yield put(runCrontabJobSuccess(result));
  } catch (err) {
    yield put(runCrontabJobError(err));
  }
}

/**
 * Reserve Next Available Locker request/response handler
 */
export function* reserveNextAvailableLocker({ marketId, lockerType }) {
  try {
    // Call our request helper (see 'utils/request')
    const locker = yield call(
      request,
      `${process.env.API_URL}/v1/locker/reserve-next-available/markets/${marketId}/${lockerType}`,
      {
        method: 'PUT',
      },
    );

    yield put(reserveNextAvailableLockerSuccess(locker));
  } catch (err) {
    yield put(reserveNextAvailableLockerError(err));
  }
}

/**
 * Enrich Property data request/response handler
 */
export function* enrichProperty({ type, ...payload }) {
  try {
    // Call our request helper (see 'utils/request')
    const property = yield call(
      request,
      `${process.env.API_URL}/v1/properties/enrich-property`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
      },
    );

    yield put(enrichPropertySuccess(property));
  } catch (err) {
    yield put(enrichPropertyError(err));
  }
}

/**
 * Sobject files request/response handler
 */
export function* loadPropertyDetailsAndOwnerSettings({ propertyId }) {
  try {
    // Call our request helper (see 'utils/request')
    const details = yield call(
      request,
      `${process.env.API_URL}/v1/properties/${propertyId}/details-and-owner-settings`,
      {
        method: 'GET',
      },
    );

    yield put(loadPropertyDetailsAndOwnerSettingsSuccess(details));
  } catch (err) {
    yield put(loadPropertyDetailsAndOwnerSettingsError(err));
  }
}

/**
 * Delete files request/response handler
 */
export function* deleteFiles({ fileIds }) {
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(
      request,
      `${process.env.API_URL}/v2/files?fileIds=${fileIds}`,
      {
        method: 'DELETE',
      },
    );

    // check for errors in the response
    const found = result?.find(({ error }) => !!error);
    if (found) {
      throw new Error(found.error);
    }

    yield put(deleteFilesSuccess(result));
  } catch (err) {
    yield put(deleteFilesError(err));
  }
}

/**
 * Update files request/response handler
 */
export function* updateFiles({ type, ...payload }) {
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, `${process.env.API_URL}/v2/files`, {
      method: 'PUT',
      body: JSON.stringify(payload),
    });

    // check for errors in the response
    const found = result?.find(({ error }) => !!error);
    if (found) {
      throw new Error(found.error);
    }

    yield put(updateFilesSuccess(result));
  } catch (err) {
    yield put(updateFilesError(err));
  }
}

// --------------------------------------------------
export function* loadAccountsByType({ accountType }) {
  try {
    const result = yield call(
      request,
      `${process.env.API_URL}/v1/accounts/by/type/${accountType}`,
    );
    yield put(loadAccountsByTypeSuccess(result));
  } catch (err) {
    yield put(loadAccountsByTypeError(err));
  }
}

/**
 * Save Invoice data request/response handler
 */
export function* saveInvoice({ type, invoiceId, ...payload }) {
  try {
    // Call our request helper (see 'utils/request')
    const hvac = yield call(
      request,
      `${process.env.API_URL}/v1/invoices/${invoiceId}`,
      {
        method: 'PUT',
        body: JSON.stringify(payload),
      },
    );

    yield put(saveInvoiceSuccess(hvac));
  } catch (err) {
    yield put(saveInvoiceError(err));
  }
}

/**
 * Appointment request/response handler
 */
export function* scheduleAppointment({ type, jobId, ...payload }) {
  try {
    // Call our request helper (see 'utils/request')
    const appointment = yield call(
      request,
      `${process.env.API_URL}/v1/jobs/${jobId}/appointments?companyId=${domainCompanyId}`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
      },
    );

    yield put(scheduleAppointmentSuccess(appointment));
  } catch (err) {
    yield put(scheduleAppointmentError(err));
  }
}

/**
 * Create Proeprty Listing request/response handler
 */
export function* createPropertyListng({ propertyId }) {
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(
      request,
      `${process.env.API_URL}/v1/property-listings`,
      {
        method: 'POST',
        body: JSON.stringify({
          propertyId,
        }),
      },
    );

    yield put(createPropertyListingSuccess(result));
  } catch (err) {
    yield put(createPropertyListingError(err));
  }
}

// --------------------------------------------------
export function* deleteInvoice({ invoiceId }) {
  try {
    const result = yield call(
      request,
      `${process.env.API_URL}/v1/invoices/${invoiceId}`,
      {
        method: 'DELETE',
      },
    );
    yield put(deleteInvoiceSuccess(result));
  } catch (err) {
    yield put(deleteInvoiceError(err));
  }
}

// --------------------------------------------------
export function* deleteExpense({ expenseId }) {
  try {
    const result = yield call(
      request,
      `${process.env.API_URL}/v1/expenses/${expenseId}`,
      {
        method: 'DELETE',
      },
    );
    yield put(deleteExpenseSuccess(result));
  } catch (err) {
    yield put(deleteExpenseError(err));
  }
}

// --------------------------------------------------
export function* loadCompanyCards() {
  try {
    const result = yield call(
      request,
      `${process.env.API_URL}/v1/company-cards`,
    );
    yield put(loadCompanyCardsSuccess(result));
  } catch (err) {
    yield put(loadCompanyCardsError(err));
  }
}

// --------------------------------------------------
export function* saveExpense({ type, expenseId, ...payload }) {
  try {
    const result = yield call(
      request,
      `${process.env.API_URL}/v1/expenses/${expenseId}`,
      {
        method: 'PUT',
        body: JSON.stringify(payload),
      },
    );
    yield put(saveExpenseSuccess(result));
  } catch (err) {
    yield put(saveExpenseError(err));
  }
}

// --------------------------------------------------
export function* loadEmailTemplates({ type, ...params }) {
  try {
    const query = qs.stringify(params);

    // Call our request helper (see 'utils/request')
    const emailTemplates = yield call(
      request,
      `${process.env.API_URL}/v1/emails/templates?${query}`,
      {
        method: 'GET',
      },
    );

    yield put(loadEmailTemplatesSuccess(emailTemplates));
  } catch (err) {
    yield put(loadEmailTemplatesError(err));
  }
}

// --------------------------------------------------
export function* updatePropertyKeyLog({ type, keyLogId, ...payload }) {
  try {
    const result = yield call(
      request,
      `${process.env.API_URL}/v1/visual-manager/property-key-log/${keyLogId}`,
      {
        method: 'PUT',
        body: JSON.stringify(payload),
      },
    );
    yield put(updatePropertyKeyLogSuccess(result));
  } catch (err) {
    yield put(updatePropertyKeyLogError(err));
  }
}

// --------------------------------------------------
export function* loadLockboxes({ status }) {
  try {
    const lockboxes = yield call(
      request,
      `${process.env.API_URL}/v1/lockboxes?status=${status}`,
      {
        method: 'GET',
      },
    );
    yield put(loadLockboxesSuccess(lockboxes));
  } catch (err) {
    yield put(loadLockboxesError(err));
  }
}

// --------------------------------------------------
export function* assignLockboxProperty({ lockboxId, propertyId }) {
  try {
    const result = yield call(
      request,
      `${process.env.API_URL}/v1/visual-manager/lockbox/${lockboxId}/properties/${propertyId}/assign`,
      {
        method: 'PUT',
      },
    );

    yield put(assignLockboxPropertySuccess(result));
  } catch (err) {
    yield put(assignLockboxPropertyError(err));
  }
}

// --------------------------------------------------
// you can pass one of the parameters propertyId or lockboxId
export function* unassignLockboxProperty({ propertyId = '', lockboxId = '' }) {
  try {
    const result = yield call(
      request,
      `${process.env.API_URL}/v1/visual-manager/lockbox/unassign?lockboxId=${lockboxId}&propertyId=${propertyId}`,
      {
        method: 'PUT',
      },
    );

    yield put(unassignLockboxPropertySuccess(result));
  } catch (err) {
    yield put(unassignLockboxPropertyError(err));
  }
}

// --------------------------------------------------
// you can pass one of the parameters propertyId or lockboxId
export function* sendOpportunityWelcomeEmail({ opportunityId }) {
  try {
    const result = yield call(
      request,
      `${process.env.API_URL}/v1/opportunities/${opportunityId}/welcome-email`,
      {
        method: 'POST',
      },
    );

    yield put(sendOpportunityWelcomeEmailSuccess(result));
  } catch (err) {
    yield put(sendOpportunityWelcomeEmailError(err));
  }
}

// --------------------------------------------------
export function* loadMarkets() {
  try {
    const result = yield call(request, `${process.env.API_URL}/v1/markets`);

    yield put(loadMarketsSuccess(result));
  } catch (err) {
    yield put(loadMarketsError(err));
  }
}

// --------------------------------------------------
export function* loadZplTemplates() {
  try {
    const result = yield call(
      request,
      `${process.env.API_URL}/v1/zpl-templates`,
    );

    yield put(loadZplTemplatesSuccess(result));
  } catch (err) {
    yield put(loadZplTemplatesError(err));
  }
}

// --------------------------------------------------
export function* deleteDocusignEnvelope({ type, envelopeId, ...params }) {
  try {
    const query = qs.stringify(params);
    const envelope = yield call(
      request,
      `${process.env.API_URL}/v1/docusign/envelopes/${envelopeId}?${query}`,
      {
        method: 'DELETE',
      },
    );

    yield put(deleteDocusignEnvelopeSuccess(envelope));
  } catch (err) {
    yield put(deleteDocusignEnvelopeError(err));
  }
}

// --------------------------------------------------
export function* updateContractAddendum({
  type,
  contractId,
  addendumId,
  extraAction,
  ...payload
}) {
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(
      request,
      `${process.env.API_URL}/v1/contracts/${contractId}/addendums/${addendumId}?extraAction=${extraAction}`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
      },
    );

    yield put(updateContractAddendumSuccess(result));
  } catch (err) {
    yield put(updateContractAddendumError(err));
  }
}

// decode the logged in user
function parseJwt(token) {
  // TODO  jwt-decode package
  if (!token) {
    return;
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  // eslint-disable-next-line consistent-return
  return JSON.parse(decodeBase64(base64));
}

export function* loadUser({ credentials }) {
  try {
    const user = yield call(customRequestSso, credentials.ssoToken);

    let { expiresIn } = credentials;
    if (!expiresIn) {
      // Get expiresIn from token payload
      const parsedToken = parseJwt(credentials.ssoToken);
      expiresIn = parsedToken?.expiresIn;
    }

    Auth.authenticate({
      user,
      token: credentials.ssoToken,
      expiresIn,
      redirectTo: credentials.redirectTo || '/',
    });

    yield put(loadUserSuccess(user));
  } catch (err) {
    yield put(loadUserError(err));
  }
}

// --------------------------------------------------
export function* createInvoice({ type, ...payload }) {
  try {
    const result = yield call(request, `${process.env.API_URL}/v1/invoices`, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    yield put(createInvoiceSuccess(result));
  } catch (err) {
    yield put(createInvoiceError(err));
  }
}

// --------------------------------------------------
export function* updateApplication({ type, applicationId, ...payload }) {
  try {
    const result = yield call(
      request,
      `${process.env.API_URL}/v1/applications/${applicationId}`,
      {
        method: 'PUT',
        body: JSON.stringify(payload),
      },
    );
    yield put(updateApplicationSuccess(result));
  } catch (err) {
    yield put(updateApplicationError(err));
  }
}

/**
 * Save Contact request/response handler
 */
export function* saveContact({ type, contactId = '', ...payload }) {
  try {
    // Call our request helper (see 'utils/request')
    const contact = yield call(
      request,
      `${process.env.API_URL}/v1/contacts/${contactId}`,
      {
        method: 'PUT',
        body: JSON.stringify(payload),
      },
    );

    yield put(saveContactSuccess(contact));
  } catch (err) {
    yield put(saveContactError(err));
  }
}

/**
 * Create Contact request/response handler
 */
export function* createContact({ type, ...payload }) {
  try {
    // Call our request helper (see 'utils/request')
    const contact = yield call(request, `${process.env.API_URL}/v1/contacts`, {
      method: 'POST',
      body: JSON.stringify(payload),
    });

    yield put(createContactSuccess(contact));
  } catch (err) {
    yield put(createContactError(err));
  }
}

/**
 * Delete/Replace Contact request/response handler
 */
export function* deleteContact({ toDeleteId, toReplaceId, accountId }) {
  try {
    const params = toReplaceId ? `/${toReplaceId}` : '';
    // Call our request helper (see 'utils/request')
    yield call(
      request,
      `${process.env.API_URL}/v1/contacts/${toDeleteId}/${accountId}${params}`,
      {
        method: 'DELETE',
      },
    );

    yield put(deleteContactSuccess(toDeleteId));
  } catch (err) {
    yield put(deleteContactError(err));
  }
}

// ----------------------------------------------------------------
export function* updateVendor({ type, accountId, ...payload }) {
  try {
    const result = yield call(
      request,
      `${process.env.API_URL}/v1/vendors/${accountId}`,
      {
        method: 'PUT',
        body: JSON.stringify(payload),
      },
    );

    yield put(updateVendorSuccess(result));
  } catch (err) {
    yield put(updateVendorError(err));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* appData() {
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(LOAD_COMPANY_INFO, getCompanyInfo);
  yield takeLatest(LOAD_COMPANIES, getCompanies);
  yield takeLatest(SUBMIT_COMMENT, submitComment);
  yield takeLatest(LOAD_USERS, getUsers);
  yield takeLatest(LOAD_TECHNICIANS, getTechnicians);
  yield takeLatest(UPDATE_TASK, updateTask);
  yield takeLatest(CREATE_TASK, createTask);
  yield takeLatest(LOAD_SOBJECT, getSobject);
  yield takeLatest(LOAD_PICK_LISTS, getPickLists);
  yield takeLatest(LOAD_RECORD_TYPES, getRecordTypes);
  yield takeLatest(SEARCH_RECORDS, searchRecords);
  yield takeLatest(DELETE_SOBJECT_FILE, deleteSobjectFile);
  yield takeLatest(RENAME_SOBJECT_FILE, renameSobjectFile);
  yield takeLatest(LOAD_JOBS, getJobs);
  yield takeLatest(LOAD_EVENTS, getEvents);
  yield takeLatest(LOAD_SMS_MESSAGES, getSmsMessages);
  yield takeLatest(SEND_SMS, sendSms);
  yield takeLatest(SEND_EMAIL, sendEntityEmail);
  yield takeLatest(LOAD_SMS_TEMPLATES, getSmsTemplates);
  yield takeLatest(UPDATE_SMS_MESSAGE, updateSmsMessage);
  yield takeLatest(UPDATE_JOB, updateJob);
  yield takeLatest(LOAD_WORK_ORDER, getWorkOrder);
  yield takeLatest(LOAD_MAINTENANCE_GROUPS, getMaintenanceGroups);
  yield takeLatest(UPDATE_INSPECTION_STATUS, updateInspectionStatus);
  yield takeLatest(
    UPDATE_PROPERTY_ROUTINE_INSPECTIONS,
    updatePropertyRoutineInspections,
  );
  yield takeLatest(LOAD_COMMUNICATION_DATA, loadCommunicationData);
  yield takeLatest(SAVE_INSPECTION, saveInspection);
  yield takeLatest(SAVE_PROPERTY, saveProperty);
  yield takeLatest(SAVE_PROPERTY_OWNER_SETTINGS, savePropertyOwnerSettings);
  yield takeLatest(SAVE_WORK_ORDER, saveWorkOrder);
  yield takeLatest(SAVE_OPPORTUNITY, saveOpportunity);
  yield takeLatest(SAVE_LEAD, saveLead);
  yield takeLatest(DELETE_LEAD, deleteLead);
  yield takeLatest(SAVE_CONTRACT, saveContract);
  yield takeLatest(SAVE_LOCKER, saveLocker);
  yield takeLatest(SAVE_PROPERTY_LISTING, savePropertyListing);
  yield takeLatest(LOOKUP_PROPERTY, lookupProperty);
  yield takeLatest(SEARCH_CONTACTS, searchContacts);
  yield takeLatest(LOAD_INSPECTION, getInspection);

  yield takeLatest(UPDATE_LINE_ITEM, updateLineItem);
  yield takeLatest(CREATE_LINE_ITEM, createLineItem);
  yield takeLatest(DELETE_LINE_ITEM, deleteLineItem);

  yield takeLatest(LOAD_PROPERTY, getProperty);
  yield takeLatest(RUN_CRONTAB_JOB, runCrontabJob);
  yield takeLatest(RESERVE_NEXT_AVAILABLE_LOCKER, reserveNextAvailableLocker);

  yield takeLatest(ENRICH_PROPERTY, enrichProperty);

  yield takeLatest(
    LOAD_PROPERTY_DETAILS_AND_OWNER_SETTINGS,
    loadPropertyDetailsAndOwnerSettings,
  );

  yield takeLatest(DELETE_FILES, deleteFiles);
  yield takeLatest(UPDATE_FILES, updateFiles);
  yield takeLatest(LOAD_ACCOUNTS_BY_TYPE, loadAccountsByType);
  yield takeLatest(SAVE_INVOICE, saveInvoice);

  yield takeLatest(SCHEDULE_APPOINTMENT, scheduleAppointment);
  yield takeLatest(CREATE_PROPERTY_LISTING, createPropertyListng);
  yield takeLatest(DELETE_INVOICE, deleteInvoice);
  yield takeLatest(DELETE_EXPENSE, deleteExpense);
  yield takeLatest(LOAD_COMPANY_CARDS, loadCompanyCards);
  yield takeLatest(SAVE_EXPENSE, saveExpense);
  yield takeLatest(LOAD_EMAIL_TEMPLATES, loadEmailTemplates);
  yield takeLatest(UPDATE_PROPERTY_KEY_LOG, updatePropertyKeyLog);

  yield takeLatest(DELETE_JOB, deleteJob);

  yield takeLatest(LOAD_LOCKBOXES, loadLockboxes);
  yield takeLatest(ASSIGN_LOCKBOX_PROPERTY, assignLockboxProperty);
  yield takeLatest(UNASSIGN_LOCKBOX_PROPERTY, unassignLockboxProperty);

  yield takeLatest(SEND_OPPORTUNITY_WELCOME_EMAIL, sendOpportunityWelcomeEmail);

  yield takeLatest(LOAD_MARKETS, loadMarkets);

  yield takeLatest(LOAD_ZPL_TEMPLATES, loadZplTemplates);

  yield takeLatest(DELETE_DOCUSIGN_ENVELOPE, deleteDocusignEnvelope);

  yield takeLatest(UPDATE_CONTRACT_ADDENDUM, updateContractAddendum);
  yield takeLatest(LOAD_USER, loadUser);

  yield takeLatest(CREATE_INVOICE, createInvoice);
  yield takeLatest(UPDATE_APPLICATION, updateApplication);

  yield takeLatest(SAVE_CONTACT, saveContact);
  yield takeLatest(CREATE_CONTACT, createContact);
  yield takeLatest(DELETE_CONTACT, deleteContact);

  yield takeLatest(UPDATE_VENDOR, updateVendor);
}
