import qs from 'qs';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

export default history;

export const getHref = () =>
  `${history.location.pathname}${history.location.search}${history.location.hash}`;

export const getRedirectTo = () => {
  // detect where to redirect
  const query = qs.parse(history.location.search.substring(1)); // remove ? in the beginning
  if (query.redirectTo) {
    // if we have it already
    return encodeURIComponent(query.redirectTo);
  }
  // generate fresh one
  return encodeURIComponent(getHref());
};
