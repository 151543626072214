import Auth from 'helpers/auth';

const getRedirectTo = () => {
  let redirectTo = window.location.href; // http://localhost:3000/work-orders
  // Do not redirect to logout, login page
  if (
    redirectTo.includes('logout') ||
    redirectTo.includes('login') ||
    redirectTo.includes('sso-callback')
  ) {
    redirectTo = `${window.location.origin}/`; // origin http://localhost:3000
  }
  return encodeURIComponent(redirectTo);
};

export const externalRedirectToSsoLogin = (callbackRedirectTo = '') => {
  let redirectTo;
  if (callbackRedirectTo) {
    redirectTo = encodeURIComponent(callbackRedirectTo);
  } else {
    redirectTo = getRedirectTo();
  }

  window.location.href = `${process.env.SSO_DOMAIN}/login?redirectTo=${redirectTo}`;
};

export const externalRedirectToSsoTermsAndConditions = ({
  companyId = '',
  userId = '',
}) => {
  window.location.href = `${
    process.env.SSO_DOMAIN
  }/terms-and-conditions/${companyId}/${userId}?redirectTo=${getRedirectTo()}`;
};

export const externalRedirectToSsoOnError = (error = '') => {
  const redirectTo = getRedirectTo();
  const encodedError = encodeURIComponent(error);
  window.location.href = `${process.env.SSO_DOMAIN}/login?redirectTo=${redirectTo}&error=${encodedError}`;
};

// To avoid relogin on external service link open
export const externalServiceLinkWithSsoLogin = (link = '') => {
  const url = new URL(link);
  const serviceOrigin = url.origin;
  const encodedLink = encodeURIComponent(link);

  const token = Auth.getToken();
  const expiresIn = Auth.getExpiresIn();
  return `${serviceOrigin}/sso-callback?ssoToken=${token}&expiresIn=${expiresIn}&redirectTo=${encodedLink}`;
};
