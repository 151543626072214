import qs from 'qs';
import { v4 as uuid } from 'uuid';
import request from 'utils/request';
import { encodeBase64 } from 'helpers/template';
import House1 from 'images/house1.jpg';
import sortBy from 'lodash/sortBy';
import { makeStringArray } from 'helpers/general';
import { formatNonUtcDate, formatPhoneNumber } from 'helpers/format';

// -----------------------------------------------------
const STREET_VIEW_URL = 'https://maps.googleapis.com/maps/api/streetview';
export const getStreetViewImage = async ({
  latitude,
  longitude,
  panoId = '',
  size = '420x255',
  isPdfView = false,
}) => {
  const query = {
    size,
    fov: 50,
    key: process.env.GOOGLE_MAPS_KEY,
  };
  if (panoId) {
    query.pano = panoId;
  } else if (latitude && longitude) {
    query.location = [latitude, longitude].join(',');
  } else {
    // if nothing provided -> default image
    return House1;
  }

  const URL = `${STREET_VIEW_URL}?${qs.stringify(query)}`;
  const response = await request(
    `${process.env.API_URL}/v1/properties/sign-maps-url?rand=${uuid()}${
      isPdfView ? '&isPdfView=true' : ''
    }`,
    {
      method: 'POST',
      body: JSON.stringify({ url: encodeBase64(URL) }),
    },
  );

  return response?.url || House1;
};

// -----------------------------------------------------
export const fetchStreetViewImageMetadata = async ({
  latitude,
  longitude,
  size = '420x255',
  isPdfView = false,
}) => {
  const query = {
    fov: 50,
    size,
    key: process.env.GOOGLE_MAPS_KEY,
    location: [latitude, longitude].join(','),
  };

  // get signed maps url
  const URL = `${STREET_VIEW_URL}/metadata?${qs.stringify(query)}`;
  const response = await request(
    `${process.env.API_URL}/v1/properties/sign-maps-url?rand=${uuid()}${
      isPdfView ? '&isPdfView=true' : ''
    }`,
    {
      method: 'POST',
      body: JSON.stringify({ url: encodeBase64(URL) }),
    },
  );

  // load the data
  const metadata = await fetch(response.url);
  const json = await metadata.json();
  if (metadata.status !== 200 || json.status !== 'OK') {
    throw new Error(`Image ${response.url} not found`);
  }
  return json;
};

// -----------------------------------------------------
export const convertAppliance = (a) => ({
  id: a.Id,
  type: a.Type__c || '',
  manufacturer: a.Manufacturer__c || '',
  model: a.Model__c || '',
  serial: a.Serial_Number__c || '',
  warranty: !!a.Warranty__c,
});

// -----------------------------------------------------
export const convertService = (s) => ({
  id: s.Id,
  type: s.Type__c || '',
  companyName: s.Company__c || '',
  holder: s.Holder__c || '',
  dateStart: formatNonUtcDate(s.Start_Date__c, 'MM/DD/YYYY'),
  dateEnd: formatNonUtcDate(s.End_Date__c, 'MM/DD/YYYY'),
  status: s.Status__c || '',
  phone: formatPhoneNumber(s.Phone_Number__c || ''),
  email: s.Email__c || '',
  accountNumber: s.Policy_Number__c || '',
  serviceIncludedInRent: !!s.Included__c,
  powerOfAttorney: !!s.Power_of_Attorney__c,
  noServiceInterruption: !!s.No_Service_Interruption__c,
});

// -----------------------------------------------------
export const getStarterPropertyOwnerSettings = ({
  swimmingPool = false,
  activeOwnerContract = null,
  relatedCrmProperty = null,
}) => {
  let responsibleForPool = activeOwnerContract?.Maintain_Pool_Owner__c || '';
  if (!responsibleForPool) {
    responsibleForPool = swimmingPool ? 'Owner' : 'Tenant';
  }
  const responsibleForPestControl =
    activeOwnerContract?.Maintain_Pest_Control_Owner__c || 'Tenant';
  const responsibleForLandscape =
    activeOwnerContract?.Maintain_Landscaping_Owner__c || 'Tenant';

  const isPetsAllowed = !!activeOwnerContract?.Allow_Pets_Owner__c;

  // convert amenities
  const amenities = makeStringArray(relatedCrmProperty?.Amenities__c);
  amenities.sort();

  // prepare appliances list
  const appliances = sortBy(
    (
      relatedCrmProperty?.Appliances__r?.records ||
      relatedCrmProperty?.Appliance__r ||
      []
    ).map(convertAppliance),
    ['type'],
  );

  return {
    appliances: {
      list: appliances,
      unlinked: [],
    },
    amenities, // string array
    isPetsAllowed,
    numberOfDogs: activeOwnerContract?.Number_of_Dogs_Allowed__c || 0,
    numberOfCats: activeOwnerContract?.Number_of_Cats_Allowed__c || 0,
    dogBreedSize: activeOwnerContract?.Dog_Breed_Size__c || '',
    petRestrictions: activeOwnerContract?.Pet_Restrictions__c || '',
    petFeeOnq: activeOwnerContract?.Pet_Fee_On_Q__c || 0,
    petFee: activeOwnerContract?.Pet_Fee__c || 0,
    petRent: activeOwnerContract?.Pet_Rent__c || 0,
    petDeposit: activeOwnerContract?.Pet_Deposit__c || 0,
    responsibleForPestControl,
    responsibleForPool,
    responsibleForLandscape,
    additionalLeaseTerms:
      activeOwnerContract?.Additional_Lease_Terms_Owner__c || '',
  };
};

// -----------------------------------------------------
const convertPropertyListing = (propertyListing) => {
  if (!propertyListing) {
    return null;
  }
  return {
    id: propertyListing.Id || '',
    status: propertyListing.Status__c || '',
    employeeComment: propertyListing.Comments__c || '',
    ownerDecision: propertyListing.Owner_Decision__c || '',
  };
};

// -----------------------------------------------------
export const convertOwnerContract = ({ contract = null, listing = null }) => {
  if (!contract) {
    return null;
  }
  return {
    id: contract.Id,
    status: contract.Status,
    numberOfDogs: contract.Number_of_Dogs_Allowed__c || 0,
    numberOfCats: contract.Number_of_Cats_Allowed__c || 0,
    dogBreedSize: contract.Dog_Breed_Size__c || '',
    responsibleForLandscape: contract.Maintain_Landscaping_Owner__c || '',
    responsibleForPestControl: contract.Maintain_Pest_Control_Owner__c || '',
    responsibleForPool: contract.Maintain_Pool_Owner__c || '',
    petRestrictions: contract.Pet_Restrictions__c || '',
    isPetsAllowed: !!contract.Allow_Pets_Owner__c,
    additionalLeaseTerms: contract.Additional_Lease_Terms_Owner__c || '',
    propertyListing: convertPropertyListing(listing),
    petFeeOnq: contract.Pet_Fee_On_Q__c || 0,
    petFee: contract.Pet_Fee__c || 0,
    petRent: contract.Pet_Rent__c || 0,
    petDeposit: contract.Pet_Deposit__c || 0,
  };
};

export const prepareServicesListAndHOA = ({ servicesToConvert }) => {
  const data = servicesToConvert.reduce(
    (acc, service) => {
      const type = service.Type__c || '';
      const companyName = service.Company__c || '';
      const phone = formatPhoneNumber(service.Phone_Number__c || '');

      if (type === 'HOA') {
        acc.hoaService = {
          companyName,
          phone,
        };
      } else if (type) {
        acc.servicesList = acc.servicesList
          ? [acc.servicesList].concat(type).join(', ')
          : [].concat(type).join(', ');
      }

      return acc;
    },
    {
      servicesList: '',
      hoaService: null,
    },
  );

  return data;
};
