import React from 'react';
import merge from 'lodash/merge';
import keyBy from 'lodash/keyBy';
import values from 'lodash/values';
import mergeWith from 'lodash/mergeWith';
import sortBy from 'lodash/sortBy';
import Auth from 'helpers/auth';
import parseValidation from 'helpers/parseValidation';
import { constants as AutomationType } from 'enums/processAutomationType';
import { isFailedFetch } from './general';

export const useMergeState = (initialState) => {
  const [state, setState] = React.useState(initialState);
  const setMergedState = (newState) =>
    setState((prevState) => ({ ...prevState, ...newState }));
  return [state, setMergedState];
};

const checkIsSkip = ({
  filters = [],
  isExclude = false,
  value = '', // '' || []
}) => {
  if (filters.length) {
    if (isExclude) {
      return !filters.every((filter) => !value.includes(filter));
    }
    return !filters.some((f) => value.includes(f));
  }
  return false;
};

const checkIsZoneSkip = ({
  filters = [],
  isExclude = false,
  value = 0,
  zones = null,
}) => {
  if (filters.length && zones) {
    // collect set of the zip codes
    const zipCodes = filters.reduce(
      (memo, zone) => memo.concat(zones[zone]),
      [],
    );
    if (isExclude) {
      return zipCodes.includes(value);
    }
    return !zipCodes.includes(value);
  }
  return false;
};

const mergeByKey = ({ a = [], b = [], key }) => {
  const merged = merge({}, keyBy(a, key), keyBy(b, key));
  return values(merged);
};

const mergeCustomizer = (objValue, srcValue) => {
  if (Array.isArray(objValue)) {
    return mergeByKey({
      a: objValue,
      b: srcValue,
      key: 'id',
    });
  }
  return undefined;
};

export const mergeWithSearch = ({ jobs, foundJobs }) => {
  if (!jobs) {
    return foundJobs;
  }
  if (!foundJobs) {
    return jobs;
  }

  const appointments = mergeByKey({
    a: foundJobs.appointments,
    b: jobs.appointments,
    key: 'id',
  });

  const vendorTechnicians = mergeByKey({
    a: foundJobs.vendorTechnicians,
    b: jobs.vendorTechnicians,
    key: 'key',
  });

  const technicians = mergeByKey({
    a: foundJobs.technicians,
    b: jobs.technicians,
    key: 'id',
  });

  const dailyRoute = mergeWith(
    {},
    foundJobs.dailyRoute,
    jobs.dailyRoute,
    mergeCustomizer,
  );

  const vendorDetails = merge({}, foundJobs.vendorDetails, jobs.vendorDetails);

  return {
    jobs: foundJobs.jobs,
    appointments,
    dailyRoute,
    vendorTechnicians,
    technicians,
    vendorDetails,
  };
};

const filterJobs = ({
  jobs = [],
  filters = null,
  selectedMarket = null,
  superintendentFor = '',
}) => {
  // filter disallowed jobs for user
  const filteredJobs = jobs.filter((job) => {
    // if user is not a superintendent and job has a group -> hide job
    if (!superintendentFor) {
      // and job has a group -> hide job
      if (job.maintenanceGroup && !filters?.maintenanceGroups?.length) {
        return false;
      }
    } else {
      // superintendent and job has no group -> hide job
      if (!job.maintenanceGroup) {
        return false;
      }
      // superintendent and job group doesn't match -> hide job
      if (job.maintenanceGroup !== superintendentFor) {
        return false;
      }
    }
    return true;
  });

  if (!filters) {
    return filteredJobs;
  }

  // apply filters
  return filteredJobs.filter((job) => {
    // filter by maintenanance groups
    let isSkip = checkIsSkip({
      filters: filters?.maintenanceGroups || [],
      isExclude: filters?.isExcludeMaintenanceGroups,
      value: job.maintenanceGroup,
    });

    if (isSkip) {
      return false;
    }

    // filter by job job status
    isSkip = checkIsSkip({
      filters: filters?.jobStatuses || [],
      isExclude: filters?.isExcludeJobStatuses,
      value: job.status,
    });

    if (isSkip) {
      return false;
    }

    // filter by job labels
    isSkip = checkIsSkip({
      filters: filters?.labels,
      isExclude: filters?.isExcludeLabels,
      value: job.labels,
    });

    if (isSkip) {
      return false;
    }

    // filter by inspection record type or work order type
    isSkip = checkIsSkip({
      filters: filters?.entityTypes,
      isExclude: filters?.isExcludeEntityTypes,
      value: job.entityType,
    });

    if (isSkip) {
      return false;
    }

    // filter by filter services
    const filterServiceValue = [
      job.isFilterServiceNeeded && 'needed',
      job.isFilterServiceRequested && 'requested',
    ]
      .filter(Boolean)
      .join(',');

    isSkip = checkIsSkip({
      filters: filters?.filterServices,
      isExclude: filters?.isExcludeFilterServices,
      value: filterServiceValue,
    });

    if (isSkip) {
      return false;
    }

    // filter by market zones
    isSkip = checkIsZoneSkip({
      filters: filters?.marketZones,
      isExclude: filters?.isExcludeMarketZones,
      value: Number(job.zip),
      zones: selectedMarket?.zones,
    });

    if (isSkip) {
      return false;
    }

    // filter by appointment status and technician
    const appointments = job.appointments.filter(
      ({ technicianName, status }) => {
        // filter by technician
        let isSkipApp = checkIsSkip({
          filters: filters?.technicians,
          isExclude: filters?.isExcludeTechnicians,
          value: technicianName,
        });
        if (isSkipApp) {
          return false;
        }

        // filter by appointment status
        isSkipApp = checkIsSkip({
          filters: filters?.appointmentStatuses || [],
          isExclude: filters?.isExcludeAppointmentStatuses,
          value: status,
        });
        if (isSkipApp) {
          return false;
        }

        return true;
      },
    );

    if (filters?.technicians?.length && appointments.length === 0) {
      return false;
    }

    if (filters?.appointmentStatuses?.length && appointments.length === 0) {
      return false;
    }

    return true;
  });
};

export const mergeJobsAndFilter = ({
  jobs = null,
  filters = null,
  foundJobs = null,
  selectedMarket = null,
  superintendentFor = '',
}) => {
  const jobsToFilter = mergeWithSearch({ jobs, foundJobs });
  if (!jobsToFilter) {
    return null;
  }
  return {
    ...jobsToFilter,
    mergedJobs: jobsToFilter?.jobs,
    jobs: filterJobs({
      jobs: jobsToFilter?.jobs,
      filters,
      superintendentFor,
      selectedMarket,
    }),
  };
};

export const filterAllowedAutomationOptions = (options) => {
  let filtered = options;

  const isAllowedCreateWo =
    Auth.isHavePermission('Create_Work_Orders__c') ||
    Auth.isHavePermission('Edit_Work_Orders__c');

  if (!isAllowedCreateWo) {
    filtered = filtered.filter(
      (o) => o.value !== AutomationType.CREATE_WORK_ORDER,
    );
  }

  const isAllowedCreateDirectWo =
    Auth.isHavePermission('Create_Work_Orders__c:Mammoth Direct') ||
    Auth.isHavePermission('Edit_Work_Orders__c:Mammoth Full') ||
    Auth.isHavePermission('Edit_Work_Orders__c:Mammoth Restricted');

  if (!isAllowedCreateDirectWo) {
    filtered = filtered.filter(
      (o) => o.value !== AutomationType.CREATE_WORK_ORDER_NEW_CUSTOMER,
    );
  }

  if (!Auth.isHavePermission('Process_Automation__c:Create Inspection')) {
    filtered = filtered.filter(
      (o) =>
        ![
          AutomationType.CREATE_INSPECTION,
          AutomationType.CREATE_INSPECTION_NEW_CUSTOMER,
        ].includes(o.value),
    );
  }

  if (!Auth.isHavePermission('Process_Automation__c:Schedule Inspection')) {
    filtered = filtered.filter(
      (o) => o.value !== AutomationType.SCHEDULE_INSPECTION,
    );
  }

  if (
    !Auth.isHavePermission('Process_Automation__c:Property Manager Notices')
  ) {
    filtered = filtered.filter((o) =>
      [
        AutomationType.CREATE_WORK_ORDER,
        AutomationType.CREATE_WORK_ORDER_NEW_CUSTOMER,
        AutomationType.CREATE_INSPECTION,
        AutomationType.CREATE_INSPECTION_NEW_CUSTOMER,
        AutomationType.SCHEDULE_INSPECTION,
      ].includes(o.value),
    );
  }

  return sortBy(filtered, ['label']);
};

export const isPreActiveLease = (status) =>
  [
    // pre-active status
    'Holding Created',
    'Holding Sent',
    'Holding Signed',
    'Holding Confirmed',
    'Lease Created',
    'Lease Sent',
    'Lease Confirmed',
  ].includes(status);

export const isActiveLease = (status) =>
  isPreActiveLease(status) ||
  [
    // active status
    'Active',
    'Month to Month',
    'Pending Lease Renewal',
    'Sent Lease Renewal',
    'Pending Termination',
  ].includes(status);

export const isActiveOwner = (status) =>
  ['Active', 'Pending Termination'].includes(status);

export const setErrorState = ({ state, error }) => {
  const validation = parseValidation(error?.responseBody?.errors);
  state.error = error?.responseBody?.message || error?.message;
  state.successMessage = '';
  state.validation = validation;
  state.isFailedFetch = isFailedFetch(error);
};
