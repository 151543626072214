import React, { memo, useState, useCallback } from 'react';
import { string, bool, func, object } from 'prop-types';
import { Button } from '@mui/material';
import DateRangePopover from 'components/DateRangePopover';

const DateRangePickerButton = ({
  startDate = '',
  endDate = '',
  isLoading = false,
  onSelectDateRange,
  rootSx = null,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onOpenPicker = useCallback((e) => setAnchorEl(e.currentTarget), []);

  const onClosePicker = useCallback(() => setAnchorEl(null), []);

  const onSelectRange = useCallback((range) => {
    onSelectDateRange(range);
    setAnchorEl(null);
  }, []);

  const [sMonth, sDay] = startDate.split('/');
  const [eMonth, eDay] = endDate.split('/');
  const headerStartEnd = `${sMonth}/${sDay}-${eMonth}/${eDay}`;

  return (
    <>
      <Button
        sx={rootSx}
        color="primary"
        variant="outlined"
        disabled={isLoading}
        onClick={onOpenPicker}
        title="Change Dataset Date Period"
      >
        {headerStartEnd}
      </Button>

      <DateRangePopover
        startDate={startDate}
        endDate={endDate}
        anchorEl={anchorEl}
        onClose={onClosePicker}
        onSelectDateRange={onSelectRange}
      />
    </>
  );
};

DateRangePickerButton.propTypes = {
  startDate: string,
  endDate: string,
  isLoading: bool,
  onSelectDateRange: func.isRequired,
  rootSx: object,
};

export default memo(DateRangePickerButton);
