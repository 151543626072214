/* eslint-disable camelcase */
import Moment from 'moment';
import sortBy from 'lodash/sortBy';
import omitBy from 'lodash/omitBy';
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';
import EntityType from 'enums/entityType';
import { formatDate } from 'helpers/format';
import { capitalize } from 'helpers/general';
import { prepareCommunicationData } from 'helpers/communication';
import { convertPropertyDetailsAndOwnerSettings } from 'helpers/propertyDetailsAndOwnerSettings';
import {
  convertCompanyCard,
  convertLockbox,
  convertAccount,
  convertMarket,
} from 'helpers/converters/shared';
import { convertUser } from 'helpers/converters/user';
import { initialState } from './reducer';

const getState = (state) => state.global || initialState;

// ------------------------------------------
export const getIsLoading = createSelector(
  getState,
  (state) => state.isLoading,
);
export const makeGetIsLoadingSelector = () =>
  createSelector(getIsLoading, (isLoading) => isLoading);

// ------------------------------------------
export const getValidation = createSelector(
  getState,
  (state) => state.validation || null,
);
export const makeGetValidationSelector = () =>
  createSelector(getValidation, (validation) => validation);

// ------------------------------------------
export const getError = createSelector(getState, (state) => state.error);
export const makeGetErrorSelector = () =>
  createSelector(getError, (err) => err);

// ------------------------------------------
export const getSuccessMessage = createSelector(
  getState,
  (state) => state.successMessage,
);

export const makeGetSuccessMessageSelector = () =>
  createSelector(getSuccessMessage, (message) => message);

// ------------------------------------------
export const getIsFailedFetch = createSelector(
  getState,
  (state) => state.isFailedFetch,
);

export const makeGetIsFailedFetchSelector = () =>
  createSelector(getIsFailedFetch, (isFailedFetch) => isFailedFetch);

// ------------------------------------------
export const makeGetIsCompaniesLoadingSelector = () =>
  createSelector(getState, (state) => state.isCompaniesLoading);

export const makeGetIsSubmitCommentLoadingSelector = () =>
  createSelector(getState, (state) => state.isSubmitCommentLoading);

export const makeGetIsCommentSubmittedSelector = () =>
  createSelector(getState, (state) => state.isCommentSubmitted);

export const makeGetIsUsersLoadingSelector = () =>
  createSelector(getState, (state) => state.isUsersLoading);

export const makeGetIsUsersLoadedSelector = () =>
  createSelector(getState, (state) => state.isUsersLoaded);

export const makeGetIsTechniciansLoadingSelector = () =>
  createSelector(getState, (state) => state.isTechniciansLoading);

export const makeGetIsSearchingSelector = () =>
  createSelector(getState, (state) => state.isSearching);

export const makeGetIsSobjectLoadingSelector = () =>
  createSelector(getState, (state) => state.isSobjectLoading);

export const makeGetIsPickListsLoadingSelector = () =>
  createSelector(getState, (state) => state.isPickListsLoading);

export const makeGetIsRecordTypesLoadingSelector = () =>
  createSelector(getState, (state) => state.isRecordTypesLoading);

export const getSobject = createSelector(getState, (state) => state.sobject);
export const makeGetSobjectSelector = () =>
  createSelector(getSobject, (sobject) => sobject);

export const makeGetIsDeletingSelector = () =>
  createSelector(getState, (state) => state.isDeletingFile);

export const makeGetIsRenamingSelector = () =>
  createSelector(getState, (state) => state.isRenamingFile);

export const makeGetIsSchedulingAppointmentSelector = () =>
  createSelector(getState, (state) => state.isSchedulingAppointment);

export const makeGetIsScheduledAppointmentSelector = () =>
  createSelector(getState, (state) => state.isScheduledAppointment);

export const makeGetIsUpdatingAppointmentSelector = () =>
  createSelector(getState, (state) => state.isUpdatingAppointment);

export const makeGetIsUpdatedAppointmentSelector = () =>
  createSelector(getState, (state) => state.isUpdatedAppointment);

export const makeGetIsJobsLoadedSelector = () =>
  createSelector(getState, (state) => state.isJobsLoaded);

export const makeGetIsUpdatingAppointmentStatusSelector = () =>
  createSelector(getState, (state) => state.isUpdatingAppointmentStatus);

export const makeGetIsUpdatedAppointmentStatusSelector = () =>
  createSelector(getState, (state) => state.isUpdatedAppointmentStatus);

export const makeIsEventsLoadingSelector = () =>
  createSelector(getState, (state) => state.isEventsLoading);

export const makeIsEventsLoadedSelector = () =>
  createSelector(getState, (state) => state.isEventsLoaded);

export const makeGetIsLoadingSmsMessagesSelector = () =>
  createSelector(getState, (state) => state.isLoadingSmsMessages);

export const makeGetIsSendingSmsSelector = () =>
  createSelector(getState, (state) => state.isSendingSms);

export const makeGetIsLoadingSmsTemplatesSelector = () =>
  createSelector(getState, (state) => state.isLoadingSmsTemplates);

export const makeGetIsUpdatingSmsMessageSelector = () =>
  createSelector(getState, (state) => state.isUpdatingSmsMessage);

export const makeIsUpdatingJobSelector = () =>
  createSelector(getState, (state) => state.isUpdatingJob);

export const makeIsUpdatedJobSelector = () =>
  createSelector(getState, (state) => state.isUpdatedJob);

export const makeGetIsMaintenanceGroupsLoadingSelector = () =>
  createSelector(getState, (state) => state.isMaintenanceGroupsLoading);

export const makeGetIsUpdatingInspectionStatusSelector = () =>
  createSelector(getState, (state) => state.isUpdatingInspectionStatus);

export const makeGetIsUpdatedInspectionStatusSelector = () =>
  createSelector(getState, (state) => state.isUpdatedInspectionStatus);

export const makeGetIsUpdatingPropertyRoutineInspectionsSelector = () =>
  createSelector(
    getState,
    (state) => state.isUpdatingPropertyRoutineInspections,
  );

export const makeGetIsUpdatedPropertyRoutineInspectionsSelector = () =>
  createSelector(
    getState,
    (state) => state.isUpdatedPropertyRoutineInspections,
  );

export const makeIsUpdatingApplicationSelector = () =>
  createSelector(getState, (state) => state.isUpdatingApplication);

export const makeIsUpdatedApplicationSelector = () =>
  createSelector(getState, (state) => state.isUpdatedApplication);

// ----------------------
export const makeGetIsCreatingLineItemSelector = () =>
  createSelector(getState, (state) => state.isCreatingLineItem);

export const makeGetIsCreatedLineItemSelector = () =>
  createSelector(getState, (state) => state.isCreatedLineItem);

export const makeGetCreatedLineItemSelector = () =>
  createSelector(getState, (state) => state.createdLineItem);

export const makeGetIsUpdatingLineItemSelector = () =>
  createSelector(getState, (state) => state.isUpdatingLineItem);

export const makeGetIsUpdatedLineItemSelector = () =>
  createSelector(getState, (state) => state.isUpdatedLineItem);

export const makeGetUpdatedLineItemSelector = () =>
  createSelector(getState, (state) => state.updatedLineItem);

export const makeGetIsDeletingLineItemSelector = () =>
  createSelector(getState, (state) => state.isDeletingLineItem);

export const makeGetIsDeletedLineItemSelector = () =>
  createSelector(getState, (state) => state.isDeletedLineItem);

export const makeGetIsRunningCrontabJobSelector = () =>
  createSelector(getState, (state) => state.isRunningCrontabJob);

// ----------------------------
export const makeGetIsSavingPropertySelector = () =>
  createSelector(getState, (state) => state.isSavingProperty);

export const makeGetIsSavedPropertySelector = () =>
  createSelector(getState, (state) => state.isSavedProperty);

export const makeGetIsSavingPropertyOwnerSettingsSelector = () =>
  createSelector(getState, (state) => state.isSavingPropertyOwnerSettings);

export const makeGetIsSavedPropertyOwnerSettingsSelector = () =>
  createSelector(getState, (state) => state.isSavedPropertyOwnerSettings);

// ----------------------------
export const makeGetIsSavingPropertyListingSelector = () =>
  createSelector(getState, (state) => state.isSavingPropertyListing);

export const makeGetIsSavedPropertyListingSelector = () =>
  createSelector(getState, (state) => state.isSavedPropertyListing);

export const makeGetIsCreatingPropertyListingSelector = () =>
  createSelector(getState, (state) => state.isCreatingPropertyListing);

export const makeGetIsCreatedPropertyListingSelector = () =>
  createSelector(getState, (state) => state.isCreatedPropertyListing);

// ----------------------------
export const makeGetIsSavingWorkOrderSelector = () =>
  createSelector(getState, (state) => state.isSavingWorkOrder);

export const makeGetIsSavedWorkOrderSelector = () =>
  createSelector(getState, (state) => state.isSavedWorkOrder);

// ----------------------------
export const makeGetIsSavingInspectionSelector = () =>
  createSelector(getState, (state) => state.isSavingInspection);

export const makeGetIsSavedInspectionSelector = () =>
  createSelector(getState, (state) => state.isSavedInspection);

// ----------------------------
export const makeGetIsSavingOpportunitySelector = () =>
  createSelector(getState, (state) => state.isSavingOpportunity);

export const makeGetIsSavedOpportunitySelector = () =>
  createSelector(getState, (state) => state.isSavedOpportunity);

// ---------------------------------------------------------

export const makeGetIsSavingLeadSelector = () =>
  createSelector(getState, (state) => state.isSavingLead);

export const makeGetIsSavedLeadSelector = () =>
  createSelector(getState, (state) => state.isSavedLead);

// ---------------------------------------------------------
export const makeGetIsDeletingLeadSelector = () =>
  createSelector(getState, (state) => state.isDeletingLead);

export const makeGetIsDeletedLeadSelector = () =>
  createSelector(getState, (state) => state.isDeletedLead);

// ---------------------------------------------------------
export const makeGetIsDeletingJobSelector = () =>
  createSelector(getState, (state) => state.isDeletingJob);

export const makeGetIsDeletedJobSelector = () =>
  createSelector(getState, (state) => state.isDeletedJob);

// ----------------------------
export const makeGetIsReservingNextAvailableLockerSelector = () =>
  createSelector(getState, (state) => state.isReservingNextAvailableLocker);

export const getNextAvailableLocker = createSelector(
  getState,
  (state) => state.nextAvailableLocker,
);

export const makeGetNextAvailableLockerSelector = () =>
  createSelector(getNextAvailableLocker, (entity) => {
    if (!entity) {
      return null;
    }
    return {
      id: entity.Id || '',
      name: entity.Name || '',
      adminCode: entity.Admin_Code__c || '',
      accessCode: entity.Code__c || '',
      description: entity.Contents_Description__c || '',
      qrCodeLink: entity.QrCodeLink || '',
    };
  });

// ----------------------------
export const makeGetIsLoadingWorkOrderSelector = () =>
  createSelector(getState, (state) => state.isLoadingWorkOrder);

export const getWorkOrder = createSelector(
  getState,
  (state) => state.workOrder,
);

// ----------------------------
export const makeGetIsLoadingInspectionSelector = () =>
  createSelector(getState, (state) => state.isLoadingInspection);

export const getInspection = createSelector(
  getState,
  (state) => state.inspection,
);

// ----------------------------
export const makeGetIsLoadingPropertySelector = () =>
  createSelector(getState, (state) => state.isLoadingProperty);

export const getProperty = createSelector(getState, (state) => state.property);

// ----------------------------
export const makeGetIsEnrichedPropertySelector = () =>
  createSelector(getState, (state) => state.isEnrichedProperty);

export const makeGetIsEnrichingPropertySelector = () =>
  createSelector(getState, (state) => state.isEnrichingProperty);

export const getEnrichmentResult = createSelector(
  getState,
  (state) => state.enrichmentResult || null,
);

export const makeGetEnrichmentResultSelector = () =>
  createSelector(getEnrichmentResult, (enrichmentResult) => enrichmentResult);

// ----------------------------
export const getCompanyInfo = createSelector(
  getState,
  (state) => state.companyInfo,
);

export const makeGetCompanyHeaderInfoSelector = () =>
  createSelector(getCompanyInfo, (companyInfo) => {
    if (companyInfo) {
      return {
        logo: companyInfo?.Logos?.LongWhite || '',
        logoColored: companyInfo?.Logos?.Long || '',
        logoNoText: companyInfo?.Logos?.NoText || '',
        favicon: companyInfo?.Logos?.favicon || '',
        companyName: companyInfo?.FullName || '',
        shortName: companyInfo?.ShortName || '',
        id: companyInfo?.id,
        phone: companyInfo?.Phone,
        website: companyInfo?.URL,
      };
    }
    return {};
  });

export const makeGetCompanyAddressSelector = () =>
  createSelector(getState, (state) => {
    const { Street, Street2, City, State, Zip } = state.companyInfo.Address;
    return `${Street} ${Street2}, ${City}, ${State} ${Zip}`;
  });

export const makeGetStandardInfoSelector = () =>
  createSelector(getCompanyInfo, (companyInfo) => {
    if (companyInfo) {
      return {
        companyName: companyInfo?.FullName,
        standardImage: companyInfo?.Logos?.WorkOrderStandard,
        standardPolicy: companyInfo?.StandardPolicy,
        shortName: companyInfo?.ShortName || '',
      };
    }
    return {};
  });

// TODO: Check and remove
export const makeGetCompanyFooterInfoSelector = () =>
  createSelector(getCompanyInfo, (companyInfo) => {
    if (companyInfo) {
      return {
        privacyPolicy: companyInfo?.PrivacyPolicy,
        termsAndConditions: companyInfo?.TermsAndConditions,
        subcontractorAgreement: companyInfo?.SubcontractorAgreement,
        phone: companyInfo?.Phone,
        roc: companyInfo?.ROC,
        companyName: companyInfo?.FullName,
        email: companyInfo?.Email,
        social: companyInfo?.Social,
      };
    }
    return {};
  });

export const makeGetCompaniesSelector = () =>
  createSelector(getState, (state) => state.companies);

export const getUsers = createSelector(getState, (state) => state.users || []);

export const getTechnicians = createSelector(
  getState,
  (state) => state.technicians || [],
);

export const getCompanies = createSelector(
  getState,
  (state) => state.companies,
);

export const getSmsTemplatesRaw = createSelector(
  getState,
  (state) => state.smsTemplates || [],
);

export const getSmsTemplates = createSelector(
  getSmsTemplatesRaw,
  (smsTemplates) => {
    // group templates
    const grouped = smsTemplates.reduce((memo, template) => {
      const group = template.group || 'General';
      if (!memo[group]) {
        memo[group] = [];
      }
      memo[group].push({
        ...template,
        // for dropdown
        value: template.id,
        label: template.name,
      });
      return memo;
    }, {});

    // restructure for optiongroups
    const templates = Object.keys(grouped).map((group) => ({
      isGroup: true,
      label: group,
      options: grouped[group],
    }));

    return templates;
  },
);

export const makeGetSmsTemplatesSelector = () =>
  createSelector(getSmsTemplates, (smsTemplates) => smsTemplates);

const getPickLists = createSelector(getState, (state) => state.pickLists);
export const makeGetPickListsSelector = () =>
  createSelector(getPickLists, (pickLists) => pickLists);

const getRecordTypes = createSelector(getState, (state) => state.recordTypes);
export const makeGetRecordTypesSelector = () =>
  createSelector(getRecordTypes, (recordTypes) => recordTypes);

export const getSearchResults = createSelector(getState, (state) => ({
  searchIn: state.searchIn || '',
  searchResults: state.searchResults || [],
}));
export const makeGetSearchResultsSelector = () =>
  createSelector(getSearchResults, ({ searchIn, searchResults = [] }) =>
    searchResults.map((r) => ({
      id: r.Id,
      title: r.Name,
      accountId: searchIn === EntityType.ACCOUNTS ? r.Id : r.Account?.Id,
      subtitle: r.Account?.Name,
    })),
  );

const convertSearchResults = (property) => ({
  id: property.Id,
  address: property.Full_Address__c,
  name: property.Name,
  pmName:
    property.Active_Owner_Contract__r?.Property_Manager__r?.Name?.split(' ')[0],
  ownerStatus: property.Active_Owner_Contract__r?.Status,
  tenantStatus: property.Active_Tenant_Contract__r?.Status,
});

export const makeGetFoundPropertiesSelector = () =>
  createSelector(getSearchResults, ({ searchIn, searchResults = [] }) => {
    if (Array.isArray(searchResults) && searchIn === EntityType.PROPERTIES) {
      const convertedSearchResults = searchResults.map((prop) =>
        convertSearchResults(prop),
      );
      return orderBy(convertedSearchResults, ['ownerStatus'], ['asc']);
    }
    return [];
  });

export const getJobs = createSelector(getState, (state) => state.jobs);

const getStartDate = createSelector(getState, (state) => state.startDate);
const getEndDate = createSelector(getState, (state) => state.endDate);
export const getDates = createSelector(
  [getStartDate, getEndDate],
  (startDate, endDate) => {
    if (startDate && endDate) {
      return {
        startDate: Moment(startDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
        endDate: Moment(endDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
      };
    }
    return {
      startDate,
      endDate,
    };
  },
);

const convertAction = (action) => action.split('_').map(capitalize).join(' ');

export const makeEventsSelector = () =>
  createSelector(getState, (state) => {
    const events = state.events.map((event) => ({
      id: event.id,
      action: convertAction(event.eventAction),
      userEmail: event.createdBy,
      jobId: event.targetId,
      eventTimestamp: event.createdAt,
      dateCreated: formatDate(event.createdAt, 'MM/DD/YYYY @ h:mma'),
      data: event.eventObject,
    }));

    return sortBy(events, ['eventTimestamp']);
  });

export const getSmsMessages = createSelector(
  [getState],
  (state) => state.smsMessages,
);

export const getMaintenanceGroups = createSelector(
  getState,
  (state) => state.maintenanceGroups || [],
);

export const makeGetMaintenanceGroupsSelector = () =>
  createSelector([getMaintenanceGroups], (groups) =>
    groups.map((group) => ({
      id: group.Id,
      name: group.Name,
      // for dropdown
      value: group.Id,
      label: group.Name,
    })),
  );

// --------------- Lookup Property -----------------

export const makeGetIsLookingPropertySelector = () =>
  createSelector(getState, (state) => state.isLookingProperty);

export const makeGetIsLookedPropertySelector = () =>
  createSelector(getState, (state) => state.isLookedProperty);

const getFoundProperty = createSelector(
  getState,
  (state) => state.foundProperty,
);
export const makeGetFoundPropertySelector = () =>
  createSelector(getFoundProperty, (property) => {
    if (!property) {
      return null;
    }
    const clean = omitBy(
      {
        id: property?.Id,
        name: property?.Name,
        zillowId: property?.zillowId,
        building: property?.Building__c,
        unit: property?.Unit__c,
        activeOwnerContractId: property?.Active_Owner_Contract__c,
        directAccountId: property?.Account__c,
        error: property?.error,
        warning: property?.warning,
      },
      isEmpty,
    );

    return {
      ...clean,
      index: property?.index || 0,
    };
  });

// --------------- Search Contacts -----------------
export const makeGetIsSearchingContactsSelector = () =>
  createSelector(getState, (state) => state.isSearchingContacts);

export const makeGetIsSearchedContactsSelector = () =>
  createSelector(getState, (state) => state.isSearchedContacts);

const convertContact = (contact) => ({
  id: contact.Id,
  firstName: contact.FirstName || '',
  middleName: contact.MiddleName || '',
  lastName: contact.LastName || '',
  title: contact.Title || '',
  phone: contact.Phone || '',
  email: contact.Email || '',
  mobilePhone: contact.MobilePhone || '',
  // mailing address
  streetAddress: contact.MailingStreet || '',
  city: contact.MailingCity || '',
  state: contact.MailingState || '', // state and region in one
  zip: contact.MailingPostalCode || '', // zip and postal code in one
  unit: contact.Mailing_Unit__c || '',
  country: contact.MailingCountry || 'US',
  // account details
  accountName: contact.Account?.Name || '',
  accountId: contact.Account?.Id || '',
  accountType: contact.Account?.RecordType?.Name || '',
  employee: convertUser(contact.Employee__r),
});

const getSearchContactsResults = createSelector(
  getState,
  (state) => state.searchContactsResults || [],
);

const getSearchContactsQuery = createSelector(
  getState,
  (state) => state.searchContactsQuery || {},
);

export const makeGetSearchContactsResultsSelector = () =>
  createSelector(
    [getSearchContactsResults, getSearchContactsQuery],
    (searchResults, searchQuery) => {
      const { firstName, lastName, email, phone, mobilePhone } = searchQuery;
      const convertedContacts = searchResults.map((contact) => {
        const converted = convertContact(contact);
        let priority = 0;
        if (
          firstName &&
          firstName.toLowerCase() === converted.firstName.toLowerCase()
        ) {
          priority += 1;
        }
        if (
          lastName &&
          lastName.toLowerCase() === converted.lastName.toLowerCase()
        ) {
          priority += 1;
        }
        if (email && email.toLowerCase() === converted.email.toLowerCase()) {
          priority += 1;
        }
        if (phone && phone.toLowerCase() === converted.phone.toLowerCase()) {
          priority += 1;
        }
        if (
          mobilePhone &&
          mobilePhone.toLowerCase() === converted.mobilePhone.toLowerCase()
        ) {
          priority += 1;
        }
        return {
          ...converted,
          priority,
        };
      });

      // sort elements by best match
      return orderBy(convertedContacts, ['priority'], ['desc']);
    },
  );

// -------------- Communication -------------------
export const getCommunicationData = createSelector(
  [getState],
  (state) => state.communicationData,
);

const getNewActivity = createSelector(getState, (state) => state.newActivity);

export const makeGetCommunicationDataSelector = () =>
  createSelector(
    [getCommunicationData, getCompanyInfo, getMarkets],
    (communicationData, companyInfo, markets) => {
      if (!communicationData) {
        return null;
      }
      return prepareCommunicationData({
        ...communicationData,
        companyInfo,
        markets: markets.map(convertMarket),
      });
    },
  );

export const makeGetNewActivitySelector = () =>
  createSelector([getNewActivity], (newActivity) => {
    if (!newActivity) {
      return null;
    }
    return newActivity;
  });

export const makeGetCompanyInfoSelector = () =>
  createSelector([getCompanyInfo], (companyInfo) => companyInfo);

export const makeGetLoadingCommunicationFlagsSelector = () =>
  createSelector(getState, (state) => ({
    // loading flags
    isCommunicationDataLoading: state.isCommunicationDataLoading,
    isSendingEmail: state.isSendingEmail,
    isSendingSms: state.isSendingSms,
    isUpdatingTask: state.isUpdatingTask,
    isCreatingTask: state.isCreatingTask,
    isSavingInspection: state.isSavingInspection,
    isSavingProperty: state.isSavingProperty,
    isSavingWorkOrder: state.isSavingWorkOrder,
    isSavingOpportunity: state.isSavingOpportunity,
    isSavingLead: state.isSavingLead,
    isSavingContract: state.isSavingContract,
    isSavingPropertyListing: state.isSavingPropertyListing,
    isSavingLocker: state.isSavingLocker,
    isUpdatingJob: state.isUpdatingJob,
    isUpdatingApplication: state.isUpdatingApplication,
    isLoading:
      state.isCommunicationDataLoading ||
      state.isSendingEmail ||
      state.isSendingSms ||
      state.isUpdatingTask ||
      state.isCreatingTask ||
      state.isSavingInspection ||
      state.isSavingProperty ||
      state.isSavingWorkOrder ||
      state.isSavingOpportunity ||
      state.isSavingLead ||
      state.isSavingContract ||
      state.isSavingPropertyListing ||
      state.isSavingLocker ||
      state.isUpdatingJob ||
      state.isUpdatingApplication,
    // successfully finished flags
    isCommunicationDataLoaded: state.isCommunicationDataLoaded,
    isUpdatedTask: state.isUpdatedTask,
    isCreatedTask: state.isCreatedTask,
    isSentEmail: state.isSentEmail,
    isSentSms: state.isSentSms,
    isSavedInspection: state.isSavedInspection,
    isSavedProperty: state.isSavedProperty,
    isSavedWorkOrder: state.isSavedWorkOrder,
    isSavedOpportunity: state.isSavedOpportunity,
    isSavedLead: state.isSavedLead,
    isSavedContract: state.isSavedContract,
    isSavedPropertyListing: state.isSavedPropertyListing,
    isSavedLocker: state.isSavedLocker,
    isUpdatedJob: state.isUpdatedJob,
    isUpdatedApplication: state.isUpdatedApplication,
    isNeedRefreshData:
      state.isUpdatedTask ||
      state.isCreatedTask ||
      state.isSentEmail ||
      state.isSentSms ||
      state.isSavedInspection ||
      state.isSavedProperty ||
      state.isSavedWorkOrder ||
      state.isSavedOpportunity ||
      state.isSavedLead ||
      state.isSavedContract ||
      state.isSavedPropertyListing ||
      state.isSavedLocker ||
      state.isUpdatedJob ||
      state.isUpdatedApplication,
  }));

// -------------- Property Details and Owner Settings -------------------
export const makeGetIsLoadingPropertyDetailsAndOwnerSettingsSelector = () =>
  createSelector(
    getState,
    (state) => state.isLoadingPropertyDetailsAndOwnerSettings,
  );
export const makeGetIsLoadedPropertyDetailsAndOwnerSettingsSelector = () =>
  createSelector(
    getState,
    (state) => state.isLoadedPropertyDetailsAndOwnerSettings,
  );

export const makeGetIsSavingContractSelector = () =>
  createSelector(getState, (state) => state.isSavingContract);

export const makeGetIsSavedContractSelector = () =>
  createSelector(getState, (state) => state.isSavedContract);

const getPropertyDetails = createSelector(
  getState,
  (state) => state.propertyDetailsAndOwnerSettings,
);

export const makeGetPropertyDetailsAndOwnerSettingsSelector = () =>
  createSelector([getPropertyDetails], (property) =>
    convertPropertyDetailsAndOwnerSettings(property),
  );

// -------------- File Manager -------------------
export const makeGetIsDeletingFilesSelector = () =>
  createSelector(getState, (state) => state.isDeletingFiles);
export const makeGetIsDeletedFilesSelector = () =>
  createSelector(getState, (state) => state.isDeletedFiles);

export const makeGetIsUpdatingFilesSelector = () =>
  createSelector(getState, (state) => state.isUpdatingFiles);
export const makeGetIsUpdatedFilesSelector = () =>
  createSelector(getState, (state) => state.isUpdatedFiles);

// -------------- WO Builder/Line Item Editor -------------------
export const makeGetIsLoadingAccountsByTypeSelector = () =>
  createSelector(getState, (state) => state.isLoadingAccountsByType);

export const makeGetIsLoadedAccountsByTypeSelector = () =>
  createSelector(getState, (state) => state.isLoadedAccountsByType);

export const getAccountsByType = createSelector(
  getState,
  (state) => state.accountsByType,
);

export const makeGetAccountsByTypeSelector = () =>
  createSelector([getAccountsByType], (accountsByType) => {
    if (!accountsByType || !Array.isArray(accountsByType)) {
      return null;
    }
    const mappedAccounts = accountsByType.map(convertAccount);
    return sortBy(mappedAccounts, ['name']);
  });

// -------------- Invoice -------------------
export const makeGetIsSavedInvoiceSelector = () =>
  createSelector(getState, (state) => state.isSavedInvoice);

export const makeGetIsSavingInvoiceSelector = () =>
  createSelector(getState, (state) => state.isSavingInvoice);

export const makeGetIsDeletingInvoiceSelector = () =>
  createSelector(getState, (state) => state.isDeletingInvoice);

export const makeGetIsDeletedInvoiceSelector = () =>
  createSelector(getState, (state) => state.isDeletedInvoice);

// -------------- Expense -------------------
export const makeGetIsDeletingExpenseSelector = () =>
  createSelector(getState, (state) => state.isDeletingExpense);

export const makeGetIsDeletedExpenseSelector = () =>
  createSelector(getState, (state) => state.isDeletedExpense);

// -------------- Company Cards -------------------
export const makeGetIsLoadingCompanyCardsSelector = () =>
  createSelector(getState, (state) => state.isLoadingCompanyCards);

export const makeGetIsLoadedCompanyCardsSelector = () =>
  createSelector(getState, (state) => state.isLoadedCompanyCards);

export const getCompanyCardsSelector = createSelector(
  getState,
  (state) => state.companyCards || null,
);
export const makeGetCompanyCardsSelector = () =>
  createSelector(getCompanyCardsSelector, (companyCards) => {
    if (!companyCards) {
      return null;
    }
    return companyCards.map(convertCompanyCard);
  });

// -------------- Expenses -------------------
export const makeGetIsSavingExpenseSelector = () =>
  createSelector(getState, (state) => state.isSavingExpense);

export const makeGetIsSavedExpenseSelector = () =>
  createSelector(getState, (state) => state.isSavedExpense);

// -------------- Email Templates -------------------
export const makeGetIsLoadingEmailTemplatesSelector = () =>
  createSelector(getState, (state) => state.isLoadingEmailTemplates);

export const getEmailTemplates = createSelector(
  getState,
  (state) => state.emailTemplates || null,
);

// -------------- Key Log -------------------
export const makeGetIsUpdatingPropertyKeyLogSelector = () =>
  createSelector(getState, (state) => state.isUpdatingPropertyKeyLog);

export const makeGetIsUpdatedPropertyKeyLogSelector = () =>
  createSelector(getState, (state) => state.isUpdatedPropertyKeyLog);

// -------------- Lockboxes -------------------
export const makeGetIsAssigningLockboxPropertySelector = () =>
  createSelector(getState, (state) => state.isAssigningLockboxProperty);

export const makeGetIsAssignedLockboxPropertySelector = () =>
  createSelector(getState, (state) => state.isAssignedLockboxProperty);

export const makeGetIsUnassigningLockboxPropertySelector = () =>
  createSelector(getState, (state) => state.isUnassigningLockboxProperty);

export const makeGetIsUnassignedLockboxPropertySelector = () =>
  createSelector(getState, (state) => state.isUnassignedLockboxProperty);

export const makeGetIsLoadingLockboxesSelector = () =>
  createSelector(getState, (state) => state.isLoadingLockboxes);

export const getAvailableLockboxes = createSelector(
  getState,
  (state) => state.availableLockboxes || null,
);

export const makeGetAvailableLockboxesSelector = () =>
  createSelector(getAvailableLockboxes, (lockboxes) => {
    if (!lockboxes) {
      return null;
    }
    // convert lockboxes
    const convertedLockboxes = (lockboxes || []).map((dataItem) => {
      const converted = convertLockbox(dataItem);
      return {
        ...converted,
        // for dropdown
        value: converted.id,
        label: converted.name,
      };
    });

    return sortBy(convertedLockboxes, ['label']);
  });

// -------------- Opportunity Welcome Email -------------------
export const makeGetIsSendingOpportunityWelcomeEmailSelector = () =>
  createSelector(getState, (state) => state.isSendingOpportunityWelcomeEmail);

export const makeGetIsSentOpportunityWelcomeEmailSelector = () =>
  createSelector(getState, (state) => state.isSentOpportunityWelcomeEmail);

// -------------- Markets -------------------
export const makeGetIsLoadingMarketsSelector = () =>
  createSelector(getState, (state) => state.isLoadingMarkets);

export const getMarkets = createSelector(
  getState,
  (state) => state.markets || [],
);

export const makeGetMarketsSelector = () =>
  createSelector([getMarkets], (markets) =>
    [{ value: '', label: '' }].concat(
      markets.map((market) => ({
        value: market.Id,
        label: market.Name,
        extraInfo: market.ExtraInfo,
      })),
    ),
  );

export const makeGetOpportunityMarketsSelector = () =>
  createSelector([getMarkets], (markets) =>
    markets.reduce((memo, r) => {
      memo[r.Name] = r.Id;
      return memo;
    }, {}),
  );

export const makeGetMarketsForDropdownSelector = () =>
  createSelector([getMarkets], (markets) =>
    [{ value: '', label: '' }].concat(
      markets.map((market) => ({
        value: market.Name,
        label: market.Name,
      })),
    ),
  );

export const makeGetMarketsWithGoogleLinkSelector = () =>
  createSelector([getMarkets], (markets) =>
    markets.map(({ Id, Name, ExtraInfo, Abbreviation__c }) => ({
      id: Id,
      name: Name,
      extraInfo: ExtraInfo,
      value: Id,
      label: Name,
      abbreviation: Abbreviation__c || '',
    })),
  );

export const makeGetRawMarketsSelector = () =>
  createSelector([getMarkets], (markets) => markets);

// -------------- ZPL Templates -------------------
export const getZplTemplates = createSelector(
  getState,
  (state) => state.zplTemplates || [],
);

export const makeGetZplTemplatesSelector = () =>
  createSelector([getZplTemplates], (zplTemplates) => zplTemplates);

export const makeGetIsLoadingZplTemplatesSelector = () =>
  createSelector([getState], (state) => state.isLoadingZplTemplates);

// -------------- DocuSign Status -------------------
export const makeGetIsDeletingDocusignEnvelopeSelector = () =>
  createSelector(getState, (state) => state.isDeletingDocusignEnvelope);

export const makeGetIsDeletedDocusignEnvelopeSelector = () =>
  createSelector(getState, (state) => state.isDeletedDocusignEnvelope);

// -------------- Contract Addendum -------------------
export const makeGetIsUpdatingContractAddendumSelector = () =>
  createSelector(getState, (state) => state.isUpdatingContractAddendum);

export const makeGetIsUpdatedContractAddendumSelector = () =>
  createSelector(getState, (state) => state.isUpdatedContractAddendum);

// --------------------------------------------------
export const makeGetIsLoadingUserSelector = () =>
  createSelector(getState, (state) => state.isLoadingUser);

export const makeGetIsLoadedUserSelector = () =>
  createSelector(getState, (state) => state.isLoadedUser);

// ---------------------------------------------------------
export const makeGetIsCreatingInvoiceSelector = () =>
  createSelector(getState, (state) => state.isCreatingInvoice);

export const makeGetIsCreatedInvoiceSelector = () =>
  createSelector(getState, (state) => state.isCreatedInvoice);

export const makeGetCreatedInvoiceSelector = () =>
  createSelector(getState, (state) => state.createdInvoice);

// ---------------------------------------------------------
export const makeGetIsSavingContactSelector = () =>
  createSelector(getState, (state) => state.isSavingContact);

export const makeGetIsSavedContactSelector = () =>
  createSelector(getState, (state) => state.isSavedContact);

// ---------------------------------------------------------
export const makeGetCreatedContactSelector = () =>
  createSelector(getState, (state) => state.createdContact);

export const makeGetIsCreatingContactSelector = () =>
  createSelector(getState, (state) => state.isCreatingContact);

export const makeGetIsCreatedContactSelector = () =>
  createSelector(getState, (state) => state.isCreatedContact);

// ---------------------------------------------------------
export const makeGetIsDeletingContactSelector = () =>
  createSelector(getState, (state) => state.isDeletingContact);

export const makeGetIsDeletedContactSelector = () =>
  createSelector(getState, (state) => state.isDeletedContact);
