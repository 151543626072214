import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import {
  loadCompanyInfo,
  clearMessages,
  loadMarkets,
} from 'containers/App/actions';
import {
  makeGetCompanyFooterInfoSelector,
  makeGetCompanyHeaderInfoSelector,
} from 'containers/App/selectors';
import { searchRecords, clearSearchRecords } from './actions';

import {
  makeGetErrorSelector,
  makeGetIsSearchingSelector,
  makeGetFoundPropertiesSelector,
  makeGetIsFailedFetchSelector,
} from './selectors';

import reducer from './reducer';
import saga from './saga';
import PageWrapper from './PageWrapper';

const mapDispatchToProps = (dispatch) => ({
  wrapperClearSearchRecords: () => dispatch(clearSearchRecords()),
  wrapperSearchRecords: (data) => dispatch(searchRecords(data)),
  loadCompanyInfo: (companyId) => dispatch(loadCompanyInfo(companyId)),
  clearMessages: () => dispatch(clearMessages()),
  loadMarkets: (data) => dispatch(loadMarkets(data)),
});

const mapStateToProps = createStructuredSelector({
  wrapperError: makeGetErrorSelector(),
  wrapperFoundProperties: makeGetFoundPropertiesSelector(),
  wrapperIsSearching: makeGetIsSearchingSelector(),
  isWrapperFailedFetch: makeGetIsFailedFetchSelector(),
  companyHeaderInfo: makeGetCompanyHeaderInfoSelector(),
  companyFooterInfo: makeGetCompanyFooterInfoSelector(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'pageWrapper',
  reducer,
});
const withSaga = injectSaga({ key: 'pageWrapper', saga });

export default compose(withReducer, withSaga, withConnect)(PageWrapper);
export { mapDispatchToProps };
