import {
  CLEAR_DATA,
  CLEAR_MESSAGES,
  LOAD_COMPANY_INFO,
  LOAD_COMPANY_INFO_SUCCESS,
  LOAD_COMPANY_INFO_ERROR,
  LOAD_COMPANIES,
  LOAD_COMPANIES_SUCCESS,
  LOAD_COMPANIES_ERROR,
  SUBMIT_COMMENT,
  SUBMIT_COMMENT_SUCCESS,
  SUBMIT_COMMENT_ERROR,
  LOAD_USERS,
  LOAD_USERS_SUCCESS,
  LOAD_USERS_ERROR,
  LOAD_TECHNICIANS,
  LOAD_TECHNICIANS_SUCCESS,
  LOAD_TECHNICIANS_ERROR,
  UPDATE_TASK,
  UPDATE_TASK_ERROR,
  UPDATE_TASK_SUCCESS,
  CREATE_TASK,
  CREATE_TASK_SUCCESS,
  CREATE_TASK_ERROR,
  SEND_EMAIL,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_ERROR,
  LOG_NEW_ACTIVITY,
  CANCEL_NEW_ACTIVITY,
  LOAD_SOBJECT,
  LOAD_SOBJECT_SUCCESS,
  LOAD_SOBJECT_ERROR,
  LOAD_PICK_LISTS,
  LOAD_PICK_LISTS_ERROR,
  LOAD_PICK_LISTS_SUCCESS,
  LOAD_RECORD_TYPES,
  LOAD_RECORD_TYPES_ERROR,
  LOAD_RECORD_TYPES_SUCCESS,
  SEARCH_RECORDS,
  SEARCH_RECORDS_SUCCESS,
  SEARCH_RECORDS_ERROR,
  CLEAR_SEARCH_RECORDS,
  DELETE_SOBJECT_FILE,
  DELETE_SOBJECT_FILE_SUCCESS,
  DELETE_SOBJECT_FILE_ERROR,
  RENAME_SOBJECT_FILE,
  RENAME_SOBJECT_FILE_SUCCESS,
  RENAME_SOBJECT_FILE_ERROR,
  CLEAR_SOBJET,
  LOAD_JOBS,
  LOAD_JOBS_SUCCESS,
  LOAD_JOBS_ERROR,
  CLEAR_JOBS,
  SCHEDULE_APPOINTMENT,
  SCHEDULE_APPOINTMENT_SUCCESS,
  SCHEDULE_APPOINTMENT_ERROR,
  UPDATE_APPOINTMENT,
  UPDATE_APPOINTMENT_SUCCESS,
  UPDATE_APPOINTMENT_ERROR,
  UPDATE_APPOINTMENT_STATUS,
  UPDATE_APPOINTMENT_STATUS_SUCCESS,
  UPDATE_APPOINTMENT_STATUS_ERROR,
  LOAD_EVENTS,
  LOAD_EVENTS_SUCCESS,
  LOAD_EVENTS_ERROR,
  CLEAR_DATES,
  CLEAR_FLAGS,
  LOAD_SMS_MESSAGES,
  LOAD_SMS_MESSAGES_SUCCESS,
  LOAD_SMS_MESSAGES_ERROR,
  SEND_SMS,
  SEND_SMS_SUCCESS,
  SEND_SMS_ERROR,
  LOAD_SMS_TEMPLATES,
  LOAD_SMS_TEMPLATES_SUCCESS,
  LOAD_SMS_TEMPLATES_ERROR,
  UPDATE_SMS_MESSAGE,
  UPDATE_SMS_MESSAGE_SUCCESS,
  UPDATE_SMS_MESSAGE_ERROR,
  UPDATE_JOB,
  UPDATE_JOB_SUCCESS,
  UPDATE_JOB_ERROR,
  LOAD_WORK_ORDER,
  LOAD_WORK_ORDER_SUCCESS,
  LOAD_WORK_ORDER_ERROR,
  LOAD_MAINTENANCE_GROUPS,
  LOAD_MAINTENANCE_GROUPS_SUCCESS,
  LOAD_MAINTENANCE_GROUPS_ERROR,
  UPDATE_INSPECTION_STATUS,
  UPDATE_INSPECTION_STATUS_SUCCESS,
  UPDATE_INSPECTION_STATUS_ERROR,
  UPDATE_PROPERTY_ROUTINE_INSPECTIONS,
  UPDATE_PROPERTY_ROUTINE_INSPECTIONS_SUCCESS,
  UPDATE_PROPERTY_ROUTINE_INSPECTIONS_ERROR,
  CLEAR_COMMUNICATION_DATA,
  LOAD_COMMUNICATION_DATA,
  LOAD_COMMUNICATION_DATA_SUCCESS,
  LOAD_COMMUNICATION_DATA_ERROR,
  SAVE_INSPECTION,
  SAVE_INSPECTION_SUCCESS,
  SAVE_INSPECTION_ERROR,
  SAVE_PROPERTY,
  SAVE_PROPERTY_SUCCESS,
  SAVE_PROPERTY_ERROR,
  SAVE_PROPERTY_OWNER_SETTINGS,
  SAVE_PROPERTY_OWNER_SETTINGS_SUCCESS,
  SAVE_PROPERTY_OWNER_SETTINGS_ERROR,
  SAVE_WORK_ORDER,
  SAVE_WORK_ORDER_SUCCESS,
  SAVE_WORK_ORDER_ERROR,
  SAVE_OPPORTUNITY,
  SAVE_OPPORTUNITY_SUCCESS,
  SAVE_OPPORTUNITY_ERROR,
  SAVE_LEAD,
  SAVE_LEAD_SUCCESS,
  SAVE_LEAD_ERROR,
  DELETE_LEAD,
  DELETE_LEAD_SUCCESS,
  DELETE_LEAD_ERROR,
  SAVE_CONTRACT,
  SAVE_CONTRACT_SUCCESS,
  SAVE_CONTRACT_ERROR,
  SAVE_PROPERTY_LISTING,
  SAVE_PROPERTY_LISTING_SUCCESS,
  SAVE_PROPERTY_LISTING_ERROR,
  SAVE_LOCKER,
  SAVE_LOCKER_SUCCESS,
  SAVE_LOCKER_ERROR,
  LOOKUP_PROPERTY,
  LOOKUP_PROPERTY_SUCCESS,
  LOOKUP_PROPERTY_ERROR,
  SEARCH_CONTACTS,
  SEARCH_CONTACTS_SUCCESS,
  SEARCH_CONTACTS_ERROR,
  LOAD_INSPECTION,
  LOAD_INSPECTION_SUCCESS,
  LOAD_INSPECTION_ERROR,
  CREATE_LINE_ITEM,
  CREATE_LINE_ITEM_SUCCESS,
  CREATE_LINE_ITEM_ERROR,
  UPDATE_LINE_ITEM,
  UPDATE_LINE_ITEM_SUCCESS,
  UPDATE_LINE_ITEM_ERROR,
  DELETE_LINE_ITEM,
  DELETE_LINE_ITEM_SUCCESS,
  DELETE_LINE_ITEM_ERROR,
  LOAD_PROPERTY,
  LOAD_PROPERTY_SUCCESS,
  LOAD_PROPERTY_ERROR,
  RUN_CRONTAB_JOB,
  RUN_CRONTAB_JOB_SUCCESS,
  RUN_CRONTAB_JOB_ERROR,
  RESERVE_NEXT_AVAILABLE_LOCKER,
  RESERVE_NEXT_AVAILABLE_LOCKER_SUCCESS,
  RESERVE_NEXT_AVAILABLE_LOCKER_ERROR,
  ENRICH_PROPERTY,
  ENRICH_PROPERTY_SUCCESS,
  ENRICH_PROPERTY_ERROR,
  LOAD_PROPERTY_DETAILS_AND_OWNER_SETTINGS,
  LOAD_PROPERTY_DETAILS_AND_OWNER_SETTINGS_SUCCESS,
  LOAD_PROPERTY_DETAILS_AND_OWNER_SETTINGS_ERROR,
  DELETE_FILES,
  DELETE_FILES_SUCCESS,
  DELETE_FILES_ERROR,
  UPDATE_FILES,
  UPDATE_FILES_SUCCESS,
  UPDATE_FILES_ERROR,
  LOAD_ACCOUNTS_BY_TYPE,
  LOAD_ACCOUNTS_BY_TYPE_SUCCESS,
  LOAD_ACCOUNTS_BY_TYPE_ERROR,
  SAVE_INVOICE,
  SAVE_INVOICE_ERROR,
  SAVE_INVOICE_SUCCESS,
  CLEAR_LINE_ITEM_CACHE,
  CREATE_PROPERTY_LISTING,
  CREATE_PROPERTY_LISTING_SUCCESS,
  CREATE_PROPERTY_LISTING_ERROR,
  DELETE_INVOICE,
  DELETE_INVOICE_SUCCESS,
  DELETE_INVOICE_ERROR,
  DELETE_EXPENSE,
  DELETE_EXPENSE_SUCCESS,
  DELETE_EXPENSE_ERROR,
  LOAD_COMPANY_CARDS,
  LOAD_COMPANY_CARDS_SUCCESS,
  LOAD_COMPANY_CARDS_ERROR,
  SAVE_EXPENSE,
  SAVE_EXPENSE_SUCCESS,
  SAVE_EXPENSE_ERROR,
  LOAD_EMAIL_TEMPLATES,
  LOAD_EMAIL_TEMPLATES_ERROR,
  LOAD_EMAIL_TEMPLATES_SUCCESS,
  UPDATE_PROPERTY_KEY_LOG,
  UPDATE_PROPERTY_KEY_LOG_SUCCESS,
  UPDATE_PROPERTY_KEY_LOG_ERROR,
  DELETE_JOB,
  DELETE_JOB_SUCCESS,
  DELETE_JOB_ERROR,
  LOAD_LOCKBOXES,
  LOAD_LOCKBOXES_SUCCESS,
  LOAD_LOCKBOXES_ERROR,
  // ----
  ASSIGN_LOCKBOX_PROPERTY,
  ASSIGN_LOCKBOX_PROPERTY_SUCCESS,
  ASSIGN_LOCKBOX_PROPERTY_ERROR,
  // ----
  UNASSIGN_LOCKBOX_PROPERTY,
  UNASSIGN_LOCKBOX_PROPERTY_SUCCESS,
  UNASSIGN_LOCKBOX_PROPERTY_ERROR,
  // ----
  SEND_OPPORTUNITY_WELCOME_EMAIL,
  SEND_OPPORTUNITY_WELCOME_EMAIL_SUCCESS,
  SEND_OPPORTUNITY_WELCOME_EMAIL_ERROR,
  // ----
  LOAD_MARKETS,
  LOAD_MARKETS_SUCCESS,
  LOAD_MARKETS_ERROR,
  // ----
  LOAD_ZPL_TEMPLATES,
  LOAD_ZPL_TEMPLATES_SUCCESS,
  LOAD_ZPL_TEMPLATES_ERROR,
  // ----
  DELETE_DOCUSIGN_ENVELOPE,
  DELETE_DOCUSIGN_ENVELOPE_SUCCESS,
  DELETE_DOCUSIGN_ENVELOPE_ERROR,
  // ----
  UPDATE_CONTRACT_ADDENDUM,
  UPDATE_CONTRACT_ADDENDUM_SUCCESS,
  UPDATE_CONTRACT_ADDENDUM_ERROR,
  LOAD_USER,
  LOAD_USER_SUCCESS,
  LOAD_USER_ERROR,
  CREATE_INVOICE,
  CREATE_INVOICE_SUCCESS,
  CREATE_INVOICE_ERROR,
  UPDATE_APPLICATION,
  UPDATE_APPLICATION_SUCCESS,
  UPDATE_APPLICATION_ERROR,
  SAVE_CONTACT,
  SAVE_CONTACT_SUCCESS,
  SAVE_CONTACT_ERROR,
  CREATE_CONTACT,
  CREATE_CONTACT_SUCCESS,
  CREATE_CONTACT_ERROR,
  DELETE_CONTACT,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_ERROR,
  UPDATE_VENDOR,
  UPDATE_VENDOR_SUCCESS,
  UPDATE_VENDOR_ERROR,
} from './constants';

// ------------------------------------------
export function clearData() {
  return {
    type: CLEAR_DATA,
  };
}

// ----------------------------------------
export function clearMessages() {
  return {
    type: CLEAR_MESSAGES,
  };
}

// ----------------------------------------
export function clearFlags() {
  return {
    type: CLEAR_FLAGS,
  };
}

// ----------------------------------------
export function clearLineItemCache(lineItemId) {
  return {
    type: CLEAR_LINE_ITEM_CACHE,
    lineItemId,
  };
}

// ----------------------------------------
export function loadCompanyInfo(companyId) {
  return {
    type: LOAD_COMPANY_INFO,
    companyId,
  };
}

export function loadCompanyInfoSuccess(companyInfo) {
  return {
    type: LOAD_COMPANY_INFO_SUCCESS,
    companyInfo,
  };
}

export function loadCompanyInfoError(error) {
  return {
    type: LOAD_COMPANY_INFO_ERROR,
    error,
  };
}

// ----------------------------------------
export function loadCompanies() {
  return {
    type: LOAD_COMPANIES,
  };
}

export function loadCompaniesSuccess(companies) {
  return {
    type: LOAD_COMPANIES_SUCCESS,
    companies,
  };
}

export function loadCompaniesError(error) {
  return {
    type: LOAD_COMPANIES_ERROR,
    error,
  };
}

// ----------------------------------------
export function submitComment(data) {
  return {
    type: SUBMIT_COMMENT,
    ...data,
  };
}

export function submitCommentSuccess(commentObject) {
  return {
    type: SUBMIT_COMMENT_SUCCESS,
    commentObject,
  };
}

export function submitCommentError(error) {
  return {
    type: SUBMIT_COMMENT_ERROR,
    error,
  };
}

// ----------------------------------------
export function loadUsers(data) {
  return {
    type: LOAD_USERS,
    ...data,
  };
}

export function loadUsersSuccess(users) {
  return {
    type: LOAD_USERS_SUCCESS,
    users,
  };
}

export function loadUsersError(error) {
  return {
    type: LOAD_USERS_ERROR,
    error,
  };
}

// ----------------------------------------
export function loadTechnicians(data) {
  return {
    type: LOAD_TECHNICIANS,
    ...data,
  };
}

export function loadTechniciansSuccess(technicians) {
  return {
    type: LOAD_TECHNICIANS_SUCCESS,
    technicians,
  };
}

export function loadTechniciansError(error) {
  return {
    type: LOAD_TECHNICIANS_ERROR,
    error,
  };
}

// ----------------------------------------
export function updateTask(data) {
  return {
    type: UPDATE_TASK,
    ...data,
  };
}

export function updateTaskSuccess(task) {
  return {
    type: UPDATE_TASK_SUCCESS,
    task,
  };
}

export function updateTaskError(error) {
  return {
    type: UPDATE_TASK_ERROR,
    error,
  };
}

// ----------------------------------------
export function createTask(data) {
  return {
    type: CREATE_TASK,
    ...data,
  };
}

export function createTaskSuccess(task) {
  return {
    type: CREATE_TASK_SUCCESS,
    task,
  };
}

export function createTaskError(error) {
  return {
    type: CREATE_TASK_ERROR,
    error,
  };
}

// ----------------------------------------
export function sendEmail(data) {
  return {
    type: SEND_EMAIL,
    ...data,
  };
}

export function sendEmailSuccess(result) {
  return {
    type: SEND_EMAIL_SUCCESS,
    result,
  };
}

export function sendEmailError(error) {
  return {
    type: SEND_EMAIL_ERROR,
    error,
  };
}

// ----------------------------------------
export function logNewActivity(activity) {
  return {
    type: LOG_NEW_ACTIVITY,
    activity,
  };
}

export function cancelNewActivity() {
  return {
    type: CANCEL_NEW_ACTIVITY,
  };
}

// ----------------------------------------
export function loadSobject(id) {
  return {
    type: LOAD_SOBJECT,
    id,
  };
}

export function loadSobjectSuccess(sobject) {
  return {
    type: LOAD_SOBJECT_SUCCESS,
    sobject,
  };
}

export function loadSobjectError(error) {
  return {
    type: LOAD_SOBJECT_ERROR,
    error,
  };
}

// ----------------------------------------
export function loadPickLists(sobject) {
  return {
    type: LOAD_PICK_LISTS,
    sobject,
  };
}

export function loadPickListsSuccess(pickLists) {
  return {
    type: LOAD_PICK_LISTS_SUCCESS,
    pickLists,
  };
}

export function loadPickListsError(error) {
  return {
    type: LOAD_PICK_LISTS_ERROR,
    error,
  };
}

// ----------------------------------------
export function loadRecordTypes(sobject) {
  return {
    type: LOAD_RECORD_TYPES,
    sobject,
  };
}

export function loadRecordTypesSuccess(recordTypes) {
  return {
    type: LOAD_RECORD_TYPES_SUCCESS,
    recordTypes,
  };
}

export function loadRecordTypesError(error) {
  return {
    type: LOAD_RECORD_TYPES_ERROR,
    error,
  };
}

// ----------------------------------------
export function searchRecords(data) {
  return {
    type: SEARCH_RECORDS,
    ...data,
  };
}

export function searchRecordsSuccess(searchResults) {
  return {
    type: SEARCH_RECORDS_SUCCESS,
    searchResults,
  };
}

export function searchRecordsError(error) {
  return {
    type: SEARCH_RECORDS_ERROR,
    error,
  };
}

export function clearSearchRecords() {
  return {
    type: CLEAR_SEARCH_RECORDS,
  };
}

// ----------------------------------------
export function deleteSobjectFile({ id, fileName }) {
  return {
    type: DELETE_SOBJECT_FILE,
    id,
    fileName,
  };
}

export function deleteSobjectFileSuccess(result) {
  return {
    type: DELETE_SOBJECT_FILE_SUCCESS,
    result,
  };
}

export function deleteSobjectFileError(error) {
  return {
    type: DELETE_SOBJECT_FILE_ERROR,
    error,
  };
}

// ----------------------------------------
export function renameSobjectFile({ id, oldFileName, newFileName }) {
  return {
    type: RENAME_SOBJECT_FILE,
    id,
    oldFileName,
    newFileName,
  };
}

export function renameSobjectFileSuccess(result) {
  return {
    type: RENAME_SOBJECT_FILE_SUCCESS,
    result,
  };
}

export function renameSobjectFileError(error) {
  return {
    type: RENAME_SOBJECT_FILE_ERROR,
    error,
  };
}

// ----------------------------------------
export function clearSobject() {
  return {
    type: CLEAR_SOBJET,
  };
}

// ----------------------------------------
export function clearJobs() {
  return {
    type: CLEAR_JOBS,
  };
}

// ----------------------------------------
export function clearDates() {
  return {
    type: CLEAR_DATES,
  };
}

// --------------------------------------
export function loadJobs(data) {
  return {
    type: LOAD_JOBS,
    ...data,
  };
}

export function loadJobsSuccess(jobs) {
  return {
    type: LOAD_JOBS_SUCCESS,
    jobs,
  };
}

export function loadJobsError(error) {
  return {
    type: LOAD_JOBS_ERROR,
    error,
  };
}

// ------------------------------------------
export function scheduleAppointment(data) {
  return {
    type: SCHEDULE_APPOINTMENT,
    ...data,
  };
}

export function scheduleAppointmentSuccess(appointment) {
  return {
    type: SCHEDULE_APPOINTMENT_SUCCESS,
    appointment,
  };
}

export function scheduleAppointmentError(error) {
  return {
    type: SCHEDULE_APPOINTMENT_ERROR,
    error,
  };
}

// ------------------------------------------
export function updateAppointment(data) {
  return {
    type: UPDATE_APPOINTMENT,
    ...data,
  };
}

export function updateAppointmentSuccess(appointment) {
  return {
    type: UPDATE_APPOINTMENT_SUCCESS,
    appointment,
  };
}

export function updateAppointmentError(error) {
  return {
    type: UPDATE_APPOINTMENT_ERROR,
    error,
  };
}

// ------------------------------------------
export function updateAppointmentStatus(data) {
  return {
    type: UPDATE_APPOINTMENT_STATUS,
    ...data,
  };
}

export function updateAppointmentStatusSuccess(appointment) {
  return {
    type: UPDATE_APPOINTMENT_STATUS_SUCCESS,
    appointment,
  };
}

export function updateAppointmentStatusError(error) {
  return {
    type: UPDATE_APPOINTMENT_STATUS_ERROR,
    error,
  };
}

// ----------------------------------------
export function loadEvents(targetId) {
  return {
    type: LOAD_EVENTS,
    targetId,
  };
}

export function loadEventsSuccess(events) {
  return {
    type: LOAD_EVENTS_SUCCESS,
    events,
  };
}

export function loadEventsError(error) {
  return {
    type: LOAD_EVENTS_ERROR,
    error,
  };
}

// --------------------------------------
export function loadSmsMessages(data) {
  return {
    type: LOAD_SMS_MESSAGES,
    ...data,
  };
}

export function loadSmsMessagesSuccess(messages) {
  return {
    type: LOAD_SMS_MESSAGES_SUCCESS,
    messages,
  };
}

export function loadSmsMessagesError(error) {
  return {
    type: LOAD_SMS_MESSAGES_ERROR,
    error,
  };
}

// --------------------------------------
export function sendSms(data) {
  return {
    type: SEND_SMS,
    ...data,
  };
}

export function sendSmsSuccess(message) {
  return {
    type: SEND_SMS_SUCCESS,
    message,
  };
}

export function sendSmsError(error) {
  return {
    type: SEND_SMS_ERROR,
    error,
  };
}

// --------------------------------------
export function loadSmsTemplates(data) {
  return {
    type: LOAD_SMS_TEMPLATES,
    ...data,
  };
}

export function loadSmsTemplatesSuccess(templates) {
  return {
    type: LOAD_SMS_TEMPLATES_SUCCESS,
    templates,
  };
}

export function loadSmsTemplatesError(error) {
  return {
    type: LOAD_SMS_TEMPLATES_ERROR,
    error,
  };
}

// --------------------------------------
export function updateSmsMessage(data) {
  return {
    type: UPDATE_SMS_MESSAGE,
    ...data,
  };
}

export function updateSmsMessageSuccess(message) {
  return {
    type: UPDATE_SMS_MESSAGE_SUCCESS,
    message,
  };
}

export function updateSmsMessageError(error) {
  return {
    type: UPDATE_SMS_MESSAGE_ERROR,
    error,
  };
}

// --------------------------------------
export function updateJob(data) {
  return {
    type: UPDATE_JOB,
    ...data,
  };
}

export function updateJobSuccess(job) {
  return {
    type: UPDATE_JOB_SUCCESS,
    job,
  };
}

export function updateJobError(error) {
  return {
    type: UPDATE_JOB_ERROR,
    error,
  };
}

// --------------------------------------
export function loadWorkOrder(data) {
  return {
    type: LOAD_WORK_ORDER,
    ...data,
  };
}

export function loadWorkOrderSuccess(workOrder) {
  return {
    type: LOAD_WORK_ORDER_SUCCESS,
    workOrder,
  };
}

export function loadWorkOrderError(error) {
  return {
    type: LOAD_WORK_ORDER_ERROR,
    error,
  };
}

// --------------------------------------
export function loadMaintenanceGroups(accountId) {
  return {
    type: LOAD_MAINTENANCE_GROUPS,
    accountId,
  };
}

export function loadMaintenanceGroupsSuccess(groups) {
  return {
    type: LOAD_MAINTENANCE_GROUPS_SUCCESS,
    groups,
  };
}

export function loadMaintenanceGroupsError(error) {
  return {
    type: LOAD_MAINTENANCE_GROUPS_ERROR,
    error,
  };
}

// --------------------------------------------------------------
export function updateInspectionStatus(data) {
  return {
    type: UPDATE_INSPECTION_STATUS,
    ...data,
  };
}

export function updateInspectionStatusSuccess(result) {
  return {
    type: UPDATE_INSPECTION_STATUS_SUCCESS,
    result,
  };
}

export function updateInspectionStatusError(error) {
  return {
    type: UPDATE_INSPECTION_STATUS_ERROR,
    error,
  };
}

// ------------------------------------------
export function updatePropertyRoutineInspections(data) {
  return {
    type: UPDATE_PROPERTY_ROUTINE_INSPECTIONS,
    ...data,
  };
}

export function updatePropertyRoutineInspectionsSuccess(result) {
  return {
    type: UPDATE_PROPERTY_ROUTINE_INSPECTIONS_SUCCESS,
    result,
  };
}

export function updatePropertyRoutineInspectionsError(error) {
  return {
    type: UPDATE_PROPERTY_ROUTINE_INSPECTIONS_ERROR,
    error,
  };
}

// ------------------------------------------
export function clearCommunicationData() {
  return {
    type: CLEAR_COMMUNICATION_DATA,
  };
}

// ------------------------------------------
export function loadCommunicationData(entityId) {
  return {
    type: LOAD_COMMUNICATION_DATA,
    entityId,
  };
}

export function loadCommunicationDataSuccess(communicationData) {
  return {
    type: LOAD_COMMUNICATION_DATA_SUCCESS,
    communicationData,
  };
}

export function loadCommunicationDataError(error) {
  return {
    type: LOAD_COMMUNICATION_DATA_ERROR,
    error,
  };
}

// ------------------------------------------
export function saveInspection(data) {
  return {
    type: SAVE_INSPECTION,
    ...data,
  };
}

export function saveInspectionSuccess(result) {
  return {
    type: SAVE_INSPECTION_SUCCESS,
    result,
  };
}

export function saveInspectionError(error) {
  return {
    type: SAVE_INSPECTION_ERROR,
    error,
  };
}

// ------------------------------------------
export function saveProperty(data) {
  return {
    type: SAVE_PROPERTY,
    ...data,
  };
}

export function savePropertySuccess(result) {
  return {
    type: SAVE_PROPERTY_SUCCESS,
    result,
  };
}

export function savePropertyError(error) {
  return {
    type: SAVE_PROPERTY_ERROR,
    error,
  };
}

// ----------------------------------------
export function savePropertyOwnerSettings(data) {
  return {
    type: SAVE_PROPERTY_OWNER_SETTINGS,
    ...data,
  };
}

export function savePropertyOwnerSettingsSuccess(result) {
  return {
    type: SAVE_PROPERTY_OWNER_SETTINGS_SUCCESS,
    result,
  };
}

export function savePropertyOwnerSettingsError(error) {
  return {
    type: SAVE_PROPERTY_OWNER_SETTINGS_ERROR,
    error,
  };
}

// ------------------------------------------
export function saveWorkOrder(data) {
  return {
    type: SAVE_WORK_ORDER,
    ...data,
  };
}

export function saveWorkOrderSuccess(result) {
  return {
    type: SAVE_WORK_ORDER_SUCCESS,
    result,
  };
}

export function saveWorkOrderError(error) {
  return {
    type: SAVE_WORK_ORDER_ERROR,
    error,
  };
}

// -----------------------------------------------------
export function saveOpportunity(data) {
  return {
    type: SAVE_OPPORTUNITY,
    ...data,
  };
}

export function saveOpportunitySuccess(result) {
  return {
    type: SAVE_OPPORTUNITY_SUCCESS,
    result,
  };
}

export function saveOpportunityError(error) {
  return {
    type: SAVE_OPPORTUNITY_ERROR,
    error,
  };
}

// -----------------------------------------------------
export function saveLead(data) {
  return {
    type: SAVE_LEAD,
    ...data,
  };
}

export function saveLeadSuccess(result) {
  return {
    type: SAVE_LEAD_SUCCESS,
    ...result,
  };
}
export function saveLeadError(error) {
  return {
    type: SAVE_LEAD_ERROR,
    error,
  };
}
// -----------------------------------------------------
export function deleteLead(data) {
  return {
    type: DELETE_LEAD,
    ...data,
  };
}

export function deleteLeadSuccess(result) {
  return {
    type: DELETE_LEAD_SUCCESS,
    ...result,
  };
}
export function deleteLeadError(error) {
  return {
    type: DELETE_LEAD_ERROR,
    error,
  };
}

// -----------------------------------------------------
export function saveContract(data) {
  return {
    type: SAVE_CONTRACT,
    ...data,
  };
}

export function saveContractSuccess(result) {
  return {
    type: SAVE_CONTRACT_SUCCESS,
    result,
  };
}

export function saveContractError(error) {
  return {
    type: SAVE_CONTRACT_ERROR,
    error,
  };
}

// -----------------------------------------------------
export function savePropertyListing(data) {
  return {
    type: SAVE_PROPERTY_LISTING,
    ...data,
  };
}

export function savePropertyListingSuccess(result) {
  return {
    type: SAVE_PROPERTY_LISTING_SUCCESS,
    result,
  };
}

export function savePropertyListingError(error) {
  return {
    type: SAVE_PROPERTY_LISTING_ERROR,
    error,
  };
}

// -----------------------------------------------------
export function saveLocker(data) {
  return {
    type: SAVE_LOCKER,
    ...data,
  };
}

export function saveLockerSuccess(result) {
  return {
    type: SAVE_LOCKER_SUCCESS,
    result,
  };
}

export function saveLockerError(error) {
  return {
    type: SAVE_LOCKER_ERROR,
    error,
  };
}

// -----------------------------------------------------
export function lookupProperty(data) {
  return {
    type: LOOKUP_PROPERTY,
    ...data,
  };
}

export function lookupPropertySuccess(property) {
  return {
    type: LOOKUP_PROPERTY_SUCCESS,
    property,
  };
}

export function lookupPropertyError(error) {
  return {
    type: LOOKUP_PROPERTY_ERROR,
    error,
  };
}

// -----------------------------------------------------
export function searchContacts(data) {
  return {
    type: SEARCH_CONTACTS,
    ...data,
  };
}

export function searchContactsSuccess(contacts) {
  return {
    type: SEARCH_CONTACTS_SUCCESS,
    contacts,
  };
}

export function searchContactsError(error) {
  return {
    type: SEARCH_CONTACTS_ERROR,
    error,
  };
}

// -----------------------------------------------------
export function loadInspection(data) {
  return {
    type: LOAD_INSPECTION,
    ...data,
  };
}

export function loadInspectionSuccess(inspection) {
  return {
    type: LOAD_INSPECTION_SUCCESS,
    inspection,
  };
}

export function loadInspectionError(error) {
  return {
    type: LOAD_INSPECTION_ERROR,
    error,
  };
}

// ------------------------------------------
export function createLineItem(data) {
  return {
    type: CREATE_LINE_ITEM,
    ...data,
  };
}

export function createLineItemSuccess(lineItem) {
  return {
    type: CREATE_LINE_ITEM_SUCCESS,
    lineItem,
  };
}

export function createLineItemError(error) {
  return {
    type: CREATE_LINE_ITEM_ERROR,
    error,
  };
}

// ------------------------------------------
export function updateLineItem(data) {
  return {
    type: UPDATE_LINE_ITEM,
    ...data,
  };
}

export function updateLineItemSuccess(lineItem) {
  return {
    type: UPDATE_LINE_ITEM_SUCCESS,
    lineItem,
  };
}

export function updateLineItemError(error) {
  return {
    type: UPDATE_LINE_ITEM_ERROR,
    error,
  };
}

// -----------------------------------------------------
export function deleteLineItem(data) {
  return {
    type: DELETE_LINE_ITEM,
    ...data,
  };
}

export function deleteLineItemSuccess(lineItem) {
  return {
    type: DELETE_LINE_ITEM_SUCCESS,
    lineItem,
  };
}

export function deleteLineItemError(error) {
  return {
    type: DELETE_LINE_ITEM_ERROR,
    error,
  };
}

// ------------------------------------------
export function loadProperty(propertyId) {
  return {
    type: LOAD_PROPERTY,
    propertyId,
  };
}

export function loadPropertySuccess(property) {
  return {
    type: LOAD_PROPERTY_SUCCESS,
    property,
  };
}

export function loadPropertyError(error) {
  return {
    type: LOAD_PROPERTY_ERROR,
    error,
  };
}

// ------------------------------------------
export function runCrontabJob(name) {
  return {
    type: RUN_CRONTAB_JOB,
    name,
  };
}

export function runCrontabJobSuccess(result) {
  return {
    type: RUN_CRONTAB_JOB_SUCCESS,
    result,
  };
}

export function runCrontabJobError(error) {
  return {
    type: RUN_CRONTAB_JOB_ERROR,
    error,
  };
}

// ------------------------------------------
export function reserveNextAvailableLocker({ marketId, lockerType }) {
  return {
    type: RESERVE_NEXT_AVAILABLE_LOCKER,
    lockerType,
    marketId,
  };
}

export function reserveNextAvailableLockerSuccess(locker) {
  return {
    type: RESERVE_NEXT_AVAILABLE_LOCKER_SUCCESS,
    locker,
  };
}

export function reserveNextAvailableLockerError(error) {
  return {
    type: RESERVE_NEXT_AVAILABLE_LOCKER_ERROR,
    error,
  };
}

// -------------------------------------
export function enrichProperty(data) {
  return {
    type: ENRICH_PROPERTY,
    ...data,
  };
}

export function enrichPropertySuccess(enrichmentResult) {
  return {
    type: ENRICH_PROPERTY_SUCCESS,
    enrichmentResult,
  };
}

export function enrichPropertyError(error) {
  return {
    type: ENRICH_PROPERTY_ERROR,
    error,
  };
}

// -------------------------------------
export const loadPropertyDetailsAndOwnerSettings = (data) => ({
  type: LOAD_PROPERTY_DETAILS_AND_OWNER_SETTINGS,
  ...data,
});

export const loadPropertyDetailsAndOwnerSettingsSuccess = (result) => ({
  type: LOAD_PROPERTY_DETAILS_AND_OWNER_SETTINGS_SUCCESS,
  result,
});

export const loadPropertyDetailsAndOwnerSettingsError = (error) => ({
  type: LOAD_PROPERTY_DETAILS_AND_OWNER_SETTINGS_ERROR,
  error,
});

// -------------------------------------
export const deleteFiles = (data) => ({
  type: DELETE_FILES,
  ...data,
});

export const deleteFilesSuccess = (result) => ({
  type: DELETE_FILES_SUCCESS,
  result,
});

export const deleteFilesError = (error) => ({
  type: DELETE_FILES_ERROR,
  error,
});

// -------------------------------------
export const updateFiles = (data) => ({
  type: UPDATE_FILES,
  ...data,
});

export const updateFilesSuccess = (result) => ({
  type: UPDATE_FILES_SUCCESS,
  result,
});

export const updateFilesError = (error) => ({
  type: UPDATE_FILES_ERROR,
  error,
});

// -------------------------------------
export const loadAccountsByType = (data) => ({
  type: LOAD_ACCOUNTS_BY_TYPE,
  ...data,
});

export const loadAccountsByTypeSuccess = (result) => ({
  type: LOAD_ACCOUNTS_BY_TYPE_SUCCESS,
  result,
});

export const loadAccountsByTypeError = (error) => ({
  type: LOAD_ACCOUNTS_BY_TYPE_ERROR,
  error,
});

// -------------------------------------
export const saveInvoice = (data) => ({
  type: SAVE_INVOICE,
  ...data,
});

export const saveInvoiceSuccess = (invoice) => ({
  type: SAVE_INVOICE_SUCCESS,
  invoice,
});

export const saveInvoiceError = (error) => ({
  type: SAVE_INVOICE_ERROR,
  error,
});

// ----------------------------------------
export function createPropertyListing(data) {
  return {
    type: CREATE_PROPERTY_LISTING,
    ...data,
  };
}

export function createPropertyListingSuccess(result) {
  return {
    type: CREATE_PROPERTY_LISTING_SUCCESS,
    result,
  };
}

export function createPropertyListingError(error) {
  return {
    type: CREATE_PROPERTY_LISTING_ERROR,
    error,
  };
}

// -------------------------------------
export const deleteInvoice = (data) => ({
  type: DELETE_INVOICE,
  ...data,
});

export const deleteInvoiceSuccess = (result) => ({
  type: DELETE_INVOICE_SUCCESS,
  result,
});

export const deleteInvoiceError = (error) => ({
  type: DELETE_INVOICE_ERROR,
  error,
});

// -------------------------------------
export const deleteExpense = (data) => ({
  type: DELETE_EXPENSE,
  ...data,
});

export const deleteExpenseSuccess = (result) => ({
  type: DELETE_EXPENSE_SUCCESS,
  result,
});

export const deleteExpenseError = (error) => ({
  type: DELETE_EXPENSE_ERROR,
  error,
});

// -------------------------------------
export const loadCompanyCards = () => ({
  type: LOAD_COMPANY_CARDS,
});

export const loadCompanyCardsSuccess = (result) => ({
  type: LOAD_COMPANY_CARDS_SUCCESS,
  result,
});

export const loadCompanyCardsError = (error) => ({
  type: LOAD_COMPANY_CARDS_ERROR,
  error,
});

// -------------------------------------
export const saveExpense = (data) => ({
  type: SAVE_EXPENSE,
  ...data,
});

export const saveExpenseSuccess = (result) => ({
  type: SAVE_EXPENSE_SUCCESS,
  result,
});

export const saveExpenseError = (error) => ({
  type: SAVE_EXPENSE_ERROR,
  error,
});

// -------------------------------------
export function loadEmailTemplates(data) {
  return {
    type: LOAD_EMAIL_TEMPLATES,
    ...data,
  };
}

export function loadEmailTemplatesSuccess(emailTemplates) {
  return {
    type: LOAD_EMAIL_TEMPLATES_SUCCESS,
    emailTemplates,
  };
}

export function loadEmailTemplatesError(error) {
  return {
    type: LOAD_EMAIL_TEMPLATES_ERROR,
    error,
  };
}
// -------------------------------------
export function updatePropertyKeyLog(data) {
  return {
    type: UPDATE_PROPERTY_KEY_LOG,
    ...data,
  };
}

export function updatePropertyKeyLogSuccess(result) {
  return {
    type: UPDATE_PROPERTY_KEY_LOG_SUCCESS,
    result,
  };
}

export function updatePropertyKeyLogError(error) {
  return {
    type: UPDATE_PROPERTY_KEY_LOG_ERROR,
    error,
  };
}

// -------------------------------------
export function deleteJob(data) {
  return {
    type: DELETE_JOB,
    ...data,
  };
}
export function deleteJobSuccess(result) {
  return {
    type: DELETE_JOB_SUCCESS,
    result,
  };
}

export function deleteJobError(error) {
  return {
    type: DELETE_JOB_ERROR,
    error,
  };
}

// -------------------------------------
export function loadLockboxes(data) {
  return {
    type: LOAD_LOCKBOXES,
    ...data,
  };
}

export function loadLockboxesSuccess(result) {
  return {
    type: LOAD_LOCKBOXES_SUCCESS,
    result,
  };
}

export function loadLockboxesError(error) {
  return {
    type: LOAD_LOCKBOXES_ERROR,
    error,
  };
}

// ------------------------------------------
export function assignLockboxProperty(data) {
  return {
    type: ASSIGN_LOCKBOX_PROPERTY,
    ...data,
  };
}

export function assignLockboxPropertySuccess(result) {
  return {
    type: ASSIGN_LOCKBOX_PROPERTY_SUCCESS,
    result,
  };
}

export function assignLockboxPropertyError(error) {
  return {
    type: ASSIGN_LOCKBOX_PROPERTY_ERROR,
    error,
  };
}

// ------------------------------------------
export function unassignLockboxProperty(data) {
  return {
    type: UNASSIGN_LOCKBOX_PROPERTY,
    ...data,
  };
}

export function unassignLockboxPropertySuccess(result) {
  return {
    type: UNASSIGN_LOCKBOX_PROPERTY_SUCCESS,
    result,
  };
}

export function unassignLockboxPropertyError(error) {
  return {
    type: UNASSIGN_LOCKBOX_PROPERTY_ERROR,
    error,
  };
}

// ------------------------------------------
export function sendOpportunityWelcomeEmail(data) {
  return {
    type: SEND_OPPORTUNITY_WELCOME_EMAIL,
    ...data,
  };
}

export function sendOpportunityWelcomeEmailSuccess(result) {
  return {
    type: SEND_OPPORTUNITY_WELCOME_EMAIL_SUCCESS,
    result,
  };
}

export function sendOpportunityWelcomeEmailError(error) {
  return {
    type: SEND_OPPORTUNITY_WELCOME_EMAIL_ERROR,
    error,
  };
}

// ------------------------------------------
export function loadMarkets(data) {
  return {
    type: LOAD_MARKETS,
    ...data,
  };
}

export function loadMarketsSuccess(result) {
  return {
    type: LOAD_MARKETS_SUCCESS,
    result,
  };
}

export function loadMarketsError(error) {
  return {
    type: LOAD_MARKETS_ERROR,
    error,
  };
}

// ------------------------------------------
export function loadZplTemplates(data) {
  return {
    type: LOAD_ZPL_TEMPLATES,
    ...data,
  };
}

export function loadZplTemplatesSuccess(result) {
  return {
    type: LOAD_ZPL_TEMPLATES_SUCCESS,
    result,
  };
}

export function loadZplTemplatesError(error) {
  return {
    type: LOAD_ZPL_TEMPLATES_ERROR,
    error,
  };
}

// -----------------------------------------------------
export function deleteDocusignEnvelope(data) {
  return {
    type: DELETE_DOCUSIGN_ENVELOPE,
    ...data,
  };
}

export function deleteDocusignEnvelopeSuccess(envelope) {
  return {
    type: DELETE_DOCUSIGN_ENVELOPE_SUCCESS,
    envelope,
  };
}

export function deleteDocusignEnvelopeError(error) {
  return {
    type: DELETE_DOCUSIGN_ENVELOPE_ERROR,
    error,
  };
}

// ------------------------------------------
export function updateContractAddendum(data) {
  return {
    type: UPDATE_CONTRACT_ADDENDUM,
    ...data,
  };
}

export function updateContractAddendumSuccess(result) {
  return {
    type: UPDATE_CONTRACT_ADDENDUM_SUCCESS,
    result,
  };
}

export function updateContractAddendumError(error) {
  return {
    type: UPDATE_CONTRACT_ADDENDUM_ERROR,
    error,
  };
}

// ------------------------------------------
export function loadUser(credentials) {
  return {
    type: LOAD_USER,
    credentials,
  };
}

export function loadUserSuccess(user) {
  return {
    type: LOAD_USER_SUCCESS,
    user,
  };
}

export function loadUserError(error) {
  return {
    type: LOAD_USER_ERROR,
    error,
  };
}

// -------------------------------------
export const createInvoice = (data) => ({
  type: CREATE_INVOICE,
  ...data,
});

export const createInvoiceSuccess = (result) => ({
  type: CREATE_INVOICE_SUCCESS,
  result,
});

export const createInvoiceError = (error) => ({
  type: CREATE_INVOICE_ERROR,
  error,
});

// -------------------------------------
export const updateApplication = (data) => ({
  type: UPDATE_APPLICATION,
  ...data,
});

export const updateApplicationSuccess = (result) => ({
  type: UPDATE_APPLICATION_SUCCESS,
  result,
});

export const updateApplicationError = (error) => ({
  type: UPDATE_APPLICATION_ERROR,
  error,
});

// -------------------------------------
export function saveContact(data) {
  return {
    type: SAVE_CONTACT,
    ...data,
  };
}

export function saveContactSuccess(contact) {
  return {
    type: SAVE_CONTACT_SUCCESS,

    contact,
  };
}

export function saveContactError(error) {
  return {
    type: SAVE_CONTACT_ERROR,
    error,
  };
}

// -------------------------------------
export function createContact(data) {
  return {
    type: CREATE_CONTACT,
    ...data,
  };
}

export function createContactSuccess(contact) {
  return {
    type: CREATE_CONTACT_SUCCESS,
    contact,
  };
}

export function createContactError(error) {
  return {
    type: CREATE_CONTACT_ERROR,
    error,
  };
}

// -------------------------------------
export function deleteContact({ toDeleteId, toReplaceId, accountId }) {
  return {
    type: DELETE_CONTACT,
    toDeleteId,
    toReplaceId,
    accountId,
  };
}

export function deleteContactSuccess(contactId) {
  return {
    type: DELETE_CONTACT_SUCCESS,
    contactId,
  };
}

export function deleteContactError(error) {
  return {
    type: DELETE_CONTACT_ERROR,
    error,
  };
}

// ------------------------------------------
export function updateVendor(data) {
  return {
    type: UPDATE_VENDOR,
    ...data,
  };
}

export function updateVendorSuccess(result) {
  return {
    type: UPDATE_VENDOR_SUCCESS,
    result,
  };
}

export function updateVendorError(error) {
  return {
    type: UPDATE_VENDOR_ERROR,
    error,
  };
}
