import React, { memo } from 'react';
import { bool, func, node, object, string, oneOfType } from 'prop-types';
import { styled } from '@mui/system';
import { Drawer } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { noop } from 'lodash';

const DrawerOverflow = styled('div')(({ theme }) => ({
  overflow: 'auto',
  width: '100%',
  height: '100%',
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& > .MuiPaper-root': {
    overflow: 'visible',
    width: '86%',
    ...(theme.palette.mode === 'dark' && {
      borderLeft: `1px solid ${theme.palette.grey[700]}`,
    }),
  },
}));

const DrawerCloseIcon = styled(CloseIcon, {
  shouldForwardProp: (prop) => prop !== 'anchor',
})(({ anchor, theme }) => ({
  position: 'absolute',
  [anchor === 'right' ? 'left' : 'right']: theme.spacing(-5.5),
  top: theme.spacing(0.5),
  color: theme.palette.common.white,
  fontSize: '2.5rem !important',
  cursor: 'pointer',
}));

const CustomDrawer = ({
  isOpen = false,
  keepMounted = false,
  isShowCloseIcon = true,
  sx = undefined,
  anchor = 'right',
  variant = 'temporary',
  children,
  onClose = noop,
}) => (
  <StyledDrawer
    sx={sx}
    open={isOpen}
    anchor={anchor}
    onClose={onClose}
    variant={variant}
    ModalProps={{
      keepMounted,
    }}
  >
    {isShowCloseIcon && <DrawerCloseIcon onClick={onClose} anchor={anchor} />}
    <DrawerOverflow>{children}</DrawerOverflow>
  </StyledDrawer>
);

CustomDrawer.propTypes = {
  isOpen: bool,
  keepMounted: bool,
  isShowCloseIcon: bool,
  anchor: string,
  variant: string,
  sx: oneOfType([object, func]),
  children: node,
  onClose: func,
};

export default memo(CustomDrawer);
