import { encodeBase64 } from 'helpers/template';
import { getHref } from 'utils/history';
import Auth from 'helpers/auth';
import Company from 'helpers/company';

const domainCompanyId = Company.getIdByDomain();

export function getDefaultHeaders(extra = {}) {
  // add general headers automatically
  const headers = {
    'Content-Type': 'application/json',
    'Api-Key': process.env.API_KEY,
    // get current page and encode to base64 to pass the WAF
    'X-RequestFrom': encodeBase64(`${getHref()}`),
    // send the company id for the current domain
    'X-DomainCompanyId': domainCompanyId,
    // extend and override the headers
    ...extra,
  };

  const userId = Auth.getUser()?.id;
  if (userId) {
    headers['X-RequestBy'] = encodeBase64(userId);
  }

  const token = Auth.getToken();
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return new Headers(headers);
}
