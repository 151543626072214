import React from 'react';
import Moize from 'moize';
import { string } from 'prop-types';
import { Link } from '@mui/material';

const LinkCell = ({ href = '', text = '' }) => (
  <Link target="_blank" href={href}>
    {text}
  </Link>
);

LinkCell.propTypes = {
  href: string.isRequired,
  text: string.isRequired,
};

export default Moize.react(LinkCell);
