import React, { memo } from 'react';
import { string, object, bool, func, oneOfType } from 'prop-types';
import { Box } from '@mui/material';
import { makeIndex } from 'helpers/statusTabs';
import {
  DateTimePicker,
  DatePicker as MuiDatePicker,
  StaticDatePicker,
  MobileDatePicker,
} from '@mui/x-date-pickers';
import Moment from 'moment';

const sxStylesCalendarButton = {
  '& button': {
    color: 'primary.main',
  },
};

// eslint-disable-next-line react/prop-types
const CalendarIconButton = ({ InputProps = {} }) => {
  // eslint-disable-next-line react/prop-types
  const { ref, endAdornment } = InputProps;

  return (
    <Box ref={ref} sx={sxStylesCalendarButton}>
      {endAdornment}
    </Box>
  );
};

const DatePicker = ({
  label = '',
  id = '',
  name = '',
  value = undefined,
  disabled = false,
  required = false,
  disablePast = false,
  time = false,
  format = 'MM/DD/YYYY',
  margin = 'dense',
  validation = null,
  variant = 'inline',
  onChange,
  slots = {},
  slotProps = {},
  minDate = undefined,
  maxDate = undefined,
  showOnlyCalendarIcon = false,
  ...other
}) => {
  let Component = MuiDatePicker;
  if (time) {
    Component = DateTimePicker;
  } else if (variant === 'static') {
    Component = StaticDatePicker;
  } else if (variant === 'mobile') {
    Component = MobileDatePicker;
  }

  let date = null;
  if (value) {
    date = Moment.isMoment(value) ? value : Moment(value, format);
  }

  let minDateMoment;
  if (minDate) {
    minDateMoment = Moment.isMoment(minDate)
      ? minDate
      : Moment(minDate, format);
  }

  let maxDateMoment;
  if (maxDate) {
    maxDateMoment = Moment.isMoment(maxDate)
      ? maxDate
      : Moment(maxDate, format);
  }

  const onDateChange = (d) => onChange(name, d?.format(format) || '');

  const defaultFieldSlot = showOnlyCalendarIcon
    ? { field: CalendarIconButton }
    : {};

  return (
    <Component
      minDate={minDateMoment || (disablePast ? Moment() : undefined)}
      maxDate={maxDateMoment}
      format={format}
      id={makeIndex(id || name || label)}
      disabled={disabled}
      label={label}
      value={date}
      name={name}
      mask="__/__/____"
      onChange={onDateChange}
      {...other}
      slotProps={{
        textField: {
          size: 'small',
          fullWidth: true,
          required,
          margin,
          helperText: validation?.help?.[name],
          error: validation?.hasError?.[name],
        },
        ...slotProps,
      }}
      slots={{
        ...defaultFieldSlot,
        ...slots,
      }}
    />
  );
};

DatePicker.propTypes = {
  label: string,
  id: string,
  name: string,
  variant: string,
  format: string,
  margin: string,
  value: oneOfType([string, object]),
  validation: object,
  disabled: bool,
  required: bool,
  disablePast: bool,
  time: bool,
  onChange: func.isRequired,
  slots: object,
  slotProps: object,
  minDate: oneOfType([string, object]),
  maxDate: oneOfType([string, object]),
  showOnlyCalendarIcon: bool,
};

export default memo(DatePicker);
