/* eslint-disable no-confusing-arrow */
/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect, useState, useMemo } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import * as Sentry from '@sentry/react';
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
  useParams,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Auth from 'helpers/auth';
import CssBaseline from '@mui/material/CssBaseline';
import globalMuiTheme from 'styles/globalMuiTheme';
import PermissionDenied from 'components/PermissionDenied';
import UserRoles, { CUSTOMER, EMPLOYEE } from 'helpers/userRoles';
import ReactGA from 'react-ga4';
import Company from 'helpers/company';
import CompanyId from 'enums/companyId';
import withGATracker from 'components/hoc/withGATracker';
import {
  externalRedirectToSsoLogin,
  externalRedirectToSsoTermsAndConditions,
} from 'helpers/singleSignOn';
import { ErrorBoundary } from 'react-error-boundary';
import OopsPage from 'components/Oops';
import AutoReload from './AutoReload';
import routes from './routes';
import './style.scss';
import { clearFlags } from './actions';

if (process.env.ENV !== 'development') {
  // Init error reporting
  Sentry.init({
    dsn: process.env.SENTRY_DNS,
    environment: process.env.ENV,
    release: process.env.APP_RELEASE,
    integrations: [
      Sentry.captureConsoleIntegration({
        levels: ['error'],
      }),
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: process.env.ENV === 'staging' ? 1.0 : 0.2, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

// ---------------------------------------------------------

const LoginRoute = () => {
  useEffect(() => {
    externalRedirectToSsoLogin();
  }, []);
  return null;
};

const HelpDeskRedirect = () => {
  window.location.replace('https://tenbar.atlassian.net/servicedesk');
};

// ---------------------------------------------------------
const PublicRoute = ({ component: Component, params: extraParams = null }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  return (
    <Component
      key={location.pathname}
      match={{
        params: {
          ...params,
          ...extraParams,
        },
      }}
      navigate={navigate}
      location={location}
    />
  );
};

PublicRoute.propTypes = {
  params: PropTypes.object,
  component: PropTypes.any.isRequired,
};

// ---------------------------------------------------------
const PrivateRoute = ({ children }) => {
  // const location = useLocation();

  const token = Auth.getToken();
  const user = Auth.getUser();
  const isAuthenticated = !!token && !!user && user.id !== 'temp';

  useEffect(() => {
    // check if user authenticated
    if (!isAuthenticated) {
      externalRedirectToSsoLogin();
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return null;
  }

  // check if accepted terms
  if (!user?.isAgreedToPortalTerms) {
    const companyId = Company.getId();
    externalRedirectToSsoTermsAndConditions({
      userId: user?.id,
      companyId,
    });
  }

  // ok -> load page
  return children;
};

PrivateRoute.propTypes = {
  // path: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

// ---------------------------------------------------------
const PrivateComponent = ({
  component,
  roles = [CUSTOMER, EMPLOYEE],
  permissions = [],
  params = null,
}) => {
  // check if roles do match and if user has permissions
  if (!Auth.isAuthenticated(roles, permissions)) {
    return <PermissionDenied />;
  }

  return <PublicRoute params={params} component={component} />;
};

PrivateComponent.propTypes = {
  params: PropTypes.object,
  component: PropTypes.any.isRequired,
  roles: PropTypes.array,
  permissions: PropTypes.array,
};
// ---------------------------------------------------------

const App = () => {
  const [userState, setUserState] = useState(() => Auth.getUser());
  const isProduction = process.env.ENV === 'production';
  const isLoadedUser = useSelector((state) => state.global.isLoadedUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isProduction) {
      const companyId = Company.getIdByDomain();
      switch (companyId) {
        case CompanyId.ONQ:
          ReactGA.initialize(process.env.GA_ONQ_KEY);
          break;
        case CompanyId.MAMMOTH:
          ReactGA.initialize(process.env.GA_MAMMOTH_KEY);
          break;
        default:
      }
    }
  }, []);

  useEffect(() => {
    // In case the App component is mounted after redirect from sso, then we need time for user data to be loaded.
    // So we use isLoadedUser flag, to trigger the useEffect
    let isExpiredTimer = null;
    const checkTokenExpired = () => {
      const isExpired = Auth.checkIsExpiresIn();

      if (isExpired) {
        if (isExpiredTimer) {
          clearInterval(isExpiredTimer);
        }

        Auth.logout({ isKeepToken: true });

        externalRedirectToSsoLogin();
      }
    };

    // eslint-disable-next-line no-shadow
    const user = Auth.getUser();

    setUserState(user);

    if (
      (user && user?.id !== 'temp') ||
      (isLoadedUser && user && user?.id !== 'temp')
    ) {
      dispatch(clearFlags()); // TODO make with connect
      checkTokenExpired();
      isExpiredTimer = setInterval(checkTokenExpired, 300000);
    }

    return () => {
      if (isExpiredTimer) {
        clearInterval(isExpiredTimer);
      }
    };
  }, [isLoadedUser]);

  const defaultRedirectTo = useMemo(
    // Redirect from '/' by user role
    () =>
      UserRoles.getDefaultRedirectTo({
        user: userState,
      }),
    [userState],
  );

  const memoizedRoutes = useMemo(
    // Is needed to avoid calling withGATracker which recreates components on App rerender
    () =>
      routes.map(
        ({ isPublic = false, path, component, roles, permissions, params }) =>
          isPublic ? (
            <Route
              exact
              path={path}
              key={path}
              element={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <PublicRoute
                  path={path}
                  params={params}
                  component={
                    isProduction ? withGATracker(component) : component
                  }
                />
              }
            />
          ) : (
            <Route
              exact
              path={path}
              key={path}
              element={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <PrivateRoute path={path}>
                  <PrivateComponent
                    params={params}
                    roles={roles}
                    permissions={permissions}
                    component={
                      isProduction ? withGATracker(component) : component
                    }
                  />
                </PrivateRoute>
              }
            />
          ),
      ),
    [],
  );

  return (
    <ThemeProvider theme={globalMuiTheme}>
      <Helmet titleTemplate="%s - On Q Cloud" defaultTitle="On Q Cloud">
        <meta
          name="description"
          content="On Q Cloud Property Management System"
        />
      </Helmet>
      <CssBaseline />
      <AutoReload />
      <ErrorBoundary fallback={<OopsPage message="Something went wrong" />}>
        <Routes>
          <Route
            exact
            path="/"
            element={
              // PrivateRoute to redirect non logged in user directly to SSO,
              // and determine defaultRedirectTo by role at SSO.
              // To prevent redirect defaultRedirectTo clientside before going to SSO

              // eslint-disable-next-line react/jsx-wrap-multilines
              <PrivateRoute path="/">
                <Navigate to={defaultRedirectTo} />
              </PrivateRoute>
            }
          />

          <Route exact path="/login" element={<LoginRoute />} />
          <Route exact path="/help-desk" element={<HelpDeskRedirect />} />
          {memoizedRoutes}
        </Routes>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;

/* eslint-enable */
