import React from 'react';
import { Tab } from '@mui/material';
import { styled } from '@mui/system';

const VerticalTab = styled(Tab)(({ theme }) => ({
  fontSize: '1rem',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  textTransform: 'none',
  color: 'white',
  minWidth: theme.spacing(6),
  '&:hover': {
    ...(theme.palette.mode === 'light' && {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    }),
    ...(theme.palette.mode === 'dark' && {
      backgroundColor: theme.palette.grey[900],
    }),
  },
  '& svg': {
    color: 'white',
    marginRight: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  '&.Mui-selected': {
    ...(theme.palette.mode === 'light' && {
      backgroundColor: 'white',
    }),
    ...(theme.palette.mode === 'dark' && {
      backgroundColor: theme.palette.grey[700],
    }),

    color: 'black !important',
    '&:hover': {
      ...(theme.palette.mode === 'light' && {
        backgroundColor: 'white',
      }),
      ...(theme.palette.mode === 'dark' && {
        backgroundColor: theme.palette.grey[400],
      }),
    },
    '& svg': {
      ...(theme.palette.mode === 'light' && { color: '#6E7986' }),
    },
  },
  '&.MuiTab-labelIcon': {
    minHeight: theme.spacing(6),
  },
}));

export default VerticalTab;
