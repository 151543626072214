import React, { memo } from 'react';
import { bool, string, func } from 'prop-types';
import { styled } from '@mui/system';
import LazyLoad from 'react-lazyload';
import { Typography, Link } from '@mui/material';
import Copyright from '@mui/icons-material/Copyright';
import Call from '@mui/icons-material/Call';
import Mail from '@mui/icons-material/Mail';
import Logo from 'images/logo-equal-housing-opportunity.png';
import AgreementTypes from '../ModalPrivacyPolicy/agreementTypes';

const StyledFooter = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  backgroundColor: '#292929',
  padding: theme.spacing(1, 2),
  width: '100%',
  position: 'absolute',
  bottom: '0',
  left: '0',
  height: theme.spacing(22),
  img: {
    width: theme.spacing(12.5),
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: theme.spacing(120),
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: theme.spacing(75),
    flexDirection: 'column',
    alignItems: 'center',
    height: theme.spacing(32),
  },
}));

const StyledFooterSection = styled('section')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
  },
}));

const StyledPhoneAndEmail = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
}));

const StyledCopyright = styled(Typography)(({ theme }) => ({
  color: 'rgba(255, 255, 255, 0.25)',
  marginBottom: theme.spacing(0.6),
  fontSize: theme.spacing(1.8),
  svg: {
    width: theme.spacing(1.8),
    height: theme.spacing(1.8),
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '0.875rem',
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: 'rgba(255, 255, 255, 0.25)',
  textDecoration: 'underline',
  cursor: 'pointer',
  marginBottom: theme.spacing(0.6),
  fontSize: theme.spacing(1.8),
  display: 'flex',
  svg: {
    width: theme.spacing(1.4),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(2.5),
  },
}));

const Footer = ({
  phone = '',
  roc = '',
  companyName = '',
  email = '',
  onOpenModal,
  isVisibleSubcontractorAgreementLink,
}) => {
  const year = new Date().getFullYear();

  return (
    <footer>
      <StyledFooter>
        <StyledFooterSection>
          <StyledCopyright variant="subtitle1">
            <span>
              <Copyright /> {year}
            </span>
            {companyName}
          </StyledCopyright>
          {!!roc && (
            <StyledLink
              href="https://roc.az.gov/contractor-search?Id=a0Yt000000ACNAGEA5"
              target="_blank"
              rel="noreferrer noopener"
            >
              ROC{roc}
            </StyledLink>
          )}
          <StyledLink
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onOpenModal(AgreementTypes.PRIVACY);
            }}
          >
            Privacy Policy
          </StyledLink>
          <StyledLink
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onOpenModal(AgreementTypes.TERMS);
            }}
          >
            Terms & Conditions
          </StyledLink>
          {isVisibleSubcontractorAgreementLink && (
            <StyledLink
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onOpenModal(AgreementTypes.SUB);
              }}
            >
              Subcontractor Agreement
            </StyledLink>
          )}
          <LazyLoad height={25} once>
            <img
              alt="Equal Housing Opportunity"
              title="Equal Housing Opportunity"
              src={Logo}
            />
          </LazyLoad>
        </StyledFooterSection>
        <StyledFooterSection>
          <StyledPhoneAndEmail>
            <StyledLink href={`mailto:${email}`} variant="subtitle2">
              <Mail />
              {email}
            </StyledLink>
            <StyledLink href={`tel:${phone}`} variant="subtitle2">
              <Call />
              {phone}
            </StyledLink>
          </StyledPhoneAndEmail>
        </StyledFooterSection>
      </StyledFooter>
    </footer>
  );
};

Footer.propTypes = {
  phone: string,
  roc: string,
  companyName: string,
  email: string,
  onOpenModal: func.isRequired,
  isVisibleSubcontractorAgreementLink: bool,
};

export default memo(Footer);
