import { createAction } from '@reduxjs/toolkit';

export const clearVMData = createAction('CLEAR_VM_DATA');

// ----------------------------------------------------------------
export const loadVMData = createAction('LOAD_VM_DATA');
export const loadVMDataSuccess = createAction('LOAD_VM_DATA_SUCCESS');
export const loadVMDataError = createAction('LOAD_VM_DATA_ERROR');

// ----------------------------------------------------------------
export const loadContractKeyHistory = createAction('LOAD_CONTRACT_KEY_HISTORY');
export const loadContractKeyHistorySuccess = createAction(
  'LOAD_CONTRACT_KEY_HISTORY_SUCCESS',
);
export const loadContractKeyHistoryError = createAction(
  'LOAD_CONTRACT_KEY_HISTORY_ERROR',
);

// ----------------------------------------------------------------
export const updateDocumentProcessing = createAction(
  'UPDATE_DOCUMENT_PROCESSING',
);
export const updateDocumentProcessingSuccess = createAction(
  'UPDATE_DOCUMENT_PROCESSING_SUCCESS',
);
export const updateDocumentProcessingError = createAction(
  'UPDATE_DOCUMENT_PROCESSING_ERROR',
);

// ----------------------------------------------------------------
export const loadDocuments = createAction('LOAD_DOCUMENTS');
export const loadDocumentsSuccess = createAction('LOAD_DOCUMENTS_SUCCESS');
export const loadDocumentsError = createAction('LOAD_DOCUMENTS_ERROR');

// ----------------------------------------------------------------
export const voidSignOnDocument = createAction('VOID_SIGN_ON_DOCUMENT');
export const voidSignOnDocumentSuccess = createAction(
  'VOID_SIGN_ON_DOCUMENT_SUCCESS',
);
export const voidSignOnDocumentError = createAction(
  'VOID_SIGN_ON_DOCUMENT_ERROR',
);

// ----------------------------------------------------------------
export const updateListingAndRelations = createAction(
  'UPDATE_LISTING_AND_RELATIONS',
);
export const updateListingAndRelationsSuccess = createAction(
  'UPDATE_LISTING_AND_RELATIONS_SUCCESS',
);
export const updateListingAndRelationsError = createAction(
  'UPDATE_LISTING_AND_RELATIONS_ERROR',
);
