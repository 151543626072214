import React, { memo } from 'react';
import { array, any } from 'prop-types';
import { styled } from '@mui/system';
import { List, ListItemText, ListItemIcon } from '@mui/material';
import StyledListItem from './StyledListItem';

const StyledTabPanel = styled('div')(({ theme }) => ({
  flex: 1,
  ...(theme.palette.mode === 'light' && {
    backgroundColor: 'white',
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.grey[700],
  }),
  fontSize: 18,
}));

const StyledList = styled(List)(({ theme }) => ({
  padding: theme.spacing(2, 0),
  minWidth: theme.spacing(31.25),
  ...(theme.palette.mode === 'light' && {
    backgroundColor: 'white',
  }),
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: theme.spacing(5),
  '& svg': {
    ...(theme.palette.mode === 'light' && {
      color: '#6E7986',
    }),
  },
}));

const StyledListItemText = styled(ListItemText)({
  fontSize: '1.075rem',
});

const TabPanel = ({ value, index, items = [] }) => (
  <StyledTabPanel role="tabpanel" hidden={value !== index}>
    <StyledList>
      {items.map(({ visible, ...item }) => (
        <StyledListItem {...item} key={item.label}>
          {!!item.icon && <StyledListItemIcon>{item.icon}</StyledListItemIcon>}
          <StyledListItemText primary={item.label} />
        </StyledListItem>
      ))}
    </StyledList>
  </StyledTabPanel>
);

TabPanel.propTypes = {
  items: array,
  index: any.isRequired,
  value: any.isRequired,
};

export default memo(TabPanel);
