/* eslint-disable indent */
import { getColorMode } from 'helpers/general';
import { createTheme } from '@mui/material/styles';

const mode = getColorMode();

export default createTheme({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          primary: {
            main: '#004a82',
            light: '#337ab7',
          },
        }
      : {
          primary: {
            main: '#0288d1',
            light: '#2297d7',
          },
        }),
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: '#337ab7',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          textTransform: 'none',
        },
      },
    },
  },
});
