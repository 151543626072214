import React, { memo } from 'react';
import { number, object, oneOfType, string } from 'prop-types';
import { styled } from '@mui/system';
import { TextField } from '@mui/material';

const StyledTextField = styled(TextField)(({ theme }) => {
  let bgColor = {};
  if (theme.palette.mode === 'light') {
    bgColor = { backgroundColor: theme.palette.common.white };
  }
  return {
    bgColor,
  };
});

const CustomTextField = ({
  name,
  validation = null,
  value = '',
  id = '',
  type = 'text',
  margin = 'dense',
  InputProps = undefined,
  inputProps = undefined,
  InputLabelProps = undefined,
  ...other
}) => (
  /* eslint-disable react/jsx-no-duplicate-props */
  <StyledTextField
    {...other}
    variant="outlined"
    size="small"
    margin={margin}
    error={validation?.hasError?.[name]}
    fullWidth
    id={id || name}
    type={type}
    name={name}
    value={value || ''}
    helperText={validation?.help?.[name]}
    slotProps={{
      input: { ...InputProps },
      inputLabel: { ...InputLabelProps },
      htmlInput: { ...inputProps },
    }}
  />
);

/* eslint-enable react/jsx-no-duplicate-props */

CustomTextField.propTypes = {
  name: string,
  validation: object,
  value: oneOfType([number, string]),
  id: string,
  type: string,
  margin: string,
  InputProps: object,
  inputProps: object,
  InputLabelProps: object,
};

export default memo(CustomTextField);
