import React, { useMemo, useState, memo } from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import { getColorMode } from 'helpers/general';
import UserRoles, { EMPLOYEE } from 'helpers/userRoles';
import {
  IconButton,
  MenuItem,
  Menu,
  Avatar,
  Link as MuiLink,
  Box,
  FormControlLabel,
  Switch,
} from '@mui/material';
import Auth from 'helpers/auth';
import { externalRedirectToSsoLogin } from 'helpers/singleSignOn';
import request from 'utils/request';
import { useMergeState } from 'helpers/state';

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  width: theme.spacing(4),
  height: theme.spacing(4),
}));

const StyledLoginLink = styled(MuiLink)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: theme.spacing(1),
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  margin: theme.spacing(0, 0, 0.5, 2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  '& > .MuiTypography-root': {
    fontSize: '0.875rem',
  },
}));

const UserMenu = () => {
  const user = useMemo(() => Auth.getUser(), []);

  const isAuthenticated = !!user;

  const isSuperAdmin = useMemo(
    () => user?.permissions?.includes('Dev_Tools__c'),
    [user],
  );

  const isEmployee = useMemo(() => EMPLOYEE === user?.role, [user]);

  const homePage = useMemo(
    // Redirect from '/' by user role
    () =>
      UserRoles.getDefaultRedirectTo({
        user,
      }),
    [user],
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const onOpenUserMenu = (e) => setAnchorEl(e.currentTarget);

  const onCloseUserMenu = () => setAnchorEl(null);

  const onLogout = (e) => {
    if (e) {
      e.preventDefault();
    }
    Auth.logout({});
    externalRedirectToSsoLogin();
  };

  // cache reset related logic
  const [state, setState] = useMergeState({
    isFlushingCache: false,
    isFlushingTokenCache: false,
  });

  const onFlushCache = async () => {
    setState({ isFlushingCache: true });
    try {
      await request(`${process.env.API_URL}/v1/cache/flushall`, {
        method: 'DELETE',
      });
    } catch (err) {
      // eslint-disable-next-line no-alert
      alert(err.message);
    }
    setState({ isFlushingCache: false });
  };

  const onFlushTokensFromCache = async () => {
    setState({ isFlushingTokenCache: true });
    try {
      await request(`${process.env.API_URL}/v1/cache/flush-tokens`, {
        method: 'DELETE',
      });
    } catch (err) {
      // eslint-disable-next-line no-alert
      alert(err.message);
    }
    setState({ isFlushingTokenCache: false });
  };

  // Switching theme mode
  const localStorageMode = useMemo(() => getColorMode(), []);
  const [mode, setMode] = useState(localStorageMode);
  const [isChecked, setIsChecked] = useState(mode !== 'light');

  const toggleColorMode = () => {
    setIsChecked((prevMode) => !prevMode);
    setMode((prevMode) => {
      const colorMode = prevMode === 'light' ? 'dark' : 'light';
      localStorage.setItem('colorMode', colorMode);
      return colorMode;
    });
    window.location.reload(false);
  };

  return (
    <Box>
      {isAuthenticated ? (
        <>
          <IconButton
            size="small"
            onClick={onOpenUserMenu}
            color="inherit"
            sx={{ ml: 0.5 }}
          >
            <StyledAvatar alt={user?.name} src={user?.picture}>
              {user?.name?.[0]}
            </StyledAvatar>
          </IconButton>
          <Menu
            keepMounted
            open={Boolean(anchorEl)}
            onClose={onCloseUserMenu}
            anchorEl={anchorEl}
          >
            <StyledFormControlLabel
              label="Dark Mode"
              labelPlacement="start"
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Switch
                  checked={isChecked}
                  onChange={toggleColorMode}
                  inputProps={{ 'aria-label': 'controlled' }}
                  size="small"
                />
              }
            />

            <MenuItem dense component={Link} to={homePage}>
              My Dashboard
            </MenuItem>

            {isSuperAdmin && (
              <MenuItem
                dense
                onClick={onFlushCache}
                disabled={state.isFlushingCache}
              >
                Flush API Cache
              </MenuItem>
            )}

            {isSuperAdmin && (
              <MenuItem
                dense
                onClick={onFlushTokensFromCache}
                disabled={state.isFlushingTokenCache}
              >
                Flush API Tokens Cache
              </MenuItem>
            )}

            {isEmployee && (
              <MenuItem dense component={Link} to="/html-signature">
                HTML Signature
              </MenuItem>
            )}
            <MenuItem dense onClick={onLogout}>
              Log out
            </MenuItem>
          </Menu>
        </>
      ) : (
        <Box sx={{ display: 'flex' }}>
          <FormControlLabel
            label="Dark Mode"
            labelPlacement="start"
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Switch
                checked={isChecked}
                onChange={toggleColorMode}
                inputProps={{ 'aria-label': 'controlled' }}
                size="small"
              />
            }
            sx={{ mr: 1 }}
          />
          <StyledLoginLink
            underline="none"
            color="inherit"
            href="/"
            onClick={onLogout}
          >
            Login
          </StyledLoginLink>
        </Box>
      )}
    </Box>
  );
};

export default memo(UserMenu);
