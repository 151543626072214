/* eslint-disable import/no-import-module-exports */
/**
 * app.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import localforage from 'localforage';
import history from 'utils/history';

// Import root app
import App from 'containers/App';

// Import CSS reset and Global Styles
import 'styles/theme.scss';

import configureStore from './configureStore';

const initialState = {};
const store = configureStore(initialState);
const MOUNT_NODE = document.getElementById('app');
const ROOT = createRoot(MOUNT_NODE);

localforage.config({
  name: 'ONQ db',
  driver: [localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE],
  storeName: 'settings',
});

const render = () => {
  ROOT.render(
    <Provider store={store}>
      <HelmetProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <HistoryRouter history={history}>
            <App />
          </HistoryRouter>
        </LocalizationProvider>
      </HelmetProvider>
    </Provider>,
  );
};

if (import.meta.hot) {
  // TODO webpack-to-vite. Was module.hot
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  import.meta.hot.accept(['containers/App'], () => {
    // ROOT.unmount();
    render();
  });
}

render();
