import qs from 'qs';
import request from 'utils/request';
import Company from 'helpers/company';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  loadVMData,
  loadVMDataSuccess,
  loadVMDataError,
  loadContractKeyHistory,
  loadContractKeyHistorySuccess,
  loadContractKeyHistoryError,
  loadDocuments,
  loadDocumentsSuccess,
  loadDocumentsError,
  updateDocumentProcessing,
  updateDocumentProcessingSuccess,
  updateDocumentProcessingError,
  voidSignOnDocument,
  voidSignOnDocumentSuccess,
  voidSignOnDocumentError,
  updateListingAndRelations,
  updateListingAndRelationsSuccess,
  updateListingAndRelationsError,
} from './actions';

// import properties from '../../../fixtures/visualManager.json';

export function* loadVMDataReq({ payload: data }) {
  try {
    const { reportType, ...other } = data;

    const query = qs.stringify(other);
    // Call our request helper (see 'utils/request')
    const properties = yield call(
      request,
      `${process.env.API_URL}/v1/visual-manager/reports/${reportType}?${query}`,
      {
        method: 'GET',
      },
    );

    yield put(loadVMDataSuccess(properties));
  } catch (err) {
    yield put(loadVMDataError(err));
  }
}

export function* loadContractKeyHistoryReq({ payload: data }) {
  const { contractId } = data;
  try {
    // Call our request helper (see 'utils/request')
    const results = yield call(
      request,
      `${process.env.API_URL}/v1/visual-manager/contracts/${contractId}/key-history`,
      {
        method: 'GET',
      },
    );

    yield put(loadContractKeyHistorySuccess(results));
  } catch (err) {
    yield put(loadContractKeyHistoryError(err));
  }
}

export function* loadDocumentsReq({ payload: { status = 'error' } }) {
  try {
    // Call our request helper (see 'utils/request')
    const results = yield call(
      request,
      `${process.env.API_URL}/v2/files/documents/byStatus/${status}`,
      {
        method: 'GET',
      },
    );

    yield put(loadDocumentsSuccess(results));
  } catch (err) {
    yield put(loadDocumentsError(err));
  }
}

export function* updateDocumentProcessingReq({
  payload: { type, documentId, ...payload },
}) {
  try {
    // Call our request helper (see 'utils/request')
    const results = yield call(
      request,
      `${process.env.API_URL}/v2/files/documents/${documentId}`,
      {
        method: 'PUT',
        body: JSON.stringify(payload),
      },
    );
    yield put(updateDocumentProcessingSuccess(results));
  } catch (err) {
    yield put(updateDocumentProcessingError(err));
  }
}

export function* voidSignOnDocumentReq({ payload: { documentId, reason } }) {
  try {
    // Call our request helper (see 'utils/request')
    const companyId = Company.getIdByDomain();
    const result = yield call(
      request,
      `${process.env.SIGN_ON_API}/v1/sign-on/${documentId}/void?companyId=${companyId}`,
      {
        method: 'PUT',
        body: JSON.stringify({ reason }),
      },
    );

    yield put(voidSignOnDocumentSuccess(result));
  } catch (err) {
    yield put(voidSignOnDocumentError(err));
  }
}

export function* updateListingAndRelationsReq({ payload }) {
  try {
    const result = yield call(
      request,
      `${process.env.API_URL}/v1/visual-manager/reports/listing-and-relations`,
      {
        method: 'PUT',
        body: JSON.stringify(payload),
      },
    );

    yield put(updateListingAndRelationsSuccess(result));
  } catch (err) {
    yield put(updateListingAndRelationsError(err));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* vmData() {
  yield takeLatest(loadVMData.type, loadVMDataReq);
  yield takeLatest(loadContractKeyHistory.type, loadContractKeyHistoryReq);
  yield takeLatest(loadDocuments.type, loadDocumentsReq);
  yield takeLatest(updateDocumentProcessing.type, updateDocumentProcessingReq);
  yield takeLatest(voidSignOnDocument.type, voidSignOnDocumentReq);
  yield takeLatest(
    updateListingAndRelations.type,
    updateListingAndRelationsReq,
  );
}
