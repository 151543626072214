import React, { memo } from 'react';
import {
  Select,
  FormHelperText,
  MenuItem,
  OutlinedInput,
  InputLabel,
  FormControl,
} from '@mui/material';
import {
  node,
  object,
  string,
  bool,
  array,
  func,
  oneOfType,
  number,
} from 'prop-types';
import { styled } from '@mui/system';

const fontSize = '0.875rem';

const StyledSelect = styled(Select)(({ theme }) => ({
  height: theme.spacing(5),
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.common.white,
  }),
}));

const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'isGroup',
})(({ theme, isGroup }) => ({
  fontSize,
  ...(isGroup && {
    paddingLeft: theme.spacing(3),
    fontWeight: 'bold',
  }),
}));

const CustomSelect = ({
  id = '',
  placeholder = '',
  value = '',
  name = '',
  items = [],
  disabled = false,
  required = false,
  native = false,
  multiple = false,
  error = false,
  helperText = '',
  validation = null,
  startAdornment = undefined,
  onChange,
  onBlur,
}) => {
  const isError = validation?.hasError?.[name] || error;
  const detectedHelperText = validation?.help?.[name] || helperText;

  const outlinedInput = (
    <OutlinedInput
      required={required}
      name={name}
      label={placeholder}
      id={id || name}
      startAdornment={startAdornment}
    />
  );

  return (
    <FormControl fullWidth required={required} size="small" error={isError}>
      {!!placeholder && (
        <InputLabel id={`${id || name}-label`} htmlFor={id || name}>
          {placeholder}
        </InputLabel>
      )}
      <StyledSelect
        labelId={`${id || name}-label`}
        value={value || ''}
        name={name}
        native={native}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        input={outlinedInput}
        multiple={multiple}
      >
        {items.map((item) => {
          if (native) {
            if (item.isGroup) {
              const groupItems = item.options;
              return (
                <optgroup label={item.label} key={item.label}>
                  {groupItems?.map((gItem) => (
                    <option
                      key={`${item.label}-${gItem.label}`}
                      value={gItem.value}
                    >
                      {gItem.label}
                    </option>
                  ))}
                </optgroup>
              );
            }

            return (
              <option
                key={item.value}
                value={item.value}
                disabled={item.isGroup}
              >
                {item.label}
              </option>
            );
          }

          // TODO: optgroup support
          return (
            <StyledMenuItem
              key={item.value}
              value={item.value}
              disabled={item.isGroup}
              isGroup={item.isGroup}
            >
              {item.label}
            </StyledMenuItem>
          );
        })}
      </StyledSelect>
      {!!detectedHelperText && (
        <FormHelperText>{detectedHelperText}</FormHelperText>
      )}
    </FormControl>
  );
};

CustomSelect.propTypes = {
  startAdornment: node,
  validation: object,
  id: string,
  placeholder: string,
  value: oneOfType([string, number, array]),
  name: string,
  helperText: string,
  items: array,
  disabled: bool,
  multiple: bool,
  required: bool,
  native: bool,
  error: bool,
  onChange: func.isRequired,
  onBlur: func,
};

export default memo(CustomSelect);
