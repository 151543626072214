import entityType from './entityType';
import { constants as processAutomationType } from './processAutomationType';
import { constants as ReportTypes } from './reportType.enum';

const templateId = {
  DESCRIPTION_TEMPLATE_FOR_TENANT_TURNER_LOCKBOX_SYNC:
    '1f57b8a3-ad39-445b-9992-a08b3dc04c90',
  RENTAL_LISTING_SYNDICATION_DESCRIPTION:
    '0ee5ad61-c37c-43cb-8878-36ba6a045fe6',
  TRANSACTION_COORDINATOR_HANDOFF_EMAIL: '0ee5ad61-c37c-43cb-8878-36ba6a045123',
  SMS_APPOINTMENT_REMINDER: '0ee5ad61-c37c-43cb-8878-36ba6a045456',
};

export const emailTemplateVariableNames = {
  communication: {
    shared: {
      COMPANY_NAME: 'COMPANY_NAME',
      COMPANY_PHONE: 'COMPANY_PHONE',
      EMPLOYEE_NAME: 'EMPLOYEE_NAME',
      EMPLOYEE_PHONE: 'EMPLOYEE_PHONE',
      EMPLOYEE_EMAIL: 'EMPLOYEE_EMAIL',
      MARKET: 'MARKET',

      PM_NAME: 'PM_NAME',
      PM_PHONE: 'PM_PHONE',
      PM_EMAIL: 'PM_EMAIL',
      PM_ASSISTANT_EMAIL: 'PM_ASSISTANT_EMAIL',
    },
    [entityType.PROPERTIES]: {
      OWNER_NAMES: 'OWNER_NAMES',
      OWNER_STARTDATE: 'OWNER_STARTDATE',
      OWNER_ENDDATE: 'OWNER_ENDDATE',
      MAINTENANCE_LIMIT: 'MAINTENANCE_LIMIT',
      TENANT_NAMES: 'TENANT_NAMES',
      TENANT_STARTDATE: 'TENANT_STARTDATE',
      TENANT_ENDDATE: 'TENANT_ENDDATE',
      CURRENT_RENT_RATE: 'CURRENT_RENT_RATE',
      TENANT_NEW_ENDDATE: 'TENANT_NEW_ENDDATE',
      PUBLIC_ACCESS_CODE: 'PUBLIC_ACCESS_CODE',
      RENT_DIFF: 'RENT_DIFF',
      NEW_RENT: 'NEW_RENT',
      ADDENDUM_ID: 'ADDENDUM_ID',
      ADDENDUM_COMMENTS: 'ADDENDUM_COMMENTS',
      ADDENDUM_OFFER_MONTH_TO_MONTH: 'ADDENDUM_OFFER_MONTH_TO_MONTH',
      PROPERTY_ADDRESS: 'PROPERTY_ADDRESS',
      PROPERTY_STREET: 'PROPERTY_STREET',
      REPORT_ID: 'REPORT_ID',
      REPORT_URL: 'REPORT_URL',
      PROPERTY_DASHBOARD_URL: 'PROPERTY_DASHBOARD_URL',
      RENT_ESTIMATE: 'RENT_ESTIMATE',
      TARGET_RENT_RATE: 'TARGET_RENT_RATE',
      MARKETING_RENTAL_RATE: 'MARKETING_RENTAL_RATE',
      PM_PICTURE: 'PM_PICTURE',
      EMAIL_SIGNATURE: 'EMAIL_SIGNATURE',
    },
    [entityType.CONTRACTS]: {
      // For Owner Contract, Tenant Contract
      OWNER_CONTRACT_NUMBER: 'OWNER_CONTRACT_NUMBER',
      TENANT_CONTRACT_NUMBER: 'TENANT_CONTRACT_NUMBER',
      OWNER_NAMES: 'OWNER_NAMES',
      OWNER_STARTDATE: 'OWNER_STARTDATE',
      OWNER_ENDDATE: 'OWNER_ENDDATE',
      MAINTENANCE_LIMIT: 'MAINTENANCE_LIMIT',
      TENANT_NAMES: 'TENANT_NAMES',
      TENANT_STARTDATE: 'TENANT_STARTDATE',
      TENANT_ENDDATE: 'TENANT_ENDDATE',
      CURRENT_RENT_RATE: 'CURRENT_RENT_RATE',
      CURRENT_BASE_RENT: 'CURRENT_BASE_RENT',
      TENANT_NEW_ENDDATE: 'TENANT_NEW_ENDDATE',
      PUBLIC_ACCESS_CODE: 'PUBLIC_ACCESS_CODE',
      NEW_RENT: 'NEW_RENT',
      RENT_DIFF: 'RENT_DIFF',
      ADDENDUM_ID: 'ADDENDUM_ID',
      ADDENDUM_COMMENTS: 'ADDENDUM_COMMENTS',
      ADDENDUM_OFFER_MONTH_TO_MONTH: 'ADDENDUM_OFFER_MONTH_TO_MONTH',
      PROPERTY_ADDRESS: 'PROPERTY_ADDRESS',
      PROPERTY_STREET: 'PROPERTY_STREET',
      REPORT_ID: 'REPORT_ID',
      REPORT_URL: 'REPORT_URL',
      PROPERTY_DASHBOARD_URL: 'PROPERTY_DASHBOARD_URL',
      RENT_ESTIMATE: 'RENT_ESTIMATE',
      MARKETING_RENTAL_RATE: 'MARKETING_RENTAL_RATE',
      PM_PICTURE: 'PM_PICTURE',
      EMAIL_SIGNATURE: 'EMAIL_SIGNATURE',
      REVIEW_LEASE_RENEWAL_URL: 'REVIEW_LEASE_RENEWAL_URL',
    },
    [entityType.INSPECTIONS]: {
      OWNER_NAMES: 'OWNER_NAMES',
      OWNER_STARTDATE: 'OWNER_STARTDATE',
      OWNER_ENDDATE: 'OWNER_ENDDATE',
      MAINTENANCE_LIMIT: 'MAINTENANCE_LIMIT',
      INSPECTION_TYPE: 'INSPECTION_TYPE',
      INSPECTION_URL: 'INSPECTION_URL',
      PROPERTY_ADDRESS: 'PROPERTY_ADDRESS',
      PROPERTY_STREET: 'PROPERTY_STREET',
      REPORT_ID: 'REPORT_ID',
      REPORT_URL: 'REPORT_URL',
      PROPERTY_DASHBOARD_URL: 'PROPERTY_DASHBOARD_URL',
      CURRENT_RENT_RATE: 'CURRENT_RENT_RATE',
      RENT_ESTIMATE: 'RENT_ESTIMATE',
      MARKETING_RENTAL_RATE: 'MARKETING_RENTAL_RATE',
      PM_PICTURE: 'PM_PICTURE',
      EMAIL_SIGNATURE: 'EMAIL_SIGNATURE',
      DATE_SCHEDULE: 'DATE_SCHEDULE',
    },
    [entityType.WORK_ORDERS]: {
      OWNER_NAMES: 'OWNER_NAMES',
      OWNER_STARTDATE: 'OWNER_STARTDATE',
      OWNER_ENDDATE: 'OWNER_ENDDATE',
      MAINTENANCE_LIMIT: 'MAINTENANCE_LIMIT',
      TENANT_NAMES: 'TENANT_NAMES',
      TENANT_STARTDATE: 'TENANT_STARTDATE',
      TENANT_ENDDATE: 'TENANT_ENDDATE',
      CURRENT_RENT_RATE: 'CURRENT_RENT_RATE',
      TENANT_NEW_ENDDATE: 'TENANT_NEW_ENDDATE',
      PUBLIC_ACCESS_CODE: 'PUBLIC_ACCESS_CODE',
      NEW_RENT: 'NEW_RENT',
      RENT_DIFF: 'RENT_DIFF',
      ADDENDUM_ID: 'ADDENDUM_ID',
      ADDENDUM_COMMENTS: 'ADDENDUM_COMMENTS',
      ADDENDUM_OFFER_MONTH_TO_MONTH: 'ADDENDUM_OFFER_MONTH_TO_MONTH',
      WORKORDER_TYPE: 'WORKORDER_TYPE',
      WORKORDER_BUILDER_URL: 'WORKORDER_BUILDER_URL',
      WORKORDER_NUMBER: 'WORKORDER_NUMBER',
      WORKORDER_VIEW_URL: 'WORKORDER_VIEW_URL',
      WORKORDER_COORDINATOR_NAME: 'WORKORDER_COORDINATOR_NAME',
      WORKORDER_COORDINATOR_EMAIL: 'WORKORDER_COORDINATOR_EMAIL',
      WORKORDER_COORDINATOR_PHONE: 'WORKORDER_COORDINATOR_PHONE',
      PROPERTY_ADDRESS: 'PROPERTY_ADDRESS',
      PROPERTY_STREET: 'PROPERTY_STREET',
      REPORT_ID: 'REPORT_ID',
      REPORT_URL: 'REPORT_URL',
      PROPERTY_DASHBOARD_URL: 'PROPERTY_DASHBOARD_URL',
      RENT_ESTIMATE: 'RENT_ESTIMATE',
      MARKETING_RENTAL_RATE: 'MARKETING_RENTAL_RATE',
      PM_PICTURE: 'PM_PICTURE',
      EMAIL_SIGNATURE: 'EMAIL_SIGNATURE',
      WORKORDER_BALANCE: 'WORKORDER_BALANCE',
      WORKORDER_BALANCE_HALF: 'WORKORDER_BALANCE_HALF',
    },
    [entityType.OPPORTUNITIES]: {
      OWNER_NAMES: 'OWNER_NAMES',
      PM_PICTURE: 'PM_PICTURE',
      ONBOARDING_NAME: 'ONBOARDING_NAME',
      ONBOARDING_EMAIL: 'ONBOARDING_EMAIL',
      ESTIMATOR_NAME: 'ESTIMATOR_NAME',
      ESTIMATOR_EMAIL: 'ESTIMATOR_EMAIL',
      SALES_NAME: 'SALES_NAME',
      SALES_EMAIL: 'SALES_EMAIL',
      SALES_PHONE: 'SALES_PHONE',
      PROPERTY_OCCUPANCY_SUMMARY: 'PROPERTY_OCCUPANCY_SUMMARY',
      IS_ANY_OPPORTUNITY_PROPERTY_VACANT: 'IS_ANY_OPPORTUNITY_PROPERTY_VACANT',
      IS_ANY_OPPORTUNITY_PROPERTY_OCCUPIED:
        'IS_ANY_OPPORTUNITY_PROPERTY_OCCUPIED',
      EMAIL_SIGNATURE: 'EMAIL_SIGNATURE',
      MARKET: 'MARKET',
    },
    [entityType.LISTINGS]: {
      OWNER_NAMES: 'OWNER_NAMES',
      OWNER_STARTDATE: 'OWNER_STARTDATE',
      OWNER_ENDDATE: 'OWNER_ENDDATE',
      MAINTENANCE_LIMIT: 'MAINTENANCE_LIMIT',
      TENANT_NAMES: 'TENANT_NAMES',
      TENANT_STARTDATE: 'TENANT_STARTDATE',
      TENANT_ENDDATE: 'TENANT_ENDDATE',
      CURRENT_RENT_RATE: 'CURRENT_RENT_RATE',
      TENANT_NEW_ENDDATE: 'TENANT_NEW_ENDDATE',
      PUBLIC_ACCESS_CODE: 'PUBLIC_ACCESS_CODE',
      NEW_RENT: 'NEW_RENT',
      RENT_DIFF: 'RENT_DIFF',
      ADDENDUM_ID: 'ADDENDUM_ID',
      ADDENDUM_COMMENTS: 'ADDENDUM_COMMENTS',
      ADDENDUM_OFFER_MONTH_TO_MONTH: 'ADDENDUM_OFFER_MONTH_TO_MONTH',
      PROPERTY_ADDRESS: 'PROPERTY_ADDRESS',
      PROPERTY_STREET: 'PROPERTY_STREET',
      REPORT_ID: 'REPORT_ID',
      REPORT_URL: 'REPORT_URL',
      PROPERTY_DASHBOARD_URL: 'PROPERTY_DASHBOARD_URL',
      RENT_ESTIMATE: 'RENT_ESTIMATE',
      MARKETING_RENTAL_RATE: 'MARKETING_RENTAL_RATE',
      PM_PICTURE: 'PM_PICTURE',
      EMAIL_SIGNATURE: 'EMAIL_SIGNATURE',
      APPLICATION_URL: 'APPLICATION_URL',
    },
    [entityType.JOBS]: {
      OWNER_NAMES: 'OWNER_NAMES',
      OWNER_STARTDATE: 'OWNER_STARTDATE',
      OWNER_ENDDATE: 'OWNER_ENDDATE',
      MAINTENANCE_LIMIT: 'MAINTENANCE_LIMIT',
      INSPECTION_TYPE: 'INSPECTION_TYPE',
      INSPECTION_URL: 'INSPECTION_URL',
      PROPERTY_ADDRESS: 'PROPERTY_ADDRESS',
      PROPERTY_STREET: 'PROPERTY_STREET',
      REPORT_ID: 'REPORT_ID',
      REPORT_URL: 'REPORT_URL',
      PROPERTY_DASHBOARD_URL: 'PROPERTY_DASHBOARD_URL',
      CURRENT_RENT_RATE: 'CURRENT_RENT_RATE',
      RENT_ESTIMATE: 'RENT_ESTIMATE',
      MARKETING_RENTAL_RATE: 'MARKETING_RENTAL_RATE',
      PM_PICTURE: 'PM_PICTURE',
      EMAIL_SIGNATURE: 'EMAIL_SIGNATURE',
      VENDOR_NAME: 'VENDOR_NAME',
      JOB_PORTAL_URL: 'JOB_PORTAL_URL',
      JOB_NUMBER: 'JOB_NUMBER',
    },
    [entityType.INVOICES]: {
      TENANT_NAMES: 'TENANT_NAMES',
      TENANT_STARTDATE: 'TENANT_STARTDATE',
      TENANT_ENDDATE: 'TENANT_ENDDATE',
      CURRENT_RENT_RATE: 'CURRENT_RENT_RATE',
      TENANT_NEW_ENDDATE: 'TENANT_NEW_ENDDATE',
      PUBLIC_ACCESS_CODE: 'PUBLIC_ACCESS_CODE',
      NEW_RENT: 'NEW_RENT',
      RENT_DIFF: 'RENT_DIFF',
      ADDENDUM_ID: 'ADDENDUM_ID',
      ADDENDUM_COMMENTS: 'ADDENDUM_COMMENTS',
      ADDENDUM_OFFER_MONTH_TO_MONTH: 'ADDENDUM_OFFER_MONTH_TO_MONTH',
      WORKORDER_TYPE: 'WORKORDER_TYPE',
      WORKORDER_BUILDER_URL: 'WORKORDER_BUILDER_URL',
      WORKORDER_NUMBER: 'WORKORDER_NUMBER',
      WORKORDER_VIEW_URL: 'WORKORDER_VIEW_URL',
      WORKORDER_COORDINATOR_NAME: 'WORKORDER_COORDINATOR_NAME',
      WORKORDER_COORDINATOR_EMAIL: 'WORKORDER_COORDINATOR_EMAIL',
      WORKORDER_COORDINATOR_PHONE: 'WORKORDER_COORDINATOR_PHONE',
      PROPERTY_ADDRESS: 'PROPERTY_ADDRESS',
      PROPERTY_STREET: 'PROPERTY_STREET',
      REPORT_ID: 'REPORT_ID',
      REPORT_URL: 'REPORT_URL',
      PROPERTY_DASHBOARD_URL: 'PROPERTY_DASHBOARD_URL',
      RENT_ESTIMATE: 'RENT_ESTIMATE',
      MARKETING_RENTAL_RATE: 'MARKETING_RENTAL_RATE',
      PM_PICTURE: 'PM_PICTURE',
      EMAIL_SIGNATURE: 'EMAIL_SIGNATURE',
      INVOICE_NUMBER: 'INVOICE_NUMBER',
      INVOICE_PORTAL_URL: 'INVOICE_PORTAL_URL',
      INVOICE_BALANCE_DUE: 'INVOICE_BALANCE_DUE',
      WORK_ORDER_NUMBER: 'WORK_ORDER_NUMBER',
      WORK_ORDER_BUILDER_URL: 'WORK_ORDER_BUILDER_URL',
      WORK_ORDER_VIEWER_URL: 'WORK_ORDER_VIEWER_URL',
    },
    [entityType.LOCKERS]: {
      TENANT_NAMES: 'TENANT_NAMES',
      TENANT_STARTDATE: 'TENANT_STARTDATE',
      TENANT_ENDDATE: 'TENANT_ENDDATE',
      CURRENT_RENT_RATE: 'CURRENT_RENT_RATE',
      TENANT_NEW_ENDDATE: 'TENANT_NEW_ENDDATE',
      PUBLIC_ACCESS_CODE: 'PUBLIC_ACCESS_CODE',
      NEW_RENT: 'NEW_RENT',
      RENT_DIFF: 'RENT_DIFF',
      ADDENDUM_ID: 'ADDENDUM_ID',
      ADDENDUM_COMMENTS: 'ADDENDUM_COMMENTS',
      ADDENDUM_OFFER_MONTH_TO_MONTH: 'ADDENDUM_OFFER_MONTH_TO_MONTH',
      DATE_EXPECTED_RETRIEVAL: 'DATE_EXPECTED_RETRIEVAL',
      CONFIRMATION_CODE: 'CONFIRMATION_CODE',
      CONTENTS_IMAGE_LINK: 'CONTENTS_IMAGE_LINK',
      PUBLIC_DESCRIPTION: 'PUBLIC_DESCRIPTION',
      DATE_CONTRACT_START: 'DATE_CONTRACT_START',
      DATE_MOVE_IN: 'DATE_MOVE_IN',
    },
    [entityType.APPOINTMENTS]: {
      DATE_SCHEDULE: 'DATE_SCHEDULE',
      INSPECTION_TYPE: 'INSPECTION_TYPE',
      INSPECTION_URL: 'INSPECTION_URL',
    },
  },
  'process-automation': {
    shared: {
      // company
      COMPANY_LOGO: 'COMPANY_LOGO',
      COMPANY_NAME: 'COMPANY_NAME',
      COMPANY_PHONE: 'COMPANY_PHONE',
      COMPANY_EMAIL: 'COMPANY_EMAIL',
      COMPANY_ADDRESS: 'COMPANY_ADDRESS',
      COMPANY_WEBSITE: 'COMPANY_WEBSITE',
      EMPLOYEE_NAME: 'EMPLOYEE_NAME',
      EMPLOYEE_EMAIL: 'EMPLOYEE_EMAIL',
      MARKET_NAME: 'MARKET_NAME',
      // property
      PROPERTY_ADDRESS: 'PROPERTY_ADDRESS',
      PROPERTY_STREET: 'PROPERTY_STREET',
      PROPERTY_MANAGER: 'PROPERTY_MANAGER',
      PROPERTY_DASHBOARD_URL: 'PROPERTY_DASHBOARD_URL',
      PM_ASSISTANT_EMAIL: 'PM_ASSISTANT_EMAIL',
      PM_EMAIL: 'PM_EMAIL',
      // tenants
      TENANT_NAMES: 'TENANT_NAMES',
      TENANT_1_NAME: 'TENANT_1_NAME',
      TENANT_2_NAME: 'TENANT_2_NAME',
      TENANT_3_NAME: 'TENANT_3_NAME',
      // owners
      OWNER_NAMES: 'OWNER_NAMES',
      OWNER_1_NAME: 'OWNER_1_NAME',
      OWNER_2_NAME: 'OWNER_2_NAME',
      OWNER_3_NAME: 'OWNER_3_NAME',
      EMAIL_SIGNATURE: 'EMAIL_SIGNATURE',
    },
    [processAutomationType.VACATE_NOTICE_10_DAY]: {
      PREPARATION_FEE: 'PREPARATION_FEE',
      DATE_NOTICE: 'DATE_NOTICE',
      ATTENTION: 'ATTENTION',
      TOTAL_UNPAID: 'TOTAL_UNPAID',
      LATE_FEE_RULE: 'LATE_FEE_RULE',
      LATE_FEE: 'LATE_FEE',
      EXPLANATION: 'EXPLANATION',
      TENANCY_TERMINATION_DATE: 'TENANCY_TERMINATION_DATE',
    },
    [processAutomationType.VACATE_NOTICE_30_DAY]: {
      DATE_NOTICE: 'DATE_NOTICE',
      ATTENTION: 'ATTENTION',
      TOTAL_UNPAID: 'TOTAL_UNPAID',
      LATE_FEE_RULE: 'LATE_FEE_RULE',
      LATE_FEE: 'LATE_FEE',
      EXPLANATION: 'EXPLANATION',
      TENANCY_TERMINATION_DATE: 'TENANCY_TERMINATION_DATE',
    },
    [processAutomationType.SCHEDULE_INSPECTION]: {
      DATE_NOTICE: 'DATE_NOTICE',
      ATTENTION: 'ATTENTION',
      DATE_SCHEDULE: 'DATE_SCHEDULE',
      TIME_START: 'TIME_START',
      TIME_END: 'TIME_END',
      INSPECTOR: 'INSPECTOR',
      INSPECTION_TYPE: 'INSPECTION_TYPE',
      INSPECTION_NUMBER: 'INSPECTION_NUMBER',
    },
    [processAutomationType.HOA_VIOLATION]: {
      DATE_NOTICE: 'DATE_NOTICE',
      ATTENTION: 'ATTENTION',
      VIOLATION_EXPLANATION: 'VIOLATION_EXPLANATION',
      PREPARATION_FEE: 'PREPARATION_FEE',
      VIOLATION_FINE: 'VIOLATION_FINE',
    },
    [processAutomationType.ACCESS_NOTICE]: {
      DATE_NOTICE: 'DATE_NOTICE',
      ATTENTION: 'ATTENTION',
      DATE_ACCESS: 'DATE_ACCESS',
      TIME_START: 'TIME_START',
      TIME_END: 'TIME_END',
      ACCESS_PURPOSE: 'ACCESS_PURPOSE',
    },
    [processAutomationType.RENT_CHANGE_NOTICE]: {
      DATE_NOTICE: 'DATE_NOTICE',
      ATTENTION: 'ATTENTION',
      CURRENT_RENT_RATE: 'CURRENT_RENT_RATE',
      NEW_RENT_RATE: 'NEW_RENT_RATE',
      DATE_EFFECTIVE: 'DATE_EFFECTIVE',
      INCREASE_DETAILS: 'INCREASE_DETAILS',
      DAYS_NOTICE: 'DAYS_NOTICE',
    },
    [processAutomationType.MANAGEMENT_CHANGE]: {
      DATE_EFFECTIVE: 'DATE_EFFECTIVE',
      OFFICIAL_PORTFOLIO_NAME: 'OFFICIAL_PORTFOLIO_NAME',
    },
    [processAutomationType.MISCELLANEOUS]: {
      DATE_NOTICE: 'DATE_NOTICE',
      ATTENTION: 'ATTENTION',
      MESSAGE: 'MESSAGE',
    },
    [processAutomationType.NOTICE_OF_HOLDOVER]: {
      DATE_NOTICE: 'DATE_NOTICE',
      ATTENTION: 'ATTENTION',
      MESSAGE: 'MESSAGE',
    },
    [processAutomationType.CREATE_WORK_ORDER]: {
      JOB_PORTAL_URL: 'JOB_PORTAL_URL',
      VENDOR_NAME: 'VENDOR_NAME',
    },
    [processAutomationType.CREATE_INSPECTION]: {
      DATE_READY_TO_INSPECT: 'DATE_READY_TO_INSPECT',
      NOTES: 'NOTES',
      INSPECTION_TYPE: 'INSPECTION_TYPE',
    },
    [processAutomationType.CREATE_INSPECTION_NEW_CUSTOMER]: {
      DATE_READY_TO_INSPECT: 'DATE_READY_TO_INSPECT',
      NOTES: 'NOTES',
      INSPECTION_TYPE: 'INSPECTION_TYPE',
    },
    [processAutomationType.CONTRACT_TERMINATION]: {
      TENANT_MOVEOUT_DATE: 'TENANT_MOVEOUT_DATE',
      MANAGEMENT_TERMINATION_DATE: 'MANAGEMENT_TERMINATION_DATE',
      CONTRACT_TYPE: 'CONTRACT_TYPE',
      RESIDENT_BENEFITS_PACKAGE_PRICE: 'RESIDENT_BENEFITS_PACKAGE_PRICE',
      ACTIVE_TENANT_CONTRACT_ID: 'ACTIVE_TENANT_CONTRACT_ID',
      RESIDENT_BENEFITS_PACKAGE: 'RESIDENT_BENEFITS_PACKAGE',
      SECURITY_DEPOSIT_TYPE: 'SECURITY_DEPOSIT_TYPE',
      SECURITY_DEPOSIT_ALTERNATIVE_RATE_MONTH:
        'SECURITY_DEPOSIT_ALTERNATIVE_RATE_MONTH',
    },
    [processAutomationType.CONTRACT_CANCELLATION]: {
      TENANT_MOVEOUT_DATE: 'TENANT_MOVEOUT_DATE',
      MANAGEMENT_TERMINATION_DATE: 'MANAGEMENT_TERMINATION_DATE',
      CONTRACT_TYPE: 'CONTRACT_TYPE',
      RESIDENT_BENEFITS_PACKAGE_PRICE: 'RESIDENT_BENEFITS_PACKAGE_PRICE',
      ACTIVE_TENANT_CONTRACT_ID: 'ACTIVE_TENANT_CONTRACT_ID',
      RESIDENT_BENEFITS_PACKAGE: 'RESIDENT_BENEFITS_PACKAGE',
      SECURITY_DEPOSIT_TYPE: 'SECURITY_DEPOSIT_TYPE',
      SECURITY_DEPOSIT_ALTERNATIVE_RATE_MONTH:
        'SECURITY_DEPOSIT_ALTERNATIVE_RATE_MONTH',
    },
    [processAutomationType.KEY_CHECK_OUT]: {
      KEYS_GIVEN_HOUSE: 'KEYS_GIVEN_HOUSE',
      KEYS_GIVEN_MAILBOX: 'KEYS_GIVEN_MAILBOX',
      REMOTES_GIVEN_GARAGE: 'REMOTES_GIVEN_GARAGE',
      KEYS_GIVEN_GATE: 'KEYS_GIVEN_GATE',
      REMOTES_GIVEN_GATE: 'REMOTES_GIVEN_GATE',
      KEYS_GIVEN_POOL: 'KEYS_GIVEN_POOL',
      KEYS_GIVEN_LAUNDRY: 'KEYS_GIVEN_LAUNDRY',
      KEY_NOTES: 'KEY_NOTES',
      TENANT_STARTDATE: 'TENANT_STARTDATE',
      LOCKBOX_LOCATION: 'LOCKBOX_LOCATION',
      LOCKBOX_CODE: 'LOCKBOX_CODE',
      KEYS_GIVEN_PICTURE: 'KEYS_GIVEN_PICTURE',
      DATE_CREATED: 'DATE_CREATED',
      PROPERTY_MANAGER_DIRECT_PHONE: 'PROPERTY_MANAGER_DIRECT_PHONE',
      SIGNATURE_PICTURE: 'SIGNATURE_PICTURE',
    },
    [processAutomationType.KEY_CHECK_IN]: {
      KEYS_GIVEN_HOUSE: 'KEYS_GIVEN_HOUSE',
      KEYS_GIVEN_MAILBOX: 'KEYS_GIVEN_MAILBOX',
      REMOTES_GIVEN_GARAGE: 'REMOTES_GIVEN_GARAGE',
      KEYS_GIVEN_GATE: 'KEYS_GIVEN_GATE',
      REMOTES_GIVEN_GATE: 'REMOTES_GIVEN_GATE',
      KEYS_GIVEN_POOL: 'KEYS_GIVEN_POOL',
      KEYS_GIVEN_LAUNDRY: 'KEYS_GIVEN_LAUNDRY',
      KEYS_RETURNED_HOUSE: 'KEYS_RETURNED_HOUSE',
      KEYS_RETURNED_MAILBOX: 'KEYS_RETURNED_MAILBOX',
      REMOTES_RETURNED_GARAGE: 'REMOTES_RETURNED_GARAGE',
      KEYS_RETURNED_GATE: 'KEYS_RETURNED_GATE',
      REMOTES_RETURNED_GATE: 'REMOTES_RETURNED_GATE',
      KEYS_RETURNED_POOL: 'KEYS_RETURNED_POOL',
      KEYS_RETURNED_LAUNDRY: 'KEYS_RETURNED_LAUNDRY',
      INSPECTION_SCHEDULE_DATETIME: 'INSPECTION_SCHEDULE_DATETIME',
      KEYS_RECEIVED_PICTURE: 'KEYS_RECEIVED_PICTURE',
      DATE_CREATED: 'DATE_CREATED',
      PROPERTY_MANAGER_DIRECT_PHONE: 'PROPERTY_MANAGER_DIRECT_PHONE',
      KEY_NOTES: 'KEY_NOTES',
    },
    [processAutomationType.TENANT_DISPOSITION]: {
      DISPOSITION_COMMENTS: 'DISPOSITION_COMMENTS',
      BALANCE: 'BALANCE',
      BALANCE_ABS: 'BALANCE_ABS',
      OFFICIAL_PORTFOLIO_NAME: 'OFFICIAL_PORTFOLIO_NAME',
      SECURITY_DEPOSIT_TYPE: 'SECURITY_DEPOSIT_TYPE',
      LINE_ITEMS: 'LINE_ITEMS',
      LEASE_NUMBER: 'LEASE_NUMBER',
      TOTAL_CREDITS: 'TOTAL_CREDITS',
      TOTAL_CHARGES: 'TOTAL_CHARGES',
      LEASE_DATE_START: 'LEASE_DATE_START',
      LEASE_DATE_END: 'LEASE_DATE_END',
      DATE_PREPARED: 'DATE_PREPARED',
      TENANTS_STREET_ADDRESS: 'STREET_ADDRESS',
      TENANTS_BUILDING: 'BUILDING',
      TENANTS_CITY: 'CITY',
      TENANTS_STATE: 'STATE',
      TENANTS_ZIP: 'ZIP',
      TENANTS_COUNTRY: 'COUNTRY',
      ATTACHED_WORK_ORDERS: 'ATTACHED_WORK_ORDERS',
      IS_VISIBLE_IMAGES: 'IS_VISIBLE_IMAGES',
    },
    [processAutomationType.LOCKER_RESERVATION]: {
      CONTACT_NAMES: 'CONTACT_NAMES',
      LOCKBOX_CODE: 'LOCKBOX_CODE',
    },
    [processAutomationType.MONTH_TO_MONTH]: {
      TENANT_ENDDATE: 'TENANT_ENDDATE',
      TENANT_ENDDATE_ADD1DAY: 'TENANT_ENDDATE_ADD1DAY',
      PM_NAME: 'PM_NAME',
      PM_PHONE: 'PM_PHONE',
      MONTH_TO_MONTH_INCREASE: 'MONTH_TO_MONTH_INCREASE',
      DATE_NOTICE: 'DATE_NOTICE',
    },
    [processAutomationType.FIVE_DAY_NOTICE]: {
      DATE_NOTICE: 'DATE_NOTICE',
      ATTENTION: 'ATTENTION',
      TOTAL_UNPAID: 'TOTAL_UNPAID',
      LATE_FEE_RULE: 'LATE_FEE_RULE',
      LATE_FEE: 'LATE_FEE',
      CERTIFIED_MAIL_CODE: 'CERTIFIED_MAIL_CODE',
      LEASE_START_DATE: 'LEASE_START_DATE',
      FIVE_DAYS_AFTER_NOTICE_DATE: 'FIVE_DAYS_AFTER_NOTICE_DATE',
      TOTAL_UNPAID_WITH_LATE_FEE: 'TOTAL_UNPAID_WITH_LATE_FEE',
    },
    [processAutomationType.KEY_RETURN_MOVEOUT]: {
      DATE_NOTICE: 'DATE_NOTICE',
      ATTENTION: 'ATTENTION',
    },
    [processAutomationType.CONTRACT_CANCELLATION]: {
      INSPECTION_UPDATES: 'INSPECTION_UPDATES',
    },
    [processAutomationType.PROPERTY_MANAGER_CHANGE]: {
      TENANT_EMAILS: 'TENANT_EMAILS',
      OWNER_EMAILS: 'OWNER_EMAILS',
      WINNING_PM_HEADSHOT: 'WINNING_PM_HEADSHOT',
      TODAY: 'TODAY',
      WINNING_PM_NAME: 'WINNING_PM_NAME',
      WINNING_PM_BIO: 'WINNING_PM_BIO',
      WINNING_PM_EMAIL: 'WINNING_PM_EMAIL',
      WINNING_PM_PHONE: 'WINNING_PM_PHONE',
      LOSING_PM_NAME: 'LOSING_PM_NAME',
      LOSING_PM_EMAIL: 'LOSING_PM_EMAIL',
      LOSING_PM_PHONE: 'LOSING_PM_PHONE',
    },
    [processAutomationType.NEW_LEASE]: {
      MONTHLY_RENT_RATE_WITH_ADMIN_FEE: 'MONTHLY_RENT_RATE_WITH_ADMIN_FEE',
      CONTRACT_TERM: 'CONTRACT_TERM',
      RENT_BREAKDOWN: 'RENT_BREAKDOWN',
      DATE_START: 'DATE_START',
      SECURITY_DEPOSIT_ALTERNATIVE_RATE_MONTH:
        'SECURITY_DEPOSIT_ALTERNATIVE_RATE_MONTH',
      COMBINED_UTILITIES: 'COMBINED_UTILITIES',
      SECURITY_DEPOSIT_ALTERNATIVE_HOLDING_RETAINER_BREAKDOWN:
        'SECURITY_DEPOSIT_ALTERNATIVE_HOLDING_RETAINER_BREAKDOWN',
      SECURITY_DEPOSIT_ALTERNATIVE_HOLDING_RETAINER:
        'SECURITY_DEPOSIT_ALTERNATIVE_HOLDING_RETAINER',
      SECURITY_DEPOSIT_ALTERNATIVE_TOTAL_DUE_UPON_MOVE_IN_BREAKDOWN:
        'SECURITY_DEPOSIT_ALTERNATIVE_TOTAL_DUE_UPON_MOVE_IN_BREAKDOWN',
      SECURITY_DEPOSIT_ALTERNATIVE_TOTAL_DUE_UPON_MOVE_IN:
        'SECURITY_DEPOSIT_ALTERNATIVE_TOTAL_DUE_UPON_MOVE_IN',
      TRADITIONAL_DEPOSIT_BREAKDOWN: 'TRADITIONAL_DEPOSIT_BREAKDOWN',
      TRADITIONAL_DEPOSIT_TOTAL_DUE_UPON_MOVE_IN_BREAKDOWN:
        'TRADITIONAL_DEPOSIT_TOTAL_DUE_UPON_MOVE_IN_BREAKDOWN',
      TRADITIONAL_DEPOSIT_TOTAL_DUE_UPON_MOVE_IN:
        'TRADITIONAL_DEPOSIT_TOTAL_DUE_UPON_MOVE_IN',
    },
  },
  report: {
    [ReportTypes.RENTAL_LISTING_SYNDICATION]: {
      [templateId.DESCRIPTION_TEMPLATE_FOR_TENANT_TURNER_LOCKBOX_SYNC]: {
        lockbox: 'lockbox',
      },
      [templateId.RENTAL_LISTING_SYNDICATION_DESCRIPTION]: {
        applicationLink: 'applicationLink',
        marketingDescription: 'marketingDescription',
        petPolicy: 'petPolicy',
        petsAllowed: 'petsAllowed',
        petFee: 'petFee',
        petDeposit: 'petDeposit',
        petRent: 'petRent',
        matterportLink: 'matterportLink',
        depositAlternativeAvailable: 'depositAlternativeAvailable',
      },
    },
  },
  system: {
    [ReportTypes.SIMPLE_SIGNUP]: {
      [templateId.TRANSACTION_COORDINATOR_HANDOFF_EMAIL]: {
        COMPANY_NAME: 'COMPANY_NAME',
        PM_NAME: 'PM_NAME',
        SALES_NAME: 'SALES_NAME',
        SALES_EMAIL: 'SALES_EMAIL',
        SALES_PHONE: 'SALES_PHONE',
        ONBOARDING_NAME: 'ONBOARDING_NAME',
        QUESTIONNAIRE_URL: 'QUESTIONNAIRE_URL',
        CALENDLY_SCHEDULE_LINK: 'CALENDLY_SCHEDULE_LINK',
        CALENDLY_RESCHEDULE_LINK: 'CALENDLY_RESCHEDULE_LINK',
        CALENDLY_APPOINTMENT_TIME: 'CALENDLY_APPOINTMENT_TIME',
      },
    },
    jobs: {
      [templateId.SMS_APPOINTMENT_REMINDER]: {
        VENDOR_NAME: 'VENDOR_NAME',
        TIME_SCHEDULED: 'TIME_SCHEDULED',
        TECHNICIAN_NAME: 'TECHNICIAN_NAME',
        COORDINATOR_PHONE_OR_EMAIL: 'COORDINATOR_PHONE_OR_EMAIL',
      },
    },
  },
  'sign-on': {
    shared: {
      COMPANY_NAME: 'COMPANY_NAME',
      COMPANY_LOGO: 'COMPANY_LOGO',
      DOCUMENT_NAME: 'DOCUMENT_NAME',

      // Doesn't exist in the Envelope Updated template
      RECIPIENT_NAME: 'RECIPIENT_NAME',
      RECIPIENT_EMAIL: 'RECIPIENT_EMAIL',
      RECIPIENT_DOCUMENT_URL: 'RECIPIENT_DOCUMENT_URL',

      SENDER_NAME: 'SENDER_NAME',
      SENDER_EMAIL: 'SENDER_EMAIL',
      SENDER_DOCUMENT_URL: 'SENDER_DOCUMENT_URL',

      DOCUMENT_AUTOMATION_TYPE: 'DOCUMENT_AUTOMATION_TYPE',
    },
    senders: {
      // A Recipient Declined To Sign
      '39f9a191-058d-4ee5-b858-c0415f35a380': {
        DECLINED_BY_NAME: 'DECLINED_BY_NAME',
        DECLINE_VOID_REASON: 'DECLINE_VOID_REASON',
      },
      // A Sender Voided an Envelope
      'ae82bc78-6050-4574-bfcf-9f091783c4cb': {
        DECLINE_VOID_REASON: 'DECLINE_VOID_REASON',
      },
      // Envelope Delivery Failed
      '42856063-d170-4943-b556-4e883db431a2': {
        FAILED_REASON: 'FAILED_REASON',
      },
      // Envelope Updated
      'dfb3f79b-28c0-42ba-ba08-028988edece2': {
        EDITOR_NAME: 'EDITOR_NAME',
        EDITOR_EMAIL: 'EDITOR_NAME',
      },
    },
    recipients: {
      // A Recipient Declined To Sign
      '0c9183a7-e761-4236-b972-5ed347152496': {
        DECLINED_BY_NAME: 'DECLINED_BY_NAME',
        DECLINE_VOID_REASON: 'DECLINE_VOID_REASON',
      },
      // A Sender Voided an Envelope
      '7b3197d1-b9d3-4d40-be82-6518bdcc06b7': {
        DECLINE_VOID_REASON: 'DECLINE_VOID_REASON',
      },
    },
  },
};
