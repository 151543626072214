/* eslint-disable camelcase */
import { makeStringArray } from 'helpers/general';

export const EMPLOYEE = 'Employee';
export const CUSTOMER = 'Customer';
export const PHONE_SUPPORT = 'Phone Support';
export const VENDOR = 'Vendor';

export default class {
  static detectRole = (user) => {
    if (user?.Employee__c) {
      return EMPLOYEE;
    }

    const permissionSet = user?.Permission_Set__r;
    if (permissionSet && permissionSet?.Id === 'a081K00000xun8ZQAQ') {
      // if it is special user with permission set 'Phone Support'
      // These are remote employees hired to manage inbound emergency phone calls
      // after hours or when we are too busy to answer the phone.
      return PHONE_SUPPORT;
    }

    const isAnyVendorAccount = (
      user.AccountContactRelations?.records || []
    ).some((rt) => rt.Account?.RecordType?.Name === 'Vendor');

    if (isAnyVendorAccount) {
      return VENDOR;
    }

    return CUSTOMER;
  };

  // Expected:
  // "Id": "a081K00000nodIVQAY",
  // "Inspection_Daily_Report__c": true,
  // "Process_Automation__c": "Property Manager Notices;Create Inspection;Schedule Inspection;Create Work Order;Create Direct Work Order",
  // "Locker_Manager__c": true,
  // "Dispatch_Manager__c": true,
  // "Dispatch_All_Jobs__c": true,
  // "Dispatch_Technician__c": true,
  // "Dispatch_Superintendent__c": true,
  // "Dispatch_Access__c": "Work Orders;Inspections"
  // ...

  static convertPermissions = (permissionsObject) => {
    if (!permissionsObject) {
      return [];
    }
    const permissions = Object.keys(permissionsObject).reduce((memo, key) => {
      const value = permissionsObject[key];
      if (value && typeof value === 'boolean') {
        memo.push(key); // ['Inspection_Daily_Report__c']
      } else if (value && typeof value === 'string' && key !== 'Id') {
        const arrPermissions = makeStringArray(value, ';');
        memo = memo
          .concat(key) // ['Process_Automation__c']
          .concat(arrPermissions.map((perm) => `${key}:${perm}`)); // ['Process_Automation__c:Property Manager Notices']
      }
      return memo;
    }, []);

    return permissions; // ['Inspection_Daily_Report__c', 'Process_Automation__c:Property Manager Notices']
  };

  static getDefaultRedirectTo = ({ redirectTo, user }) => {
    // redirect user to page where he was
    if (redirectTo) {
      return redirectTo;
    }

    if (!user) {
      return '/login';
    }

    if (EMPLOYEE === user.role) {
      return user.homePage || '/work-orders';
    }

    // special employee
    if (PHONE_SUPPORT === user.role) {
      return (
        user.homePage || '/process-automation?automationType=createWorkOrder'
      );
    }

    if (VENDOR === user.role) {
      return user.homePage || `/job-dashboard/${user.contactAccountIds?.[0]}`;
    }

    // CUSTOMER
    return '/portfolio';
  };
}
