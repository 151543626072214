/* eslint-disable camelcase */
import localforage from 'localforage';
import CompanyId from 'enums/companyId';
import { buildS3TeamImageUrl } from 'helpers/image';
import history from 'utils/history';
import Company from './company';
import UserRoles, { EMPLOYEE } from './userRoles';

const domainCompanyId = Company.getIdByDomain();

const checkUserAndRedirect = ({ user, isThrowError = false }) => {
  if (user.isEmployee && process.env.ENV !== 'development') {
    const onqAccountId = Company.getAccountIdByCompanyId(CompanyId.ONQ);
    const isOnqUser = user.contactAccountIds.includes(onqAccountId);

    const mammothAccountId = Company.getAccountIdByCompanyId(CompanyId.MAMMOTH);
    const isMammothUser = user.contactAccountIds.includes(mammothAccountId);

    if (domainCompanyId === CompanyId.MAMMOTH) {
      // if mammoth domain
      if (isOnqUser && !isMammothUser) {
        // check if user is onq
        window.location.replace(
          window.location.href.replace(
            process.env.MAMMOTH_DOMAIN,
            process.env.ONQ_DOMAIN,
          ),
        );

        if (isThrowError) {
          throw new Error(
            'Detected On Q User on the Mammoth domain. Redirecting...',
          );
        }

        return false;
      }
    } else if (isMammothUser && !isOnqUser) {
      // check if user is mammoth
      window.location.replace(
        window.location.href.replace(
          process.env.ONQ_DOMAIN,
          process.env.MAMMOTH_DOMAIN,
        ),
      );

      if (isThrowError) {
        throw new Error(
          'Detected Mammoth User on the On Q domain. Redirecting...',
        );
      }

      return false;
    }
  }

  return true;
};

const auth = {
  isAuthenticated(roles = [], permissions = []) {
    const token = this.getToken();
    const user = this.getUser();
    if (!user || !token) {
      return false;
    }

    const haveRoles = roles && Array.isArray(roles) && roles.length > 0;
    const havePermissions =
      permissions && Array.isArray(permissions) && permissions.length > 0;

    if (!haveRoles && !havePermissions) {
      return true;
    }

    if (haveRoles && !roles.includes(user.role)) {
      return false;
    }

    if (havePermissions) {
      const isAuthenticated = permissions.some((permission) =>
        user.permissions?.includes(permission),
      );

      if (!isAuthenticated) {
        return false;
      }
    }

    // check domain and redirect user if needed
    return checkUserAndRedirect({ user });
  },

  isHavePermission(permission) {
    const user = this.getUser();
    if (user) {
      if (Array.isArray(permission)) {
        return permission.some((p) => user.permissions?.includes(p));
      }
      return user.permissions?.includes(permission);
    }
    return false;
  },

  isEditAllowed() {
    return this.isAuthenticated([EMPLOYEE]);
  },

  isWorkOrderMatchesUserCompany(recordTypeId) {
    if (!recordTypeId) {
      return false;
    }
    // get the account id from the company records we have in code
    const accountId = Company.getAccountIdByWorkOrderRecordType(recordTypeId);
    if (!accountId) {
      return false;
    }
    // get user object
    const user = this.getUser();
    if (!user) {
      return false;
    }
    // match user account ids to the company account id
    return (user.contactAccountIds || []).includes(accountId);
  },

  isEmployeeCanEditWorkOrder(recordTypeId) {
    if (!recordTypeId) {
      return false;
    }
    const isMammothWorkOrder = recordTypeId === '01241000001EG5GAAW'; // Mammoth Services
    if (isMammothWorkOrder) {
      return this.isHavePermission('Edit_Work_Orders__c:Mammoth Full');
    }
    return this.isHavePermission('Edit_Work_Orders__c:OnQ Full');
  },

  isEmployeeOf(companyId) {
    if (!companyId) {
      return false;
    }
    // get the account id from the company records we have in code
    const accountId = Company.getAccountIdByCompanyId(companyId);
    if (!accountId) {
      return false;
    }
    // get user object
    const user = this.getUser();
    if (!user) {
      return false;
    }

    // match user account ids to the company account id
    return (user.contactAccountIds || []).includes(accountId);
  },

  getSuperintendentFor() {
    const user = this.getUser();
    return user?.superintendentFor || '';
  },

  authenticate({ user, token, expiresIn, redirectTo }) {
    const userData = user;

    const companyId = Company.convertNameToId(
      userData?.Cloud_Portal_Company__c,
    );

    const userRole = UserRoles.detectRole(userData);

    // collect the account ids to detect managing company relation
    const contactAccountIds = (
      userData?.AccountContactRelations?.records || []
    ).map((r) => r.AccountId);

    const toSave = {
      id: userData?.Id,
      employeeId: userData?.Employee__c, // Used for Employees only that has a User record in SF
      isEmployee: !!userData?.Employee__c,
      firstName: userData?.FirstName,
      lastName: userData?.LastName,
      name: userData?.Name,
      role: userRole,
      email: userData?.Email,
      phone: userData?.Phone,
      mobilePhone: userData?.MobilePhone,
      title: userData?.Title || userData?.Employee__r?.Title,
      picture: userData?.Picture || buildS3TeamImageUrl(userData?.Employee__c),
      companyId,
      contactAccountIds,
      permissionSetId: userData?.Permission_Set__r?.Id,
      market: { id: userData?.Market__r?.Id, name: userData?.Market__r?.Name },
      permissions: UserRoles.convertPermissions(userData?.Permission_Set__r),
      superintendentFor: userData?.Superintendent_For__c,
      maintenanceGroup: userData?.Maintenance_Group__c,
      homePage: userData?.Permission_Set__r?.Default_Home_Page__c,
      isAgreedToPortalTerms: !!userData.Agreed_To_Portal_Terms__c,
    };

    localStorage.setItem('token', token);
    localStorage.setItem('expiresIn', expiresIn);
    localStorage.setItem('user', JSON.stringify(toSave));

    // save the company id into local storage
    if (toSave.companyId) {
      Company.setId(toSave.companyId);
    }
    history.push(redirectTo || '/');
    // check domain and redirect user if needed
    // checkUserAndRedirect({ user: toSave, isThrowError: true }); // TODO sso redirect check
  },

  logout({ isKeepToken = false }) {
    if (!isKeepToken) {
      localStorage.removeItem('token');
    }
    localStorage.removeItem('expiresIn');
    localStorage.removeItem('companyId');
    localStorage.removeItem('user');
    localStorage.removeItem('settings');
    localStorage.removeItem('fslightbox-types');
    localStorage.removeItem('fslightbox-scrollbar-width');
    localforage.clear();
  },

  getUser() {
    const token = this.getToken();
    if (token) {
      return JSON.parse(localStorage.getItem('user'));
    }
    return null;
  },

  setUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
  },

  getToken() {
    return localStorage.getItem('token');
  },

  setToken(token) {
    localStorage.setItem('token', token);
  },

  setExpiresIn(expiresIn) {
    localStorage.setItem('expiresIn', expiresIn);
  },

  getExpiresIn() {
    return localStorage.getItem('expiresIn');
  },

  checkIsExpiresIn() {
    const expiresIn = localStorage.getItem('expiresIn');
    return Number(expiresIn) * 1000 < Date.now();
  },
};

export default auth;
