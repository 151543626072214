import React, { memo, useMemo } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { array, bool, object } from 'prop-types';

const defaultInitialState = {
  pagination: {
    paginationModel: {
      pageSize: 25,
    },
  },
};

const defaultSx = {
  '& .MuiDataGrid-cell': {
    py: 1,
  },
};

const StyledDataGrid = ({
  rows,
  initialState,
  sx,
  alwaysKeepPagination = false,
  ...props
}) => {
  const hideFooter = useMemo(() => {
    const initialPagination =
      initialState?.pagination?.paginationModel?.pageSize || 0;
    const defaultPagination =
      defaultInitialState.pagination?.paginationModel?.pageSize;
    return rows.length <= (initialPagination || defaultPagination);
  }, [initialState, rows]);

  return (
    <DataGrid
      rows={rows}
      hideFooter={alwaysKeepPagination ? false : hideFooter}
      getRowId={(row) => row?.Id || row?.id || Date.now()}
      getRowHeight={() => 'auto'}
      sx={{ ...defaultSx, ...sx }}
      pageSizeOptions={[10, 25, 50, 100]}
      initialState={{ ...defaultInitialState, ...initialState }}
      {...props}
    />
  );
};

StyledDataGrid.propTypes = {
  rows: array,
  initialState: object,
  sx: object,
  alwaysKeepPagination: bool,
};

export default memo(StyledDataGrid);
