import React, { memo, useCallback, useEffect } from 'react';
import { string, bool, array, func } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button, Tooltip, CircularProgress } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/system';
import Auth from 'helpers/auth';
import { useMergeState } from 'helpers/state';
import CustomSelect from 'components/CustomSelect';
import { constants, tooltips } from 'enums/reportType.enum';
import useReportTypes from 'components/hooks/useReportTypes';
import CustomAutocomplete from 'components/CustomAutocomplete';

const WorkOrderSelect = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const BoxSelect = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'transparent',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const BoxSelectWidth = styled('div')(({ theme }) => ({
  minWidth: theme.spacing(37.5),
  marginRight: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginRight: 0,
    marginBottom: theme.spacing(2),
  },
}));

const AutocompleteAndRefresh = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  gap: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginRight: 0,
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

const StyledCustomAutocomplete = styled(CustomAutocomplete)(({ theme }) => ({
  width: theme.spacing(37.5),
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const RefreshContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-end',
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiButton-root': {
      width: '100%',
    },
  },
}));

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  color: theme.palette.grey[600],
}));

const HeaderActions = ({
  reportType = '',
  propertyManager = '',
  isLoading = false,
  isSkipLoadUsers = false,
  isRefreshButtonHidden = false,
  propertyManagers = [],
  onRefreshReport,
}) => {
  const reportTypes = useReportTypes();
  const navigate = useNavigate();

  const [state, setState] = useMergeState({
    propertyManager,
    reportType,
  });

  useEffect(() => {
    if (
      propertyManagers.length &&
      reportType !== constants.PROPERTYWARE_AUDIT
    ) {
      const currentUser = Auth.getUser();
      if (currentUser && currentUser.employeeId) {
        const foundTeam = propertyManagers.find((pm) =>
          pm.value.includes(currentUser.employeeId),
        );
        if (foundTeam) {
          setState({ propertyManager: foundTeam.value });
        }
      }
    }
  }, [propertyManagers, reportType]);

  const onChange = useCallback(
    ({ target }) => setState({ [target.name]: target.value }),
    [],
  );

  const onRefresh = () => onRefreshReport(state);

  const onReportTypeChange = useCallback(
    (name, value) => navigate(`/visual-manager/${value}`),
    [],
  );

  return (
    <BoxSelect>
      <BoxSelectWidth>
        <WorkOrderSelect>
          <CustomSelect
            native
            name="propertyManager"
            items={propertyManagers}
            disabled={
              isLoading || propertyManagers?.length <= 1 || isSkipLoadUsers
            }
            value={state.propertyManager}
            onChange={onChange}
          />
        </WorkOrderSelect>
      </BoxSelectWidth>
      <AutocompleteAndRefresh>
        <StyledCustomAutocomplete
          type="single"
          disableClearable
          name="reportType"
          options={reportTypes}
          groupBy={(option) => option.group}
          onChange={onReportTypeChange}
          value={reportType}
          label="Report Type"
          placeholder="Select Report Type"
        />
        <RefreshContainer>
          {!isRefreshButtonHidden && (
            <Button
              variant="contained"
              color="primary"
              title="Refresh List"
              onClick={onRefresh}
              disabled={isLoading || !reportType}
              startIcon={
                isLoading ? <CircularProgress size={12} /> : <RefreshIcon />
              }
            >
              Refresh
            </Button>
          )}

          {!!tooltips[reportType] && (
            <Tooltip
              enterTouchDelay={500}
              leaveTouchDelay={3000}
              title={tooltips[reportType]}
            >
              <StyledInfoIcon />
            </Tooltip>
          )}
        </RefreshContainer>
      </AutocompleteAndRefresh>
    </BoxSelect>
  );
};

HeaderActions.propTypes = {
  reportType: string,
  propertyManager: string,
  isLoading: bool,
  isSkipLoadUsers: bool,
  isRefreshButtonHidden: bool,
  propertyManagers: array,
  onRefreshReport: func.isRequired,
};

export default memo(HeaderActions);
