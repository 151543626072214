import React, { memo } from 'react';
import { string, func, node, oneOf } from 'prop-types';
import { styled } from '@mui/system';
import { Box, Snackbar, IconButton, SnackbarContent } from '@mui/material';
import { amber, green } from '@mui/material/colors';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const StyledSnackbarContent = styled(SnackbarContent)({
  maxWidth: 500,
  '&> .MuiSnackbarContent-message': {
    whiteSpace: 'pre-line',
  },
});

const SnackbarContentSxStyles = {
  success: {
    backgroundColor: green[600],
  },
  error: [(theme) => ({ backgroundColor: theme.palette.error.dark })],
  info: [(theme) => ({ backgroundColor: theme.palette.primary.main })],
  warning: {
    backgroundColor: amber[700],
  },
};

const sxIconStyles = (theme) => ({
  opacity: 0.9,
  fontSize: theme.spacing(2.5),
  marginRight: theme.spacing(1),
});

const sxBoxStyles = { display: 'flex', alignItems: 'center' };

// eslint-disable-next-line react/display-name
const SnackbarContentWrapper = React.forwardRef(
  ({ className, message, onClose, variant, ...other }, ref) => {
    const Icon = variantIcon[variant];

    const messageHtml = (
      <Box component="span" sx={sxBoxStyles} id="client-snackbar">
        <Icon sx={sxIconStyles} />
        {message}
      </Box>
    );

    return (
      <StyledSnackbarContent
        ref={ref}
        aria-describedby="client-snackbar"
        message={messageHtml}
        sx={SnackbarContentSxStyles[variant]}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={onClose}
            size="large"
          >
            <CloseIcon sx={(theme) => ({ fontSize: theme.spacing(2.5) })} />
          </IconButton>,
        ]}
        {...other}
      />
    );
  },
);

SnackbarContentWrapper.propTypes = {
  className: string,
  message: node,
  onClose: func,
  variant: oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const Message = ({ message = '', type = 'error', onClose }) => (
  <Snackbar
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    open
    autoHideDuration={6000}
    onClose={(e, reason) => {
      if (reason !== 'clickaway') {
        onClose();
      }
    }}
  >
    <SnackbarContentWrapper
      onClose={onClose}
      variant={type}
      message={message}
    />
  </Snackbar>
);

Message.propTypes = {
  message: string.isRequired,
  type: string,
  onClose: func.isRequired,
};

export default memo(Message);
