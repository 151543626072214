import React, { useCallback, useEffect } from 'react';
import { string, bool, func, array, node, object, oneOfType } from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import LoadingIndicator from 'components/LoadingIndicator';
import PermissionDenied from 'components/PermissionDenied';
import Message from 'components/Message';
import Oops from 'components/Oops';
import { useMergeState } from 'helpers/state';
import Header from 'components/Header';
import AgreementTypes from './ModalPrivacyPolicy/agreementTypes';
import ModalPrivacyPolicy from './ModalPrivacyPolicy';
import Footer from './Footer';

const PageWrapper = ({
  error = '',
  title = '',
  children = null,
  isLoading = false,
  isSkipLoadCompanyInfo = false,
  isVisibleFooter = true,
  isVisibleSubcontractorAgreementLink = false,
  isPermissionDenied = false,
  isManagerIconsVisible = true,
  isVisibleHeader = true,
  customHeaderContent = null,
  oopsMessage = '',
  customHeader = null,
  rootStyle = null,
  onMessageClose: onMessageCloseProp,
  successMessage = '',
  isFailedFetch = false,
  isLoadMarkets = false,
  // Local
  wrapperError = '',
  wrapperFoundProperties = [],
  wrapperIsSearching = false,
  isWrapperFailedFetch = false,
  companyHeaderInfo = null,
  companyFooterInfo = null,
  wrapperSearchRecords,
  loadCompanyInfo,
  clearMessages,
  loadMarkets,
}) => {
  const [state, setState] = useMergeState({
    isOpenModalPrivacyPolicy: false,
    isOpenModalTermsAndConditions: false,
    isOpenModalSubcontractorAgreement: false,
  });

  const [searchParams] = useSearchParams();
  const isInIframe = searchParams.get('iframe') === 'true';

  useEffect(() => {
    if (!isSkipLoadCompanyInfo) {
      loadCompanyInfo();
    }
    if (isLoadMarkets) {
      loadMarkets();
    }
  }, []);

  const onMessageClose = useCallback(() => {
    clearMessages();

    if (onMessageCloseProp) {
      onMessageCloseProp();
    }
  }, []);

  const onToggleModalPrivacy = useCallback(
    (value) => {
      switch (value) {
        case AgreementTypes.PRIVACY:
          setState({
            isOpenModalPrivacyPolicy: !state.isOpenModalPrivacyPolicy,
          });
          break;
        case AgreementTypes.TERMS:
          setState({
            isOpenModalTermsAndConditions: !state.isOpenModalTermsAndConditions,
          });
          break;
        case AgreementTypes.SUB:
          setState({
            isOpenModalSubcontractorAgreement:
              !state.isOpenModalSubcontractorAgreement,
          });
          break;
        default:
      }
    },
    [
      state.isOpenModalSubcontractorAgreement,
      state.isOpenModalTermsAndConditions,
      state.isOpenModalPrivacyPolicy,
    ],
  );

  return (
    <>
      <Helmet>
        <title>{title || 'Undefined'}</title>
        {!!companyHeaderInfo && (
          <link rel="icon" type="image/png" href={companyHeaderInfo.favicon} />
        )}
      </Helmet>

      {(!!error || !!wrapperError) && (
        <Message message={error || wrapperError} onClose={onMessageClose} />
      )}

      {isLoading && (!isWrapperFailedFetch || !isFailedFetch) ? (
        <LoadingIndicator />
      ) : (
        <>
          {isPermissionDenied ? (
            <PermissionDenied />
          ) : (
            <>
              {isVisibleHeader && (
                <>
                  {customHeader || (
                    <Header
                      {...companyHeaderInfo}
                      title={title}
                      isManagerIconsVisible={isManagerIconsVisible}
                      isSearching={wrapperIsSearching}
                      searchRecords={wrapperSearchRecords}
                      foundProperties={wrapperFoundProperties}
                      customHeaderContent={customHeaderContent}
                      isInIframe={isInIframe}
                    />
                  )}
                </>
              )}

              <Box
                component="section"
                sx={[
                  rootStyle,
                  (theme) => ({
                    padding: 0,
                    paddingTop: `${isVisibleHeader ? theme.spacing(7) : 0}`,
                    paddingBottom: `${isVisibleFooter ? theme.spacing(20) : 0}`,
                    [theme.breakpoints.down('sm')]: {
                      paddingBottom: `${isVisibleFooter ? theme.spacing(28) : 0}`,
                    },
                  }),
                ]}
              >
                {isWrapperFailedFetch || isFailedFetch ? (
                  <Oops
                    companyFooterInfo={companyFooterInfo}
                    message={oopsMessage}
                  />
                ) : (
                  <>
                    {!!successMessage && (
                      <Message
                        type="success"
                        message={successMessage}
                        onClose={onMessageClose}
                      />
                    )}

                    {children}
                  </>
                )}
              </Box>

              {state.isOpenModalPrivacyPolicy && (
                <ModalPrivacyPolicy
                  companyName={companyFooterInfo?.companyName}
                  html={companyFooterInfo.privacyPolicy}
                  onClose={onToggleModalPrivacy}
                  modalType={AgreementTypes.PRIVACY}
                  titleName="Privacy Policy"
                />
              )}

              {state.isOpenModalTermsAndConditions && (
                <ModalPrivacyPolicy
                  companyName={companyFooterInfo?.companyName}
                  html={companyFooterInfo.termsAndConditions}
                  onClose={onToggleModalPrivacy}
                  modalType={AgreementTypes.TERMS}
                  titleName="Terms & Conditions"
                />
              )}

              {state.isOpenModalSubcontractorAgreement && (
                <ModalPrivacyPolicy
                  companyName={companyFooterInfo?.companyName}
                  html={companyFooterInfo.subcontractorAgreement}
                  onClose={onToggleModalPrivacy}
                  modalType={AgreementTypes.SUB}
                  titleName="Subcontractor Agreement"
                />
              )}

              {isVisibleFooter && (
                <Footer
                  {...companyFooterInfo}
                  isVisibleSubcontractorAgreementLink={
                    isVisibleSubcontractorAgreementLink
                  }
                  onOpenModal={onToggleModalPrivacy}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

PageWrapper.propTypes = {
  error: string,
  isLoading: bool,
  isFailedFetch: bool,
  successMessage: string,
  title: string,
  loadCompanyInfo: func,
  children: node,
  isSkipLoadCompanyInfo: bool,
  isVisibleFooter: bool,
  isVisibleHeader: bool,
  isManagerIconsVisible: bool,
  oopsMessage: string,
  onMessageClose: func,
  isPermissionDenied: bool,
  customHeader: node,
  customHeaderContent: node,
  rootStyle: oneOfType([object, func]),
  isVisibleSubcontractorAgreementLink: bool,
  isLoadMarkets: bool,
  // Local props
  wrapperError: string,
  wrapperFoundProperties: array,
  wrapperIsSearching: bool,
  isWrapperFailedFetch: bool,
  companyHeaderInfo: object,
  companyFooterInfo: object,
  wrapperSearchRecords: func,
  clearMessages: func.isRequired,
  loadMarkets: func.isRequired,
};

export default PageWrapper;
