import Moment from 'moment';
import deepEqual from 'deep-equal';

/**
 * Makes a string array from comma separated string
 */
export const makeStringArray = (input, delimiter = ',') => {
  // if array -> trim and return
  if (input && Array.isArray(input)) {
    return input.map((str) => str.trim()).filter(Boolean);
  }
  // if string -> convert
  if (input && typeof input === 'string') {
    if (input.includes(delimiter)) {
      return input
        .split(delimiter)
        .map((str) => str.trim())
        .filter(Boolean);
    }
    return [input.trim()];
  }
  return [];
};

export const clearSubject = (str) => {
  if (str && str.includes('{') && str.includes('}')) {
    return str.replace(/{.+}/g, '').trim(); // or replaceFirst
  }
  return str;
};

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const sanitize = (s) => {
  if (typeof s !== 'string') return '';
  return s.replace(/[^a-z0-9._-]/gi, '').toLowerCase();
};

export const getFileExtension = (fname) => {
  if (!fname) {
    return '';
  }
  const [noQueryParams] = fname.split('?');
  const ext = noQueryParams.slice(
    (Math.max(0, noQueryParams.lastIndexOf('.')) || Infinity) + 1,
  );
  return ext.toLowerCase();
};

export const statusColorMap = {
  orange: '#ff9800',
  blue: '#2196f3',
  red: '#f44336',
  green: '#4caf50',
  teal: '#4db6ac',
  userEvent: '#9e9e9e',
};

export const onDragStart = (e, data) => {
  // clone DOM node
  const clone = e.target.cloneNode(true);

  // apply styles
  clone.style.position = 'absolute';
  clone.style.top = '0';
  clone.style.left = '0';
  clone.style.zIndex = '-1';

  // put into dom
  e.target.appendChild(clone);

  // override drag image by clone
  e.dataTransfer.setDragImage(clone, e.offsetX, e.offsetY);

  // set data to transfer
  e.dataTransfer.setData('text/plain', JSON.stringify(data));

  // remove clone after timeout
  window.setTimeout(() => {
    clone.parentNode.removeChild(clone);
  }, 500);
};

/* eslint-disable camelcase */
export const getOccupiedBy = (job) => {
  const workOrder = job?.Work_Order__r;
  const inspection = job?.Property_Inspection__r;
  const property = (workOrder || inspection)?.Property__r;
  const status = property?.Occupancy__c;
  const tenantContact = property?.Tenant_Contact_1__r;

  let occupiedBy = '';
  let occupiedById = '';
  let occupiedByEmail = '';
  let occupiedByPhone = '';

  if (status !== 'Vacant' || tenantContact) {
    occupiedById = job?.Scheduling_Contact__r?.Id;
    occupiedBy = job?.Scheduling_Contact__r?.Name;
    occupiedByEmail = job?.Scheduling_Contact__r?.Email;
    occupiedByPhone = job?.Scheduling_Contact__r?.Phone;

    if (!occupiedBy && tenantContact) {
      occupiedById = tenantContact?.Id;
      occupiedBy = tenantContact?.Name;
      occupiedByEmail = tenantContact?.Email;
      occupiedByPhone = tenantContact?.Phone;
    }
  }

  const contacts = [
    property?.Tenant_Contact_1__r,
    property?.Tenant_Contact_2__r,
    property?.Tenant_Contact_3__r,
  ].filter((c) => !!c && c.Id !== occupiedById);

  return {
    occupiedBy,
    occupiedById,
    occupiedByEmail,
    occupiedByPhone,
    occupiedByAlternates: contacts.map((c) => ({
      id: c.Id,
      name: c.Name,
      email: c.Email,
      phone: c.Phone,
    })),
  };
};

/* eslint-enable camelcase */

export const mergeCustomizer = (objValue, srcValue) => {
  if (Array.isArray(objValue)) {
    return objValue.concat(srcValue);
  }
  return undefined;
};

export const joinDates = (first, second) => {
  if (!first && !second) {
    return '';
  }
  return [first, second].filter(Boolean).join(' - ');
};

export const isFailedFetch = (err) => {
  const actionMessage = (err?.message || '').toLowerCase();
  return (
    err?.responseBody?.code === 500 ||
    actionMessage.includes('failed to fetch') ||
    (actionMessage.includes('internal server error') &&
      err?.response?.status === 500) ||
    actionMessage.includes('networkerror')
  );
};

export const collectResultMessages = (obj, pad = 0) => {
  if (!obj) {
    return [];
  }
  // if array
  if (Array.isArray(obj) && obj.length > 0) {
    let found = [];
    for (let i = 0; i < obj.length; i += 1) {
      const element = obj[i];
      found = found.concat([
        { message: `Item ${i + 1}`, pad /* no status */, bold: true },
      ]);

      const resultId =
        element?.id || element?.Id || element?.MessageId || element?.ETag;
      const padPlus = pad + 1;
      if (element?.errors?.length) {
        found = found.concat({
          message: `${element?.errors?.[0]}`,
          status: 'fail',
          pad: padPlus,
        });
      } else if (resultId) {
        found = found.concat({
          message: resultId,
          status: 'pass',
          pad: padPlus,
          id: resultId,
        });
      } else {
        const collected = collectResultMessages(element, padPlus);
        found = found.concat(collected);
      }
    }
    return found;
  }

  // if object
  if (typeof obj === 'object') {
    let found = [];
    const keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      const value = obj[key];
      const formattedKey = capitalize(key)
        .split(/(?=[A-Z])/)
        .join(' ');
      let prefixedKey = formattedKey;

      // if (pad === 0) {
      //   prefixedKey = `Processed ${prefixedKey}`;
      // }

      if (typeof value === 'object') {
        const resultId =
          value?.id || value?.Id || value?.MessageId || value?.ETag;
        if (value?.errors?.length) {
          prefixedKey = `Error ${formattedKey}`;
          const message = `${prefixedKey} ${value?.id ? ` ${value.id}: ` : ''}${
            value?.errors?.[0]
          }`;
          found = found.concat({
            message,
            status: 'fail',
            pad,
          });
        } else if (resultId) {
          found = found.concat({
            message: `${prefixedKey} ${resultId}`,
            status: 'pass',
            pad,
            id: resultId,
          });
        } else {
          found = found.concat([
            {
              message: `${prefixedKey}`,
              pad,
              bold: pad === 0,
              /* no status */
            },
          ]);
          const collected = collectResultMessages(value, pad + 1);
          found = found.concat(collected);
        }
      } else {
        found = found.concat([{ message: `${prefixedKey}` /* no status */ }]);
        const collected = collectResultMessages(value, pad + 1);
        found = found.concat(collected);
      }
    }
    return found;
  }
  return [];
};

export const currentDomainName = `${location.protocol}//${location.hostname}${
  location.port ? `:${location.port}` : ''
}`;

export const getJobNumber = (name) =>
  Number((name || '').split('-').pop() || 0); // Job-0000001 -> 1

export const checkEventMatchesWorkingHours = ({
  workingHours,
  eventStartDate, // date or moment
  eventEndDate, // date or moment
}) => {
  // matches by default
  if (!workingHours || !eventEndDate || !eventEndDate) {
    return true;
  }

  // get schedule time frame
  const eventStartMoment = Moment(eventStartDate);
  const eventEndMoment = Moment(eventEndDate);

  // check if start and end on the same day
  if (!eventStartMoment.isSame(eventEndMoment, 'day')) {
    return false;
  }

  // get weekday name
  const startWeekDay = eventStartMoment.format('dddd'); // Monday, Tuesday

  // use only hous and minutes
  const scheduleStartMoment = Moment(eventStartMoment.format('HH:mm'), 'HH:mm');
  const scheduleEndMoment = Moment(eventEndMoment.format('HH:mm'), 'HH:mm');

  // get technicians working schedule
  const period = workingHours[startWeekDay];
  const workStartMoment = Moment(period.start, 'HH:mm');
  const workEndMoment = Moment(period.end, 'HH:mm');

  // check if start date falls into time period
  const isStartOk = scheduleStartMoment.isBetween(
    workStartMoment,
    workEndMoment,
    'minutes',
    '[)', // include start time, exclude end time
  );

  // check if end date falls into time period
  const isEndOk = scheduleEndMoment.isBetween(
    workStartMoment,
    workEndMoment,
    'minutes',
    '(]', // exclude start time, include end time
  );

  // true if start and end dates are in time period
  return isStartOk && isEndOk;
};

export const sendWindowMessage = ({ source, action, payload }) => {
  window.parent.postMessage(
    {
      source,
      action,
      payload,
    },
    '*',
  );
};

export const wrapUrls = (text, newWindow) => {
  if (text.includes('localhost')) {
    return text;
  }
  const urlPattern =
    /(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\x{00a1}\-\x{ffff}0-9]+-?)*[a-z\x{00a1}\-\x{ffff}0-9]+)(?:\.(?:[a-z\x{00a1}\-\x{ffff}0-9]+-?)*[a-z\x{00a1}\-\x{ffff}0-9]+)*(?:\.(?:[a-z\x{00a1}\-\x{ffff}]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?/gi;

  const target = newWindow === true || newWindow == null ? '_blank' : '';

  return text.replace(urlPattern, (url) => {
    const protocolPattern = /^(?:(?:https?|ftp):\/\/)/i;
    const href = protocolPattern.test(url) ? url : `http://${url}`;
    return `<a href="${href}" target="${target}">${url}</a>`;
  });
};

// decode Html Entities
export const decodeHtmlEntities = (str) => {
  if (!str) return '';
  const txt = new DOMParser().parseFromString(str, 'text/html');
  return txt.documentElement.textContent;
};

/**
 * Get the host name from url
 */
export const getHostnameFromRegex = (url = '') => {
  // run against regex
  const matches = (url || '').match(/^https?:\/\/([^/?#]+)(?:[/?#]|$)/i);
  // extract hostname (will be null if no match is found)
  return matches && matches[1];
};

const renderAddresses = (markets) =>
  markets
    .map(
      ({ city, googleLink }, i) =>
        `${
          i ? '<span>&nbsp;-&nbsp;</span>' : ''
        }<a href="${googleLink}" target="_blank" style="text-decoration: none; color: black">${city}</a>`,
    )
    .join('');

// Generate Email signature html
export const generateEmailSignature = (user, companyInfo, markets = []) => {
  if (!user || !companyInfo) {
    return '';
  }
  const { name, title, phone, mobilePhone, email } = user;
  const { URL, Logos } = companyInfo || {};
  const { Long } = Logos;
  const phoneNumber = phone || mobilePhone || '-';
  const website = getHostnameFromRegex(URL);

  return `
    <div style="font-weight: normal">
      <h3 style="margin: 0">${name}</h3>
      ${title ? `<h4>${title}</h4>` : ''}
      <p style="margin: 8px 0 0 0">
        <img src="https://www.onqpm.com/wp-content/uploads/2022/08/phone.png" alt="Phone Number" width="12" style="margin: 0 4px 0 0;" />
        <a href="tel:${phoneNumber}" style="text-decoration: none;color:black">${phoneNumber}</a>
      </p>
      <p style="margin: 8px 0 0 0">
        <img src="https://www.onqpm.com/wp-content/uploads/2022/08/email.png" alt="Email Address" width="12" style="margin: 0 4px 0 0;" />
        <a href="mailto:${email}" style="text-decoration: none; color: black">${email}</a>
      </p>
      <p style="margin: 8px 0 0 0">
        <img src="https://www.onqpm.com/wp-content/uploads/2022/08/link.png" alt="On Q Property Management Website" style="margin: 0 4px 0 0;" />
        <a href="${URL}" target="_blank" width="12" style="text-decoration: none; color: black">${website}</a>
      </p>
      <p style="margin: 8px 0 0 0">
        <img src="https://www.onqpm.com/wp-content/uploads/2022/08/location.png" width="12" alt="Directions" style="margin: 0 4px 0 0;" />
        ${renderAddresses(markets)}
      </p>
      <p style="margin: 8px 0 0 0">
        <img src="${Long}" role="presentation" alt="On Q Property Management" width="200" height="75" style="display:block" />
      </p>
    </div>
  `;
};

// Filter only changes fields from
export const getUpdatedFields = (initialObject = {}, updatedObject = {}) => {
  const isEqual = deepEqual(initialObject, updatedObject);

  // If objects are equal
  if (isEqual) {
    return {};
  }

  return Object.keys(updatedObject).reduce((memo, field) => {
    const initialValue = initialObject[field];
    let newValue = updatedObject[field];

    // If initial value type is number, convert updated value type to number
    if (typeof initialValue === 'number') {
      newValue = Number(updatedObject[field]);
    }

    // if Initial value type is object
    if (initialValue !== null && typeof initialValue === 'object') {
      const newObject = getUpdatedFields(initialValue[field], newValue[field]);
      if (Object.keys(newObject).length) {
        newValue[field] = newObject;
      }
    }

    if (Array.isArray(initialValue)) {
      const newArray = [];
      for (let i = 1; i < initialValue?.length; i += 1) {
        if (!deepEqual(initialValue[i], newValue[i])) {
          newArray.push(initialValue[i]);
        }
      }
      if (newArray.length) {
        memo[field] = newArray;
      }
    }

    if (newValue !== initialValue) {
      memo[field] = newValue;
    }
    return memo;
  }, {});
};

export const getColorMode = () => localStorage.getItem('colorMode') || 'light';

export const injectCss = (styles) => {
  const styleSheet = document.createElement('style');
  styleSheet.type = 'text/css';
  styleSheet.setAttribute('id', 'dynamicStylesheet');
  styleSheet.innerText = styles;
  document.head.appendChild(styleSheet);
};

export const loadScript = ({
  src,
  position,
  id,
  callback,
  action = 'load',
}) => {
  if (!position) {
    return;
  }
  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  if (action === 'load') {
    script.src = src;
  } else if (action === 'inject') {
    script.textContent = src;
  }

  if (callback) {
    script.onload = callback;
  }
  position.appendChild(script);
};

export const getEmailFromString = (text = '') =>
  text.split(/<(.*?)>/gi)[1] || text;

export const isMobile = () => {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
      navigator.userAgent,
    )
  ) {
    return true;
  }
  return false;
};
