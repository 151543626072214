import { loadMarkets, runCrontabJob } from 'containers/App/actions';
import Component from './LockerManager';
import connectWithStore from '../connectWithStore';
import reducer, {
  loadLockerLog,
  error,
  successMessage,
  isFailedFetch,
  isLoadingVMData,
  isLoadedVMData,
  vmData,
  lockerLog,
  isLoadingLockerHistoryLog,
  isRunningCrontabJob,
  markets,
} from './reducer';
import saga from './saga';

export default connectWithStore({
  Component,
  reducer,
  saga,
  injectName: 'lockerManager',
  stateToProps: {
    isLoadingVMData,
    isLoadedVMData,
    vmData,

    isRunningCrontabJob,

    isLoadingLockerHistoryLog,
    lockerLog,

    markets,

    error,
    isFailedFetch,
    successMessage,
  },
  dispatchToProps: (dispatch) => ({
    runCrontabJob: (name) => dispatch(runCrontabJob(name)),
    loadLockerHistoryLog: (data) => dispatch(loadLockerLog(data)),
    loadMarkets: (data) => dispatch(loadMarkets(data)),
  }),
});
