import Auth from 'helpers/auth';
import 'whatwg-fetch';
import history, { getRedirectTo, getHref } from 'utils/history';
import Company from 'helpers/company';
import { encodeBase64 } from 'helpers/template';
import {
  externalRedirectToSsoOnError,
  externalRedirectToSsoLogin,
} from 'helpers/singleSignOn';
import { getDefaultHeaders } from './requestHeaders';
import RequestMemoWrapper from './request-memo-wrapper';

const requestMemoWrapper = new RequestMemoWrapper(fetch.bind(window));

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
export function parseJSON(response) {
  // parse the json response
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.clone().json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
export async function checkStatus(response) {
  const expiredIn = response.headers.get('Expires-In');
  if (expiredIn) {
    Auth.setExpiresIn(expiredIn);
  }
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const redirectTo = getRedirectTo();

  // UNAUTHORIZED 401
  if ([401].includes(response.status)) {
    // clear storage
    Auth.logout({});

    externalRedirectToSsoOnError(response.statusText);
  }

  // FORBIDDEN 403
  if ([403].includes(response.status)) {
    // when going to portfolio/<contact id> if it will generate an access denied,
    if (history.location.pathname.includes('/portfolio/')) {
      // redirect to just plain /portfolio with out the contact id.
      history.push(`/portfolio?redirectTo=${redirectTo}`);
    }

    // redirect to forbidden page
    history.push(`/forbidden?redirectTo=${redirectTo}`);
  }

  // NOT FOUND 404
  if ([404].includes(response.status)) {
    // redirect to not found page
    history.push(`/not-found?redirectTo=${redirectTo}`);
  }

  const error = new Error(response.statusText || 'Internal Server Error');
  const body = await response.json();
  error.response = response;
  error.responseBody = body;

  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url, options = {}) {
  const token = Auth.getToken();
  if (token) {
    const isExpired = Auth.checkIsExpiresIn();

    if (isExpired) {
      Auth.logout({});
      externalRedirectToSsoLogin();
      return {};
    }
  }

  const { makeRequest } = requestMemoWrapper;
  const method = options.method || 'GET';
  return makeRequest({
    url,
    method,
    uniqueKey: `${method}-${url}`,
    options: {
      ...options,
      headers: getDefaultHeaders(options.headers),
    },
  })
    .then(checkStatus)
    .then(parseJSON);
}

export const customRequestSso = (token) =>
  fetch(`${process.env.SSO_API}/v1/user`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Api-Key': process.env.SSO_API_KEY,
      // get current page and encode to base64 to pass the WAF
      'X-RequestFrom': encodeBase64(`${getHref()}`),
      // send the company id for the current domain
      'X-DomainCompanyId': Company.getIdByDomain(),
      Authorization: `Bearer ${token}`,
    }),
  })
    .then(checkStatus)
    .then(parseJSON);
