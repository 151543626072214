import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import createReducer from './reducers'; // TODO webpack-to-vite was reducers

export default (initialState = {}) => {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    // Can create a root reducer separately and pass that in
    reducer: createReducer(),
    middleware: (getDefaultMiddleware) => {
      const middleware = getDefaultMiddleware({ thunk: false }).concat(
        sagaMiddleware,
      );
      return middleware;
    },
    // Turn off devtools in prod, or pass options in dev
    devTools: process.env.NODE_ENV === 'production' ? false : {},
    preloadedState: initialState,
  });

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (import.meta.hot) {
    // TODO webpack-to-vite was module.hot
    import.meta.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers));
    });
  }

  return store;
};
