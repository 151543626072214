import { sortBy } from 'lodash';

export const constants = {
  SCHEDULE_INSPECTION: 'scheduleInspection',
  HOA_VIOLATION: 'hoaViolation',
  VACATE_NOTICE_30_DAY: 'vacateNotice30Day',
  VACATE_NOTICE_10_DAY: 'vacateNotice10Day',
  ACCESS_NOTICE: 'accessNotice',
  RENT_CHANGE_NOTICE: 'rentChangeNotice',
  KEY_RETURN_MOVEOUT: 'keyReturnMoveOut',
  MANAGEMENT_CHANGE: 'managementChange',
  MISCELLANEOUS: 'miscellaneous',
  NOTICE_OF_HOLDOVER: 'noticeOfHoldover',
  CREATE_WORK_ORDER: 'createWorkOrder',
  CREATE_INSPECTION: 'createInspection',
  CREATE_WORK_ORDER_NEW_CUSTOMER: 'createWorkOrderNewCustomer',
  CREATE_INSPECTION_NEW_CUSTOMER: 'createInspectionNewCustomer',
  CONTRACT_TERMINATION: 'contractTermination',
  KEY_CHECK_OUT: 'keyCheckOut',
  KEY_CHECK_IN: 'keyCheckIn',
  TENANT_DISPOSITION: 'tenantDisposition',
  LOCKER_RESERVATION: 'lockerReservation',
  MONTH_TO_MONTH: 'monthToMonth',
  PROPERTY_MANAGER_CHANGE: 'propertyManagerChange',
  CONTRACT_CANCELLATION: 'contractCancellation',
  FIVE_DAY_NOTICE: 'fiveDayNotice',
  FIVE_DAY_NOTICE_BULK: 'fiveDayNoticeBulk',
  PAYMENT_PLAN: 'paymentPlan',
  NEW_LEASE: 'newLease',
};

export const titles = {
  [constants.SCHEDULE_INSPECTION]: 'Schedule Inspection',
  [constants.HOA_VIOLATION]: 'HOA Violation',
  [constants.VACATE_NOTICE_30_DAY]: '30 Day Notice to Vacate',
  [constants.VACATE_NOTICE_10_DAY]: '10 Day Notice',
  [constants.ACCESS_NOTICE]: 'Access Notice',
  [constants.RENT_CHANGE_NOTICE]: 'Notice of Rent Change',
  [constants.KEY_RETURN_MOVEOUT]: 'Key Return & Move Out Inspection Reminder',
  [constants.MANAGEMENT_CHANGE]: 'Change Of Management Notice',
  [constants.MISCELLANEOUS]: 'Miscellaneous Notice',
  [constants.NOTICE_OF_HOLDOVER]: 'Notice Of Holdover',
  [constants.CREATE_WORK_ORDER]: 'Create Work Order',
  [constants.CREATE_INSPECTION]: 'Create Inspection',
  [constants.CREATE_WORK_ORDER_NEW_CUSTOMER]:
    'Create Work Order & New Property for Direct Customer',
  [constants.CREATE_INSPECTION_NEW_CUSTOMER]:
    'Create Inspection & New Property for Direct Customer',
  [constants.CONTRACT_TERMINATION]: 'Contract Termination/Cancellation',
  [constants.KEY_CHECK_OUT]: 'Key Check Out',
  [constants.KEY_CHECK_IN]: 'Key Check In',
  [constants.TENANT_DISPOSITION]: 'Tenant Disposition',
  [constants.LOCKER_RESERVATION]: 'Locker Reservation',
  [constants.MONTH_TO_MONTH]: 'Month to Month',
  [constants.PROPERTY_MANAGER_CHANGE]: 'Change Property Manager',
  [constants.CONTRACT_CANCELLATION]: 'Contract Cancellation',
  [constants.FIVE_DAY_NOTICE]: '5 Day Notice',
  [constants.FIVE_DAY_NOTICE_BULK]: '5 Day Notice Bulk Processing',
  [constants.PAYMENT_PLAN]: 'Payment Plan',
  [constants.NEW_LEASE]: 'New Lease',
};

export const permissions = {
  [constants.SCHEDULE_INSPECTION]: [
    'Process_Automation__c:Schedule Inspection',
  ],
  [constants.HOA_VIOLATION]: ['Process_Automation__c:Property Manager Notices'],
  [constants.VACATE_NOTICE_30_DAY]: [
    'Process_Automation__c:Property Manager Notices',
  ],
  [constants.VACATE_NOTICE_10_DAY]: [
    'Process_Automation__c:Property Manager Notices',
  ],
  [constants.ACCESS_NOTICE]: ['Process_Automation__c:Property Manager Notices'],
  [constants.RENT_CHANGE_NOTICE]: [
    'Process_Automation__c:Property Manager Notices',
  ],
  [constants.KEY_RETURN_MOVEOUT]: [
    'Process_Automation__c:Property Manager Notices',
  ],
  [constants.MANAGEMENT_CHANGE]: [
    'Process_Automation__c:Property Manager Notices',
  ],
  [constants.MISCELLANEOUS]: ['Process_Automation__c:Property Manager Notices'],
  [constants.CREATE_WORK_ORDER]: [
    'Create_Work_Orders__c',
    'Edit_Work_Orders__c',
  ],
  [constants.NOTICE_OF_HOLDOVER]: [
    'Process_Automation__c:Property Manager Notices',
  ],
  [constants.CREATE_INSPECTION]: ['Process_Automation__c:Create Inspection'],
  [constants.CREATE_WORK_ORDER_NEW_CUSTOMER]: [
    'Create_Work_Orders__c:Mammoth Direct',
    'Edit_Work_Orders__c:Mammoth Full',
    'Edit_Work_Orders__c:Mammoth Restricted',
  ],
  [constants.CREATE_INSPECTION_NEW_CUSTOMER]: [
    'Process_Automation__c:Mammoth Direct Customers',
  ],
  [constants.CONTRACT_TERMINATION]: [
    'Process_Automation__c:Property Manager Notices',
  ],
  [constants.KEY_CHECK_OUT]: ['Process_Automation__c:Key Management'],
  [constants.KEY_CHECK_IN]: ['Process_Automation__c:Key Management'],
  [constants.TENANT_DISPOSITION]: [
    'Process_Automation__c:Property Manager Notices',
  ],
  [constants.LOCKER_RESERVATION]: ['Process_Automation__c:Lockers'],
  [constants.MONTH_TO_MONTH]: [
    'Process_Automation__c:Property Manager Notices',
  ],
  [constants.PROPERTY_MANAGER_CHANGE]: [
    'Process_Automation__c:Property Manager Change',
  ],
  [constants.CONTRACT_CANCELLATION]: [
    'Process_Automation__c:Property Manager Notices',
  ],
  [constants.FIVE_DAY_NOTICE]: [
    'Process_Automation__c:Property Manager Notices',
  ],
  [constants.FIVE_DAY_NOTICE_BULK]: [
    'Process_Automation__c:Property Manager Notices',
  ],
  [constants.PAYMENT_PLAN]: ['Process_Automation__c:Property Manager Notices'],
  [constants.NEW_LEASE]: ['Visual_Manager__c:Leasing'],
};

export const forDropdown = Object.keys(titles)
  // exclude from dropdown
  .filter(
    (c) =>
      ![
        // disable it for now
        constants.KEY_RETURN_MOVEOUT,
        // will handle it differently
        constants.CREATE_WORK_ORDER_NEW_CUSTOMER,
        constants.CREATE_INSPECTION_NEW_CUSTOMER,
        constants.CONTRACT_CANCELLATION,
        constants.NEW_LEASE,
      ].includes(c),
  )
  .map((value) => ({
    value,
    label: titles[value],
  }));

export const forOwnerPage = sortBy(
  Object.keys(titles)
    .filter((c) =>
      [
        constants.CREATE_INSPECTION,
        constants.CREATE_WORK_ORDER,
        constants.KEY_CHECK_IN,
        constants.KEY_CHECK_OUT,
        constants.LOCKER_RESERVATION,
        constants.MONTH_TO_MONTH,
        constants.SCHEDULE_INSPECTION,
        constants.NEW_LEASE,
      ].includes(c),
    )
    .map((value) => ({
      value,
      label: titles[value],
    })),
  ['label'],
);

export const forResidentPage = sortBy(
  Object.keys(titles)
    .filter((c) =>
      [
        constants.CREATE_INSPECTION,
        constants.CREATE_WORK_ORDER,
        constants.KEY_CHECK_IN,
        constants.KEY_CHECK_OUT,
        constants.LOCKER_RESERVATION,
        constants.MISCELLANEOUS,
        constants.SCHEDULE_INSPECTION,
        constants.NEW_LEASE,
      ].includes(c),
    )
    .map((value) => ({
      value,
      label: titles[value],
    })),
  ['label'],
);
