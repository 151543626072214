import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'utils/request';

import { SEARCH_RECORDS } from './constants';

import { searchRecordsSuccess, searchRecordsError } from './actions';

const { API_URL } = process.env;

export function* searchRecords({ search, searchIn }) {
  try {
    const query = encodeURIComponent(search);
    const searchResults = yield call(
      request,
      `${API_URL}/v1/${searchIn}/search/by/name/${query}`,
      { method: 'GET' },
    );

    yield put(searchRecordsSuccess(searchResults));
  } catch (err) {
    yield put(searchRecordsError(err));
  }
}

export default function* appData() {
  yield takeLatest(SEARCH_RECORDS, searchRecords);
}
