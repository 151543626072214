import { formatNonUtcDate } from 'helpers/format';
import { decodeHtmlEntities } from 'helpers/general';

// ------------------------------------
export const convertPropertyAddress = (property) => {
  if (!property) {
    return {
      propertyStreet: '',
      propertyAddress: '',
    };
  }
  const ownerContract = property.Active_Owner_Contract__r;
  const street = property.Street_Address__c || '';
  const building = property.Building__c || '';
  const unit = property.Unit__c || '';

  const city = property.City__c || '';
  const state = property.State__c || '';
  const zip = property.Zip_Code__c || '';
  const market = decodeHtmlEntities(ownerContract?.Market__r?.Name);

  const propertyStreet = `${street}${street && (building || unit) ? ', ' : ''}${
    building ? `Building ${building}` : ''
  }${building && unit ? ', ' : ''}${unit ? `Unit ${unit}` : ''}`;

  const propertyAddress = `${city}${
    city && (state || zip) ? ', ' : ''
  }${state}${zip ? ' ' : ''}${zip}${market ? ` (${market})` : ''}`;

  return {
    propertyStreet,
    propertyAddress,
  };
};

// ------------------------------------
export const convertPropertyService = (s) => {
  if (!s) {
    return null;
  }
  return {
    id: s.Id || '',
    type: s.Type__c || '',
    companyName: decodeHtmlEntities(s.Company__c),
    holder: s.Holder__c || '',
    dateStart: formatNonUtcDate(s.Start_Date__c, 'MM/DD/YYYY'), // YYYY-MM-DD
    dateEnd: formatNonUtcDate(s.End_Date__c, 'MM/DD/YYYY'), // YYYY-MM-DD
    dateConfirmed: formatNonUtcDate(s.Confirmation_Date__c, 'MM/DD/YYYY'), // YYYY-MM-DD
    description: decodeHtmlEntities(s.Description__c),
    isStatusConfirmed: !!s.Confirmation_Date__c,
    status: s.Status__c || '',
    phone: s.Phone_Number__c || '',
    email: s.Email__c || '',
    accountNumber: s.Policy_Number__c || '',
    serviceIncludedInRent: !!s.Included__c,
    powerOfAttorney: !!s.Power_of_Attorney__c,
    noServiceInterruption: !!s.No_Service_Interruption__c,
  };
};

// ------------------------------------
export const convertPropertyServices = (services = []) =>
  services.map((service) => convertPropertyService(service));

// -------------------------------------------
export const convertAppliance = (a) => ({
  id: a.Id,
  type: a.Type__c || '',
  manufacturer: a.Manufacturer__c || '',
  model: a.Model__c || '',
  serial: a.Serial_Number__c || '',
  warranty: !!a.Warranty__c,
});

// ------------------------------------
export const convertPropertyAppliances = (appliances = []) =>
  appliances.map((a) => convertAppliance(a));
