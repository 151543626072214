import moment from 'moment';

export const formatMoney = (value, options) => {
  if (value || value === 0) {
    let maximumFractionDigits = 2;
    if (options?.maximumFractionDigits !== undefined) {
      maximumFractionDigits = options.maximumFractionDigits;
    }

    let minimumFractionDigits = 2;
    if (options?.minimumFractionDigits !== undefined) {
      minimumFractionDigits = options.minimumFractionDigits;
    }

    if (minimumFractionDigits > maximumFractionDigits) {
      minimumFractionDigits = maximumFractionDigits;
    }

    return Number(value).toLocaleString('en-US', {
      ...options,
      style: 'currency',
      currency: 'USD',
      // for backward compatibility
      minimumFractionDigits,
      maximumFractionDigits,
    });
  }
  return 0;
};

export const formatNumber = (value) =>
  (value || value === 0) &&
  Number(value).toLocaleString('en-US', { maximumFractionDigits: 2 });

export const formatDate = (date, format) => {
  if (!date || !format) {
    return '';
  }
  return moment.utc(date, 'YYYY-MM-DDTHH:mm:ss').local().format(format);
};

// convert non UTC date to default format
export const formatNonUtcDate = (date, format) => {
  if (!date || !format) {
    return '';
  }
  return moment(date, 'YYYY-MM-DD').format(format);
};

// replace <br/> to new line and strip all html tags
export const formatTextForPdf = (text = '') => {
  if (!text) {
    return '';
  }
  return text.replace(/<br\s*\/?>/gm, '\n').replace(/(<([^>]+)>)/gi, '');
};

// Use SI standard
// https://wiki.ubuntu.com/UnitsPolicy
export const formatFileSize = (bytes, decimalPoint = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1000;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(decimalPoint))} ${sizes[i]}`;
};

export const formatDateWithWindow = (startDate, window, format) => {
  if (!startDate) {
    return '';
  }
  const dateMomemt = moment.utc(startDate, 'YYYY-MM-DDTHH:mm:ss').local();
  if (!window) {
    return dateMomemt.format(format);
  }
  return dateMomemt.add(window, 'hours').format(format);
};

const clearAndFormat = ({
  string = '',
  length = 0,
  checkPattern = '',
  replacePattern = '',
}) => {
  if (!string) {
    return '';
  }
  // normalize string and remove all unnecessary characters
  const normalized = string.replace(/[^\d]/g, '');
  // check if number length equals to 9
  if (normalized.length === length) {
    // reformat and return number
    return normalized.replace(checkPattern, replacePattern);
  }
  return normalized;
};

// (###) ###-####
export const formatPhoneNumber = (string) =>
  clearAndFormat({
    string,
    length: 10,
    checkPattern: /(\d{3})(\d{3})(\d{4})/,
    replacePattern: '($1) $2-$3',
  });

// ##-#######
export const formatEin = (string) =>
  clearAndFormat({
    string,
    length: 9,
    checkPattern: /(\d{2})(\d{7})/,
    replacePattern: '$1-$2',
  });

// ####-####-####-####
export const formatCreditCard = (string) =>
  clearAndFormat({
    string,
    length: 16,
    checkPattern: /(\d{4})(\d{4})(\d{4})(\d{4})/,
    replacePattern: '$1-$2-$3-$4',
  });

// ###-##-####
export const formatSsn = (string) =>
  clearAndFormat({
    string,
    length: 9,
    checkPattern: /(\d{3})(\d{2})(\d{4})/,
    replacePattern: '$1-$2-$3',
  });

const capitalize = (word) => word.charAt(0).toUpperCase() + word.substring(1);

export const humanReadable = (name) => {
  const words =
    String(name || '').match(/[A-Z]?[a-z]+|[0-9]+|[A-Z]+(?![a-z])/g) || [];
  return words.map(capitalize).join(' ');
};

export const nFormatter = (num, digits = 0) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((i) => num >= i.value);

  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
};

// ----------------------------------------------
export const round = (num) => Math.round((num + Number.EPSILON) * 100) / 100;
