import React, { memo } from 'react';
import { string, func } from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

/* eslint-disable react/no-danger */
const ModalPrivacyPolicy = ({
  companyName = '',
  html = '',
  modalType = '',
  titleName = '',
  onClose,
}) => {
  const handleClose = () => onClose(modalType);

  return (
    <Dialog open onClose={handleClose} fullWidth>
      <DialogTitle>
        {companyName} {titleName}
      </DialogTitle>
      <DialogContent dividers sx={{ wordWrap: 'break-word' }}>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
/* eslint-enable */

ModalPrivacyPolicy.propTypes = {
  companyName: string,
  html: string,
  onClose: func.isRequired,
  modalType: string,
  titleName: string,
};

export default memo(ModalPrivacyPolicy);
