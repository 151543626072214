import React, { memo } from 'react';
import { any, string, func } from 'prop-types';
import { styled } from '@mui/system';
import { Popover } from '@mui/material';
import DateRangePicker from 'components/DateRangePicker';

const StyledPaperPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPopover-paper': {
    padding: theme.spacing(2),
    maxWidth: theme.spacing(42),
  },
}));

const DateRangePopover = ({
  anchorEl = null,
  startDate = '',
  endDate = '',
  onSelectDateRange,
  onClose,
}) => {
  const isOpenPicker = Boolean(anchorEl);
  const pickerId = isOpenPicker ? 'date-range-popover' : undefined;

  return (
    <StyledPaperPopover
      id={pickerId}
      open={isOpenPicker}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        onSubmit={onSelectDateRange}
      />
    </StyledPaperPopover>
  );
};

DateRangePopover.propTypes = {
  anchorEl: any,
  startDate: string,
  endDate: string,
  onClose: func.isRequired,
  onSelectDateRange: func.isRequired,
};

export default memo(DateRangePopover);
