import React, { useMemo, useEffect, useCallback, memo } from 'react';
import { array, bool, func, string } from 'prop-types';
import { Link } from 'react-router-dom';
import StyledDataGrid from 'components/StyledDataGrid';
import CustomDrawer from 'components/CustomDrawer';
import { Button, Box, Badge } from '@mui/material';
import { styled } from '@mui/system';
import { constants as AutomationType } from 'enums/processAutomationType';
import { formatDate, formatNonUtcDate } from 'helpers/table';
import HeaderSearch from 'components/VmHeaderSearch/HeaderSearch';
import ClickCell from 'components/DatagridCells/Click';
import Company from 'helpers/company';
import { useMergeState } from 'helpers/state';
import CustomSelect from 'components/CustomSelect';
import Auth from 'helpers/auth';
import { get } from 'lodash';
import LockerLog from '../LockerLog';

const filterItem = ({
  item,
  searchText = '',
  marketId = '',
  selectors = [],
}) => {
  let isMatch = true;

  if (searchText) {
    const lSearch = searchText.trim().toLowerCase();
    for (let i = 0; i < selectors.length; i += 1) {
      const selector = selectors[i];
      const value = String(get(item, selector, '')).toLowerCase();
      if (value.includes(lSearch)) {
        isMatch = true;
        break;
      }
    }
  }

  if (isMatch && marketId) {
    isMatch = marketId === item.market?.id;
  }

  return isMatch;
};

const domainCompanyId = Company.getIdByDomain();

const ButtonsAndFilter = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  gap: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-end',
    width: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    // flexDirection: 'column',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    flexGrow: 1,
    '& .MuiButton-root': {
      width: 'calc(50% - 8px)',
    },
  },
}));

const StyledFilterWrapper = styled('div')(({ theme }) => ({
  minWidth: 110,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const initialState = {
  pagination: {
    paginationModel: {
      pageSize: 100,
    },
  },
};

const getDefaultMarketId = () => {
  const currentUser = Auth.getUser();
  return currentUser?.market?.id || '';
};

const LockersTable = ({
  data = [],
  unreadLogs = [],
  lockerLog = [],
  isLoading = false,
  onReleaseLockers,
  onOpenSidebarIframe,
  onLoadLockerHistoryLog,
  isLoadingLockerHistoryLog = false,
  startDate = '',
  endDate = '',
  onSelectDateRange,
  markets = [],
}) => {
  const defaultMarketId = useMemo(() => getDefaultMarketId(), []);

  const [state, setState] = useMergeState({
    searchText: '',
    rows: data,
    isLockerLogOpen: false,
    numberOfUnreadLogs: unreadLogs.length,
    marketId: defaultMarketId,
  });

  const onOpenSmsManager = useCallback(
    () => onOpenSidebarIframe(`/sms-manager/${domainCompanyId}/Locker`),
    [],
  );

  // update rows data when changes
  useEffect(() => {
    if (data.length > 0) {
      setState({ rows: data });
    }
  }, [data]);

  // ----------------------------------
  const filtered = useMemo(() => {
    if (state.searchText || state.marketId) {
      return state.rows.filter((item) =>
        filterItem({
          item,
          searchText: state.searchText,
          marketId: state.marketId,
          selectors: [
            'number',
            'lockerType',
            'status',
            'filledBy',
            'dateOccupied',
            'contentsFor',
            'contents',
            'adminCode',
            'dateLastChange',
            'dateExpectedRetrieval',
            'dateRetrieved',
          ],
        }),
      );
    }
    return state.rows;
  }, [state.rows, state.searchText, state.marketId]);

  const onSearch = useCallback((text) => setState({ searchText: text }), []);

  // prepare default state and render templates
  const columns = useMemo(
    () => [
      /* eslint-disable react/display-name, react/prop-types */
      {
        width: 80,
        field: 'number',
        headerName: 'Number',
        renderCell: ({ row, value }) => (
          <ClickCell salesforceId={row.id} text={value} />
        ),
      },
      {
        flex: 1,
        minWidth: 120,
        field: 'lockerType',
        headerName: 'Locker Type',
      },
      {
        flex: 1,
        minWidth: 120,
        field: 'status',
        headerName: 'Status',
      },
      {
        flex: 1,
        minWidth: 120,
        field: 'filledBy',
        headerName: 'Filled By',
      },
      {
        flex: 1,
        minWidth: 120,
        field: 'market.name',
        headerName: 'Market',
        renderCell: ({ row }) => row.market?.name || '',
      },
      {
        flex: 1,
        minWidth: 120,
        field: 'dateOccupied',
        headerName: 'Occupied Date',
        type: 'date',
        renderCell: ({ row }) => formatNonUtcDate(row.dateOccupied) || '',
        valueGetter: (value, row) => {
          if (row.dateOccupied) {
            return new Date(row.dateOccupied);
          }
          return undefined;
        },
      },
      {
        flex: 1,
        minWidth: 200,
        field: 'contentsFor',
        headerName: 'Contents For',
      },
      {
        flex: 1,
        minWidth: 200,
        field: 'contents',
        headerName: 'Contents',
      },
      {
        flex: 1,
        minWidth: 120,
        field: 'adminCode',
        headerName: 'Admin Code',
      },
      {
        flex: 1,
        minWidth: 120,
        field: 'accessCode',
        headerName: 'Code',
      },
      {
        flex: 1,
        minWidth: 120,
        field: 'dateLastChange',
        headerName: 'Last Changed Date',
        type: 'date',
        renderCell: ({ row }) =>
          formatDate(row.dateLastChange, 'MM/DD/YYYY h:mma') || '',
        valueGetter: (value, row) => {
          if (row.dateLastChange) {
            return new Date(row.dateLastChange);
          }
          return undefined;
        },
      },
      {
        flex: 1,
        minWidth: 120,
        field: 'dateExpectedRetrieval',
        headerName: 'Expected Retrieval Date',
        type: 'date',
        renderCell: ({ row }) =>
          formatNonUtcDate(row.dateExpectedRetrieval) || '',
        valueGetter: (value, row) => {
          if (row.dateExpectedRetrieval) {
            return new Date(row.dateExpectedRetrieval);
          }
          return undefined;
        },
      },
      {
        flex: 1,
        minWidth: 120,
        field: 'dateRetrieved',
        headerName: 'Retrieved Date',
        type: 'date',
        renderCell: ({ row }) =>
          formatDate(row.dateRetrieved, 'MM/DD/YYYY h:mma') || '',
        valueGetter: (value, row) => {
          if (row.dateRetrieved) {
            return new Date(row.dateRetrieved);
          }
          return undefined;
        },
      },
      /* eslint-enable react/display-name, react/prop-types */
    ],
    [onOpenSidebarIframe],
  );

  const onRowClick = useCallback(
    ({ row }, e) => {
      if (!isLoading && !['A', 'svg', 'path'].includes(e.target.nodeName)) {
        onOpenSidebarIframe(`/communication/${row.id}`);
      }
    },
    [isLoading],
  );
  const toggleLockerLock = () => {
    setState({
      isLockerLogOpen: !state.isLockerLogOpen,
      numberOfUnreadLogs: 0,
    });
    onLoadLockerHistoryLog();
  };

  const onSelectChange = useCallback(
    ({ target }) => setState({ [target.name]: target.value }),
    [],
  );

  return (
    <>
      <HeaderSearch isLoading={isLoading} onSearch={onSearch}>
        <ButtonsAndFilter>
          <StyledFilterWrapper>
            <CustomSelect
              native
              name="marketId"
              items={markets}
              placeholder="Market"
              value={state.marketId}
              disabled={isLoading}
              onChange={onSelectChange}
            />
          </StyledFilterWrapper>
          <Button variant="outlined" color="primary" onClick={toggleLockerLock}>
            <Badge badgeContent={state.numberOfUnreadLogs} color="secondary">
              Locker Log
            </Badge>
          </Button>

          <Button
            variant="outlined"
            color="primary"
            to={`/process-automation?automationType=${AutomationType.KEY_CHECK_OUT}`}
            component={Link}
          >
            Key Check Out
          </Button>

          <Button
            variant="outlined"
            color="primary"
            to={`/process-automation?automationType=${AutomationType.LOCKER_RESERVATION}`}
            component={Link}
          >
            Locker Reservation
          </Button>

          <Button
            variant="contained"
            color="primary"
            disabled={isLoading}
            onClick={onReleaseLockers}
          >
            Release Lockers
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={onOpenSmsManager}
          >
            Open SMS Manager
          </Button>
        </ButtonsAndFilter>
      </HeaderSearch>

      <Box
        sx={{
          height: 'calc(100vh - 208px)',
          minHeight: 400,
          mt: 2,
        }}
      >
        <StyledDataGrid
          sx={{
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },
          }}
          disableRowSelectionOnClick
          loading={isLoading}
          rows={filtered}
          columns={columns}
          initialState={initialState}
          onRowClick={onRowClick}
        />
      </Box>
      <CustomDrawer isOpen={state.isLockerLogOpen} onClose={toggleLockerLock}>
        <LockerLog
          isLoading={isLoadingLockerHistoryLog}
          data={lockerLog}
          startDate={startDate}
          endDate={endDate}
          onSelectDateRange={onSelectDateRange}
        />
      </CustomDrawer>
    </>
  );
};

LockersTable.propTypes = {
  data: array,
  isLoading: bool,
  lockerLog: array,
  onReleaseLockers: func.isRequired,
  onOpenSidebarIframe: func.isRequired,
  onLoadLockerHistoryLog: func.isRequired,
  unreadLogs: array,
  isLoadingLockerHistoryLog: bool,
  startDate: string,
  endDate: string,
  onSelectDateRange: func.isRequired,
  markets: array,
};

export default memo(LockersTable);
