/**
 * Make the proper index for the element
 */
export const makeIndex = (str = '') => {
  if (!str) {
    return str;
  }
  return str.toLowerCase().trim().replace(/ /g, '-');
};

/**
 * Make the label for tab and count items
 */
export const makeTabLabel = ({ groupedData, label }) =>
  `${label} (${groupedData?.[label]?.length || 0})`;

/**
 * Prepare parameters for tab
 */
export const makeAttributes = ({ status, groupedData }) => {
  const index = makeIndex(status);
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
    value: index,
    label: makeTabLabel({
      groupedData,
      label: status,
    }),
  };
};
