import React, { memo, useCallback } from 'react';
import Moment from 'moment';
import { string, func } from 'prop-types';
import {
  Grid2 as Grid,
  Button,
  List,
  ListItemButton,
  Typography,
  ListItemText,
} from '@mui/material';
import DatePicker from 'components/DatePicker';
import { useMergeState } from 'helpers/state';

const DateRangePicker = ({
  startDate: defaultStartDate = '',
  endDate: defaultEndDate = '',
  format = 'MM/DD/YYYY',
  onSubmit,
}) => {
  // init state
  const [state, setState] = useMergeState({
    startDate: defaultStartDate || Moment().startOf('month').format(format),
    endDate: defaultEndDate || Moment().endOf('month').format(format),
  });

  const onDateChangeStart = useCallback(
    (name, value) => {
      let startDate = Moment(value, format);
      let endDate = Moment(state.endDate, format);

      // check for proper date period
      if (endDate && startDate > endDate) {
        // swap dates
        [startDate, endDate] = [endDate, startDate];
      }

      setState({
        startDate: startDate.format(format),
        endDate: endDate.format(format),
      });
    },
    [state.endDate],
  );

  const onDateChangeEnd = useCallback(
    (name, value) => {
      let startDate = Moment(state.startDate, format);
      let endDate = Moment(value, format);

      // check for proper date period
      if (startDate && endDate < startDate) {
        // swap dates
        [startDate, endDate] = [endDate, startDate];
      }

      setState({
        startDate: startDate.format(format),
        endDate: endDate.format(format),
      });
    },
    [state.startDate],
  );

  const onSubmitForm = useCallback(
    (e) => {
      e.preventDefault();
      onSubmit({
        startDate: state.startDate,
        endDate: state.endDate,
      });
    },
    [state.startDate, state.endDate],
  );

  // consider these selectors
  // https://help.salesforce.com/articleView?id=filter_dates_relative.htm&type=5
  const onItemSelected = useCallback(
    (selector) => () => {
      // default today
      let startDate = Moment();
      let endDate = Moment();

      // calculate start and end dates
      switch (selector) {
        case 'yesterday':
          startDate = Moment().subtract(1, 'days');
          endDate = Moment().subtract(1, 'days');
          break;

        case 'this-week':
          startDate = Moment().startOf('isoWeek');
          endDate = Moment().endOf('isoWeek');
          break;

        case 'this-month':
          startDate = Moment().startOf('month');
          endDate = Moment().endOf('month');
          break;

        case 'last-month':
          startDate = Moment().subtract(1, 'month').startOf('month');
          endDate = Moment().subtract(1, 'month').endOf('month');
          break;

        case 'this-year':
          startDate = Moment().startOf('year');
          endDate = Moment().endOf('year');
          break;

        case 'last-6-months':
          startDate = Moment().subtract(6, 'months');
          break;

        default:
      }

      onSubmit({
        startDate: startDate.format(format),
        endDate: endDate.format(format),
      });
    },
    [],
  );

  return (
    <form onSubmit={onSubmitForm}>
      <Grid container spacing={1}>
        <Grid size={{ xs: 6 }}>
          <Typography variant="subtitle2">Ranges</Typography>
          <List component="nav" dense sx={{ pb: 0 }}>
            <ListItemButton onClick={onItemSelected('today')}>
              <ListItemText primary="Today" />
            </ListItemButton>
            <ListItemButton onClick={onItemSelected('yesterday')}>
              <ListItemText primary="Yesterday" />
            </ListItemButton>
            <ListItemButton onClick={onItemSelected('this-week')}>
              <ListItemText primary="This Week" />
            </ListItemButton>
            <ListItemButton onClick={onItemSelected('this-month')}>
              <ListItemText primary="This Month" />
            </ListItemButton>
            <ListItemButton onClick={onItemSelected('last-month')}>
              <ListItemText primary="Last Month" />
            </ListItemButton>
            <ListItemButton onClick={onItemSelected('this-year')}>
              <ListItemText primary="This Year" />
            </ListItemButton>
            <ListItemButton onClick={onItemSelected('last-6-months')}>
              <ListItemText primary="Last 6 Months" />
            </ListItemButton>
          </List>
        </Grid>
        <Grid size={{ xs: 6 }}>
          <Typography variant="subtitle2">Custom</Typography>
          <DatePicker
            required
            format={format}
            label="From"
            name="startDate"
            value={state.startDate}
            onChange={onDateChangeStart}
          />
          <DatePicker
            required
            format={format}
            label="To"
            name="endDate"
            value={state.endDate}
            onChange={onDateChangeEnd}
          />
          <Button
            sx={{ mt: 1 }}
            color="primary"
            variant="contained"
            type="submit"
            fullWidth
          >
            Load
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

DateRangePicker.propTypes = {
  format: string,
  endDate: string,
  startDate: string,
  onSubmit: func.isRequired,
};

export default memo(DateRangePicker);
